@charset "UTF-8";
/*
 Fonts
 */
/*!
 * Bootstrap v4.6.2 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,700;1,300;1,700&display=swap");
@import "dhtmlxscheduler_material.css";
:root {
  --blue: #2196f3;
  --indigo: #3f51b5;
  --purple: #9c27b0;
  --pink: #e91e63;
  --red: #f44336;
  --orange: #ff9800;
  --yellow: #ffeb3b;
  --green: #4caf50;
  --teal: #009688;
  --cyan: #00bcd4;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #1976D2;
  --secondary: #26A69A;
  --success: #4caf50;
  --info: #31CCEC;
  --warning: #F2C037;
  --danger: #f44336;
  --light: #f8f9fa;
  --dark: #1D1D1D;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #1976D2;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #11508e;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 0.875em;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e91e63;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}

.col-2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.col-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}

.col-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}

.col-11 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333333%;
  }

  .offset-sm-2 {
    margin-left: 16.66666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333333%;
  }

  .offset-sm-5 {
    margin-left: 41.66666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333333%;
  }

  .offset-sm-8 {
    margin-left: 66.66666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333333%;
  }

  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-md-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-md-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-md-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333333%;
  }

  .offset-md-2 {
    margin-left: 16.66666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333333%;
  }

  .offset-md-5 {
    margin-left: 41.66666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333333%;
  }

  .offset-md-8 {
    margin-left: 66.66666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333333%;
  }

  .offset-md-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333333%;
  }

  .offset-lg-2 {
    margin-left: 16.66666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333333%;
  }

  .offset-lg-5 {
    margin-left: 41.66666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333333%;
  }

  .offset-lg-8 {
    margin-left: 66.66666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333333%;
  }

  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid rgba(0, 0, 0, 0.12);
}
.table tbody + tbody {
  border-top: 2px solid rgba(0, 0, 0, 0.12);
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.table-bordered th,
.table-bordered td {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #bfd9f2;
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #87b8e8;
}

.table-hover .table-primary:hover {
  background-color: #aaccee;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #aaccee;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #c2e6e3;
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #8ed1ca;
}

.table-hover .table-secondary:hover {
  background-color: #b0dfdb;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #b0dfdb;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #cde9ce;
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #a2d5a4;
}

.table-hover .table-success:hover {
  background-color: #bbe1bd;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #bbe1bd;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #c5f1fa;
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #94e4f5;
}

.table-hover .table-info:hover {
  background-color: #aeebf8;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #aeebf8;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fbedc7;
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #f8de97;
}

.table-hover .table-warning:hover {
  background-color: #f9e5af;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #f9e5af;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #fccac7;
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #f99d96;
}

.table-hover .table-danger:hover {
  background-color: #fbb3af;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #fbb3af;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: silver;
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #898989;
}

.table-hover .table-dark:hover {
  background-color: #b3b3b3;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b3b3b3;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: rgba(255, 255, 255, 0.28);
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: rgba(0, 0, 0, 0.12);
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: rgba(255, 255, 255, 0.28);
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #7bb6ef;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(25, 118, 210, 0.25);
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
  appearance: none;
}

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #4caf50;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(76, 175, 80, 0.9);
  border-radius: 4px;
}
.form-row > .col > .valid-tooltip, .form-row > [class*=col-] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #4caf50;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%234caf50' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #4caf50;
  box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.25);
}

.was-validated select.form-control:valid, select.form-control.is-valid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #4caf50;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%234caf50' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #4caf50;
  box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #4caf50;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #4caf50;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #4caf50;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #6ec071;
  background-color: #6ec071;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #4caf50;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #4caf50;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #4caf50;
  box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #f44336;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(244, 67, 54, 0.9);
  border-radius: 4px;
}
.form-row > .col > .invalid-tooltip, .form-row > [class*=col-] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #f44336;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23f44336' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23f44336' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #f44336;
  box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.25);
}

.was-validated select.form-control:invalid, select.form-control.is-invalid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #f44336;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23f44336' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23f44336' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #f44336;
  box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #f44336;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #f44336;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #f44336;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #f77066;
  background-color: #f77066;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #f44336;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #f44336;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #f44336;
  box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
.form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #212529;
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(25, 118, 210, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #1976D2;
  border-color: #1976D2;
}
.btn-primary:hover {
  color: #fff;
  background-color: #1563b0;
  border-color: #145ca4;
}
.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #1563b0;
  border-color: #145ca4;
  box-shadow: 0 0 0 0.2rem rgba(60, 139, 217, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #1976D2;
  border-color: #1976D2;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .contact-list-sidebar-wrapper .btn-primary.router-link-exact-active:not(:disabled):not(.disabled), .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #145ca4;
  border-color: #125699;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .contact-list-sidebar-wrapper .btn-primary.router-link-exact-active:not(:disabled):not(.disabled):focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(60, 139, 217, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #26A69A;
  border-color: #26A69A;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #1f877d;
  border-color: #1d7d74;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #1f877d;
  border-color: #1d7d74;
  box-shadow: 0 0 0 0.2rem rgba(71, 179, 169, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #26A69A;
  border-color: #26A69A;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .contact-list-sidebar-wrapper .btn-secondary.router-link-exact-active:not(:disabled):not(.disabled), .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #1d7d74;
  border-color: #1a726a;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .contact-list-sidebar-wrapper .btn-secondary.router-link-exact-active:not(:disabled):not(.disabled):focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(71, 179, 169, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #4caf50;
  border-color: #4caf50;
}
.btn-success:hover {
  color: #fff;
  background-color: #409444;
  border-color: #3d8b40;
}
.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #409444;
  border-color: #3d8b40;
  box-shadow: 0 0 0 0.2rem rgba(103, 187, 106, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #4caf50;
  border-color: #4caf50;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .contact-list-sidebar-wrapper .btn-success.router-link-exact-active:not(:disabled):not(.disabled), .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #3d8b40;
  border-color: #39833c;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .contact-list-sidebar-wrapper .btn-success.router-link-exact-active:not(:disabled):not(.disabled):focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(103, 187, 106, 0.5);
}

.btn-info {
  color: #212529;
  background-color: #31CCEC;
  border-color: #31CCEC;
}
.btn-info:hover {
  color: #fff;
  background-color: #15bfe2;
  border-color: #14b5d6;
}
.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #15bfe2;
  border-color: #14b5d6;
  box-shadow: 0 0 0 0.2rem rgba(47, 179, 207, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #212529;
  background-color: #31CCEC;
  border-color: #31CCEC;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .contact-list-sidebar-wrapper .btn-info.router-link-exact-active:not(:disabled):not(.disabled), .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #14b5d6;
  border-color: #13abcb;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .contact-list-sidebar-wrapper .btn-info.router-link-exact-active:not(:disabled):not(.disabled):focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(47, 179, 207, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #F2C037;
  border-color: #F2C037;
}
.btn-warning:hover {
  color: #212529;
  background-color: #f0b513;
  border-color: #e7ad0f;
}
.btn-warning:focus, .btn-warning.focus {
  color: #212529;
  background-color: #f0b513;
  border-color: #e7ad0f;
  box-shadow: 0 0 0 0.2rem rgba(211, 169, 53, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #F2C037;
  border-color: #F2C037;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .contact-list-sidebar-wrapper .btn-warning.router-link-exact-active:not(:disabled):not(.disabled), .show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #e7ad0f;
  border-color: #dba40e;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .contact-list-sidebar-wrapper .btn-warning.router-link-exact-active:not(:disabled):not(.disabled):focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(211, 169, 53, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #f44336;
  border-color: #f44336;
}
.btn-danger:hover {
  color: #fff;
  background-color: #f22112;
  border-color: #ea1c0d;
}
.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #f22112;
  border-color: #ea1c0d;
  box-shadow: 0 0 0 0.2rem rgba(246, 95, 84, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #f44336;
  border-color: #f44336;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .contact-list-sidebar-wrapper .btn-danger.router-link-exact-active:not(:disabled):not(.disabled), .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #ea1c0d;
  border-color: #de1b0c;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .contact-list-sidebar-wrapper .btn-danger.router-link-exact-active:not(:disabled):not(.disabled):focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(246, 95, 84, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .contact-list-sidebar-wrapper .btn-light.router-link-exact-active:not(:disabled):not(.disabled), .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .contact-list-sidebar-wrapper .btn-light.router-link-exact-active:not(:disabled):not(.disabled):focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #1D1D1D;
  border-color: #1D1D1D;
}
.btn-dark:hover {
  color: #fff;
  background-color: #0a0a0a;
  border-color: #040404;
}
.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #0a0a0a;
  border-color: #040404;
  box-shadow: 0 0 0 0.2rem rgba(63, 63, 63, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #1D1D1D;
  border-color: #1D1D1D;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .contact-list-sidebar-wrapper .btn-dark.router-link-exact-active:not(:disabled):not(.disabled), .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #040404;
  border-color: black;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .contact-list-sidebar-wrapper .btn-dark.router-link-exact-active:not(:disabled):not(.disabled):focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(63, 63, 63, 0.5);
}

.btn-outline-primary {
  color: #1976D2;
  border-color: #1976D2;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #1976D2;
  border-color: #1976D2;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(25, 118, 210, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #1976D2;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .contact-list-sidebar-wrapper .btn-outline-primary.router-link-exact-active:not(:disabled):not(.disabled), .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #1976D2;
  border-color: #1976D2;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .contact-list-sidebar-wrapper .btn-outline-primary.router-link-exact-active:not(:disabled):not(.disabled):focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(25, 118, 210, 0.5);
}

.btn-outline-secondary {
  color: #26A69A;
  border-color: #26A69A;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #26A69A;
  border-color: #26A69A;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 166, 154, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #26A69A;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .contact-list-sidebar-wrapper .btn-outline-secondary.router-link-exact-active:not(:disabled):not(.disabled), .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #26A69A;
  border-color: #26A69A;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .contact-list-sidebar-wrapper .btn-outline-secondary.router-link-exact-active:not(:disabled):not(.disabled):focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 166, 154, 0.5);
}

.btn-outline-success {
  color: #4caf50;
  border-color: #4caf50;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #4caf50;
  border-color: #4caf50;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #4caf50;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .contact-list-sidebar-wrapper .btn-outline-success.router-link-exact-active:not(:disabled):not(.disabled), .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #4caf50;
  border-color: #4caf50;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .contact-list-sidebar-wrapper .btn-outline-success.router-link-exact-active:not(:disabled):not(.disabled):focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.5);
}

.btn-outline-info {
  color: #31CCEC;
  border-color: #31CCEC;
}
.btn-outline-info:hover {
  color: #212529;
  background-color: #31CCEC;
  border-color: #31CCEC;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(49, 204, 236, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #31CCEC;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .contact-list-sidebar-wrapper .btn-outline-info.router-link-exact-active:not(:disabled):not(.disabled), .show > .btn-outline-info.dropdown-toggle {
  color: #212529;
  background-color: #31CCEC;
  border-color: #31CCEC;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .contact-list-sidebar-wrapper .btn-outline-info.router-link-exact-active:not(:disabled):not(.disabled):focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(49, 204, 236, 0.5);
}

.btn-outline-warning {
  color: #F2C037;
  border-color: #F2C037;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #F2C037;
  border-color: #F2C037;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(242, 192, 55, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #F2C037;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .contact-list-sidebar-wrapper .btn-outline-warning.router-link-exact-active:not(:disabled):not(.disabled), .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #F2C037;
  border-color: #F2C037;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .contact-list-sidebar-wrapper .btn-outline-warning.router-link-exact-active:not(:disabled):not(.disabled):focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(242, 192, 55, 0.5);
}

.btn-outline-danger {
  color: #f44336;
  border-color: #f44336;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #f44336;
  border-color: #f44336;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #f44336;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .contact-list-sidebar-wrapper .btn-outline-danger.router-link-exact-active:not(:disabled):not(.disabled), .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #f44336;
  border-color: #f44336;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .contact-list-sidebar-wrapper .btn-outline-danger.router-link-exact-active:not(:disabled):not(.disabled):focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .contact-list-sidebar-wrapper .btn-outline-light.router-link-exact-active:not(:disabled):not(.disabled), .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .contact-list-sidebar-wrapper .btn-outline-light.router-link-exact-active:not(:disabled):not(.disabled):focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark, .btn-outline-dark-primary {
  color: #1D1D1D;
  border-color: #1D1D1D;
}
.btn-outline-dark:hover, .btn-outline-dark-primary:hover {
  color: #fff;
  background-color: #1D1D1D;
  border-color: #1D1D1D;
}
.btn-outline-dark:focus, .btn-outline-dark-primary:focus, .btn-outline-dark.focus, .focus.btn-outline-dark-primary {
  box-shadow: 0 0 0 0.2rem rgba(29, 29, 29, 0.5);
}
.btn-outline-dark.disabled, .disabled.btn-outline-dark-primary, .btn-outline-dark:disabled, .btn-outline-dark-primary:disabled {
  color: #1D1D1D;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark-primary:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .btn-outline-dark-primary:not(:disabled):not(.disabled).active, .contact-list-sidebar-wrapper .btn-outline-dark.router-link-exact-active:not(:disabled):not(.disabled), .contact-list-sidebar-wrapper .router-link-exact-active.btn-outline-dark-primary:not(:disabled):not(.disabled), .show > .btn-outline-dark.dropdown-toggle, .show > .dropdown-toggle.btn-outline-dark-primary {
  color: #fff;
  background-color: #1D1D1D;
  border-color: #1D1D1D;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .btn-outline-dark-primary:not(:disabled):not(.disabled).active:focus, .contact-list-sidebar-wrapper .btn-outline-dark.router-link-exact-active:not(:disabled):not(.disabled):focus, .contact-list-sidebar-wrapper .router-link-exact-active.btn-outline-dark-primary:not(:disabled):not(.disabled):focus, .show > .btn-outline-dark.dropdown-toggle:focus, .show > .dropdown-toggle.btn-outline-dark-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(29, 29, 29, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #1976D2;
  text-decoration: none;
}
.btn-link:hover {
  color: #11508e;
  text-decoration: underline;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.width {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.width {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #e9ecef;
}
.dropdown-item.active, .contact-list-sidebar-wrapper .dropdown-item.router-link-exact-active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #1976D2;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active, .contact-list-sidebar-wrapper .btn-group > .btn.router-link-exact-active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active,
.contact-list-sidebar-wrapper .btn-group-vertical > .btn.router-link-exact-active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group:not(.has-validation) > .form-control:not(:last-child),
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label,
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > .form-control:nth-last-child(n+3),
.input-group.has-validation > .custom-select:nth-last-child(n+3),
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label,
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  print-color-adjust: exact;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #1976D2;
  background-color: #1976D2;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(25, 118, 210, 0.25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #7bb6ef;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #a9cff5;
  border-color: #a9cff5;
}
.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: 1px solid #adb5bd;
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50%/50% 50% no-repeat;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #1976D2;
  background-color: #1976D2;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(25, 118, 210, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(25, 118, 210, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(25, 118, 210, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(25, 118, 210, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}
.custom-select:focus {
  border-color: #7bb6ef;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(25, 118, 210, 0.25);
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #7bb6ef;
  box-shadow: 0 0 0 0.2rem rgba(25, 118, 210, 0.25);
}
.custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: 0;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(25, 118, 210, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(25, 118, 210, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(25, 118, 210, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #1976D2;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #a9cff5;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #1976D2;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #a9cff5;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #1976D2;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #a9cff5;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
.custom-file-label,
.custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background-color: transparent;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active, .nav-tabs .contact-list-sidebar-wrapper .nav-link.router-link-exact-active, .contact-list-sidebar-wrapper .nav-tabs .nav-link.router-link-exact-active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active, .nav-pills .contact-list-sidebar-wrapper .nav-link.router-link-exact-active, .contact-list-sidebar-wrapper .nav-pills .nav-link.router-link-exact-active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #1976D2;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active, .contact-list-sidebar-wrapper .tab-content > .router-link-exact-active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50%/100% 100% no-repeat;
}

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg,
.navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg,
.navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg,
.navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg,
.navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg,
.navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg,
.navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg,
.navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg,
.navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .contact-list-sidebar-wrapper .router-link-exact-active > .nav-link,
.contact-list-sidebar-wrapper .navbar-light .navbar-nav .router-link-exact-active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .contact-list-sidebar-wrapper .nav-link.router-link-exact-active,
.contact-list-sidebar-wrapper .navbar-light .navbar-nav .nav-link.router-link-exact-active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .contact-list-sidebar-wrapper .router-link-exact-active > .nav-link,
.contact-list-sidebar-wrapper .navbar-dark .navbar-nav .router-link-exact-active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .contact-list-sidebar-wrapper .nav-link.router-link-exact-active,
.contact-list-sidebar-wrapper .navbar-dark .navbar-nav .nav-link.router-link-exact-active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active, .contact-list-sidebar-wrapper .breadcrumb-item.router-link-exact-active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #1976D2;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: #11508e;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(25, 118, 210, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-item.active .page-link, .contact-list-sidebar-wrapper .page-item.router-link-exact-active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #1976D2;
  border-color: #1976D2;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #1976D2;
}
a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #145ca4;
}
a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(25, 118, 210, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #26A69A;
}
a.badge-secondary:hover, a.badge-secondary:focus {
  color: #fff;
  background-color: #1d7d74;
}
a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 166, 154, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #4caf50;
}
a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: #3d8b40;
}
a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.5);
}

.badge-info {
  color: #212529;
  background-color: #31CCEC;
}
a.badge-info:hover, a.badge-info:focus {
  color: #212529;
  background-color: #14b5d6;
}
a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(49, 204, 236, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #F2C037;
}
a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #e7ad0f;
}
a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(242, 192, 55, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #f44336;
}
a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #ea1c0d;
}
a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}
a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}
a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #1D1D1D;
}
a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #040404;
}
a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(29, 29, 29, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #0d3d6d;
  background-color: #d1e4f6;
  border-color: #bfd9f2;
}
.alert-primary hr {
  border-top-color: #aaccee;
}
.alert-primary .alert-link {
  color: #08243f;
}

.alert-secondary {
  color: #145650;
  background-color: #d4edeb;
  border-color: #c2e6e3;
}
.alert-secondary hr {
  border-top-color: #b0dfdb;
}
.alert-secondary .alert-link {
  color: #0a2d2a;
}

.alert-success {
  color: #285b2a;
  background-color: #dbefdc;
  border-color: #cde9ce;
}
.alert-success hr {
  border-top-color: #bbe1bd;
}
.alert-success .alert-link {
  color: #18381a;
}

.alert-info {
  color: #196a7b;
  background-color: #d6f5fb;
  border-color: #c5f1fa;
}
.alert-info hr {
  border-top-color: #aeebf8;
}
.alert-info .alert-link {
  color: #104551;
}

.alert-warning {
  color: #7e641d;
  background-color: #fcf2d7;
  border-color: #fbedc7;
}
.alert-warning hr {
  border-top-color: #f9e5af;
}
.alert-warning .alert-link {
  color: #554313;
}

.alert-danger {
  color: #7f231c;
  background-color: #fdd9d7;
  border-color: #fccac7;
}
.alert-danger hr {
  border-top-color: #fbb3af;
}
.alert-danger .alert-link {
  color: #551713;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light hr {
  border-top-color: #ececf6;
}
.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #0f0f0f;
  background-color: #d2d2d2;
  border-color: silver;
}
.alert-dark hr {
  border-top-color: #b3b3b3;
}
.alert-dark .alert-link {
  color: black;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #1976D2;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active, .contact-list-sidebar-wrapper .list-group-item.router-link-exact-active {
  z-index: 2;
  color: #fff;
  background-color: #1976D2;
  border-color: #1976D2;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active, .contact-list-sidebar-wrapper .list-group-item + .list-group-item.router-link-exact-active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active, .contact-list-sidebar-wrapper .list-group-horizontal > .list-group-item.router-link-exact-active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active, .contact-list-sidebar-wrapper .list-group-horizontal > .list-group-item + .list-group-item.router-link-exact-active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active, .contact-list-sidebar-wrapper .list-group-horizontal-sm > .list-group-item.router-link-exact-active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active, .contact-list-sidebar-wrapper .list-group-horizontal-sm > .list-group-item + .list-group-item.router-link-exact-active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active, .contact-list-sidebar-wrapper .list-group-horizontal-md > .list-group-item.router-link-exact-active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active, .contact-list-sidebar-wrapper .list-group-horizontal-md > .list-group-item + .list-group-item.router-link-exact-active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active, .contact-list-sidebar-wrapper .list-group-horizontal-lg > .list-group-item.router-link-exact-active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active, .contact-list-sidebar-wrapper .list-group-horizontal-lg > .list-group-item + .list-group-item.router-link-exact-active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active, .contact-list-sidebar-wrapper .list-group-horizontal-xl > .list-group-item.router-link-exact-active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active, .contact-list-sidebar-wrapper .list-group-horizontal-xl > .list-group-item + .list-group-item.router-link-exact-active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #0d3d6d;
  background-color: #bfd9f2;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #0d3d6d;
  background-color: #aaccee;
}
.list-group-item-primary.list-group-item-action.active, .contact-list-sidebar-wrapper .list-group-item-primary.list-group-item-action.router-link-exact-active {
  color: #fff;
  background-color: #0d3d6d;
  border-color: #0d3d6d;
}

.list-group-item-secondary {
  color: #145650;
  background-color: #c2e6e3;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #145650;
  background-color: #b0dfdb;
}
.list-group-item-secondary.list-group-item-action.active, .contact-list-sidebar-wrapper .list-group-item-secondary.list-group-item-action.router-link-exact-active {
  color: #fff;
  background-color: #145650;
  border-color: #145650;
}

.list-group-item-success {
  color: #285b2a;
  background-color: #cde9ce;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #285b2a;
  background-color: #bbe1bd;
}
.list-group-item-success.list-group-item-action.active, .contact-list-sidebar-wrapper .list-group-item-success.list-group-item-action.router-link-exact-active {
  color: #fff;
  background-color: #285b2a;
  border-color: #285b2a;
}

.list-group-item-info {
  color: #196a7b;
  background-color: #c5f1fa;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #196a7b;
  background-color: #aeebf8;
}
.list-group-item-info.list-group-item-action.active, .contact-list-sidebar-wrapper .list-group-item-info.list-group-item-action.router-link-exact-active {
  color: #fff;
  background-color: #196a7b;
  border-color: #196a7b;
}

.list-group-item-warning {
  color: #7e641d;
  background-color: #fbedc7;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #7e641d;
  background-color: #f9e5af;
}
.list-group-item-warning.list-group-item-action.active, .contact-list-sidebar-wrapper .list-group-item-warning.list-group-item-action.router-link-exact-active {
  color: #fff;
  background-color: #7e641d;
  border-color: #7e641d;
}

.list-group-item-danger {
  color: #7f231c;
  background-color: #fccac7;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #7f231c;
  background-color: #fbb3af;
}
.list-group-item-danger.list-group-item-action.active, .contact-list-sidebar-wrapper .list-group-item-danger.list-group-item-action.router-link-exact-active {
  color: #fff;
  background-color: #7f231c;
  border-color: #7f231c;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}
.list-group-item-light.list-group-item-action.active, .contact-list-sidebar-wrapper .list-group-item-light.list-group-item-action.router-link-exact-active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #0f0f0f;
  background-color: silver;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #0f0f0f;
  background-color: #b3b3b3;
}
.list-group-item-dark.list-group-item-action.active, .contact-list-sidebar-wrapper .list-group-item-dark.list-group-item-action.router-link-exact-active {
  color: #fff;
  background-color: #0f0f0f;
  border-color: #0f0f0f;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }

  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=top], .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=top], .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=top], .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=top], .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=top], .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=top], .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=top], .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=top], .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=top] .arrow, .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=top] .arrow, .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=top] .arrow, .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=top] .arrow, .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=top] .arrow, .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=top] .arrow, .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=top] .arrow, .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=top] .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=top] .arrow::before, .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=top] .arrow::before, .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=top] .arrow::before, .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=top] .arrow::before, .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=top] .arrow::before, .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=top] .arrow::before, .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=top] .arrow::before, .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=top] .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=right], .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=right], .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=right], .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=right], .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=right], .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=right], .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=right], .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=right], .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=right] .arrow, .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=right] .arrow, .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=right] .arrow, .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=right] .arrow, .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=right] .arrow, .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=right] .arrow, .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=right] .arrow, .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=right] .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=right] .arrow::before, .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=right] .arrow::before, .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=right] .arrow::before, .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=right] .arrow::before, .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=right] .arrow::before, .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=right] .arrow::before, .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=right] .arrow::before, .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=right] .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=bottom], .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=bottom], .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=bottom], .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=bottom], .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=bottom], .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=bottom], .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=bottom], .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=bottom], .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=bottom] .arrow, .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=bottom] .arrow, .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=bottom] .arrow, .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=bottom] .arrow, .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=bottom] .arrow, .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=bottom] .arrow, .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=bottom] .arrow, .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=bottom] .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=left], .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=left], .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=left], .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=left], .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=left], .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=left], .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=left], .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=left], .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=left] .arrow, .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=left] .arrow, .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=left] .arrow, .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=left] .arrow, .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=left] .arrow, .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=left] .arrow, .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=left] .arrow, .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=left] .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=left] .arrow::before, .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=left] .arrow::before, .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=left] .arrow::before, .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=left] .arrow::before, .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=left] .arrow::before, .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=left] .arrow::before, .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=left] .arrow::before, .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=left] .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fafafa;
  text-align: center;
  background-color: #000;
  border-radius: 4px;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .b-popover-dark.bs-popover-auto[x-placement^=top], .b-popover-light.bs-popover-auto[x-placement^=top], .b-popover-danger.bs-popover-auto[x-placement^=top], .b-popover-warning.bs-popover-auto[x-placement^=top], .b-popover-info.bs-popover-auto[x-placement^=top], .b-popover-success.bs-popover-auto[x-placement^=top], .b-popover-secondary.bs-popover-auto[x-placement^=top], .b-popover-primary.bs-popover-auto[x-placement^=top], .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .b-popover-dark.bs-popover-auto[x-placement^=right], .b-popover-light.bs-popover-auto[x-placement^=right], .b-popover-danger.bs-popover-auto[x-placement^=right], .b-popover-warning.bs-popover-auto[x-placement^=right], .b-popover-info.bs-popover-auto[x-placement^=right], .b-popover-success.bs-popover-auto[x-placement^=right], .b-popover-secondary.bs-popover-auto[x-placement^=right], .b-popover-primary.bs-popover-auto[x-placement^=right], .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^=right] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .b-popover-dark.bs-popover-auto[x-placement^=bottom], .b-popover-light.bs-popover-auto[x-placement^=bottom], .b-popover-danger.bs-popover-auto[x-placement^=bottom], .b-popover-warning.bs-popover-auto[x-placement^=bottom], .b-popover-info.bs-popover-auto[x-placement^=bottom], .b-popover-success.bs-popover-auto[x-placement^=bottom], .b-popover-secondary.bs-popover-auto[x-placement^=bottom], .b-popover-primary.bs-popover-auto[x-placement^=bottom], .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .b-popover-dark.bs-popover-auto[x-placement^=left], .b-popover-light.bs-popover-auto[x-placement^=left], .b-popover-danger.bs-popover-auto[x-placement^=left], .b-popover-warning.bs-popover-auto[x-placement^=left], .b-popover-info.bs-popover-auto[x-placement^=left], .b-popover-success.bs-popover-auto[x-placement^=left], .b-popover-secondary.bs-popover-auto[x-placement^=left], .b-popover-primary.bs-popover-auto[x-placement^=left], .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^=left] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active, .contact-list-sidebar-wrapper .carousel-item.router-link-exact-active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right,
.contact-list-sidebar-wrapper .carousel-item-right.router-link-exact-active {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left,
.contact-list-sidebar-wrapper .carousel-item-left.router-link-exact-active {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active, .carousel-fade .contact-list-sidebar-wrapper .carousel-item.router-link-exact-active, .contact-list-sidebar-wrapper .carousel-fade .carousel-item.router-link-exact-active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left, .carousel-fade .contact-list-sidebar-wrapper .carousel-item-left.router-link-exact-active, .contact-list-sidebar-wrapper .carousel-fade .carousel-item-left.router-link-exact-active,
.carousel-fade .active.carousel-item-right,
.carousel-fade .contact-list-sidebar-wrapper .carousel-item-right.router-link-exact-active,
.contact-list-sidebar-wrapper .carousel-fade .carousel-item-right.router-link-exact-active {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left, .carousel-fade .contact-list-sidebar-wrapper .carousel-item-left.router-link-exact-active, .contact-list-sidebar-wrapper .carousel-fade .carousel-item-left.router-link-exact-active,
.carousel-fade .active.carousel-item-right,
.carousel-fade .contact-list-sidebar-wrapper .carousel-item-right.router-link-exact-active,
.contact-list-sidebar-wrapper .carousel-fade .carousel-item-right.router-link-exact-active {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50%/100% 100% no-repeat;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active, .carousel-indicators .contact-list-sidebar-wrapper .router-link-exact-active, .contact-list-sidebar-wrapper .carousel-indicators .router-link-exact-active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentcolor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentcolor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
.spinner-grow {
    animation-duration: 1.5s;
  }
}
.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #1976D2 !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #145ca4 !important;
}

.bg-secondary {
  background-color: #26A69A !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #1d7d74 !important;
}

.bg-success {
  background-color: #4caf50 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #3d8b40 !important;
}

.bg-info {
  background-color: #31CCEC !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #14b5d6 !important;
}

.bg-warning {
  background-color: #F2C037 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #e7ad0f !important;
}

.bg-danger {
  background-color: #f44336 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #ea1c0d !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #1D1D1D !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #040404 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #1976D2 !important;
}

.border-secondary {
  border-color: #26A69A !important;
}

.border-success {
  border-color: #4caf50 !important;
}

.border-info {
  border-color: #31CCEC !important;
}

.border-warning {
  border-color: #F2C037 !important;
}

.border-danger {
  border-color: #f44336 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #1D1D1D !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start, .search-form .input-group-prepend .input-group-text {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}
.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.w-xs {
  width: 0 !important;
}

.w-sm {
  width: 600px !important;
}

.w-md {
  width: 1024px !important;
}

.w-lg {
  width: 1440px !important;
}

.w-xl {
  width: 1920px !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.h-xs {
  height: 0 !important;
}

.h-sm {
  height: 600px !important;
}

.h-md {
  height: 1024px !important;
}

.h-lg {
  height: 1440px !important;
}

.h-xl {
  height: 1920px !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
.my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
.mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
.my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
.mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
.my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1,
.mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
.my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
.mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
.my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-2,
.mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2,
.my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2,
.mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
.my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
.mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
.my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
.mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
.my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
.mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
.my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
.mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
.my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
.mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
.my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
.mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
.py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
.px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
.py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
.px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
.py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1,
.px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
.py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
.px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
.py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-2,
.px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2,
.py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2,
.px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
.py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
.px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
.py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
.px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
.py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
.px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
.py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
.px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
.py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
.px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
.py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
.px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .mt-sm-n1,
.my-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-sm-n1,
.mx-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-sm-n1,
.my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-sm-n1,
.mx-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .mt-sm-n2,
.my-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-sm-n2,
.mx-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-sm-n2,
.my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-sm-n2,
.mx-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .mt-sm-n3,
.my-sm-n3 {
    margin-top: -1rem !important;
  }

  .mr-sm-n3,
.mx-sm-n3 {
    margin-right: -1rem !important;
  }

  .mb-sm-n3,
.my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n3,
.mx-sm-n3 {
    margin-left: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mt-sm-n4,
.my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n4,
.mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4,
.my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n4,
.mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5,
.my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5,
.mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5,
.my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5,
.mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
.my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
.mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
.my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
.mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
.my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
.mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
.my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
.mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
.my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1,
.mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
.my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
.mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
.my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mr-md-2,
.mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2,
.my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2,
.mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
.my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
.mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
.my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
.mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
.my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
.mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
.my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
.mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
.my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
.mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
.my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
.mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
.py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
.px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
.py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
.px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
.py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1,
.px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
.py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
.px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
.py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pr-md-2,
.px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2,
.py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2,
.px-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
.py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
.px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
.py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
.px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
.py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
.px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
.py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
.px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
.py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
.px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
.py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
.px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .mt-md-n1,
.my-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-md-n1,
.mx-md-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-md-n1,
.my-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-md-n1,
.mx-md-n1 {
    margin-left: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .mt-md-n2,
.my-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-md-n2,
.mx-md-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-md-n2,
.my-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-md-n2,
.mx-md-n2 {
    margin-left: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .mt-md-n3,
.my-md-n3 {
    margin-top: -1rem !important;
  }

  .mr-md-n3,
.mx-md-n3 {
    margin-right: -1rem !important;
  }

  .mb-md-n3,
.my-md-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n3,
.mx-md-n3 {
    margin-left: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mt-md-n4,
.my-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n4,
.mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4,
.my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n4,
.mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5,
.my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5,
.mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5,
.my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5,
.mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
.my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
.mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
.my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
.mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
.my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
.mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
.my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
.mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
.my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
.mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
.my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
.mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
.my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2,
.mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
.my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
.mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
.my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
.mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
.my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
.mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
.my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
.mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
.my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
.mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
.my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
.mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
.my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
.mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
.py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
.px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
.py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
.px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
.py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1,
.px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
.py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
.px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
.py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-2,
.px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2,
.py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2,
.px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
.py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
.px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
.py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
.px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
.py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
.px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
.py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
.px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
.py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
.px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
.py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
.px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .mt-lg-n1,
.my-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-lg-n1,
.mx-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-lg-n1,
.my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-lg-n1,
.mx-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .mt-lg-n2,
.my-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-lg-n2,
.mx-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-lg-n2,
.my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-lg-n2,
.mx-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .mt-lg-n3,
.my-lg-n3 {
    margin-top: -1rem !important;
  }

  .mr-lg-n3,
.mx-lg-n3 {
    margin-right: -1rem !important;
  }

  .mb-lg-n3,
.my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n3,
.mx-lg-n3 {
    margin-left: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mt-lg-n4,
.my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n4,
.mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4,
.my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n4,
.mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5,
.my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5,
.mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5,
.my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5,
.mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
.my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
.mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
.my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
.mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
.my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
.mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
.my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
.mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
.my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1,
.mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
.my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1,
.mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
.my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-2,
.mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2,
.my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-2,
.mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
.my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
.mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
.my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
.mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
.my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
.mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
.my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
.mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
.my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
.mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
.my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
.mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
.py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
.px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
.py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
.px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
.py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1,
.px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
.py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1,
.px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
.py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-2,
.px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2,
.py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-2,
.px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
.py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
.px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
.py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
.px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
.py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
.px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
.py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
.px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
.py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
.px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
.py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
.px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xl-n1,
.my-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xl-n1,
.mx-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xl-n1,
.my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xl-n1,
.mx-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xl-n2,
.my-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xl-n2,
.mx-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xl-n2,
.my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xl-n2,
.mx-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .mt-xl-n3,
.my-xl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xl-n3,
.mx-xl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xl-n3,
.my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n3,
.mx-xl-n3 {
    margin-left: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xl-n4,
.my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n4,
.mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4,
.my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n4,
.mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5,
.my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5,
.mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5,
.my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5,
.mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
.my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
.mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
.my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
.mx-xl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white, .active-call {
  color: #fff !important;
}

.text-primary {
  color: #1976D2 !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #11508e !important;
}

.text-secondary {
  color: #26A69A !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #186860 !important;
}

.text-success {
  color: #4caf50 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #357a38 !important;
}

.text-info {
  color: #31CCEC !important;
}

a.text-info:hover, a.text-info:focus {
  color: #12a1bf !important;
}

.text-warning {
  color: #F2C037 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #cf9b0d !important;
}

.text-danger {
  color: #f44336 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #d2190b !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #1D1D1D !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: black !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
*::before,
*::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }

  tr,
img {
    page-break-inside: avoid;
  }

  p,
h2,
h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }

  .container {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }
  .table td,
.table th {
    background-color: #fff !important;
  }

  .table-bordered th,
.table-bordered td {
    border: 1px solid #dee2e6 !important;
  }

  .table-dark {
    color: inherit;
  }
  .table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
    border-color: rgba(0, 0, 0, 0.12);
  }

  .table .thead-dark th {
    color: inherit;
    border-color: rgba(0, 0, 0, 0.12);
  }
}
.bv-no-focus-ring:focus {
  outline: none;
}

@media (max-width: 575.98px) {
  .bv-d-xs-down-none {
    display: none !important;
  }
}
@media (max-width: 767.98px) {
  .bv-d-sm-down-none {
    display: none !important;
  }
}
@media (max-width: 991.98px) {
  .bv-d-md-down-none {
    display: none !important;
  }
}
@media (max-width: 1199.98px) {
  .bv-d-lg-down-none {
    display: none !important;
  }
}
.bv-d-xl-down-none {
  display: none !important;
}

.form-control.focus {
  color: #495057;
  background-color: #fff;
  border-color: #7bb6ef;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(25, 118, 210, 0.25);
}
.form-control.focus.is-valid {
  border-color: #4caf50;
  box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.25);
}
.form-control.focus.is-invalid {
  border-color: #f44336;
  box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.25);
}

.b-avatar {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  flex-shrink: 0;
  width: 2.5rem;
  height: 2.5rem;
  font-size: inherit;
  font-weight: 400;
  line-height: 1;
  max-width: 100%;
  max-height: auto;
  text-align: center;
  overflow: visible;
  position: relative;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.b-avatar:focus {
  outline: 0;
}
.b-avatar.btn, .b-avatar[href] {
  padding: 0;
  border: 0;
}
.b-avatar.btn .b-avatar-img img, .b-avatar[href] .b-avatar-img img {
  transition: transform 0.15s ease-in-out;
}
.b-avatar.btn:not(:disabled):not(.disabled), .b-avatar[href]:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.b-avatar.btn:not(:disabled):not(.disabled):hover .b-avatar-img img, .b-avatar[href]:not(:disabled):not(.disabled):hover .b-avatar-img img {
  transform: scale(1.15);
}
.b-avatar.disabled, .b-avatar:disabled, .b-avatar[disabled] {
  opacity: 0.65;
  pointer-events: none;
}
.b-avatar .b-avatar-custom,
.b-avatar .b-avatar-text,
.b-avatar .b-avatar-img {
  border-radius: inherit;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  mask-image: radial-gradient(white, black);
}
.b-avatar .b-avatar-text {
  text-transform: uppercase;
  white-space: nowrap;
}
.b-avatar[href] {
  text-decoration: none;
}
.b-avatar > .b-icon {
  width: 60%;
  height: auto;
  max-width: 100%;
}
.b-avatar .b-avatar-img img {
  width: 100%;
  height: 100%;
  max-height: auto;
  border-radius: inherit;
  object-fit: cover;
}
.b-avatar .b-avatar-badge {
  position: absolute;
  min-height: 1.5em;
  min-width: 1.5em;
  padding: 0.25em;
  line-height: 1;
  border-radius: 10em;
  font-size: 70%;
  font-weight: 700;
  z-index: 1;
}

.b-avatar-sm {
  width: 1.5rem;
  height: 1.5rem;
}
.b-avatar-sm .b-avatar-text {
  font-size: calc(0.6rem);
}
.b-avatar-sm .b-avatar-badge {
  font-size: calc(0.42rem);
}

.b-avatar-lg {
  width: 3.5rem;
  height: 3.5rem;
}
.b-avatar-lg .b-avatar-text {
  font-size: calc(1.4rem);
}
.b-avatar-lg .b-avatar-badge {
  font-size: calc(0.98rem);
}

.b-avatar-group .b-avatar-group-inner {
  display: flex;
  flex-wrap: wrap;
}
.b-avatar-group .b-avatar {
  border: 1px solid #dee2e6;
}
.b-avatar-group a.b-avatar:hover:not(.disabled):not(disabled),
.b-avatar-group .btn.b-avatar:hover:not(.disabled):not(disabled) {
  z-index: 1;
}

.b-calendar {
  display: inline-flex;
}
.b-calendar .b-calendar-inner {
  min-width: 250px;
}
.b-calendar .b-calendar-header,
.b-calendar .b-calendar-nav {
  margin-bottom: 0.25rem;
}
.b-calendar .b-calendar-nav .btn {
  padding: 0.25rem;
}
.b-calendar output {
  padding: 0.25rem;
  font-size: 80%;
}
.b-calendar output.readonly {
  background-color: #e9ecef;
  opacity: 1;
}
.b-calendar .b-calendar-footer {
  margin-top: 0.5rem;
}
.b-calendar .b-calendar-grid {
  padding: 0;
  margin: 0;
  overflow: hidden;
}
.b-calendar .b-calendar-grid .row {
  flex-wrap: nowrap;
}
.b-calendar .b-calendar-grid-caption {
  padding: 0.25rem;
}
.b-calendar .b-calendar-grid-body .col[data-date] .btn {
  width: 32px;
  height: 32px;
  font-size: 14px;
  line-height: 1;
  margin: 3px auto;
  padding: 9px 0;
}
.b-calendar .btn:disabled, .b-calendar .btn.disabled, .b-calendar .btn[aria-disabled=true] {
  cursor: default;
  pointer-events: none;
}

.card-img-left {
  border-top-left-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-img-right {
  border-top-right-radius: calc(0.25rem - 1px);
  border-bottom-right-radius: calc(0.25rem - 1px);
}

.dropdown:not(.dropleft) .dropdown-toggle.dropdown-toggle-no-caret::after {
  display: none !important;
}
.dropdown.dropleft .dropdown-toggle.dropdown-toggle-no-caret::before {
  display: none !important;
}
.dropdown .dropdown-menu:focus {
  outline: none;
}

.b-dropdown-form {
  display: inline-block;
  padding: 0.25rem 1.5rem;
  width: 100%;
  clear: both;
  font-weight: 400;
}
.b-dropdown-form:focus {
  outline: 1px dotted !important;
  outline: 5px auto -webkit-focus-ring-color !important;
}
.b-dropdown-form.disabled, .b-dropdown-form:disabled {
  outline: 0 !important;
  color: #adb5bd;
  pointer-events: none;
}

.b-dropdown-text {
  display: inline-block;
  padding: 0.25rem 1.5rem;
  margin-bottom: 0;
  width: 100%;
  clear: both;
  font-weight: lighter;
}

.custom-checkbox.b-custom-control-lg,
.input-group-lg .custom-checkbox {
  font-size: 1.25rem;
  line-height: 1.5;
  padding-left: 1.875rem;
}
.custom-checkbox.b-custom-control-lg .custom-control-label::before,
.input-group-lg .custom-checkbox .custom-control-label::before {
  top: 0.3125rem;
  left: -1.875rem;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 0.3rem;
}
.custom-checkbox.b-custom-control-lg .custom-control-label::after,
.input-group-lg .custom-checkbox .custom-control-label::after {
  top: 0.3125rem;
  left: -1.875rem;
  width: 1.25rem;
  height: 1.25rem;
  background-size: 50% 50%;
}

.custom-checkbox.b-custom-control-sm,
.input-group-sm .custom-checkbox {
  font-size: 0.875rem;
  line-height: 1.5;
  padding-left: 1.3125rem;
}
.custom-checkbox.b-custom-control-sm .custom-control-label::before,
.input-group-sm .custom-checkbox .custom-control-label::before {
  top: 0.21875rem;
  left: -1.3125rem;
  width: 0.875rem;
  height: 0.875rem;
  border-radius: 0.2rem;
}
.custom-checkbox.b-custom-control-sm .custom-control-label::after,
.input-group-sm .custom-checkbox .custom-control-label::after {
  top: 0.21875rem;
  left: -1.3125rem;
  width: 0.875rem;
  height: 0.875rem;
  background-size: 50% 50%;
}

.custom-switch.b-custom-control-lg,
.input-group-lg .custom-switch {
  padding-left: 2.8125rem;
}
.custom-switch.b-custom-control-lg .custom-control-label,
.input-group-lg .custom-switch .custom-control-label {
  font-size: 1.25rem;
  line-height: 1.5;
}
.custom-switch.b-custom-control-lg .custom-control-label::before,
.input-group-lg .custom-switch .custom-control-label::before {
  top: 0.3125rem;
  height: 1.25rem;
  left: -2.8125rem;
  width: 2.1875rem;
  border-radius: 0.625rem;
}
.custom-switch.b-custom-control-lg .custom-control-label::after,
.input-group-lg .custom-switch .custom-control-label::after {
  top: calc( 0.3125rem + 2px );
  left: calc( -2.8125rem + 2px );
  width: calc( 1.25rem - 4px );
  height: calc( 1.25rem - 4px );
  border-radius: 0.625rem;
  background-size: 50% 50%;
}
.custom-switch.b-custom-control-lg .custom-control-input:checked ~ .custom-control-label::after,
.input-group-lg .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  transform: translateX(0.9375rem);
}

.custom-switch.b-custom-control-sm,
.input-group-sm .custom-switch {
  padding-left: 1.96875rem;
}
.custom-switch.b-custom-control-sm .custom-control-label,
.input-group-sm .custom-switch .custom-control-label {
  font-size: 0.875rem;
  line-height: 1.5;
}
.custom-switch.b-custom-control-sm .custom-control-label::before,
.input-group-sm .custom-switch .custom-control-label::before {
  top: 0.21875rem;
  left: -1.96875rem;
  width: 1.53125rem;
  height: 0.875rem;
  border-radius: 0.4375rem;
}
.custom-switch.b-custom-control-sm .custom-control-label::after,
.input-group-sm .custom-switch .custom-control-label::after {
  top: calc( 0.21875rem + 2px );
  left: calc( -1.96875rem + 2px );
  width: calc( 0.875rem - 4px );
  height: calc( 0.875rem - 4px );
  border-radius: 0.4375rem;
  background-size: 50% 50%;
}
.custom-switch.b-custom-control-sm .custom-control-input:checked ~ .custom-control-label::after,
.input-group-sm .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  transform: translateX(0.65625rem);
}

.input-group > .input-group-prepend > .btn-group > .btn,
.input-group > .input-group-append:not(:last-child) > .btn-group > .btn,
.input-group > .input-group-append:last-child > .btn-group:not(:last-child):not(.dropdown-toggle) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .input-group-append > .btn-group > .btn,
.input-group > .input-group-prepend:not(:first-child) > .btn-group > .btn,
.input-group > .input-group-prepend:first-child > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.b-form-btn-label-control.form-control {
  display: flex;
  align-items: stretch;
  height: auto;
  padding: 0;
  background-image: none;
}
.input-group .b-form-btn-label-control.form-control {
  padding: 0;
}

[dir=rtl] .b-form-btn-label-control.form-control, .b-form-btn-label-control.form-control[dir=rtl] {
  flex-direction: row-reverse;
}
[dir=rtl] .b-form-btn-label-control.form-control > label, .b-form-btn-label-control.form-control[dir=rtl] > label {
  text-align: right;
}

.b-form-btn-label-control.form-control > .btn {
  line-height: 1;
  font-size: inherit;
  box-shadow: none !important;
  border: 0;
}
.b-form-btn-label-control.form-control > .btn:disabled {
  pointer-events: none;
}
.b-form-btn-label-control.form-control.is-valid > .btn {
  color: #4caf50;
}
.b-form-btn-label-control.form-control.is-invalid > .btn {
  color: #f44336;
}
.b-form-btn-label-control.form-control > .dropdown-menu {
  padding: 0.5rem;
}
.b-form-btn-label-control.form-control > .form-control {
  height: auto;
  min-height: calc(calc(1.5em + 0.75rem + 2px) - 2px);
  padding-left: 0.25rem;
  margin: 0;
  border: 0;
  outline: 0;
  background: transparent;
  word-break: break-word;
  font-size: inherit;
  white-space: normal;
  cursor: pointer;
}
.b-form-btn-label-control.form-control > .form-control.form-control-sm {
  min-height: calc(calc(1.5em + 0.5rem + 2px) - 2px);
}
.b-form-btn-label-control.form-control > .form-control.form-control-lg {
  min-height: calc(calc(1.5em + 1rem + 2px) - 2px);
}
.input-group.input-group-sm .b-form-btn-label-control.form-control > .form-control {
  min-height: calc(calc(1.5em + 0.5rem + 2px) - 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.input-group.input-group-lg .b-form-btn-label-control.form-control > .form-control {
  min-height: calc(calc(1.5em + 1rem + 2px) - 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.b-form-btn-label-control.form-control[aria-disabled=true], .b-form-btn-label-control.form-control[aria-readonly=true] {
  background-color: #e9ecef;
  opacity: 1;
}
.b-form-btn-label-control.form-control[aria-disabled=true] {
  pointer-events: none;
}
.b-form-btn-label-control.form-control[aria-disabled=true] > label {
  cursor: default;
}

.b-form-btn-label-control.btn-group > .dropdown-menu {
  padding: 0.5rem;
}

.custom-file-label {
  white-space: nowrap;
  overflow-x: hidden;
}

.b-custom-control-lg.custom-file,
.b-custom-control-lg .custom-file-input,
.b-custom-control-lg .custom-file-label,
.input-group-lg.custom-file,
.input-group-lg .custom-file-input,
.input-group-lg .custom-file-label {
  font-size: 1.25rem;
  height: calc(1.5em + 1rem + 2px);
}
.b-custom-control-lg .custom-file-label,
.b-custom-control-lg .custom-file-label:after,
.input-group-lg .custom-file-label,
.input-group-lg .custom-file-label:after {
  padding: 0.5rem 1rem;
  line-height: 1.5;
}
.b-custom-control-lg .custom-file-label,
.input-group-lg .custom-file-label {
  border-radius: 0.3rem;
}
.b-custom-control-lg .custom-file-label::after,
.input-group-lg .custom-file-label::after {
  font-size: inherit;
  height: calc( 1.5em + 1rem );
  border-radius: 0 0.3rem 0.3rem 0;
}

.b-custom-control-sm.custom-file,
.b-custom-control-sm .custom-file-input,
.b-custom-control-sm .custom-file-label,
.input-group-sm.custom-file,
.input-group-sm .custom-file-input,
.input-group-sm .custom-file-label {
  font-size: 0.875rem;
  height: calc(1.5em + 0.5rem + 2px);
}
.b-custom-control-sm .custom-file-label,
.b-custom-control-sm .custom-file-label:after,
.input-group-sm .custom-file-label,
.input-group-sm .custom-file-label:after {
  padding: 0.25rem 0.5rem;
  line-height: 1.5;
}
.b-custom-control-sm .custom-file-label,
.input-group-sm .custom-file-label {
  border-radius: 0.2rem;
}
.b-custom-control-sm .custom-file-label::after,
.input-group-sm .custom-file-label::after {
  font-size: inherit;
  height: calc( 1.5em + 0.5rem );
  border-radius: 0 0.2rem 0.2rem 0;
}

.was-validated .form-control:invalid, .was-validated .form-control:valid, .form-control.is-invalid, .form-control.is-valid {
  background-position: right calc(0.375em + 0.1875rem) center;
}

input[type=color].form-control {
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.125rem 0.25rem;
}

input[type=color].form-control.form-control-sm,
.input-group-sm input[type=color].form-control {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.125rem 0.25rem;
}

input[type=color].form-control.form-control-lg,
.input-group-lg input[type=color].form-control {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.125rem 0.25rem;
}

input[type=color].form-control:disabled {
  background-color: #adb5bd;
  opacity: 0.65;
}

.input-group > .custom-range {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}
.input-group > .custom-range + .form-control,
.input-group > .custom-range + .form-control-plaintext,
.input-group > .custom-range + .custom-select,
.input-group > .custom-range + .custom-range,
.input-group > .custom-range + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control + .custom-range,
.input-group > .form-control-plaintext + .custom-range,
.input-group > .custom-select + .custom-range,
.input-group > .custom-range + .custom-range,
.input-group > .custom-file + .custom-range {
  margin-left: -1px;
}
.input-group > .custom-range:focus {
  z-index: 3;
}
.input-group > .custom-range:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-range:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-range {
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0 0.75rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  height: calc(1.5em + 0.75rem + 2px);
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .input-group > .custom-range {
    transition: none;
  }
}
.input-group > .custom-range:focus {
  color: #495057;
  background-color: #fff;
  border-color: #7bb6ef;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(25, 118, 210, 0.25);
}
.input-group > .custom-range:disabled, .input-group > .custom-range[readonly] {
  background-color: #e9ecef;
}

.input-group-lg > .custom-range {
  height: calc(1.5em + 1rem + 2px);
  padding: 0 1rem;
  border-radius: 0.3rem;
}

.input-group-sm > .custom-range {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0 0.5rem;
  border-radius: 0.2rem;
}

.was-validated .input-group .custom-range:valid, .input-group .custom-range.is-valid {
  border-color: #4caf50;
}
.was-validated .input-group .custom-range:valid:focus, .input-group .custom-range.is-valid:focus {
  border-color: #4caf50;
  box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.25);
}

.was-validated .custom-range:valid:focus::-webkit-slider-thumb, .custom-range.is-valid:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem #c7e7c8;
}
.was-validated .custom-range:valid:focus::-moz-range-thumb, .custom-range.is-valid:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem #c7e7c8;
}
.was-validated .custom-range:valid:focus::-ms-thumb, .custom-range.is-valid:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem #c7e7c8;
}
.was-validated .custom-range:valid::-webkit-slider-thumb, .custom-range.is-valid::-webkit-slider-thumb {
  background-color: #4caf50;
  background-image: none;
}
.was-validated .custom-range:valid::-webkit-slider-thumb:active, .custom-range.is-valid::-webkit-slider-thumb:active {
  background-color: #c7e7c8;
  background-image: none;
}
.was-validated .custom-range:valid::-webkit-slider-runnable-track, .custom-range.is-valid::-webkit-slider-runnable-track {
  background-color: rgba(76, 175, 80, 0.35);
}
.was-validated .custom-range:valid::-moz-range-thumb, .custom-range.is-valid::-moz-range-thumb {
  background-color: #4caf50;
  background-image: none;
}
.was-validated .custom-range:valid::-moz-range-thumb:active, .custom-range.is-valid::-moz-range-thumb:active {
  background-color: #c7e7c8;
  background-image: none;
}
.was-validated .custom-range:valid::-moz-range-track, .custom-range.is-valid::-moz-range-track {
  background: rgba(76, 175, 80, 0.35);
}
.was-validated .custom-range:valid ~ .valid-feedback,
.was-validated .custom-range:valid ~ .valid-tooltip, .custom-range.is-valid ~ .valid-feedback,
.custom-range.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .custom-range:valid::-ms-thumb, .custom-range.is-valid::-ms-thumb {
  background-color: #4caf50;
  background-image: none;
}
.was-validated .custom-range:valid::-ms-thumb:active, .custom-range.is-valid::-ms-thumb:active {
  background-color: #c7e7c8;
  background-image: none;
}
.was-validated .custom-range:valid::-ms-track-lower, .custom-range.is-valid::-ms-track-lower {
  background: rgba(76, 175, 80, 0.35);
}
.was-validated .custom-range:valid::-ms-track-upper, .custom-range.is-valid::-ms-track-upper {
  background: rgba(76, 175, 80, 0.35);
}

.was-validated .input-group .custom-range:invalid, .input-group .custom-range.is-invalid {
  border-color: #f44336;
}
.was-validated .input-group .custom-range:invalid:focus, .input-group .custom-range.is-invalid:focus {
  border-color: #f44336;
  box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.25);
}

.was-validated .custom-range:invalid:focus::-webkit-slider-thumb, .custom-range.is-invalid:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem #fde1df;
}
.was-validated .custom-range:invalid:focus::-moz-range-thumb, .custom-range.is-invalid:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem #fde1df;
}
.was-validated .custom-range:invalid:focus::-ms-thumb, .custom-range.is-invalid:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem #fde1df;
}
.was-validated .custom-range:invalid::-webkit-slider-thumb, .custom-range.is-invalid::-webkit-slider-thumb {
  background-color: #f44336;
  background-image: none;
}
.was-validated .custom-range:invalid::-webkit-slider-thumb:active, .custom-range.is-invalid::-webkit-slider-thumb:active {
  background-color: #fde1df;
  background-image: none;
}
.was-validated .custom-range:invalid::-webkit-slider-runnable-track, .custom-range.is-invalid::-webkit-slider-runnable-track {
  background-color: rgba(244, 67, 54, 0.35);
}
.was-validated .custom-range:invalid::-moz-range-thumb, .custom-range.is-invalid::-moz-range-thumb {
  background-color: #f44336;
  background-image: none;
}
.was-validated .custom-range:invalid::-moz-range-thumb:active, .custom-range.is-invalid::-moz-range-thumb:active {
  background-color: #fde1df;
  background-image: none;
}
.was-validated .custom-range:invalid::-moz-range-track, .custom-range.is-invalid::-moz-range-track {
  background: rgba(244, 67, 54, 0.35);
}
.was-validated .custom-range:invalid ~ .invalid-feedback,
.was-validated .custom-range:invalid ~ .invalid-tooltip, .custom-range.is-invalid ~ .invalid-feedback,
.custom-range.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .custom-range:invalid::-ms-thumb, .custom-range.is-invalid::-ms-thumb {
  background-color: #f44336;
  background-image: none;
}
.was-validated .custom-range:invalid::-ms-thumb:active, .custom-range.is-invalid::-ms-thumb:active {
  background-color: #fde1df;
  background-image: none;
}
.was-validated .custom-range:invalid::-ms-track-lower, .custom-range.is-invalid::-ms-track-lower {
  background: rgba(244, 67, 54, 0.35);
}
.was-validated .custom-range:invalid::-ms-track-upper, .custom-range.is-invalid::-ms-track-upper {
  background: rgba(244, 67, 54, 0.35);
}

.custom-radio.b-custom-control-lg,
.input-group-lg .custom-radio {
  font-size: 1.25rem;
  line-height: 1.5;
  padding-left: 1.875rem;
}
.custom-radio.b-custom-control-lg .custom-control-label::before,
.input-group-lg .custom-radio .custom-control-label::before {
  top: 0.3125rem;
  left: -1.875rem;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
}
.custom-radio.b-custom-control-lg .custom-control-label::after,
.input-group-lg .custom-radio .custom-control-label::after {
  top: 0.3125rem;
  left: -1.875rem;
  width: 1.25rem;
  height: 1.25rem;
  background: no-repeat 50%/50% 50%;
}

.custom-radio.b-custom-control-sm,
.input-group-sm .custom-radio {
  font-size: 0.875rem;
  line-height: 1.5;
  padding-left: 1.3125rem;
}
.custom-radio.b-custom-control-sm .custom-control-label::before,
.input-group-sm .custom-radio .custom-control-label::before {
  top: 0.21875rem;
  left: -1.3125rem;
  width: 0.875rem;
  height: 0.875rem;
  border-radius: 50%;
}
.custom-radio.b-custom-control-sm .custom-control-label::after,
.input-group-sm .custom-radio .custom-control-label::after {
  top: 0.21875rem;
  left: -1.3125rem;
  width: 0.875rem;
  height: 0.875rem;
  background: no-repeat 50%/50% 50%;
}

.b-rating {
  text-align: center;
}
.b-rating.d-inline-flex {
  width: auto;
}
.b-rating .b-rating-star,
.b-rating .b-rating-value {
  padding: 0 0.25em;
}
.b-rating .b-rating-value {
  min-width: 2.5em;
}
.b-rating .b-rating-star {
  display: inline-flex;
  justify-content: center;
  outline: 0;
}
.b-rating .b-rating-star .b-rating-icon {
  display: inline-flex;
  transition: all 0.15s ease-in-out;
}
.b-rating.disabled, .b-rating:disabled {
  background-color: #e9ecef;
  color: #6c757d;
}
.b-rating:not(.disabled):not(.readonly) .b-rating-star {
  cursor: pointer;
}
.b-rating:not(.disabled):not(.readonly):focus:not(:hover) .b-rating-star.focused .b-rating-icon,
.b-rating:not(.disabled):not(.readonly) .b-rating-star:hover .b-rating-icon {
  transform: scale(1.5);
}
.b-rating[dir=rtl] .b-rating-star-half {
  transform: scale(-1, 1);
}

.b-form-spinbutton {
  text-align: center;
  overflow: hidden;
  background-image: none;
  padding: 0;
}
[dir=rtl] .b-form-spinbutton:not(.flex-column), .b-form-spinbutton[dir=rtl]:not(.flex-column) {
  flex-direction: row-reverse;
}

.b-form-spinbutton output {
  font-size: inherit;
  outline: 0;
  border: 0;
  background-color: transparent;
  width: auto;
  margin: 0;
  padding: 0 0.25rem;
}
.b-form-spinbutton output > div,
.b-form-spinbutton output > bdi {
  display: block;
  min-width: 2.25em;
  height: 1.5em;
}
.b-form-spinbutton.flex-column {
  height: auto;
  width: auto;
}
.b-form-spinbutton.flex-column output {
  margin: 0 0.25rem;
  padding: 0.25rem 0;
}
.b-form-spinbutton:not(.d-inline-flex):not(.flex-column) {
  output-width: 100%;
}
.b-form-spinbutton.d-inline-flex:not(.flex-column) {
  width: auto;
}
.b-form-spinbutton .btn {
  line-height: 1;
  box-shadow: none !important;
}
.b-form-spinbutton .btn:disabled {
  pointer-events: none;
}
.b-form-spinbutton .btn:hover:not(:disabled) > div > .b-icon {
  transform: scale(1.25);
}
.b-form-spinbutton.disabled, .b-form-spinbutton.readonly {
  background-color: #e9ecef;
}
.b-form-spinbutton.disabled {
  pointer-events: none;
}

.b-form-tags.focus {
  color: #495057;
  background-color: #fff;
  border-color: #7bb6ef;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(25, 118, 210, 0.25);
}
.b-form-tags.focus.is-valid {
  border-color: #4caf50;
  box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.25);
}
.b-form-tags.focus.is-invalid {
  border-color: #f44336;
  box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.25);
}
.b-form-tags.disabled {
  background-color: #e9ecef;
}

.b-form-tags-list {
  margin-top: -0.25rem;
}
.b-form-tags-list .b-form-tags-field,
.b-form-tags-list .b-form-tag {
  margin-top: 0.25rem;
}

.b-form-tags-input {
  color: #495057;
}

.b-form-tag {
  font-size: 75%;
  font-weight: normal;
  line-height: 1.5;
  margin-right: 0.25rem;
}
.b-form-tag.disabled {
  opacity: 0.75;
}
.b-form-tag > button.b-form-tag-remove {
  color: inherit;
  font-size: 125%;
  line-height: 1;
  float: none;
  margin-left: 0.25rem;
}

.form-control-sm .b-form-tag {
  line-height: 1.5;
}

.form-control-lg .b-form-tag {
  line-height: 1.5;
}

.media-aside {
  display: flex;
  margin-right: 1rem;
}

.media-aside-right {
  margin-right: 0;
  margin-left: 1rem;
}

.modal-backdrop {
  opacity: 0.5;
}

.b-pagination-pills .page-item .page-link {
  border-radius: 50rem !important;
  margin-left: 0.25rem;
  line-height: 1;
}
.b-pagination-pills .page-item:first-child .page-link {
  margin-left: 0;
}

.popover.b-popover {
  display: block;
  opacity: 1;
  outline: 0;
}
.popover.b-popover.fade:not(.show) {
  opacity: 0;
}
.popover.b-popover.show {
  opacity: 1;
}

.b-popover-primary.popover {
  background-color: #d1e4f6;
  border-color: #bfd9f2;
}
.b-popover-primary.bs-popover-top > .arrow::before, .b-popover-primary.bs-popover-auto[x-placement^=top] > .arrow::before {
  border-top-color: #bfd9f2;
}
.b-popover-primary.bs-popover-top > .arrow::after, .b-popover-primary.bs-popover-auto[x-placement^=top] > .arrow::after {
  border-top-color: #d1e4f6;
}
.b-popover-primary.bs-popover-right > .arrow::before, .b-popover-primary.bs-popover-auto[x-placement^=right] > .arrow::before {
  border-right-color: #bfd9f2;
}
.b-popover-primary.bs-popover-right > .arrow::after, .b-popover-primary.bs-popover-auto[x-placement^=right] > .arrow::after {
  border-right-color: #d1e4f6;
}
.b-popover-primary.bs-popover-bottom > .arrow::before, .b-popover-primary.bs-popover-auto[x-placement^=bottom] > .arrow::before {
  border-bottom-color: #bfd9f2;
}
.b-popover-primary.bs-popover-bottom > .arrow::after, .b-popover-primary.bs-popover-auto[x-placement^=bottom] > .arrow::after {
  border-bottom-color: #c4dcf3;
}
.b-popover-primary.bs-popover-bottom .popover-header::before, .b-popover-primary.bs-popover-auto[x-placement^=bottom] .popover-header::before {
  border-bottom-color: #c4dcf3;
}
.b-popover-primary.bs-popover-left > .arrow::before, .b-popover-primary.bs-popover-auto[x-placement^=left] > .arrow::before {
  border-left-color: #bfd9f2;
}
.b-popover-primary.bs-popover-left > .arrow::after, .b-popover-primary.bs-popover-auto[x-placement^=left] > .arrow::after {
  border-left-color: #d1e4f6;
}
.b-popover-primary .popover-header {
  color: #212529;
  background-color: #c4dcf3;
  border-bottom-color: #afd0ef;
}
.b-popover-primary .popover-body {
  color: #0d3d6d;
}

.b-popover-secondary.popover {
  background-color: #d4edeb;
  border-color: #c2e6e3;
}
.b-popover-secondary.bs-popover-top > .arrow::before, .b-popover-secondary.bs-popover-auto[x-placement^=top] > .arrow::before {
  border-top-color: #c2e6e3;
}
.b-popover-secondary.bs-popover-top > .arrow::after, .b-popover-secondary.bs-popover-auto[x-placement^=top] > .arrow::after {
  border-top-color: #d4edeb;
}
.b-popover-secondary.bs-popover-right > .arrow::before, .b-popover-secondary.bs-popover-auto[x-placement^=right] > .arrow::before {
  border-right-color: #c2e6e3;
}
.b-popover-secondary.bs-popover-right > .arrow::after, .b-popover-secondary.bs-popover-auto[x-placement^=right] > .arrow::after {
  border-right-color: #d4edeb;
}
.b-popover-secondary.bs-popover-bottom > .arrow::before, .b-popover-secondary.bs-popover-auto[x-placement^=bottom] > .arrow::before {
  border-bottom-color: #c2e6e3;
}
.b-popover-secondary.bs-popover-bottom > .arrow::after, .b-popover-secondary.bs-popover-auto[x-placement^=bottom] > .arrow::after {
  border-bottom-color: #c9e8e6;
}
.b-popover-secondary.bs-popover-bottom .popover-header::before, .b-popover-secondary.bs-popover-auto[x-placement^=bottom] .popover-header::before {
  border-bottom-color: #c9e8e6;
}
.b-popover-secondary.bs-popover-left > .arrow::before, .b-popover-secondary.bs-popover-auto[x-placement^=left] > .arrow::before {
  border-left-color: #c2e6e3;
}
.b-popover-secondary.bs-popover-left > .arrow::after, .b-popover-secondary.bs-popover-auto[x-placement^=left] > .arrow::after {
  border-left-color: #d4edeb;
}
.b-popover-secondary .popover-header {
  color: #212529;
  background-color: #c9e8e6;
  border-bottom-color: #b7e1de;
}
.b-popover-secondary .popover-body {
  color: #145650;
}

.b-popover-success.popover {
  background-color: #dbefdc;
  border-color: #cde9ce;
}
.b-popover-success.bs-popover-top > .arrow::before, .b-popover-success.bs-popover-auto[x-placement^=top] > .arrow::before {
  border-top-color: #cde9ce;
}
.b-popover-success.bs-popover-top > .arrow::after, .b-popover-success.bs-popover-auto[x-placement^=top] > .arrow::after {
  border-top-color: #dbefdc;
}
.b-popover-success.bs-popover-right > .arrow::before, .b-popover-success.bs-popover-auto[x-placement^=right] > .arrow::before {
  border-right-color: #cde9ce;
}
.b-popover-success.bs-popover-right > .arrow::after, .b-popover-success.bs-popover-auto[x-placement^=right] > .arrow::after {
  border-right-color: #dbefdc;
}
.b-popover-success.bs-popover-bottom > .arrow::before, .b-popover-success.bs-popover-auto[x-placement^=bottom] > .arrow::before {
  border-bottom-color: #cde9ce;
}
.b-popover-success.bs-popover-bottom > .arrow::after, .b-popover-success.bs-popover-auto[x-placement^=bottom] > .arrow::after {
  border-bottom-color: #d0ead2;
}
.b-popover-success.bs-popover-bottom .popover-header::before, .b-popover-success.bs-popover-auto[x-placement^=bottom] .popover-header::before {
  border-bottom-color: #d0ead2;
}
.b-popover-success.bs-popover-left > .arrow::before, .b-popover-success.bs-popover-auto[x-placement^=left] > .arrow::before {
  border-left-color: #cde9ce;
}
.b-popover-success.bs-popover-left > .arrow::after, .b-popover-success.bs-popover-auto[x-placement^=left] > .arrow::after {
  border-left-color: #dbefdc;
}
.b-popover-success .popover-header {
  color: #212529;
  background-color: #d0ead2;
  border-bottom-color: #bfe2c1;
}
.b-popover-success .popover-body {
  color: #285b2a;
}

.b-popover-info.popover {
  background-color: #d6f5fb;
  border-color: #c5f1fa;
}
.b-popover-info.bs-popover-top > .arrow::before, .b-popover-info.bs-popover-auto[x-placement^=top] > .arrow::before {
  border-top-color: #c5f1fa;
}
.b-popover-info.bs-popover-top > .arrow::after, .b-popover-info.bs-popover-auto[x-placement^=top] > .arrow::after {
  border-top-color: #d6f5fb;
}
.b-popover-info.bs-popover-right > .arrow::before, .b-popover-info.bs-popover-auto[x-placement^=right] > .arrow::before {
  border-right-color: #c5f1fa;
}
.b-popover-info.bs-popover-right > .arrow::after, .b-popover-info.bs-popover-auto[x-placement^=right] > .arrow::after {
  border-right-color: #d6f5fb;
}
.b-popover-info.bs-popover-bottom > .arrow::before, .b-popover-info.bs-popover-auto[x-placement^=bottom] > .arrow::before {
  border-bottom-color: #c5f1fa;
}
.b-popover-info.bs-popover-bottom > .arrow::after, .b-popover-info.bs-popover-auto[x-placement^=bottom] > .arrow::after {
  border-bottom-color: #c8f2fa;
}
.b-popover-info.bs-popover-bottom .popover-header::before, .b-popover-info.bs-popover-auto[x-placement^=bottom] .popover-header::before {
  border-bottom-color: #c8f2fa;
}
.b-popover-info.bs-popover-left > .arrow::before, .b-popover-info.bs-popover-auto[x-placement^=left] > .arrow::before {
  border-left-color: #c5f1fa;
}
.b-popover-info.bs-popover-left > .arrow::after, .b-popover-info.bs-popover-auto[x-placement^=left] > .arrow::after {
  border-left-color: #d6f5fb;
}
.b-popover-info .popover-header {
  color: #212529;
  background-color: #c8f2fa;
  border-bottom-color: #b1ecf7;
}
.b-popover-info .popover-body {
  color: #196a7b;
}

.b-popover-warning.popover {
  background-color: #fcf2d7;
  border-color: #fbedc7;
}
.b-popover-warning.bs-popover-top > .arrow::before, .b-popover-warning.bs-popover-auto[x-placement^=top] > .arrow::before {
  border-top-color: #fbedc7;
}
.b-popover-warning.bs-popover-top > .arrow::after, .b-popover-warning.bs-popover-auto[x-placement^=top] > .arrow::after {
  border-top-color: #fcf2d7;
}
.b-popover-warning.bs-popover-right > .arrow::before, .b-popover-warning.bs-popover-auto[x-placement^=right] > .arrow::before {
  border-right-color: #fbedc7;
}
.b-popover-warning.bs-popover-right > .arrow::after, .b-popover-warning.bs-popover-auto[x-placement^=right] > .arrow::after {
  border-right-color: #fcf2d7;
}
.b-popover-warning.bs-popover-bottom > .arrow::before, .b-popover-warning.bs-popover-auto[x-placement^=bottom] > .arrow::before {
  border-bottom-color: #fbedc7;
}
.b-popover-warning.bs-popover-bottom > .arrow::after, .b-popover-warning.bs-popover-auto[x-placement^=bottom] > .arrow::after {
  border-bottom-color: #fbedc9;
}
.b-popover-warning.bs-popover-bottom .popover-header::before, .b-popover-warning.bs-popover-auto[x-placement^=bottom] .popover-header::before {
  border-bottom-color: #fbedc9;
}
.b-popover-warning.bs-popover-left > .arrow::before, .b-popover-warning.bs-popover-auto[x-placement^=left] > .arrow::before {
  border-left-color: #fbedc7;
}
.b-popover-warning.bs-popover-left > .arrow::after, .b-popover-warning.bs-popover-auto[x-placement^=left] > .arrow::after {
  border-left-color: #fcf2d7;
}
.b-popover-warning .popover-header {
  color: #212529;
  background-color: #fbedc9;
  border-bottom-color: #f9e6b1;
}
.b-popover-warning .popover-body {
  color: #7e641d;
}

.b-popover-danger.popover {
  background-color: #fdd9d7;
  border-color: #fccac7;
}
.b-popover-danger.bs-popover-top > .arrow::before, .b-popover-danger.bs-popover-auto[x-placement^=top] > .arrow::before {
  border-top-color: #fccac7;
}
.b-popover-danger.bs-popover-top > .arrow::after, .b-popover-danger.bs-popover-auto[x-placement^=top] > .arrow::after {
  border-top-color: #fdd9d7;
}
.b-popover-danger.bs-popover-right > .arrow::before, .b-popover-danger.bs-popover-auto[x-placement^=right] > .arrow::before {
  border-right-color: #fccac7;
}
.b-popover-danger.bs-popover-right > .arrow::after, .b-popover-danger.bs-popover-auto[x-placement^=right] > .arrow::after {
  border-right-color: #fdd9d7;
}
.b-popover-danger.bs-popover-bottom > .arrow::before, .b-popover-danger.bs-popover-auto[x-placement^=bottom] > .arrow::before {
  border-bottom-color: #fccac7;
}
.b-popover-danger.bs-popover-bottom > .arrow::after, .b-popover-danger.bs-popover-auto[x-placement^=bottom] > .arrow::after {
  border-bottom-color: #fccbc8;
}
.b-popover-danger.bs-popover-bottom .popover-header::before, .b-popover-danger.bs-popover-auto[x-placement^=bottom] .popover-header::before {
  border-bottom-color: #fccbc8;
}
.b-popover-danger.bs-popover-left > .arrow::before, .b-popover-danger.bs-popover-auto[x-placement^=left] > .arrow::before {
  border-left-color: #fccac7;
}
.b-popover-danger.bs-popover-left > .arrow::after, .b-popover-danger.bs-popover-auto[x-placement^=left] > .arrow::after {
  border-left-color: #fdd9d7;
}
.b-popover-danger .popover-header {
  color: #212529;
  background-color: #fccbc8;
  border-bottom-color: #fbb4b0;
}
.b-popover-danger .popover-body {
  color: #7f231c;
}

.b-popover-light.popover {
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.b-popover-light.bs-popover-top > .arrow::before, .b-popover-light.bs-popover-auto[x-placement^=top] > .arrow::before {
  border-top-color: #fdfdfe;
}
.b-popover-light.bs-popover-top > .arrow::after, .b-popover-light.bs-popover-auto[x-placement^=top] > .arrow::after {
  border-top-color: #fefefe;
}
.b-popover-light.bs-popover-right > .arrow::before, .b-popover-light.bs-popover-auto[x-placement^=right] > .arrow::before {
  border-right-color: #fdfdfe;
}
.b-popover-light.bs-popover-right > .arrow::after, .b-popover-light.bs-popover-auto[x-placement^=right] > .arrow::after {
  border-right-color: #fefefe;
}
.b-popover-light.bs-popover-bottom > .arrow::before, .b-popover-light.bs-popover-auto[x-placement^=bottom] > .arrow::before {
  border-bottom-color: #fdfdfe;
}
.b-popover-light.bs-popover-bottom > .arrow::after, .b-popover-light.bs-popover-auto[x-placement^=bottom] > .arrow::after {
  border-bottom-color: #f6f6f6;
}
.b-popover-light.bs-popover-bottom .popover-header::before, .b-popover-light.bs-popover-auto[x-placement^=bottom] .popover-header::before {
  border-bottom-color: #f6f6f6;
}
.b-popover-light.bs-popover-left > .arrow::before, .b-popover-light.bs-popover-auto[x-placement^=left] > .arrow::before {
  border-left-color: #fdfdfe;
}
.b-popover-light.bs-popover-left > .arrow::after, .b-popover-light.bs-popover-auto[x-placement^=left] > .arrow::after {
  border-left-color: #fefefe;
}
.b-popover-light .popover-header {
  color: #212529;
  background-color: #f6f6f6;
  border-bottom-color: #eaeaea;
}
.b-popover-light .popover-body {
  color: #818182;
}

.b-popover-dark.popover {
  background-color: #d2d2d2;
  border-color: silver;
}
.b-popover-dark.bs-popover-top > .arrow::before, .b-popover-dark.bs-popover-auto[x-placement^=top] > .arrow::before {
  border-top-color: silver;
}
.b-popover-dark.bs-popover-top > .arrow::after, .b-popover-dark.bs-popover-auto[x-placement^=top] > .arrow::after {
  border-top-color: #d2d2d2;
}
.b-popover-dark.bs-popover-right > .arrow::before, .b-popover-dark.bs-popover-auto[x-placement^=right] > .arrow::before {
  border-right-color: silver;
}
.b-popover-dark.bs-popover-right > .arrow::after, .b-popover-dark.bs-popover-auto[x-placement^=right] > .arrow::after {
  border-right-color: #d2d2d2;
}
.b-popover-dark.bs-popover-bottom > .arrow::before, .b-popover-dark.bs-popover-auto[x-placement^=bottom] > .arrow::before {
  border-bottom-color: silver;
}
.b-popover-dark.bs-popover-bottom > .arrow::after, .b-popover-dark.bs-popover-auto[x-placement^=bottom] > .arrow::after {
  border-bottom-color: #cacaca;
}
.b-popover-dark.bs-popover-bottom .popover-header::before, .b-popover-dark.bs-popover-auto[x-placement^=bottom] .popover-header::before {
  border-bottom-color: #cacaca;
}
.b-popover-dark.bs-popover-left > .arrow::before, .b-popover-dark.bs-popover-auto[x-placement^=left] > .arrow::before {
  border-left-color: silver;
}
.b-popover-dark.bs-popover-left > .arrow::after, .b-popover-dark.bs-popover-auto[x-placement^=left] > .arrow::after {
  border-left-color: #d2d2d2;
}
.b-popover-dark .popover-header {
  color: #212529;
  background-color: #cacaca;
  border-bottom-color: #bebebe;
}
.b-popover-dark .popover-body {
  color: #0f0f0f;
}

.b-sidebar-outer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 0;
  overflow: visible;
  z-index: calc(1030 + 5);
}

.b-sidebar-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100vw;
  height: 100vh;
  opacity: 0.6;
}

.b-sidebar {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  width: 320px;
  max-width: 100%;
  height: 100vh;
  max-height: 100%;
  margin: 0;
  outline: 0;
  transform: translateX(0);
}
.b-sidebar.slide {
  transition: transform 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .b-sidebar.slide {
    transition: none;
  }
}
.b-sidebar:not(.b-sidebar-right) {
  left: 0;
  right: auto;
}
.b-sidebar:not(.b-sidebar-right).slide:not(.show) {
  transform: translateX(-100%);
}
.b-sidebar:not(.b-sidebar-right) > .b-sidebar-header .close {
  margin-left: auto;
}
.b-sidebar.b-sidebar-right {
  left: auto;
  right: 0;
}
.b-sidebar.b-sidebar-right.slide:not(.show) {
  transform: translateX(100%);
}
.b-sidebar.b-sidebar-right > .b-sidebar-header .close {
  margin-right: auto;
}
.b-sidebar > .b-sidebar-header {
  font-size: 1.5rem;
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  align-items: center;
}
[dir=rtl] .b-sidebar > .b-sidebar-header {
  flex-direction: row-reverse;
}

.b-sidebar > .b-sidebar-header .close {
  float: none;
  font-size: 1.5rem;
}
.b-sidebar > .b-sidebar-body {
  flex-grow: 1;
  height: 100%;
  overflow-y: auto;
}
.b-sidebar > .b-sidebar-footer {
  flex-grow: 0;
}

.b-skeleton-wrapper {
  cursor: wait;
}

.b-skeleton {
  position: relative;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.12);
  cursor: wait;
  mask-image: radial-gradient(white, black);
}
.b-skeleton::before {
  content: " ";
}

.b-skeleton-text {
  height: 1rem;
  margin-bottom: 0.25rem;
  border-radius: 0.25rem;
}

.b-skeleton-button {
  width: 75px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
}

.b-skeleton-avatar {
  width: 2.5em;
  height: 2.5em;
  border-radius: 50%;
}

.b-skeleton-input {
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  border: #ced4da solid 1px;
  border-radius: 0.25rem;
}

.b-skeleton-icon-wrapper svg {
  color: rgba(0, 0, 0, 0.12);
}

.b-skeleton-img {
  height: 100%;
  width: 100%;
}

.b-skeleton-animate-wave::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.4), transparent);
  animation: b-skeleton-animate-wave 1.75s linear infinite;
}
@media (prefers-reduced-motion: reduce) {
  .b-skeleton-animate-wave::after {
    background: none;
    animation: none;
  }
}

@keyframes b-skeleton-animate-wave {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(100%);
  }
}
.b-skeleton-animate-fade {
  animation: b-skeleton-animate-fade 0.875s ease-in-out alternate infinite;
}
@media (prefers-reduced-motion: reduce) {
  .b-skeleton-animate-fade {
    animation: none;
  }
}

@keyframes b-skeleton-animate-fade {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.4;
  }
}
.b-skeleton-animate-throb {
  animation: b-skeleton-animate-throb 0.875s ease-in alternate infinite;
}
@media (prefers-reduced-motion: reduce) {
  .b-skeleton-animate-throb {
    animation: none;
  }
}

@keyframes b-skeleton-animate-throb {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.975);
  }
}
.table.b-table.b-table-fixed {
  table-layout: fixed;
}
.table.b-table.b-table-no-border-collapse {
  border-collapse: separate;
  border-spacing: 0;
}
.table.b-table[aria-busy=true] {
  opacity: 0.55;
}
.table.b-table > tbody > tr.b-table-details > td {
  border-top: none !important;
}
.table.b-table > caption {
  caption-side: bottom;
}
.table.b-table.b-table-caption-top > caption {
  caption-side: top !important;
}
.table.b-table > tbody > .table-active,
.table.b-table > tbody > .table-active > th,
.table.b-table > tbody > .table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}
.table.b-table.table-hover > tbody > tr.table-active:hover td,
.table.b-table.table-hover > tbody > tr.table-active:hover th {
  color: #212529;
  background-image: linear-gradient(rgba(0, 0, 0, 0.075), rgba(0, 0, 0, 0.075));
  background-repeat: no-repeat;
}
.table.b-table > tbody > .bg-active,
.table.b-table > tbody > .bg-active > th,
.table.b-table > tbody > .bg-active > td {
  background-color: rgba(255, 255, 255, 0.075) !important;
}
.table.b-table.table-hover.table-dark > tbody > tr.bg-active:hover td,
.table.b-table.table-hover.table-dark > tbody > tr.bg-active:hover th {
  color: #fff;
  background-image: linear-gradient(rgba(255, 255, 255, 0.075), rgba(255, 255, 255, 0.075));
  background-repeat: no-repeat;
}

.b-table-sticky-header,
.table-responsive,
[class*=table-responsive-] {
  margin-bottom: 1rem;
}
.b-table-sticky-header > .table,
.table-responsive > .table,
[class*=table-responsive-] > .table {
  margin-bottom: 0;
}

.b-table-sticky-header {
  overflow-y: auto;
  max-height: 300px;
}

@media print {
  .b-table-sticky-header {
    overflow-y: visible !important;
    max-height: none !important;
  }
}
@supports (position: sticky) {
  .b-table-sticky-header > .table.b-table > thead > tr > th {
    position: sticky;
    top: 0;
    z-index: 2;
  }

  .b-table-sticky-header > .table.b-table > thead > tr > .b-table-sticky-column,
.b-table-sticky-header > .table.b-table > tbody > tr > .b-table-sticky-column,
.b-table-sticky-header > .table.b-table > tfoot > tr > .b-table-sticky-column,
.table-responsive > .table.b-table > thead > tr > .b-table-sticky-column,
.table-responsive > .table.b-table > tbody > tr > .b-table-sticky-column,
.table-responsive > .table.b-table > tfoot > tr > .b-table-sticky-column,
[class*=table-responsive-] > .table.b-table > thead > tr > .b-table-sticky-column,
[class*=table-responsive-] > .table.b-table > tbody > tr > .b-table-sticky-column,
[class*=table-responsive-] > .table.b-table > tfoot > tr > .b-table-sticky-column {
    position: sticky;
    left: 0;
  }
  .b-table-sticky-header > .table.b-table > thead > tr > .b-table-sticky-column,
.table-responsive > .table.b-table > thead > tr > .b-table-sticky-column,
[class*=table-responsive-] > .table.b-table > thead > tr > .b-table-sticky-column {
    z-index: 5;
  }
  .b-table-sticky-header > .table.b-table > tbody > tr > .b-table-sticky-column,
.b-table-sticky-header > .table.b-table > tfoot > tr > .b-table-sticky-column,
.table-responsive > .table.b-table > tbody > tr > .b-table-sticky-column,
.table-responsive > .table.b-table > tfoot > tr > .b-table-sticky-column,
[class*=table-responsive-] > .table.b-table > tbody > tr > .b-table-sticky-column,
[class*=table-responsive-] > .table.b-table > tfoot > tr > .b-table-sticky-column {
    z-index: 2;
  }

  .table.b-table > thead > tr > .table-b-table-default,
.table.b-table > tbody > tr > .table-b-table-default,
.table.b-table > tfoot > tr > .table-b-table-default {
    color: #212529;
    background-color: #fff;
  }
  .table.b-table.table-dark > thead > tr > .bg-b-table-default,
.table.b-table.table-dark > tbody > tr > .bg-b-table-default,
.table.b-table.table-dark > tfoot > tr > .bg-b-table-default {
    color: #fff;
    background-color: #343a40;
  }
  .table.b-table.table-striped > tbody > tr:nth-of-type(odd) > .table-b-table-default {
    background-image: linear-gradient(rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05));
    background-repeat: no-repeat;
  }
  .table.b-table.table-striped.table-dark > tbody > tr:nth-of-type(odd) > .bg-b-table-default {
    background-image: linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05));
    background-repeat: no-repeat;
  }
  .table.b-table.table-hover > tbody > tr:hover > .table-b-table-default {
    color: #212529;
    background-image: linear-gradient(rgba(0, 0, 0, 0.075), rgba(0, 0, 0, 0.075));
    background-repeat: no-repeat;
  }
  .table.b-table.table-hover.table-dark > tbody > tr:hover > .bg-b-table-default {
    color: #fff;
    background-image: linear-gradient(rgba(255, 255, 255, 0.075), rgba(255, 255, 255, 0.075));
    background-repeat: no-repeat;
  }
}
.table.b-table > thead > tr > [aria-sort],
.table.b-table > tfoot > tr > [aria-sort] {
  cursor: pointer;
  background-image: none;
  background-repeat: no-repeat;
  background-size: 0.65em 1em;
}
.table.b-table > thead > tr > [aria-sort]:not(.b-table-sort-icon-left),
.table.b-table > tfoot > tr > [aria-sort]:not(.b-table-sort-icon-left) {
  background-position: right calc(0.75rem / 2) center;
  padding-right: calc(0.75rem + 0.65em);
}
.table.b-table > thead > tr > [aria-sort].b-table-sort-icon-left,
.table.b-table > tfoot > tr > [aria-sort].b-table-sort-icon-left {
  background-position: left calc(0.75rem / 2) center;
  padding-left: calc(0.75rem + 0.65em);
}
.table.b-table > thead > tr > [aria-sort=none],
.table.b-table > tfoot > tr > [aria-sort=none] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='black' opacity='.3' d='M51 1l25 23 24 22H1l25-22zM51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e");
}
.table.b-table > thead > tr > [aria-sort=ascending],
.table.b-table > tfoot > tr > [aria-sort=ascending] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='black' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='black' opacity='.3' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e");
}
.table.b-table > thead > tr > [aria-sort=descending],
.table.b-table > tfoot > tr > [aria-sort=descending] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='black' opacity='.3' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='black' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e");
}
.table.b-table.table-dark > thead > tr > [aria-sort=none], .table.b-table.table-dark > tfoot > tr > [aria-sort=none],
.table.b-table > .thead-dark > tr > [aria-sort=none] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='white' opacity='.3' d='M51 1l25 23 24 22H1l25-22zM51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e");
}
.table.b-table.table-dark > thead > tr > [aria-sort=ascending], .table.b-table.table-dark > tfoot > tr > [aria-sort=ascending],
.table.b-table > .thead-dark > tr > [aria-sort=ascending] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='white' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='white' opacity='.3' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e");
}
.table.b-table.table-dark > thead > tr > [aria-sort=descending], .table.b-table.table-dark > tfoot > tr > [aria-sort=descending],
.table.b-table > .thead-dark > tr > [aria-sort=descending] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='white' opacity='.3' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='white' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e");
}
.table.b-table > thead > tr > .table-dark[aria-sort=none],
.table.b-table > tfoot > tr > .table-dark[aria-sort=none] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='white' opacity='.3' d='M51 1l25 23 24 22H1l25-22zM51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e");
}
.table.b-table > thead > tr > .table-dark[aria-sort=ascending],
.table.b-table > tfoot > tr > .table-dark[aria-sort=ascending] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='white' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='white' opacity='.3' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e");
}
.table.b-table > thead > tr > .table-dark[aria-sort=descending],
.table.b-table > tfoot > tr > .table-dark[aria-sort=descending] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='white' opacity='.3' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='white' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e");
}
.table.b-table.table-sm > thead > tr > [aria-sort]:not(.b-table-sort-icon-left),
.table.b-table.table-sm > tfoot > tr > [aria-sort]:not(.b-table-sort-icon-left) {
  background-position: right calc(0.3rem / 2) center;
  padding-right: calc(0.3rem + 0.65em);
}
.table.b-table.table-sm > thead > tr > [aria-sort].b-table-sort-icon-left,
.table.b-table.table-sm > tfoot > tr > [aria-sort].b-table-sort-icon-left {
  background-position: left calc(0.3rem / 2) center;
  padding-left: calc(0.3rem + 0.65em);
}

.table.b-table.b-table-selectable:not(.b-table-selectable-no-click) > tbody > tr {
  cursor: pointer;
}
.table.b-table.b-table-selectable:not(.b-table-selectable-no-click).b-table-selecting.b-table-select-range > tbody > tr {
  user-select: none;
}

@media (max-width: 575.98px) {
  .table.b-table.b-table-stacked-sm {
    display: block;
    width: 100%;
  }
  .table.b-table.b-table-stacked-sm > caption,
.table.b-table.b-table-stacked-sm > tbody,
.table.b-table.b-table-stacked-sm > tbody > tr,
.table.b-table.b-table-stacked-sm > tbody > tr > td,
.table.b-table.b-table-stacked-sm > tbody > tr > th {
    display: block;
  }
  .table.b-table.b-table-stacked-sm > thead,
.table.b-table.b-table-stacked-sm > tfoot {
    display: none;
  }
  .table.b-table.b-table-stacked-sm > thead > tr.b-table-top-row,
.table.b-table.b-table-stacked-sm > thead > tr.b-table-bottom-row,
.table.b-table.b-table-stacked-sm > tfoot > tr.b-table-top-row,
.table.b-table.b-table-stacked-sm > tfoot > tr.b-table-bottom-row {
    display: none;
  }
  .table.b-table.b-table-stacked-sm > caption {
    caption-side: top !important;
  }
  .table.b-table.b-table-stacked-sm > tbody > tr > [data-label]::before {
    content: attr(data-label);
    width: 40%;
    float: left;
    text-align: right;
    overflow-wrap: break-word;
    font-weight: bold;
    font-style: normal;
    padding: 0 calc(1rem / 2) 0 0;
    margin: 0;
  }
  .table.b-table.b-table-stacked-sm > tbody > tr > [data-label]::after {
    display: block;
    clear: both;
    content: "";
  }
  .table.b-table.b-table-stacked-sm > tbody > tr > [data-label] > div {
    display: inline-block;
    width: calc(100% - 40%);
    padding: 0 0 0 calc(1rem / 2);
    margin: 0;
  }
  .table.b-table.b-table-stacked-sm > tbody > tr.top-row, .table.b-table.b-table-stacked-sm > tbody > tr.bottom-row {
    display: none;
  }
  .table.b-table.b-table-stacked-sm > tbody > tr > :first-child {
    border-top-width: 3px;
  }
  .table.b-table.b-table-stacked-sm > tbody > tr > [rowspan] + td,
.table.b-table.b-table-stacked-sm > tbody > tr > [rowspan] + th {
    border-top-width: 3px;
  }
}
@media (max-width: 767.98px) {
  .table.b-table.b-table-stacked-md {
    display: block;
    width: 100%;
  }
  .table.b-table.b-table-stacked-md > caption,
.table.b-table.b-table-stacked-md > tbody,
.table.b-table.b-table-stacked-md > tbody > tr,
.table.b-table.b-table-stacked-md > tbody > tr > td,
.table.b-table.b-table-stacked-md > tbody > tr > th {
    display: block;
  }
  .table.b-table.b-table-stacked-md > thead,
.table.b-table.b-table-stacked-md > tfoot {
    display: none;
  }
  .table.b-table.b-table-stacked-md > thead > tr.b-table-top-row,
.table.b-table.b-table-stacked-md > thead > tr.b-table-bottom-row,
.table.b-table.b-table-stacked-md > tfoot > tr.b-table-top-row,
.table.b-table.b-table-stacked-md > tfoot > tr.b-table-bottom-row {
    display: none;
  }
  .table.b-table.b-table-stacked-md > caption {
    caption-side: top !important;
  }
  .table.b-table.b-table-stacked-md > tbody > tr > [data-label]::before {
    content: attr(data-label);
    width: 40%;
    float: left;
    text-align: right;
    overflow-wrap: break-word;
    font-weight: bold;
    font-style: normal;
    padding: 0 calc(1rem / 2) 0 0;
    margin: 0;
  }
  .table.b-table.b-table-stacked-md > tbody > tr > [data-label]::after {
    display: block;
    clear: both;
    content: "";
  }
  .table.b-table.b-table-stacked-md > tbody > tr > [data-label] > div {
    display: inline-block;
    width: calc(100% - 40%);
    padding: 0 0 0 calc(1rem / 2);
    margin: 0;
  }
  .table.b-table.b-table-stacked-md > tbody > tr.top-row, .table.b-table.b-table-stacked-md > tbody > tr.bottom-row {
    display: none;
  }
  .table.b-table.b-table-stacked-md > tbody > tr > :first-child {
    border-top-width: 3px;
  }
  .table.b-table.b-table-stacked-md > tbody > tr > [rowspan] + td,
.table.b-table.b-table-stacked-md > tbody > tr > [rowspan] + th {
    border-top-width: 3px;
  }
}
@media (max-width: 991.98px) {
  .table.b-table.b-table-stacked-lg {
    display: block;
    width: 100%;
  }
  .table.b-table.b-table-stacked-lg > caption,
.table.b-table.b-table-stacked-lg > tbody,
.table.b-table.b-table-stacked-lg > tbody > tr,
.table.b-table.b-table-stacked-lg > tbody > tr > td,
.table.b-table.b-table-stacked-lg > tbody > tr > th {
    display: block;
  }
  .table.b-table.b-table-stacked-lg > thead,
.table.b-table.b-table-stacked-lg > tfoot {
    display: none;
  }
  .table.b-table.b-table-stacked-lg > thead > tr.b-table-top-row,
.table.b-table.b-table-stacked-lg > thead > tr.b-table-bottom-row,
.table.b-table.b-table-stacked-lg > tfoot > tr.b-table-top-row,
.table.b-table.b-table-stacked-lg > tfoot > tr.b-table-bottom-row {
    display: none;
  }
  .table.b-table.b-table-stacked-lg > caption {
    caption-side: top !important;
  }
  .table.b-table.b-table-stacked-lg > tbody > tr > [data-label]::before {
    content: attr(data-label);
    width: 40%;
    float: left;
    text-align: right;
    overflow-wrap: break-word;
    font-weight: bold;
    font-style: normal;
    padding: 0 calc(1rem / 2) 0 0;
    margin: 0;
  }
  .table.b-table.b-table-stacked-lg > tbody > tr > [data-label]::after {
    display: block;
    clear: both;
    content: "";
  }
  .table.b-table.b-table-stacked-lg > tbody > tr > [data-label] > div {
    display: inline-block;
    width: calc(100% - 40%);
    padding: 0 0 0 calc(1rem / 2);
    margin: 0;
  }
  .table.b-table.b-table-stacked-lg > tbody > tr.top-row, .table.b-table.b-table-stacked-lg > tbody > tr.bottom-row {
    display: none;
  }
  .table.b-table.b-table-stacked-lg > tbody > tr > :first-child {
    border-top-width: 3px;
  }
  .table.b-table.b-table-stacked-lg > tbody > tr > [rowspan] + td,
.table.b-table.b-table-stacked-lg > tbody > tr > [rowspan] + th {
    border-top-width: 3px;
  }
}
@media (max-width: 1199.98px) {
  .table.b-table.b-table-stacked-xl {
    display: block;
    width: 100%;
  }
  .table.b-table.b-table-stacked-xl > caption,
.table.b-table.b-table-stacked-xl > tbody,
.table.b-table.b-table-stacked-xl > tbody > tr,
.table.b-table.b-table-stacked-xl > tbody > tr > td,
.table.b-table.b-table-stacked-xl > tbody > tr > th {
    display: block;
  }
  .table.b-table.b-table-stacked-xl > thead,
.table.b-table.b-table-stacked-xl > tfoot {
    display: none;
  }
  .table.b-table.b-table-stacked-xl > thead > tr.b-table-top-row,
.table.b-table.b-table-stacked-xl > thead > tr.b-table-bottom-row,
.table.b-table.b-table-stacked-xl > tfoot > tr.b-table-top-row,
.table.b-table.b-table-stacked-xl > tfoot > tr.b-table-bottom-row {
    display: none;
  }
  .table.b-table.b-table-stacked-xl > caption {
    caption-side: top !important;
  }
  .table.b-table.b-table-stacked-xl > tbody > tr > [data-label]::before {
    content: attr(data-label);
    width: 40%;
    float: left;
    text-align: right;
    overflow-wrap: break-word;
    font-weight: bold;
    font-style: normal;
    padding: 0 calc(1rem / 2) 0 0;
    margin: 0;
  }
  .table.b-table.b-table-stacked-xl > tbody > tr > [data-label]::after {
    display: block;
    clear: both;
    content: "";
  }
  .table.b-table.b-table-stacked-xl > tbody > tr > [data-label] > div {
    display: inline-block;
    width: calc(100% - 40%);
    padding: 0 0 0 calc(1rem / 2);
    margin: 0;
  }
  .table.b-table.b-table-stacked-xl > tbody > tr.top-row, .table.b-table.b-table-stacked-xl > tbody > tr.bottom-row {
    display: none;
  }
  .table.b-table.b-table-stacked-xl > tbody > tr > :first-child {
    border-top-width: 3px;
  }
  .table.b-table.b-table-stacked-xl > tbody > tr > [rowspan] + td,
.table.b-table.b-table-stacked-xl > tbody > tr > [rowspan] + th {
    border-top-width: 3px;
  }
}
.table.b-table.b-table-stacked {
  display: block;
  width: 100%;
}
.table.b-table.b-table-stacked > caption,
.table.b-table.b-table-stacked > tbody,
.table.b-table.b-table-stacked > tbody > tr,
.table.b-table.b-table-stacked > tbody > tr > td,
.table.b-table.b-table-stacked > tbody > tr > th {
  display: block;
}
.table.b-table.b-table-stacked > thead,
.table.b-table.b-table-stacked > tfoot {
  display: none;
}
.table.b-table.b-table-stacked > thead > tr.b-table-top-row,
.table.b-table.b-table-stacked > thead > tr.b-table-bottom-row,
.table.b-table.b-table-stacked > tfoot > tr.b-table-top-row,
.table.b-table.b-table-stacked > tfoot > tr.b-table-bottom-row {
  display: none;
}
.table.b-table.b-table-stacked > caption {
  caption-side: top !important;
}
.table.b-table.b-table-stacked > tbody > tr > [data-label]::before {
  content: attr(data-label);
  width: 40%;
  float: left;
  text-align: right;
  overflow-wrap: break-word;
  font-weight: bold;
  font-style: normal;
  padding: 0 calc(1rem / 2) 0 0;
  margin: 0;
}
.table.b-table.b-table-stacked > tbody > tr > [data-label]::after {
  display: block;
  clear: both;
  content: "";
}
.table.b-table.b-table-stacked > tbody > tr > [data-label] > div {
  display: inline-block;
  width: calc(100% - 40%);
  padding: 0 0 0 calc(1rem / 2);
  margin: 0;
}
.table.b-table.b-table-stacked > tbody > tr.top-row, .table.b-table.b-table-stacked > tbody > tr.bottom-row {
  display: none;
}
.table.b-table.b-table-stacked > tbody > tr > :first-child {
  border-top-width: 3px;
}
.table.b-table.b-table-stacked > tbody > tr > [rowspan] + td,
.table.b-table.b-table-stacked > tbody > tr > [rowspan] + th {
  border-top-width: 3px;
}

.b-time {
  min-width: 150px;
}
.b-time[aria-disabled=true] output, .b-time[aria-readonly=true] output,
.b-time output.disabled {
  background-color: #e9ecef;
  opacity: 1;
}
.b-time[aria-disabled=true] output {
  pointer-events: none;
}
[dir=rtl] .b-time > .d-flex:not(.flex-column) {
  flex-direction: row-reverse;
}

.b-time .b-time-header {
  margin-bottom: 0.5rem;
}
.b-time .b-time-header output {
  padding: 0.25rem;
  font-size: 80%;
}
.b-time .b-time-footer {
  margin-top: 0.5rem;
}
.b-time .b-time-ampm {
  margin-left: 0.5rem;
}

.b-toast {
  display: block;
  position: relative;
  max-width: 350px;
  backface-visibility: hidden;
  background-clip: padding-box;
  z-index: 1;
  border-radius: 0.25rem;
}
.b-toast .toast {
  background-color: rgba(255, 255, 255, 0.85);
}
.b-toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.b-toast.b-toast-solid .toast {
  background-color: white;
}
.b-toast .toast {
  opacity: 1;
}
.b-toast .toast.fade:not(.show) {
  opacity: 0;
}
.b-toast .toast .toast-body {
  display: block;
}

.b-toast-primary .toast {
  background-color: rgba(230, 241, 250, 0.85);
  border-color: rgba(191, 217, 242, 0.85);
  color: #0d3d6d;
}
.b-toast-primary .toast .toast-header {
  color: #0d3d6d;
  background-color: rgba(209, 228, 246, 0.85);
  border-bottom-color: rgba(191, 217, 242, 0.85);
}
.b-toast-primary.b-toast-solid .toast {
  background-color: #e6f1fa;
}

.b-toast-secondary .toast {
  background-color: rgba(230, 245, 243, 0.85);
  border-color: rgba(194, 230, 227, 0.85);
  color: #145650;
}
.b-toast-secondary .toast .toast-header {
  color: #145650;
  background-color: rgba(212, 237, 235, 0.85);
  border-bottom-color: rgba(194, 230, 227, 0.85);
}
.b-toast-secondary.b-toast-solid .toast {
  background-color: #e6f5f3;
}

.b-toast-success .toast {
  background-color: rgba(237, 247, 237, 0.85);
  border-color: rgba(205, 233, 206, 0.85);
  color: #285b2a;
}
.b-toast-success .toast .toast-header {
  color: #285b2a;
  background-color: rgba(219, 239, 220, 0.85);
  border-bottom-color: rgba(205, 233, 206, 0.85);
}
.b-toast-success.b-toast-solid .toast {
  background-color: #edf7ed;
}

.b-toast-info .toast {
  background-color: rgba(237, 251, 253, 0.85);
  border-color: rgba(197, 241, 250, 0.85);
  color: #196a7b;
}
.b-toast-info .toast .toast-header {
  color: #196a7b;
  background-color: rgba(214, 245, 251, 0.85);
  border-bottom-color: rgba(197, 241, 250, 0.85);
}
.b-toast-info.b-toast-solid .toast {
  background-color: #edfbfd;
}

.b-toast-warning .toast {
  background-color: rgba(254, 250, 239, 0.85);
  border-color: rgba(251, 237, 199, 0.85);
  color: #7e641d;
}
.b-toast-warning .toast .toast-header {
  color: #7e641d;
  background-color: rgba(252, 242, 215, 0.85);
  border-bottom-color: rgba(251, 237, 199, 0.85);
}
.b-toast-warning.b-toast-solid .toast {
  background-color: #fefaef;
}

.b-toast-danger .toast {
  background-color: rgba(254, 240, 239, 0.85);
  border-color: rgba(252, 202, 199, 0.85);
  color: #7f231c;
}
.b-toast-danger .toast .toast-header {
  color: #7f231c;
  background-color: rgba(253, 217, 215, 0.85);
  border-bottom-color: rgba(252, 202, 199, 0.85);
}
.b-toast-danger.b-toast-solid .toast {
  background-color: #fef0ef;
}

.b-toast-light .toast {
  background-color: rgba(255, 255, 255, 0.85);
  border-color: rgba(253, 253, 254, 0.85);
  color: #818182;
}
.b-toast-light .toast .toast-header {
  color: #818182;
  background-color: rgba(254, 254, 254, 0.85);
  border-bottom-color: rgba(253, 253, 254, 0.85);
}
.b-toast-light.b-toast-solid .toast {
  background-color: white;
}

.b-toast-dark .toast {
  background-color: rgba(223, 223, 223, 0.85);
  border-color: rgba(192, 192, 192, 0.85);
  color: #0f0f0f;
}
.b-toast-dark .toast .toast-header {
  color: #0f0f0f;
  background-color: rgba(210, 210, 210, 0.85);
  border-bottom-color: rgba(192, 192, 192, 0.85);
}
.b-toast-dark.b-toast-solid .toast {
  background-color: #dfdfdf;
}

.b-toaster {
  z-index: 1100;
}
.b-toaster .b-toaster-slot {
  position: relative;
  display: block;
}
.b-toaster .b-toaster-slot:empty {
  display: none !important;
}

.b-toaster.b-toaster-top-right, .b-toaster.b-toaster-top-left, .b-toaster.b-toaster-top-center, .b-toaster.b-toaster-top-full, .b-toaster.b-toaster-bottom-right, .b-toaster.b-toaster-bottom-left, .b-toaster.b-toaster-bottom-center, .b-toaster.b-toaster-bottom-full {
  position: fixed;
  left: 0.5rem;
  right: 0.5rem;
  margin: 0;
  padding: 0;
  height: 0;
  overflow: visible;
}
.b-toaster.b-toaster-top-right .b-toaster-slot, .b-toaster.b-toaster-top-left .b-toaster-slot, .b-toaster.b-toaster-top-center .b-toaster-slot, .b-toaster.b-toaster-top-full .b-toaster-slot, .b-toaster.b-toaster-bottom-right .b-toaster-slot, .b-toaster.b-toaster-bottom-left .b-toaster-slot, .b-toaster.b-toaster-bottom-center .b-toaster-slot, .b-toaster.b-toaster-bottom-full .b-toaster-slot {
  position: absolute;
  max-width: 350px;
  width: 100%;
  /* IE 11 fix */
  left: 0;
  right: 0;
  padding: 0;
  margin: 0;
}
.b-toaster.b-toaster-top-full .b-toaster-slot, .b-toaster.b-toaster-bottom-full .b-toaster-slot {
  width: 100%;
  max-width: 100%;
}
.b-toaster.b-toaster-top-full .b-toaster-slot .b-toast,
.b-toaster.b-toaster-top-full .b-toaster-slot .toast, .b-toaster.b-toaster-bottom-full .b-toaster-slot .b-toast,
.b-toaster.b-toaster-bottom-full .b-toaster-slot .toast {
  width: 100%;
  max-width: 100%;
}
.b-toaster.b-toaster-top-right, .b-toaster.b-toaster-top-left, .b-toaster.b-toaster-top-center, .b-toaster.b-toaster-top-full {
  top: 0;
}
.b-toaster.b-toaster-top-right .b-toaster-slot, .b-toaster.b-toaster-top-left .b-toaster-slot, .b-toaster.b-toaster-top-center .b-toaster-slot, .b-toaster.b-toaster-top-full .b-toaster-slot {
  top: 0.5rem;
}
.b-toaster.b-toaster-bottom-right, .b-toaster.b-toaster-bottom-left, .b-toaster.b-toaster-bottom-center, .b-toaster.b-toaster-bottom-full {
  bottom: 0;
}
.b-toaster.b-toaster-bottom-right .b-toaster-slot, .b-toaster.b-toaster-bottom-left .b-toaster-slot, .b-toaster.b-toaster-bottom-center .b-toaster-slot, .b-toaster.b-toaster-bottom-full .b-toaster-slot {
  bottom: 0.5rem;
}
.b-toaster.b-toaster-top-right .b-toaster-slot, .b-toaster.b-toaster-bottom-right .b-toaster-slot, .b-toaster.b-toaster-top-center .b-toaster-slot, .b-toaster.b-toaster-bottom-center .b-toaster-slot {
  margin-left: auto;
}
.b-toaster.b-toaster-top-left .b-toaster-slot, .b-toaster.b-toaster-bottom-left .b-toaster-slot, .b-toaster.b-toaster-top-center .b-toaster-slot, .b-toaster.b-toaster-bottom-center .b-toaster-slot {
  margin-right: auto;
}

.b-toaster.b-toaster-top-right .b-toast.b-toaster-enter-active, .b-toaster.b-toaster-top-right .b-toast.b-toaster-leave-active, .b-toaster.b-toaster-top-right .b-toast.b-toaster-move, .b-toaster.b-toaster-top-left .b-toast.b-toaster-enter-active, .b-toaster.b-toaster-top-left .b-toast.b-toaster-leave-active, .b-toaster.b-toaster-top-left .b-toast.b-toaster-move, .b-toaster.b-toaster-bottom-right .b-toast.b-toaster-enter-active, .b-toaster.b-toaster-bottom-right .b-toast.b-toaster-leave-active, .b-toaster.b-toaster-bottom-right .b-toast.b-toaster-move, .b-toaster.b-toaster-bottom-left .b-toast.b-toaster-enter-active, .b-toaster.b-toaster-bottom-left .b-toast.b-toaster-leave-active, .b-toaster.b-toaster-bottom-left .b-toast.b-toaster-move {
  transition: transform 0.175s;
}
.b-toaster.b-toaster-top-right .b-toast.b-toaster-enter-to .toast.fade, .b-toaster.b-toaster-top-right .b-toast.b-toaster-enter-active .toast.fade, .b-toaster.b-toaster-top-left .b-toast.b-toaster-enter-to .toast.fade, .b-toaster.b-toaster-top-left .b-toast.b-toaster-enter-active .toast.fade, .b-toaster.b-toaster-bottom-right .b-toast.b-toaster-enter-to .toast.fade, .b-toaster.b-toaster-bottom-right .b-toast.b-toaster-enter-active .toast.fade, .b-toaster.b-toaster-bottom-left .b-toast.b-toaster-enter-to .toast.fade, .b-toaster.b-toaster-bottom-left .b-toast.b-toaster-enter-active .toast.fade {
  transition-delay: 0.175s;
}
.b-toaster.b-toaster-top-right .b-toast.b-toaster-leave-active, .b-toaster.b-toaster-top-left .b-toast.b-toaster-leave-active, .b-toaster.b-toaster-bottom-right .b-toast.b-toaster-leave-active, .b-toaster.b-toaster-bottom-left .b-toast.b-toaster-leave-active {
  position: absolute;
  transition-delay: 0.175s;
}
.b-toaster.b-toaster-top-right .b-toast.b-toaster-leave-active .toast.fade, .b-toaster.b-toaster-top-left .b-toast.b-toaster-leave-active .toast.fade, .b-toaster.b-toaster-bottom-right .b-toast.b-toaster-leave-active .toast.fade, .b-toaster.b-toaster-bottom-left .b-toast.b-toaster-leave-active .toast.fade {
  transition-delay: 0s;
}
.tooltip.b-tooltip {
  display: block;
  opacity: 0.9;
  outline: 0;
}
.tooltip.b-tooltip.fade:not(.show) {
  opacity: 0;
}
.tooltip.b-tooltip.show {
  opacity: 0.9;
}
.tooltip.b-tooltip.noninteractive {
  pointer-events: none;
}
.tooltip.b-tooltip .arrow {
  margin: 0 4px;
}
.tooltip.b-tooltip.bs-tooltip-right .arrow, .tooltip.b-tooltip.bs-tooltip-auto[x-placement^=right] .arrow, .tooltip.b-tooltip.bs-tooltip-left .arrow, .tooltip.b-tooltip.bs-tooltip-auto[x-placement^=left] .arrow {
  margin: 4px 0;
}

.tooltip.b-tooltip-primary.bs-tooltip-top .arrow::before, .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=top] .arrow::before {
  border-top-color: #1976d2;
}
.tooltip.b-tooltip-primary.bs-tooltip-right .arrow::before, .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=right] .arrow::before {
  border-right-color: #1976d2;
}
.tooltip.b-tooltip-primary.bs-tooltip-bottom .arrow::before, .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  border-bottom-color: #1976d2;
}
.tooltip.b-tooltip-primary.bs-tooltip-left .arrow::before, .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^=left] .arrow::before {
  border-left-color: #1976d2;
}
.tooltip.b-tooltip-primary .tooltip-inner {
  color: #fff;
  background-color: #1976d2;
}

.tooltip.b-tooltip-secondary.bs-tooltip-top .arrow::before, .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=top] .arrow::before {
  border-top-color: #26a69a;
}
.tooltip.b-tooltip-secondary.bs-tooltip-right .arrow::before, .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=right] .arrow::before {
  border-right-color: #26a69a;
}
.tooltip.b-tooltip-secondary.bs-tooltip-bottom .arrow::before, .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  border-bottom-color: #26a69a;
}
.tooltip.b-tooltip-secondary.bs-tooltip-left .arrow::before, .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^=left] .arrow::before {
  border-left-color: #26a69a;
}
.tooltip.b-tooltip-secondary .tooltip-inner {
  color: #fff;
  background-color: #26a69a;
}

.tooltip.b-tooltip-success.bs-tooltip-top .arrow::before, .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=top] .arrow::before {
  border-top-color: #4caf50;
}
.tooltip.b-tooltip-success.bs-tooltip-right .arrow::before, .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=right] .arrow::before {
  border-right-color: #4caf50;
}
.tooltip.b-tooltip-success.bs-tooltip-bottom .arrow::before, .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  border-bottom-color: #4caf50;
}
.tooltip.b-tooltip-success.bs-tooltip-left .arrow::before, .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^=left] .arrow::before {
  border-left-color: #4caf50;
}
.tooltip.b-tooltip-success .tooltip-inner {
  color: #fff;
  background-color: #4caf50;
}

.tooltip.b-tooltip-info.bs-tooltip-top .arrow::before, .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=top] .arrow::before {
  border-top-color: #31ccec;
}
.tooltip.b-tooltip-info.bs-tooltip-right .arrow::before, .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=right] .arrow::before {
  border-right-color: #31ccec;
}
.tooltip.b-tooltip-info.bs-tooltip-bottom .arrow::before, .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  border-bottom-color: #31ccec;
}
.tooltip.b-tooltip-info.bs-tooltip-left .arrow::before, .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^=left] .arrow::before {
  border-left-color: #31ccec;
}
.tooltip.b-tooltip-info .tooltip-inner {
  color: #212529;
  background-color: #31ccec;
}

.tooltip.b-tooltip-warning.bs-tooltip-top .arrow::before, .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=top] .arrow::before {
  border-top-color: #f2c037;
}
.tooltip.b-tooltip-warning.bs-tooltip-right .arrow::before, .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=right] .arrow::before {
  border-right-color: #f2c037;
}
.tooltip.b-tooltip-warning.bs-tooltip-bottom .arrow::before, .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  border-bottom-color: #f2c037;
}
.tooltip.b-tooltip-warning.bs-tooltip-left .arrow::before, .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^=left] .arrow::before {
  border-left-color: #f2c037;
}
.tooltip.b-tooltip-warning .tooltip-inner {
  color: #212529;
  background-color: #f2c037;
}

.tooltip.b-tooltip-danger.bs-tooltip-top .arrow::before, .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=top] .arrow::before {
  border-top-color: #f44336;
}
.tooltip.b-tooltip-danger.bs-tooltip-right .arrow::before, .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=right] .arrow::before {
  border-right-color: #f44336;
}
.tooltip.b-tooltip-danger.bs-tooltip-bottom .arrow::before, .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  border-bottom-color: #f44336;
}
.tooltip.b-tooltip-danger.bs-tooltip-left .arrow::before, .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^=left] .arrow::before {
  border-left-color: #f44336;
}
.tooltip.b-tooltip-danger .tooltip-inner {
  color: #fff;
  background-color: #f44336;
}

.tooltip.b-tooltip-light.bs-tooltip-top .arrow::before, .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=top] .arrow::before {
  border-top-color: #f8f9fa;
}
.tooltip.b-tooltip-light.bs-tooltip-right .arrow::before, .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=right] .arrow::before {
  border-right-color: #f8f9fa;
}
.tooltip.b-tooltip-light.bs-tooltip-bottom .arrow::before, .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  border-bottom-color: #f8f9fa;
}
.tooltip.b-tooltip-light.bs-tooltip-left .arrow::before, .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^=left] .arrow::before {
  border-left-color: #f8f9fa;
}
.tooltip.b-tooltip-light .tooltip-inner {
  color: #212529;
  background-color: #f8f9fa;
}

.tooltip.b-tooltip-dark.bs-tooltip-top .arrow::before, .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=top] .arrow::before {
  border-top-color: #1d1d1d;
}
.tooltip.b-tooltip-dark.bs-tooltip-right .arrow::before, .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=right] .arrow::before {
  border-right-color: #1d1d1d;
}
.tooltip.b-tooltip-dark.bs-tooltip-bottom .arrow::before, .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  border-bottom-color: #1d1d1d;
}
.tooltip.b-tooltip-dark.bs-tooltip-left .arrow::before, .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^=left] .arrow::before {
  border-left-color: #1d1d1d;
}
.tooltip.b-tooltip-dark .tooltip-inner {
  color: #fff;
  background-color: #1d1d1d;
}

.b-icon.bi {
  display: inline-block;
  overflow: visible;
  vertical-align: -0.15em;
}
.b-icon.b-icon-animation-cylon, .b-icon.b-iconstack .b-icon-animation-cylon > g {
  transform-origin: center;
  animation: 0.75s infinite ease-in-out alternate b-icon-animation-cylon;
}
@media (prefers-reduced-motion: reduce) {
  .b-icon.b-icon-animation-cylon, .b-icon.b-iconstack .b-icon-animation-cylon > g {
    animation: none;
  }
}
.b-icon.b-icon-animation-cylon-vertical, .b-icon.b-iconstack .b-icon-animation-cylon-vertical > g {
  transform-origin: center;
  animation: 0.75s infinite ease-in-out alternate b-icon-animation-cylon-vertical;
}
@media (prefers-reduced-motion: reduce) {
  .b-icon.b-icon-animation-cylon-vertical, .b-icon.b-iconstack .b-icon-animation-cylon-vertical > g {
    animation: none;
  }
}
.b-icon.b-icon-animation-fade, .b-icon.b-iconstack .b-icon-animation-fade > g {
  transform-origin: center;
  animation: 0.75s infinite ease-in-out alternate b-icon-animation-fade;
}
@media (prefers-reduced-motion: reduce) {
  .b-icon.b-icon-animation-fade, .b-icon.b-iconstack .b-icon-animation-fade > g {
    animation: none;
  }
}
.b-icon.b-icon-animation-spin, .b-icon.b-iconstack .b-icon-animation-spin > g {
  transform-origin: center;
  animation: 2s infinite linear normal b-icon-animation-spin;
}
@media (prefers-reduced-motion: reduce) {
  .b-icon.b-icon-animation-spin, .b-icon.b-iconstack .b-icon-animation-spin > g {
    animation: none;
  }
}
.b-icon.b-icon-animation-spin-reverse, .b-icon.b-iconstack .b-icon-animation-spin-reverse > g {
  transform-origin: center;
  animation: 2s infinite linear reverse b-icon-animation-spin;
}
@media (prefers-reduced-motion: reduce) {
  .b-icon.b-icon-animation-spin-reverse, .b-icon.b-iconstack .b-icon-animation-spin-reverse > g {
    animation: none;
  }
}
.b-icon.b-icon-animation-spin-pulse, .b-icon.b-iconstack .b-icon-animation-spin-pulse > g {
  transform-origin: center;
  animation: 1s infinite steps(8) normal b-icon-animation-spin;
}
@media (prefers-reduced-motion: reduce) {
  .b-icon.b-icon-animation-spin-pulse, .b-icon.b-iconstack .b-icon-animation-spin-pulse > g {
    animation: none;
  }
}
.b-icon.b-icon-animation-spin-reverse-pulse, .b-icon.b-iconstack .b-icon-animation-spin-reverse-pulse > g {
  transform-origin: center;
  animation: 1s infinite steps(8) reverse b-icon-animation-spin;
}
@media (prefers-reduced-motion: reduce) {
  .b-icon.b-icon-animation-spin-reverse-pulse, .b-icon.b-iconstack .b-icon-animation-spin-reverse-pulse > g {
    animation: none;
  }
}
.b-icon.b-icon-animation-throb, .b-icon.b-iconstack .b-icon-animation-throb > g {
  transform-origin: center;
  animation: 0.75s infinite ease-in-out alternate b-icon-animation-throb;
}
@media (prefers-reduced-motion: reduce) {
  .b-icon.b-icon-animation-throb, .b-icon.b-iconstack .b-icon-animation-throb > g {
    animation: none;
  }
}

@keyframes b-icon-animation-cylon {
  0% {
    transform: translateX(-25%);
  }
  100% {
    transform: translateX(25%);
  }
}
@keyframes b-icon-animation-cylon-vertical {
  0% {
    transform: translateY(25%);
  }
  100% {
    transform: translateY(-25%);
  }
}
@keyframes b-icon-animation-fade {
  0% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes b-icon-animation-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
@keyframes b-icon-animation-throb {
  0% {
    opacity: 0.5;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
.btn .b-icon.bi,
.nav-link .b-icon.bi,
.dropdown-toggle .b-icon.bi,
.dropdown-item .b-icon.bi,
.input-group-text .b-icon.bi {
  font-size: 125%;
  vertical-align: text-bottom;
}

@font-face {
  font-family: "Aloicons";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url("./fonts/aloicons.woff2") format("woff2");
}
/*
 Mixins
 */
@keyframes ripple {
  from {
    opacity: 1;
    transform: scale3d(0.75, 0.75, 1);
  }
  to {
    opacity: 0;
    transform: scale3d(2.5, 2.5, 1);
  }
}
/*
 Design style guide
 */
.text-primary {
  color: #256eff !important;
}

.text-success {
  color: #00bf4a !important;
}

.text-danger {
  color: #fa003f !important;
}

.text-white, .active-call {
  color: #FFFFFF !important;
}

.text-info {
  color: #17a2b8 !important;
}

.text-dark {
  color: #15163f !important;
}

.text-light-grey, .active-call .call-status {
  color: #f8f9fa !important;
}

.text-dark-grey {
  color: #343a40 !important;
}

.text-grey-100, .parked-call .call-status, .parked-call, .text-xxs, .text-xs, .text-sm, .q-menu, .text-rg, .text-lg, .text-xl, .text-xxl, .text-xxxl, body, h5, h4, h3, h2, h1 {
  color: #040404;
}

.text-grey-90 {
  color: #62666E;
}

.text-grey-82 {
  color: #D5D6D8;
}

.text-grey-81 {
  color: #EAEAEC;
}

.text-grey-80, .search-form .input-group-text {
  color: #95989E;
}

.text-grey-70 {
  color: #D8D8D8;
}

.text-grey-60 {
  color: #EBEBEB;
}

.text-grey-50 {
  color: #F4F4F6;
}

.text-grey-40 {
  color: #F9F9FB;
}

.text-grey-30 {
  color: #A3A3A3;
}

.text-grey-20 {
  color: #6F6F6F;
}

.text-grey-10 {
  color: #828282 !important;
}

.text-red-100 {
  color: #C4183C;
}

.text-red-80 {
  color: #EB5757;
}

.text-red-130 {
  color: #721C24;
}

.bg-grey-light {
  background-color: #d8d8d8 !important;
}

.bg-grey-light11 {
  background-color: #e0e0e0 !important;
}

.bg-grey-93 {
  background-color: #BDBDBD !important;
}

.bg-grey-91 {
  background-color: #E5E5E5 !important;
}

.bg-grey-90 {
  background-color: #62666E !important;
}

.bg-grey-80 {
  background-color: #95989E !important;
}

.bg-grey-70 {
  background-color: #D8D8D8 !important;
}

.bg-grey-60 {
  background-color: #EBEBEB !important;
}

.bg-grey-50 {
  background-color: #F4F4F6 !important;
}

.bg-grey-40 {
  background-color: #F9F9FB !important;
}

.bg-grey-9 {
  background-color: #767C89 !important;
}

.bg-red-100 {
  background-color: #C4183C !important;
}

.bg-blue-80 {
  background-color: #15163F !important;
}

.bg-blue-70 {
  background-color: #EEF3FF !important;
}

.bg-blue-60 {
  background-color: #434464 !important;
}

.bg-blue-60-opaque {
  background-color: rgba(21, 22, 63, 0.8) !important;
}

.bg-blue-10 {
  background-color: #D3E2FF !important;
}

.bg-blue {
  background-color: #256eff !important;
}

.bg-green-90 {
  background-color: #CCF2DB !important;
}

.bg-green-10 {
  background-color: #CCF2DB !important;
}

.bg-red-90 {
  background-color: #FECCD9 !important;
}

.bg-red-10 {
  background-color: #FECCD9 !important;
}

.bg-grey-80 {
  background-color: #95989E !important;
}

.bg-grey-70 {
  background-color: #D8D8D8 !important;
}

.bg-primary {
  background-color: #256eff !important;
}

.bg-purple-100 {
  background-color: #9B51E0 !important;
}

.bg-red-9 {
  background-color: #F8D7DA !important;
}

.bg-yellow-10 {
  background-color: #f47f16 !important;
}

.bg-yellow-9 {
  background-color: #F9A925 !important;
}

.bg-yellow-8 {
  background-color: #FBC02d !important;
}

.bg-yellow-7 {
  background-color: #FDD836 !important;
}

.bg-yellow-6 {
  background-color: #FFEb3b !important;
}

.bg-yellow-5 {
  background-color: #FFEE58 !important;
}

.bg-amber-4 {
  background-color: #FFD54E !important;
}

.bg-orange-2 {
  background-color: #FFE0B1 !important;
}

.bg-primary {
  background: #256eff !important;
}

.bg-success {
  background: #00bf4a !important;
}

.bg-danger {
  background: #fa003f !important;
}

.bg-warning {
  background: #F2994A !important;
}

.bg-dark {
  background: #15163f !important;
}

._100 {
  font-weight: 100 !important;
}

._200 {
  font-weight: 200 !important;
}

._300 {
  font-weight: 300 !important;
}

._400 {
  font-weight: 400 !important;
}

._500 {
  font-weight: 500 !important;
}

._600 {
  font-weight: 600 !important;
}

._700 {
  font-weight: 700 !important;
}

._800 {
  font-weight: 800 !important;
}

h1 {
  font-size: 22px;
  font-weight: 600;
  letter-spacing: -0.0025em;
}

h2 {
  font-size: 17px;
  font-weight: 600;
  letter-spacing: -0.0025em;
}

h3 {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.0025em;
}

h4 {
  font-size: 15px;
  font-weight: 600;
  letter-spacing: -0.0025em;
}

h5 {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.0025em;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: "Inter", sans-serif;
  margin-bottom: 0rem;
}

body {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.0025em;
  font-family: "Inter", sans-serif;
}

.text-xxxl {
  font-size: 18px !important;
  font-weight: 400 !important;
  letter-spacing: -0.0025em !important;
}

.text-size-xxxl {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: -0.0025em;
}

.text-xxl {
  font-size: 17px !important;
  font-weight: 400 !important;
  letter-spacing: -0.0025em !important;
}

.text-size-xxl {
  font-size: 17px;
  font-weight: 400;
  letter-spacing: -0.0025em;
}

.text-xl {
  font-size: 16px !important;
  font-weight: 400 !important;
  letter-spacing: -0.0025em !important;
}

.text-size-xl {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.0025em;
}

.text-lg {
  font-size: 15px !important;
  font-weight: 400 !important;
  letter-spacing: -0.0025em !important;
}

.text-size-lg {
  font-size: 15px;
  font-weight: 400;
  letter-spacing: -0.0025em;
}

.text-size-lg-2 {
  font-size: 15px;
  font-weight: 500;
  letter-spacing: -0.0025em;
}

.text-rg {
  font-size: 14px !important;
  font-weight: 400 !important;
  letter-spacing: -0.0025em !important;
}

.text-size-rg, .form-control-search .q-field__focusable-action {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.0025em;
}

.text-sm, .q-menu {
  font-size: 13px !important;
  font-weight: 500 !important;
  letter-spacing: -0.0025em !important;
}

.text-size-sm {
  font-size: 13px;
  font-weight: 500;
  letter-spacing: -0.0025em;
}

.text-xs {
  font-size: 12px !important;
  font-weight: 400 !important;
  letter-spacing: -0.0025em !important;
}

.text-size-xs, .search-form .input-group-text {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: -0.0025em;
}

.text-xxs {
  font-size: 11px !important;
  font-weight: 400 !important;
  letter-spacing: -0.0025em !important;
}

.text-xxs2 {
  font-size: 11px !important;
  font-weight: 500 !important;
  letter-spacing: -0.0025em !important;
}

.text-size-xxs {
  font-size: 11px;
  font-weight: 400;
  letter-spacing: -0.0025em;
}

/*
 Other styling
 */
.bg-agent-status-offline {
  background-color: #95989E !important;
}

.bg-agent-status-available {
  background-color: #00bf4a !important;
}

.bg-agent-status-busy {
  background-color: #F15A22 !important;
}

.bg-agent-status-on-break {
  background-color: #f2994a !important;
}

.bg-agent-status-wrap-up {
  background-color: #BB6BD9 !important;
}

.bg-agent-status-on-call {
  background-color: #D3E2FF !important;
}

.bg-agent-status-ringing {
  background-color: #00c0cc !important;
}

.bg-agent-status-auto-dial {
  background-color: #BDBDBD !important;
}

.bg-agent-status-sentry {
  background-color: #15163f !important;
}

.bg-agent-status-all {
  background-color: transparent !important;
}

.text-agent-status-offline {
  color: #95989E;
}

.text-agent-status-available {
  color: #00bf4a;
}

.text-agent-status-busy {
  color: #F15A22;
}

.text-agent-status-on-break {
  color: #f2994a;
}

.text-agent-status-wrap-up {
  color: #BB6BD9;
}

.text-agent-status-on-call {
  color: #D3E2FF;
}

.text-agent-status-ringing {
  color: #00c0cc;
}

.text-agent-status-auto-dial {
  color: #BDBDBD;
}

.text-agent-status-sentry {
  color: #15163f;
}

.svg-fill-green {
  fill: #00bf4a;
}

.svg-fill-dark {
  fill: #15163f;
}

.svg-fill-black {
  fill: #040404;
}

.svg-fill-grey-dark {
  fill: #666666;
}

.svg-fill-grey-2 {
  fill: #989899;
}

.svg-fill-grey-light {
  fill: #d8d8d8;
}

.svg-fill-grey-light2 {
  fill: #F4F4F6;
}

.svg-fill-grey-light3 {
  fill: #e4e4e4;
}

.svg-fill-grey-light4 {
  fill: #d2d2d2;
}

.svg-fill-grey-light10 {
  fill: #cccccc;
}

.svg-fill-grey-mid {
  fill: #999999;
}

.svg-fill-white {
  fill: #FFFFFF;
}

.svg-fill-blue {
  fill: #256eff;
}

.svg-fill-orange {
  fill: #ff9f1c;
}

.svg-fill-red {
  fill: #fa003f;
}

.svg-fill-light-green {
  fill: #cff8df;
}

.svg-fill-flesh {
  fill: #feefe5;
}

.svg-fill-light-red {
  fill: #ffe8ee;
}

.svg-fill-light-blue {
  fill: #e1ebfe;
}

.svg-fill-light-orange {
  fill: #fff2df;
}

.svg-fill-grey-30 {
  fill: #A3A3A3;
}

.svg-fill-grey-90 {
  fill: #62666E;
}

.svg-fill-primary {
  fill: #256eff;
}

.box-container {
  position: relative;
}
.box-container .box-container-badge {
  padding: 0;
  margin: 0;
  z-index: 10;
  border: 1px grey solid;
  position: absolute;
  top: -5px;
}
.box-container .box-container-badge.floating-right {
  right: 0;
}
.box-container .box-container-badge.floating-left {
  left: 0;
}

.metric-box {
  background-color: #FFFFFF !important;
  position: relative;
  width: 199px !important;
  border: 1px solid #EBEBEB;
  border-radius: 10px;
  min-height: 130px;
  padding: 25px 20px 13px 20px;
}
.metric-box-item {
  width: 215px;
  height: 146px;
}
.metric-box.dashed-box {
  border: dashed 1px #D8D8D8 !important;
  background-color: #F9F9FB !important;
}
.metric-box .q-card__actions {
  padding: 0 0;
  margin: 0 0;
}
.metric-box .metric-box-label {
  font-size: 28px;
  font-weight: 600;
  letter-spacing: -0.25px !important;
  line-height: 34px;
  white-space: nowrap;
}
.metric-box .metric-box-desc {
  font-size: 15px;
  color: #62666E;
  letter-spacing: -0.6px;
  width: 100%;
  padding: 16px 0 0 0;
}
.metric-box .metric-box-desc .metric-label {
  line-height: 20px;
}
.metric-box .metric-box-desc .category-label {
  line-height: 20px;
  letter-spacing: -0.0025em;
  font-weight: normal;
}
.metric-box .metric-box-desc.metric-box-header {
  font-weight: 500;
  letter-spacing: 0;
}
.metric-box .metric-box-desc .add-metrics-wrapper {
  margin-top: 20px;
  font-size: 14px;
  color: #62666E;
  font-weight: 600;
}
.metric-box .metric-floating-btn {
  right: 5px;
  top: 5px;
  position: absolute;
}

.metric-group {
  position: relative;
}
.metric-group .group-delete {
  z-index: 10;
  border: 1px grey solid;
  right: -5px;
  top: -5px;
  position: absolute;
}
.metric-group .group-delete i {
  font-size: 11px;
  padding: 3px;
}
.metric-group .group-wrapper {
  border-radius: 12px;
}
.metric-group .group-wrapper .add-metrics,
.metric-group .group-wrapper .metric-loader {
  vertical-align: top;
}
.metric-group .group-wrapper .add-metrics .metric-box,
.metric-group .group-wrapper .add-metrics .metric-box-desc {
  padding: 0 0;
}
.metric-group .group-wrapper .metric-group-drawer {
  position: absolute;
  right: 0px;
  top: 0;
  height: 100%;
  width: 35px;
  padding: 10px;
  margin: 0;
}
.metric-group .group-wrapper .metric-group-drawer .drawer-icon {
  position: absolute;
  bottom: 10px;
}
.metric-group .group-wrapper .metric-group-drawer .drawer-icon i {
  font-size: 20px;
}

.report-group-header {
  margin-bottom: 3px;
}

.movable {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
}

.color-primary {
  color: #256eff;
}

.color-danger {
  color: #fa003f;
}

.color-black {
  color: #040404;
}

.t-menu,
.t-menu1 {
  display: flex;
  flex-direction: column;
}
.t-menu__header,
.t-menu1__header {
  min-height: 40px;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  border-top: 1px solid #D8D8D8;
  color: #62666E;
  letter-spacing: 1px;
}
.t-menu__header.no-border,
.t-menu1__header.no-border {
  border-top: none !important;
}
.t-menu__header.t-dense,
.t-menu1__header.t-dense {
  min-height: 25px !important;
  padding-top: 12px;
}
.t-menu__header .header__header__title,
.t-menu1__header .header__header__title {
  font-size: 11px;
}
.t-menu__content,
.t-menu1__content {
  height: calc(100% - 40px);
  overflow: auto;
}
.t-menu__content.over-flow,
.t-menu1__content.over-flow {
  overflow: unset;
}
.t-menu__content .t-label,
.t-menu1__content .t-label {
  font-size: 13px;
  letter-spacing: 0;
}
.t-menu__content .t-value,
.t-menu1__content .t-value {
  font-size: 18px;
}
.t-menu__content > .tree-folder .folder__arrow svg,
.t-menu1__content > .tree-folder .folder__arrow svg {
  margin-top: 10px !important;
  color: #62666E;
}
.t-menu__content > .tree-folder .folder__icon svg,
.t-menu1__content > .tree-folder .folder__icon svg {
  margin-top: 8px !important;
}
.t-menu__content > .tree-folder .folder__name,
.t-menu1__content > .tree-folder .folder__name {
  padding-top: 0px;
}
.t-menu__content > .tree-folder .folder__name.item-name,
.t-menu1__content > .tree-folder .folder__name.item-name {
  padding: 0 !important;
}
.t-menu__content > .tree-folder .folder__icon.item-icon,
.t-menu1__content > .tree-folder .folder__icon.item-icon {
  margin-top: -5px !important;
}
.t-menu__content > .tree-folder > .sublists > .tree-list-item:hover,
.t-menu1__content > .tree-folder > .sublists > .tree-list-item:hover {
  background-color: rgba(37, 110, 255, 0.06);
}
.t-menu__content > .tree-folder > .sublists > .tree-list-item > .folder .folder__icon,
.t-menu1__content > .tree-folder > .sublists > .tree-list-item > .folder .folder__icon {
  padding-left: 5px !important;
  top: -2px;
  position: relative;
}
.t-menu__content > .tree-folder > .animated > .sublists > .tree-list-item:hover,
.t-menu1__content > .tree-folder > .animated > .sublists > .tree-list-item:hover {
  background-color: rgba(37, 110, 255, 0.06);
}
.t-menu__content > .tree-folder > .animated > .sublists > .tree-list-item > .folder .folder__icon,
.t-menu1__content > .tree-folder > .animated > .sublists > .tree-list-item > .folder .folder__icon {
  padding-left: 5px !important;
  top: -2px;
  position: relative;
}
.t-menu__content > .tree-folder > .animated > .subfolders > .tree-folder > .animated > .sublists > .tree-list-item:hover,
.t-menu1__content > .tree-folder > .animated > .subfolders > .tree-folder > .animated > .sublists > .tree-list-item:hover {
  background-color: rgba(37, 110, 255, 0.06);
}
.t-menu__content > .tree-folder > .animated > .subfolders > .tree-folder > .animated > .sublists > .tree-list-item > .folder > .folder__icon,
.t-menu1__content > .tree-folder > .animated > .subfolders > .tree-folder > .animated > .sublists > .tree-list-item > .folder > .folder__icon {
  padding-left: 5px !important;
  top: -2px;
  position: relative;
}
.t-menu .icon,
.t-menu1 .icon {
  margin-top: -5px;
}
.t-menu .item,
.t-menu1 .item {
  color: #15163f;
  cursor: pointer;
  min-height: 40px;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-decoration: none;
  text-overflow: ellipsis;
  transition: background-color 100ms ease-in-out;
  white-space: nowrap;
}
.t-menu .item__no-cursor,
.t-menu1 .item__no-cursor {
  cursor: context-menu !important;
}
.t-menu .item:hover,
.t-menu1 .item:hover {
  background: #F9F9FB;
  color: #15163f;
  text-decoration: none;
}
.t-menu .item.router-link-exact-active,
.t-menu1 .item.router-link-exact-active {
  background: #F9F9FB;
}

.t-scroll-y {
  overflow-x: hidden;
  overflow-y: auto;
}

.t-scroll-y1 {
  height: calc(100vh - 478px) !important;
  overflow-x: hidden;
  overflow-y: auto;
}

.t-scroll-y2 {
  height: calc(100vh - 478px) !important;
  overflow-x: hidden;
  overflow-y: auto;
}

.no-border {
  border: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid #EBEBEB !important;
  border-radius: 0;
}

.border-top {
  border-top: 1px solid #EBEBEB !important;
  border-radius: 0;
}

.border-left {
  border-left: 1px solid #EBEBEB !important;
  border-radius: 0;
}

.border-right {
  border-right: 1px solid #EBEBEB !important;
  border-radius: 0;
}

/*.q-tree {

  .q-tree__node {
    padding-left: 0;

    .q-icon {
      padding: 0px 2px 4px 0px;
      font-size: 12px;
      margin-left: 5px;
    }

    > .q-tree__node-header {
      padding-left: 0;

      &:hover, &.active {
        background-color: lighten($green-10, 8%);
        border-radius: 0;
      }

      > .q-focus-helper {
        width: 100% !important;
      }

      > .q-tree__node-header-content {
        width: 100% !important;
        padding: 0;
        > .row {
          width: 100% !important;
        }
        .tree-list-item {
          width: 100%;
          color: black;
          text-decoration: none;
        }
      }
    }

    > .q-tree__node-collapsible {
      padding-left: 0;
      > .q-tree__children {
        > .q-tree__node {
          > .q-tree__node-header {
            padding-left: 15px;
          }
          > .q-tree__node-collapsible {
            > .q-tree__children {
              > .q-tree__node {
                > .q-tree__node-header {
                  padding-left: 30px;
                }
              }
            }
          }
        }
      }
    }

  }

  button {
    background-color: rgba(0,0,0,0);
    border: 0;
    top: -5px;
  }
}

.q-tree__node {
  // padding: 0 !important;
  padding-left: 0;

  .q-tree__children {
    padding-left: 0;
  }

  .q-tree__node-header-content {
    padding-left: 0 !important;
  }
}*/
.t-directory {
  padding: 0 !important;
}

.t-flex-group,
.t-flex-group__no-bg {
  display: flex;
  border-spacing: 0;
  width: 100%;
  height: 100%;
  position: relative;
}

.t-flex-group {
  background-color: #FFFFFF;
}

.t-flex-group__no-bg {
  background-color: #fafafa;
  overflow: hidden;
}
.t-flex-group__no-bg .card {
  flex: 0 0 auto;
}
.t-flex-group__no-bg .t-panel-container {
  flex: 1 1 auto;
}
.t-flex-group__no-bg .t-panel-container .q-panel {
  overflow: hidden;
}

.t-panel-container {
  height: 100%;
  overflow: hidden;
}
.t-panel-container .q-tab-panels {
  height: 100%;
  overflow: hidden;
}

.t-cascade-card {
  width: 100%;
  width: 426px;
}
.t-cascade-card__mini {
  width: 150px;
}

.breadcrumbs__directory {
  padding-right: 5px;
}
.breadcrumbs__directory div {
  font-family: Roboto;
  font-size: 12px;
}
.breadcrumbs__name {
  top: 2px;
  position: relative;
}

.right-spacing-1 {
  margin-right: 10px;
}

.right-spacing-2 {
  margin-right: 15px;
}

.text-10 {
  font-size: 10px;
}

.text-13 {
  font-size: 13px;
}

.text-14 {
  font-size: 14px;
}

.text-16 {
  font-size: 16px;
}

.bg-btn-red {
  background-color: #FCE7E7 !important;
}

.scrollable-dropdown-list .dropdown-menu {
  max-height: 500px;
  overflow-y: auto;
}

.filter-chips {
  position: absolute;
  left: 0;
  right: 0;
  display: block;
}

.hidden {
  display: none;
}

iframe > html {
  overflow: hidden;
}

.session-settings-title {
  max-width: 100%;
  word-break: break-word;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal;
  -webkit-line-clamp: 1;
  /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}

#session-list-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
}

.session-sidebar {
  min-width: 300px !important;
}

.hubspot-iframe-container,
.referrizer-iframe-container {
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.hubspot-iframe-container #hubspot-crm,
.hubspot-iframe-container #referrizer-crm,
.referrizer-iframe-container #hubspot-crm,
.referrizer-iframe-container #referrizer-crm {
  width: 100%;
  height: 100%;
}

.session-settings-sidebar .q-item__section {
  max-width: 135px;
}
.session-settings-sidebar .q-item__section button {
  max-height: 32px;
}

.f-btn--cancel {
  background-color: #95989E;
  color: #FFFFFF;
}

.modal-content {
  border-radius: 8px !important;
}

.t-badge {
  padding: 1px;
}
.t-badge__warning {
  background-color: #EB5757 !important;
}

.t-badge-name {
  padding: 0px;
}

.t-grouped-buttons {
  background-color: #EBEBEB;
  padding: 2px 0px;
  display: inline-block;
  border-radius: 6px;
}
.t-grouped-buttons a.link {
  text-decoration: none;
}
.t-grouped-buttons__btn {
  background-color: #EBEBEB;
  padding: 4px 10px;
  color: #62666E;
  display: inline-flex;
  margin: 1px 0;
  height: 26px;
  border-radius: 5px;
}
.t-grouped-buttons__btn .t__badge {
  background-color: #D8D8D8;
  font-size: 10px;
  line-height: 16px;
  padding: 0px 5px;
  margin: 1px 5px;
  border-radius: 5px;
  color: #62666E;
  height: 16px;
  top: 0px;
  position: relative;
}
.t-grouped-buttons__btn .t-badge-name {
  font-size: 14px;
}
.t-grouped-buttons__btn:active, .t-grouped-buttons__btn.active, .contact-list-sidebar-wrapper .t-grouped-buttons__btn.router-link-exact-active {
  background-color: #256eff;
  border-radius: 5px;
  color: #FFFFFF;
}
.t-grouped-buttons__btn:active .t__badge, .t-grouped-buttons__btn.active .t__badge, .contact-list-sidebar-wrapper .t-grouped-buttons__btn.router-link-exact-active .t__badge {
  background-color: #6297ff;
  color: #FFFFFF;
}
.t-grouped-buttons__btn:hover {
  background-color: #5890ff;
  border-radius: 5px;
  color: #FFFFFF;
}
.t-grouped-buttons__btn:hover .t__badge {
  background-color: #6297ff;
  color: #FFFFFF;
}

.start-dial-button {
  border-radius: 6px;
  height: 28px;
  width: 121px;
  font-size: 13px;
}
.start-dial-button .q-btn__wrapper {
  top: -2px;
}

.sessions-button {
  border-radius: 6px;
  height: 28px;
  width: 121px;
  font-size: 13px;
}
.sessions-button.no-outline .q-btn__wrapper {
  top: 0px !important;
  padding-left: 5px;
  padding-right: 5px;
}
.sessions-button.free-width {
  width: auto !important;
}
.sessions-button .q-btn__wrapper {
  top: -2px;
}
.sessions-button .q-btn__content {
  flex-wrap: nowrap;
}

.dropdown.show .dropdown-toggle {
  border: #256eff solid 1px !important;
}
.dropdown .dropdown-toggle {
  border-radius: 6px;
}

.t-expansion-panels .t-expansion-panel {
  padding: 14px 5px;
  position: relative;
}
.t-expansion-panels .t-expansion-panel:hover .dropdown.t-btn-floater__top {
  display: block;
}
.t-expansion-panels .t-expansion-panel:hover .dropdown.t-btn-floater__top .btn {
  display: block;
}
.t-expansion-panels .t-expansion-panel:hover .dropdown.t-btn-floater__bottom {
  display: block;
}
.t-expansion-panels .t-expansion-panel .dropdown.t-btn-floater__bottom {
  position: absolute;
  bottom: 12px;
  right: 15px;
  height: 15px;
  width: 15px;
  display: none;
}
.t-expansion-panels .t-expansion-panel .dropdown.t-btn-floater__top {
  position: absolute;
  top: 5px;
  right: 5px;
}
.t-expansion-panels .t-expansion-panel .dropdown.t-btn-floater__top .btn {
  display: none;
  padding: 0 5px !important;
  border: 0 !important;
}

.chip-ellipsis .q-chip.active, .chip-ellipsis .contact-list-sidebar-wrapper .q-chip.router-link-exact-active, .contact-list-sidebar-wrapper .chip-ellipsis .q-chip.router-link-exact-active {
  background-color: #256eff !important;
  color: #FFFFFF;
}
.chip-ellipsis .q-chip.active .q-chip__content .chip-label, .chip-ellipsis .contact-list-sidebar-wrapper .q-chip.router-link-exact-active .q-chip__content .chip-label, .contact-list-sidebar-wrapper .chip-ellipsis .q-chip.router-link-exact-active .q-chip__content .chip-label {
  color: #FFFFFF !important;
}
.chip-ellipsis .dropdown ul li.active, .chip-ellipsis .dropdown ul .contact-list-sidebar-wrapper li.router-link-exact-active, .contact-list-sidebar-wrapper .chip-ellipsis .dropdown ul li.router-link-exact-active {
  background-color: #256eff;
  border-radius: 8px;
}
.chip-ellipsis .dropdown ul li.active a, .chip-ellipsis .dropdown ul .contact-list-sidebar-wrapper li.router-link-exact-active a, .contact-list-sidebar-wrapper .chip-ellipsis .dropdown ul li.router-link-exact-active a {
  color: #FFFFFF;
}
.chip-ellipsis .dropdown ul li.active a:hover, .chip-ellipsis .dropdown ul .contact-list-sidebar-wrapper li.router-link-exact-active a:hover, .contact-list-sidebar-wrapper .chip-ellipsis .dropdown ul li.router-link-exact-active a:hover {
  color: black;
}

.contact-sidebar-list-wrapper.hide-toggle .q-expansion-item__toggle-focus,
.contact-sidebar-list-wrapper.hide-toggle .q-focusable {
  display: none;
}
.contact-sidebar-list-wrapper.hide-toggle #bs-folder-options {
  margin-top: -8px;
  right: 0;
  display: inline;
  position: absolute;
}

.btn-rounded {
  border-radius: 6px;
}

select optgroup {
  font-weight: 400;
  color: #95989E;
  padding: 10px;
}
select optgroup option {
  color: #040404;
  padding: 10px;
}

.square-box {
  height: 10px;
  width: 10px;
}

.make-invisible {
  opacity: 0;
}

.mini-select .q-field__inner .q-field__control {
  height: auto !important;
  min-height: auto !important;
  padding-top: 7px !important;
  padding-bottom: 7px !important;
}
.mini-select .q-field__inner .q-field__control .q-field__control-container .q-field__native {
  min-height: auto !important;
  font-size: 13px;
  padding: 0;
  padding-left: 15px !important;
}
.mini-select .q-field__inner .q-field__control .q-field__append {
  padding-right: 14px !important;
}

.text-11 {
  font-size: 11px !important;
}

.text-13 {
  font-size: 13px !important;
}

.text-15 {
  font-size: 15px !important;
}

.text-18 {
  font-size: 18px !important;
}

.t-cards .q-list .q-item {
  border-radius: 8px;
}
.t-cards .q-list .q-item.active, .t-cards .q-list .contact-list-sidebar-wrapper .q-item.router-link-exact-active, .contact-list-sidebar-wrapper .t-cards .q-list .q-item.router-link-exact-active {
  background-color: #EEF3FF;
}
.t-cards .q-list .q-item:hover {
  background-color: #F4F4F6 !important;
  cursor: pointer;
}
.t-cards .q-list .q-item .q-item__section .q-item__label {
  font-size: 14px;
  font-weight: 500 !important;
}
.t-cards .q-list .q-item .q-avatar {
  color: #FFFFFF;
}
.t-cards .q-list .q-item .q-avatar .q-avatar__content {
  font-size: 13px;
}

.t-expansion-panels .q-expansion-item__container .q-item .q-item__section.t-item-icon {
  padding-right: 0;
}
.t-expansion-panels .q-expansion-item__container .q-item .q-item__section--side {
  padding-right: 16px;
}

.vertical-collapse-enter-active,
.vertical-collapse-leave-active {
  transition: all 0.3s;
  max-height: 1500px;
}

.vertical-collapse-enter,
.vertical-collapse-leave-to {
  max-height: 0px;
}

.list-fade-enter,
.list-fade-leave-to {
  opacity: 0;
}

.list-fade-leave-active {
  position: absolute;
}

.slide-left-enter {
  transform: translateX(100%);
}

.slide-left-leave-active {
  transform: translateX(100%);
}

.horizontal-slide-left-enter-active,
.horizontal-slide-left-leave-active,
.horizontal-slide-right-enter-active,
.horizontal-slide-right-leave-active {
  transition: transform 0.5s;
}

.horizontal-slide-left-enter,
.horizontal-slide-left-leave {
  transform: translateX(-100vw);
}

.horizontal-slide-right-enter,
.horizontal-slide-right-leave {
  transform: translateX(100vw);
}

@keyframes fade {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fade {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}
html {
  overflow-y: hidden;
}

body {
  overflow-y: hidden;
  font-family: "Inter", sans-serif;
  color: #040404;
}

html,
body,
#q-app {
  height: 100%;
}

::-webkit-scrollbar-track {
  background-color: #f2f2f2;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d8d8d8;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #00bf4a;
}

.text-black,
.general-notification.text-black {
  color: #040404 !important;
}

.text-blue {
  color: #256eff !important;
}

.btn-white {
  border: 1px solid #EBEBEB;
  background: #FFFFFF;
}

.btn-white-secondary {
  border: 1px solid #D8D8D8;
  background: #FFFFFF;
}

.btn-primary {
  color: #FFFFFF;
  background-color: #256eff;
  border-color: #256eff;
}

.btn-primary:hover {
  background-color: #054CDB;
}

.btn-white:focus,
.btn-white.focus {
  background: #FFFFFF !important;
}

.btn-secondary {
  color: #62666E;
  background: #F4F4F6;
  border: none;
}

.btn-secondary:hover {
  color: #62666E;
  background: rgba(5, 76, 219, 0.1);
}

.btn-secondary:disabled {
  color: #62666E;
  background: #f4f4f6;
}

.btn-tertiary {
  color: #62666E !important;
  border: none !important;
  font-weight: 500;
}

.btn-tertiary:disabled {
  color: #62666E !important;
  opacity: 0.5;
}

.btn-tertiary:hover {
  color: #040404 !important;
  border: none !important;
}

.btn-contact-prev-next {
  width: 28px;
  height: 28px;
  padding: 0 4px !important;
  line-height: 1;
  border-radius: 6px;
}
.btn-contact-prev-next i {
  font-size: 16px;
}

.btn-contact-list-navigation {
  position: absolute;
  left: 300px;
}

.btn-inbox-list-navigation {
  position: absolute;
  left: 197px;
  outline: none !important;
}

.q-separator--horizontal-inset {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.row.q-field, .row.q-field__control-container, .row.q-field__control, .row.q-field__control-container, .row.q-notification__wrapper, .row.q-notification__content, .row.q-btn__wrapper, .row.q-btn__content, .row.q-avatar__content, .row.q-item, .row.q-field__native, .row.q-chip__content,
.row .q-field__inner {
  padding-right: 0;
  padding-left: 0;
  margin-left: 0;
  margin-right: 0;
}
.row.q-input {
  padding: 0 0;
}
.row.q-input .q-field__prepend {
  height: auto;
}
.row.q-input .q-field__prepend + .q-field__control-container .q-field__native {
  padding-left: 10px !important;
}
.row.q-input .q-field__control {
  height: 100%;
}
.row.q-input .q-field__control .q-field__native {
  padding-top: 0;
  padding-bottom: 0;
}
.row.q-chip {
  padding-left: 5.6px !important;
  padding-right: 5.6px !important;
  margin-left: 0 !important;
  margin-right: 5px !important;
}
.row.q-notification {
  margin-left: 0;
  margin-right: 15px;
}
.row .q-field__native,
.row .q-field:not(.q-field--float) .q-field__label,
.row .q-item__label {
  padding-right: 10px !important;
  padding-left: 10px !important;
}
.row .q-item__section .q-item__label {
  padding: inherit !important;
}
.row .q-field.q-field--float .q-field__label {
  padding-right: 20px;
  padding-left: 20px;
}
.row .q-field__append {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 8px;
}
.row .q-field__append.q-field__marginal {
  height: auto !important;
}
.row .q-field__bottom {
  padding-top: 3px;
}
.row .q-field__messages {
  flex: auto !important;
  width: 100% !important;
}

.q-field--outlined.q-field--highlighted.required .q-field__control:after {
  border-color: #fa003f;
}
.q-field--outlined.q-field--with-bottom {
  padding-bottom: 15px;
  height: initial !important;
}
.q-field--outlined.q-field--with-bottom .q-field__bottom {
  min-height: 15px;
  padding-left: 0;
}
.q-field--outlined.q-field--with-bottom .q-field__bottom .q-field__messages {
  padding-left: 10px;
}

.q-notifications .q-notification__message.col {
  padding-left: 0px;
  padding-right: 0px;
}

.blink {
  animation: fade 1500ms infinite;
  -webkit-animation: fade 1500ms infinite;
}

.guest .page-container {
  padding: 0 0 0 0 !important;
  margin: 0 0 0 0 !important;
}
.guest .main-content {
  padding: 0 !important;
}

.default-bg {
  background-color: #15163f;
}

.banner-font {
  font-family: "Poppins", sans-serif;
}

.text-banner-title {
  font-size: 2.47rem;
  font-weight: bold;
  line-height: 1.2;
}

.text-banner-subtitle {
  font-size: 1.25rem;
  font-weight: 300;
}

.ai-bg {
  background-image: url("../assets/pattern2_overlay.svg"), url("../assets/pattern2.jpg");
  background-repeat: no-repeat;
  background-size: 49vw, cover;
  background-position: center;
}

.xmas-bg {
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url("../assets/xmas-tree.jpg");
  background-color: #01132B;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

@media (max-width: 992px) {
  .page-title {
    font-size: 22px !important;
  }

  .q-drawer--right.q-drawer--bordered {
    border-left: 0px !important;
  }
}
@media (min-width: 605px) {
  .contact-details-container {
    margin-left: 15px;
    margin-top: 15px;
  }
}
@media (min-width: 605px) and (max-width: 991px) {
  .paginated .table-pagination button {
    min-width: 2em !important;
  }
  .paginated .table-pagination .q-btn__wrapper {
    padding: 0 0 !important;
  }
}
@media (min-width: 992px) {
  .dashboard header {
    left: 64px;
  }
  .dashboard .sidebar-wrapper,
.dashboard .sidebar-wrapper > aside {
    display: block !important;
  }
  .dashboard .sidebar-wrapper {
    width: 64px;
  }
  .dashboard.sidebar-expanded .sidebar-wrapper {
    width: 250px;
  }
  .dashboard .sidebar-wrapper > aside {
    transform: translateX(0px) !important;
    visibility: visible !important;
  }
}
@media (max-width: 575px) {
  .page-header {
    left: 0 !important;
  }

  .sidebar-active .page-container {
    padding-left: 0 !important;
  }
}
@media (max-width: 480px) {
  .sidebar-wrapper-sm {
    max-width: 38px;
  }
}
.rectangle {
  box-sizing: border-box;
  height: 60px;
  border-radius: 10px;
  background-color: #FFFFFF;
}
.rectangle:not(.q-field--highlighted) {
  border: 1px solid #d2d2d2;
}
.rectangle input {
  color: #202125;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.35px;
  line-height: 19px;
}
.rectangle .q-field__label {
  opacity: 0.9;
  color: #666666;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
}
.rectangle .q-field__inner .q-field__control:before {
  border: none !important;
}
.rectangle .q-field__inner .q-field__control:after {
  border-radius: 10px;
  top: -1px;
  height: 59px;
}

.checkbox input {
  cursor: pointer;
}
.checkbox .q-checkbox__inner .q-checkbox__bg {
  border-radius: 4px;
}
.checkbox .q-checkbox__inner:not(.text-positive) .q-checkbox__bg {
  border: 1px solid #d2d2d2;
}
.checkbox .q-checkbox__inner.text-positive .q-checkbox__bg {
  border: 1px solid #00BF4A;
}

.title {
  color: #202125;
  font-family: "Inter", sans-serif;
  font-size: 26px;
  font-weight: bold;
  letter-spacing: 0.35px;
  line-height: 36px;
}

.description {
  color: #e4e4e4;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  letter-spacing: 0.19px;
  line-height: 22px;
  text-align: center;
}

.description-sm {
  color: #999999;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
}
.description-sm a {
  color: #00BF4A;
}

.text-regular {
  text-transform: none !important;
}

.small-text {
  color: #202125;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  letter-spacing: 0.3px;
  line-height: 16px;
}
.small-text .status-label {
  margin-top: -15px;
}

.xs-text {
  font-family: "Inter", sans-serif;
  font-size: 10px;
  letter-spacing: 0.25px;
  line-height: 13px;
}

.link {
  height: 18px;
  width: 107px;
  color: #256eff;
  font-family: "Inter", sans-serif;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 18px;
}

.link-only {
  color: #256eff;
}
.link-only:hover {
  text-decoration: underline;
}

.mark-as {
  color: #256eff;
  font-family: "Inter", sans-serif;
  font-size: 13px;
  letter-spacing: -0.0025em;
  line-height: 16px;
}

.tag-text {
  font-weight: normal;
  font-size: 13px;
  letter-spacing: -0.0025em;
  line-height: 16px;
}

.details-contact-tags .tag-text {
  max-width: 177px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.tag-items {
  padding: 1px 5px;
  border-radius: 5px !important;
}

.generic-multi-select .list-wrapper .is-dot {
  top: 40%;
  position: absolute;
}
.generic-multi-select .edit-wrapper .dot-wrapper {
  height: 16px;
}
.generic-multi-select .edit-wrapper > .q-field__inner > .q-field__control:before {
  border: 0;
}
.generic-multi-select .edit-wrapper > .q-field__inner > .q-field__control:after {
  display: none;
}
.generic-multi-select .q-field > .q-field .tag-items {
  max-width: 230px;
}
.generic-multi-select .q-field > .q-field .tag-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.generic-multi-select .dropdown-select {
  max-height: 160px;
  height: 100%;
}
.generic-multi-select .dropdown-select .tag-items .tag-text {
  padding-left: 4px;
}
.generic-multi-select .dropdown-select .select-option:hover {
  background-color: #F4F4F6;
  border-radius: 6px;
}
.generic-multi-select .dropdown-select .select-group .tag-text {
  font-size: 14px !important;
  font-weight: 600 !important;
  letter-spacing: -0.0025em !important;
}
.generic-multi-select .dropdown-select .tag-items .is-dot {
  top: 43%;
  left: -8px;
}

.mb-30 {
  margin-bottom: 30px;
}

.remember-me {
  color: #666666;
  font-family: "Inter", sans-serif;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 18px;
}

.stats-page-btn {
  padding: 11px 29px 11px 28px !important;
  line-height: 18px;
}
.stats-page-btn .q-btn__wrapper {
  padding: 0;
  min-height: auto !important;
}
.stats-page-btn .q-btn__wrapper .q-btn__content {
  align-items: center;
  flex: auto;
}

.button {
  color: #FFFFFF;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 19px;
  text-align: center;
  border-radius: 8px;
}
.button .q-btn__wrapper,
.button .q-btn__content {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
}
.button .q-btn__wrapper:before {
  box-shadow: none;
}

.thank-you-container {
  width: 380px;
  border-radius: 8px;
  background-color: #FFFFFF;
  box-shadow: 2px 4px 20px 0 rgba(21, 22, 63, 0.2);
}
.thank-you-container .message {
  color: #666666;
  font-family: "Inter", sans-serif;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 18px;
}

.rounded-icon-opaque {
  border-radius: 32px;
  box-shadow: 0 0 0 8px rgba(0, 191, 74, 0.2);
}

.page-header {
  border-bottom: 1px solid #EBEBEB;
  width: auto;
  height: 62px;
  z-index: 1;
}
.page-header .q-item {
  padding: 0;
}
.page-header .q-icon {
  width: auto;
  height: auto;
}
.page-header > div:first-child {
  min-width: 0;
  flex: 1;
}
.page-header > div:first-child > h1 {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.page-sidebar {
  height: 100%;
  background-color: #15163f;
}
.page-sidebar .app-logo {
  -webkit-appearance: none;
}
.page-sidebar .app-logo .q-btn__wrapper:before {
  box-shadow: none;
}
.page-sidebar .nav-icons {
  width: 48px;
  height: 52px;
  -webkit-appearance: none;
}
.page-sidebar .nav-icons:hover {
  text-decoration: none;
}
.page-sidebar .nav-icons .q-icon {
  width: auto;
  height: auto;
}
.page-sidebar .nav-icons.custom-rotate-90 .q-icon {
  transform: rotate(90deg);
}
.page-sidebar .nav-icons.bordered-menu-icon .q-icon {
  border: 1px solid #9797AE;
  border-radius: 6px;
}
.page-sidebar .nav-icons .q-btn__wrapper:before {
  box-shadow: none;
}
.page-sidebar .q-badge--floating {
  top: 8px;
  right: 15px;
}

.sidebar-expanded .page-sidebar .app-logo .q-icon {
  width: auto !important;
}

.sidebar-expanded .app-logo {
  padding-left: 17px !important;
}

.page-footer .tab-icons {
  padding-top: 8px !important;
  padding-bottom: 2px !important;
  font-family: "Inter", sans-serif;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 12px;
  text-align: center;
}
.page-footer .tab-icons .tab-icon {
  display: flex;
  align-items: center;
  height: 20px;
  margin-bottom: 7px;
  color: #A3A3A3;
}
.page-footer .tab-icons .tab-icon .q-badge--floating {
  top: 2px;
  right: 14px;
}
.page-footer .q-tab {
  padding: 0 0 0 0;
  text-decoration: none;
}
.page-footer .q-tabs {
  margin-left: 0;
  margin-right: 0;
}
.page-footer .separator {
  width: 134px;
}
.page-footer .dialpad .q-tab__icon {
  width: 40px;
  height: 40px;
  color: #FFFFFF;
}
.page-footer .q-router-link--active.q-tab--inactive .q-tab__content:not(.tab-inactive),
.page-footer .q-router-link--active .q-tab__content.tab-inactive {
  color: #A3A3A3;
}
.page-footer .q-tab__content.tab-active {
  color: #2F80ED;
}

.sidebar-wrapper,
.sidebar-wrapper > aside {
  max-width: 64px;
  position: absolute !important;
  top: 0;
}

.sidebar-expanded .sidebar-wrapper,
.sidebar-expanded .sidebar-wrapper > aside {
  max-width: 250px;
}

.separator-blur {
  opacity: 0.2;
}

.page-icon,
.mobile-menu {
  display: none;
}

.profile-menu {
  padding: 0 5px !important;
}

.no-results {
  padding: 0 10px;
}

.avatar {
  border-radius: 17px;
  width: 34px;
  height: 34px;
  display: inline-block;
  font-size: 13px;
}
.avatar > span {
  vertical-align: middle;
}

.inactive {
  color: #666666;
}

.b-compact-dropdown-button {
  height: 24px;
}
.b-compact-dropdown-button button {
  padding: 0 0.5rem;
  font-size: 14px;
}
.b-compact-dropdown-button .dropdown-item {
  font-size: 14px;
}

.badge-tag {
  background: transparent;
  font-weight: 500;
  border: 1px solid #EBEBEB;
  margin-right: 5px;
}

.badge-tag-primary {
  color: #256eff;
}

.btn-bg-transparent {
  background: transparent;
}

.btn-b-0 {
  border: none;
}

.dropdown.bg-transparent > button.dropdown-toggle {
  background: transparent;
}

.dropdown.b-0 > button.dropdown-toggle {
  border: none;
}

.dropdown.bg-transparent > button.dropdown-toggle:hover,
.dropdown.b-0 > button.dropdown-toggle:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.w-px__250 {
  width: 250px;
}

.popover {
  font-family: "Inter", sans-serif !important;
}

.ellipse {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.z-index-1 {
  z-index: 1 !important;
}

.z-index-9999 {
  z-index: 9999 !important;
}

.badge-tag {
  font-size: 13px;
  max-width: 250px;
  padding-right: 8px;
  text-align: left;
}

.custom-link {
  font-size: 13px;
  color: #256eff;
}
.custom-link i {
  margin-top: -1px;
}

.contact-edit-popovers {
  left: -298px !important;
  width: 300px;
}

.message {
  display: flex;
  justify-content: flex-start;
}
.message .text-xxs {
  font-size: 12px;
  color: #62666E !important;
}
.message .contact-audit {
  font-size: 12px;
  color: #62666E !important;
}
.message:hover .mark-as {
  display: block !important;
}

.q-custom-select .q-field__control-container {
  border: 1px solid #256eff;
  border-radius: 5px;
}
.q-custom-select .q-field__append {
  display: none;
}
.q-custom-select .q-chip {
  height: auto;
  border: 1px solid #EBEBEB;
  border-radius: 6px;
  background: transparent;
}

.q-menu {
  max-height: 50vh !important;
  font-size: 90%;
}
.q-menu .q-virtual-scroll__content {
  padding: 5px;
}
.q-menu .q-virtual-scroll__content .q-item.q-item-type {
  padding: 5px;
  border-radius: 6px;
  min-height: 32px !important;
  margin: 2px 0;
}
.q-menu .q-virtual-scroll__content .q-item.q-item-type .q-item__label--header {
  padding: 4px !important;
}
.q-menu .q-virtual-scroll__content .q-item__label {
  padding-left: 0 !important;
}
.q-menu .q-virtual-scroll__content .q-item__section--avatar {
  min-width: 10px !important;
}

i.option-separator {
  font-size: 6px;
  top: -1px;
  position: relative;
}

.inline-select {
  padding-top: 10px;
}

.inline-select.q-select--without-input {
  padding-top: 0;
}

.inline-select.q-select--with-input {
  padding-top: 0;
  min-width: 245px;
}
.inline-select.q-select--with-input .q-field__input {
  display: none;
}
.inline-select.q-select--with-input .q-field__native {
  margin-top: 0;
}

.inline-select.q-field--disabled .q-field__control > div {
  opacity: 1 !important;
}

.inline-select.show-caret__always .q-field__marginal {
  opacity: 1 !important;
}

.inline-select.caret__grey-90 .q-select__dropdown-icon {
  color: #62666E;
}

.inline-select {
  margin-top: 0;
  width: 100%;
}
.inline-select .q-field__control,
.inline-select .q-field__native {
  height: 29px !important;
  min-height: 1px !important;
}
.inline-select .q-field__native, .inline-select .q-field__label, .inline-select .q-field__input {
  padding-left: 0 !important;
}
.inline-select .q-field__marginal {
  height: 29px !important;
  opacity: 0;
}
.inline-select .q-field__control:before {
  border-bottom: none !important;
}
.inline-select .q-select__dropdown-icon {
  color: #256eff;
}
.inline-select .q-anchor--skip {
  font-size: 100% !important;
  color: #256eff !important;
}
.inline-select .selected-option-separator {
  color: #D8D8D8;
  font-size: 40%;
  position: absolute;
  top: 13px;
}
.inline-select .selected-option-title {
  color: #D8D8D8;
  font-size: 13px;
  font-weight: 500;
  margin-left: 10px;
  position: relative;
  top: -4px;
}
.inline-select .selected-option {
  color: #040404;
  font-size: 13px;
  font-weight: 500;
  max-width: 110px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block !important;
  margin-top: -1px;
}
.inline-select .q-field__input {
  font-size: 13px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  margin-top: -4px;
}

.inline-select:focus-within .q-field__control:before {
  border-bottom: 0.5px solid #256eff !important;
}
.inline-select:focus-within .q-field__marginal {
  opacity: 1;
}

.inline-select.q-select--with-input .q-field__input {
  display: none;
}

.inline-select.q-select--with-input:focus-within .q-field__input {
  display: block;
}

.inline-select:hover .q-field__control:before {
  border-bottom: 0.5px solid #256eff !important;
}
.inline-select:hover .q-field__marginal {
  opacity: 1;
}

.inline-input:focus-within .q-field__append.q-field__marginal {
  opacity: 0;
}

.inline-input {
  margin-top: -2px;
  width: 100%;
}
.inline-input .q-field__native {
  padding-left: 0 !important;
  height: 29px !important;
}
.inline-input .q-field__marginal {
  height: 29px !important;
  opacity: 0;
}
.inline-input .q-field__control:before {
  border-bottom: none !important;
}
.inline-input .input-edit-icon {
  color: #256eff;
}
.inline-input .q-anchor--skip {
  font-size: 100% !important;
  color: #256eff !important;
}

.inline-input:focus-within .q-field__control:before {
  border-bottom: 0.5px solid #256eff !important;
}
.inline-input:focus-within .q-field__marginal {
  opacity: 1;
}

.inline-input:hover .q-field__control:before {
  border-bottom: 0.5px solid #256eff !important;
}
.inline-input:hover .q-field__marginal {
  opacity: 1;
}

.q-input-borderless textarea {
  min-height: 500px;
}
.q-input-borderless .q-field__control:before {
  border: none !important;
}

.fax-file-wrapper {
  height: 80px;
  width: 100%;
  border-radius: 4px;
  padding: 10px !important;
  border: 1px dashed #D8D8D8;
}
.fax-file-wrapper #fileInput {
  position: relative;
}
.fax-file-wrapper .uploader-label {
  position: absolute;
  width: 100%;
  top: 3px;
  font-size: 14px;
  font-weight: 500;
}
.fax-file-wrapper .uploading-label {
  position: absolute;
  top: 28px;
  width: 100%;
}
.fax-file-wrapper .uploading-label p {
  font-size: 12px;
}
.fax-file-wrapper form {
  height: 6vh;
}
.fax-file-wrapper #fileInput.dragdrop .custom-file,
.fax-file-wrapper #fileInput.dragdrop .custom-file-input {
  height: 100px;
}
.fax-file-wrapper #fileInput.dragdrop .custom-file-label {
  height: 80px;
  line-height: 90px;
  text-align: center;
  padding: 0;
  border: 1px dashed #D8D8D8;
  background: transparent;
}
.fax-file-wrapper #fileInput.dragdrop .custom-file-label span {
  visibility: hidden;
}
.fax-file-wrapper #fileInput.dragdrop label.custom-file-label:after {
  display: none !important;
}

.q-field__native.q-input-pl-0 {
  padding-left: 0 !important;
}

.q-field__native.q-input-pr-0 {
  padding-right: 0 !important;
}

.q-field__native.q-input-pl-4 {
  padding-left: 4px !important;
}

.q-field__native.q-input-pr-4 {
  padding-right: 4px !important;
}

.q-input-composer {
  max-height: 31vh;
}
.q-input-composer ::-webkit-scrollbar {
  display: none;
}

.q-input-composer textarea {
  resize: none !important;
  max-height: 31vh;
  overflow-y: auto !important;
}

.handle-whitespace {
  white-space: pre-line;
}

.break-word {
  word-break: break-word;
}

.q-input-composer .q-field__native,
.q-input-composer .q-field__control {
  min-height: 18px;
}

.contact-activity.activity-expanded {
  background: #FFFFFF !important;
}

.contact-activity {
  max-width: 380px;
  transition: transform 270ms cubic-bezier(0.79, 0.14, 0.15, 0.86) 0ms, border-color 270ms linear 0ms;
}
.contact-activity .q-item {
  min-height: 41px;
}
.contact-activity.activity-expanded.q-expansion-item {
  border: 1px solid #EBEBEB;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
.contact-activity.activity-expanded.q-expansion-item, .contact-activity.activity-expanded.q-expansion-item .q-expansion-item__container > .q-item, .contact-activity.activity-expanded.q-expansion-item .q-expansion-item__container > .q-item > .q-focus-helper {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
.contact-activity.activity-expanded.q-expansion-item .q-expansion-item__container > .q-item {
  border-bottom: 1px solid #EBEBEB;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  width: 100%;
}
.contact-activity.q-expansion-item--collapsed .q-expansion-item__container > .q-item {
  background: linear-gradient(0deg, #FFFFFF, #FFFFFF), rgba(37, 110, 255, 0.1);
  box-sizing: border-box;
  border-radius: 12px;
  border-bottom: 1px solid transparent;
}
.contact-activity.expand-animation-finished.q-expansion-item {
  border-radius: 12px;
}
.contact-activity.activity-unexpanded > .q-expansion-item__container > .q-item {
  border: 1px solid #EBEBEB;
}

.communication-info {
  display: inline-block;
  font-size: 14px;
  line-height: 18px;
}
.communication-info .communication-header {
  display: flex;
  align-items: center;
}
.communication-info .form-control-label {
  color: #62666E;
  font-size: 12px;
  line-height: 15px;
  padding-left: 0px;
  vertical-align: top;
  white-space: initial;
}
.communication-info .q-item__section--side {
  padding-left: 0px;
  padding-right: 0.5rem !important;
}

.contact-activity-sender {
  border: 1px solid rgba(47, 128, 237, 0.25);
}

.font-weight-light-bold {
  font-weight: 500;
}

.play-button {
  width: 26px;
  height: 26px;
  background: #256eff;
  border: 0;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.play-button i {
  font-size: 10.83px;
  color: #FFFFFF;
}

.waveform wave {
  height: 25px !important;
  overflow: hidden !important;
}

.waveform-timeline {
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.004em;
  color: #62666E;
}

.audio-player {
  background: #F4F4F6;
  border-radius: 8px;
  min-height: 42px;
}

@-webkit-keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
.rotating {
  -webkit-animation: rotating 2s linear infinite;
}

.border-half-rounded, .phone .btn-sm, .phone .btn-group-sm > .btn {
  border-radius: 6px;
}
.border-half-rounded.q-field--rounded .q-field__control, .phone .q-field--rounded.btn-sm .q-field__control, .phone .btn-group-sm > .q-field--rounded.btn .q-field__control {
  border-radius: 6px;
}

.border-rounded, .q-menu {
  border-radius: 8px;
}

.border-full-rounded {
  border-radius: 14px;
}

.sms-activity {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  padding: 11.2px 21.5px;
}

.message-composer-dropdown .dropdown-menu {
  font-size: 13px;
}

.q-btn-toggle {
  display: flex;
  border-radius: 6px !important;
}
.q-btn-toggle .q-btn__wrapper {
  padding: 0;
}
.q-btn-toggle button {
  font-size: 13px;
  flex: 1;
  padding-left: 5px;
  padding-right: 5px;
}
.q-btn-toggle button.text-primary {
  color: #000 !important;
}
.q-btn-toggle button:nth-child(3) {
  border-left: 1px solid #D8D8D8;
  border-right: 1px solid #D8D8D8;
}

.custom-multi-select.always-open .multiselect__content-wrapper {
  display: block !important;
  position: relative;
}

.custom-multi-select.multiselect--above.multiselect--active .multiselect__current,
.custom-multi-select.multiselect--above.multiselect--active .multiselect__input,
.custom-multi-select.multiselect--above.multiselect--active .multiselect__tags {
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
}

.custom-multi-select .multiselect__content-wrapper {
  margin-top: 4px;
  position: absolute;
}
.custom-multi-select .multiselect__tags {
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}
.custom-multi-select .multiselect__option {
  padding: 5px !important;
  border-radius: 8px;
  min-height: 28px !important;
  margin-top: 2px;
  margin-bottom: 2px;
}
.custom-multi-select .multiselect__option--highlight {
  background: #F4F4F6;
  color: #040404;
  border-radius: 8px;
}
.custom-multi-select .multiselect__option--selected.multiselect__option--highlight {
  background: #F4F4F6;
  color: #040404;
}
.custom-multi-select .multiselect__placeholder {
  margin-bottom: 8px !important;
  padding-top: 0 !important;
  font-size: 13px;
}
.badge-phone-info {
  font-size: 10px;
  font-weight: normal;
  color: #FFFFFF;
}

.multiselect, .multiselect__input, .multiselect__single {
  font-size: 13px !important;
}

.scheduled-messages-modal .modal-body {
  overflow-x: hidden !important;
  font-size: 13px;
}

.t2-badge {
  color: #FFFFFF;
}

.badge-primary {
  background: #256eff;
}

.badge-warning {
  background: #F2994A;
}

.badge-danger {
  background: #fa003f;
}

.badge-grey {
  background: #9e9e9e;
}

.badge-grey-80 {
  background: #95989E;
}

.badge-green-3 {
  background: #6FCF97;
}

.badge-yellow-1 {
  background: #F2C94C;
}

.badge-blue-3 {
  background: #56CCF2;
}

.badge-purple-2 {
  background: #BB6BD9;
}

.fs-45 {
  font-size: 45px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-24 {
  font-size: 24px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-13 {
  font-size: 13px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-8 {
  font-size: 8px !important;
}

.fs-6 {
  font-size: 6px !important;
}

.multiselect.border-blue .multiselect__tags {
  border: 1px solid #256eff;
}

.multiselect.always-open .multiselect__content-wrapper {
  display: block !important;
  position: relative;
}

.multiselect.options__no-border .multiselect__content-wrapper {
  border: none !important;
}

.multiselect.chip__clear-blue .multiselect__tag {
  color: #256eff;
  background: transparent;
  border: 1px solid #EBEBEB;
}
.multiselect.chip__clear-blue .multiselect__tag-icon {
  height: 15px;
  line-height: 11px;
  border-radius: 10px;
  border: 1px solid #62666E;
  right: 3px;
  top: 3px;
  width: 15px;
}
.multiselect.chip__clear-blue .multiselect.shrink-options .multiselect__option {
  padding: 5px !important;
  border-radius: 8px;
  min-height: 28px !important;
  margin-top: 2px;
  margin-bottom: 2px;
}
.multiselect.chip__clear-blue .multiselect__content-wrapper {
  border: 1px solid #256eff !important;
}

.multiselect.options-list__tall .multiselect__content-wrapper {
  max-height: 300px !important;
}

.multiselect.options__relative .multiselect__content-wrapper {
  position: relative !important;
}

.multiselect__option--selected {
  background: #EBEBEB !important;
  color: #040404 !important;
  font-weight: 500 !important;
}

.multiselect__option--highlight {
  background: #F4F4F6 !important;
  color: #62666E !important;
  outline: none !important;
}

.multiselect__option--highlight.multiselect__option--selected {
  background: #F4F4F6 !important;
  color: #62666E !important;
}

.multiselect__option {
  padding: 9px !important;
  border-radius: 8px;
  min-height: 32px !important;
  margin-top: 2px;
  margin-bottom: 2px;
}

.multiselect__content-wrapper {
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.multiselect__content-wrapper ul.multiselect__content li.multiselect__element span.multiselect__option span {
  display: inline-block;
  width: inherit;
  white-space: normal;
}

.multiselect .multiselect__content-wrapper::-webkit-scrollbar-track {
  display: none !important;
}

.b-radius__equal.multiselect--active:not(.multiselect--above) .multiselect__current,
.b-radius__equal.multiselect--active:not(.multiselect--above) .multiselect__input,
.b-radius__equal.multiselect--active:not(.multiselect--above) .multiselect__tags {
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.multiselect--active .multiselect__tags {
  border: 1px solid #256eff !important;
}

.contact-audit {
  width: 100%;
}

.is-dot {
  min-height: 5px;
  min-width: 4.8px;
  height: 5px;
  width: 4.8px;
  padding: 0;
  margin: 0;
}
.is-dot.unread-dot {
  transform: translateX(-14px);
}

.grey-light {
  background: #d8d8d8;
}

.blue {
  background: #256eff;
}

.contact-avatar {
  margin: 4.2px 0;
}

.activity-bottom-info {
  height: 30px;
}
.activity-bottom-info span {
  white-space: nowrap;
}

.date-time-picker .field-input {
  font-size: 13px !important;
  font-family: "Inter", sans-serif !important;
}

.time-picker-column::-webkit-scrollbar {
  display: block !important;
}

.time-picker-column[data-v-5bc85983]::-webkit-scrollbar {
  display: block;
}

.date-time-picker {
  font-family: "Inter", sans-serif !important;
  font-size: 13px !important;
}

.form-control:focus {
  color: #62666E;
  background-color: #FFFFFF;
  border-color: #256eff !important;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(25, 118, 210, 0.25);
}

.atwho-wrap .atwho-ul {
  margin: 0;
  max-height: 235px;
}
.atwho-wrap .atwho-ul .atwho-li {
  height: 38px !important;
  margin: 10px;
  border-radius: 8px;
}
.atwho-wrap .atwho-ul .atwho-li .contact-avatar {
  font-weight: 600;
}
.atwho-wrap .atwho-ul .atwho-li .contact-avatar span {
  overflow: inherit !important;
}
.atwho-wrap .atwho-ul .atwho-cur {
  background: #F4F4F6;
  color: #040404;
}
.atwho-wrap .atwho-view {
  max-width: 100% !important;
  min-width: 386px !important;
  max-height: 245px;
}

.tippy-content button.item {
  height: 38px !important;
  margin: 10px;
  border-radius: 8px;
}

[contenteditable=true] {
  font-size: 14px;
}

[contenteditable=true]:empty:before {
  content: attr(placeholder);
  pointer-events: none;
  display: block;
  /* For Firefox */
  opacity: 0.9;
}

[contenteditable=true]:focus-visible {
  outline: none !important;
}

.ProseMirror p.is-editor-empty:first-child::before {
  content: attr(data-placeholder);
  float: left;
  color: #F9F9FB;
  pointer-events: none;
  height: 0;
}

.border-no-top {
  border: 1px solid #EBEBEB;
  border-top: 0 !important;
}

.bottom-radius {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.collapsed-has-notes.no-expansion,
.collapsed-has-notes .q-item {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-bottom: 0 !important;
}

.scrollbar-white::-webkit-scrollbar-track {
  background-color: #FFFFFF;
}
.scrollbar-white::-webkit-scrollbar {
  background-color: #FFFFFF;
}

.text-left-align {
  text-align: left;
}

.text-center-align {
  text-align: center;
}

.text-right-align {
  text-align: right;
}

.no-box-shadow {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

/*
 Main layout
 */
.main-content {
  background: #FFFFFF;
}
.main-content .contacts .main {
  width: 100%;
  overflow: hidden;
}
.main-content .contacts .main .table-header > div > .row > div:first-child > div:nth-child(2) label.disabled,
.main-content .contacts .main .table-header > div > .row > div:nth-child(2) > div:nth-child(2) label.disabled {
  color: inherit !important;
}

/*
 Contact page
 */
.contact-list-sidebar-wrapper {
  height: 100%;
  border-right: 1px solid #e4e4e4;
  display: flex;
  justify-content: flex-end;
  position: relative;
}
.contact-list-sidebar-wrapper .router-link-exact-active {
  margin-top: 0 !important;
}
.contact-list-sidebar-wrapper .sidebar-toggle {
  position: absolute;
  top: 12px;
  right: -10px;
  z-index: 1;
  border: 1px solid #EBEBEB;
  background: #FFFFFF;
  padding: 0.15rem;
  line-height: 0.5;
  border-radius: 6px;
}
.contact-list-sidebar-wrapper .card {
  overflow: auto;
  position: relative;
  width: 300px;
}
.contact-list-sidebar-wrapper .card .list-group-item {
  padding: 0 0.5rem;
  height: 65px;
  margin-bottom: 13px;
}
.contact-list-sidebar-wrapper .card .list-group-item > div > div:nth-child(2),
.contact-list-sidebar-wrapper .card .list-group-item > div > div:nth-child(3) {
  height: 65px !important;
}
.contact-list-sidebar-wrapper .card .list-group-item > div div:nth-child(3) {
  border-bottom: 1px solid #EBEBEB;
}
.contact-list-sidebar-wrapper .card .list-group-item > div div:nth-child(3) .badge {
  padding: 7px 5px 5px;
  height: 20px;
  width: 20px;
}
.contact-list-sidebar-wrapper .card .list-group-item.active, .contact-list-sidebar-wrapper .card .list-group-item.router-link-exact-active,
.contact-list-sidebar-wrapper .card .list-group-item:hover {
  background: #F4F4F6;
  color: #040404;
  border-radius: 10px;
}
.contact-list-sidebar-wrapper .card .contact-details {
  border-bottom: 1px solid #F4F4F6;
  padding-top: 13px;
  min-width: 0;
  flex: 1;
}
.contact-list-sidebar-wrapper .card .contact-details .contact-name {
  font-size: 14px;
  color: #040404;
  font-weight: 500;
  display: inline-block;
  width: 190px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.contact-list-sidebar-wrapper .card .contact-details .contact-phone {
  font-size: 13px;
  font-weight: 400;
  color: #95989E;
}
.contact-list-sidebar-wrapper .card .contact-details .contact-badge {
  height: 20px;
  width: 20px;
  padding: 5px 0 !important;
  text-align: center;
}
.contact-list-sidebar-wrapper .contact-avatar {
  background-color: #95989E !important;
  color: #FFFFFF !important;
  font-weight: 600;
}
.contact-list-sidebar-wrapper .t-menu {
  max-height: 208px;
  height: 100%;
}

.contact-list-sidebar-container.width-0 {
  margin-left: 15px;
}

.contact-details-wrapper {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
}
.contact-details-wrapper .details-component-container {
  width: 100%;
  overflow: auto;
}
.contact-details-wrapper .details-component-container .card {
  width: 100%;
  margin: 0 auto 10px auto;
}

.contact-view-wrapper {
  overflow: hidden;
  position: relative;
  background: #F4F4F6;
  height: 100%;
}
.contact-view-wrapper .contact-activity-wrapper.w-less-630px {
  width: calc(100% - 630px);
}
.contact-view-wrapper .contact-activity-wrapper.w-less-345px {
  width: calc(100% - 345px);
}
.contact-view-wrapper .contact-activity-wrapper.w-less-330px {
  width: calc(100% - 330px);
}
.contact-view-wrapper .prev-activities .q-btn__wrapper {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.contact-info-wrapper:hover .btn-edit-contact-info {
  opacity: 1;
}

.contact-info-wrapper {
  display: flex;
  min-width: 0;
}
.contact-info-wrapper .contact-name-wrapper {
  display: flex;
  min-width: 0;
  flex-direction: column;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  flex-grow: 1;
}
.contact-info-wrapper .contact-name-wrapper .contact-name {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.contact-info-wrapper .contact-avatar {
  background-color: #95989E !important;
  font-weight: 600;
}
.contact-info-wrapper .q-item__section--avatar {
  padding-right: 0 !important;
  min-width: 0 !important;
}
.contact-info-wrapper .contact-phone {
  font-size: 12px;
  margin-top: -5px;
  right: 0;
}
.contact-info-wrapper .contact-phone .contact-primary-phone {
  position: relative;
  top: 0.5px;
  margin-right: 2px;
}
.contact-info-wrapper .contact-phone .copy-phone-number {
  text-decoration: none;
  color: #62666E;
}
.contact-info-wrapper .btn-edit-contact-info {
  position: absolute;
  right: -7px;
  top: -9px;
  opacity: 0;
}
.contact-info-wrapper .contact-action-button button:not(:last-child) {
  margin-right: 8px;
}
.contact-info-wrapper .contact-action-button .custom-action-button {
  background: #F4F4F6;
  color: #62666E;
  border: none;
  line-height: 1;
  width: 32px;
  height: 32px;
}

.edit-form-popover {
  left: -330px !important;
  width: 300px;
}

#appointment-modal .time-picker-column::-webkit-scrollbar {
  display: block;
}
#appointment-modal .appointment-form {
  font-size: 12px;
}
#appointment-modal .appointment-form .form-title {
  font-size: 11px;
}
#appointment-modal .appointment-form .sms-reminder-template-variables {
  cursor: pointer;
}
#appointment-modal .appointment-form .sms-reminder-template-variables:hover {
  color: #C4183C !important;
}
#appointment-modal .appointment-form .checkbox-wrapper .custom-control-label {
  padding-top: 0;
}
#appointment-modal .appointment-form span.sms-reminder-label {
  display: block;
}

.contact-activity-counts-wrapper .data-text {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  color: #040404;
}
.contact-activity-counts-wrapper .data-label {
  font-size: 13px;
  font-weight: 400;
  letter-spacing: -0.0025em;
  color: #62666E;
}

.phone-number-wrapper:hover div.options {
  opacity: 1;
}

.phone-number-wrapper .phone-number {
  font-size: 14px;
}
.phone-number-wrapper .phone-number-title {
  font-size: 12px;
  font-weight: normal;
  color: #FFFFFF;
  display: inline-block;
  top: 0;
  position: relative;
}
.phone-number-wrapper div.options {
  margin-top: -3px;
  opacity: 0;
}
.phone-number-wrapper div.options > *:not(:last-child) {
  margin-right: 4px;
}
.phone-number-wrapper div.options button {
  padding: 0.1rem 0.4rem !important;
  line-height: 12px !important;
}
.phone-number-wrapper div.options button svg, .phone-number-wrapper div.options button i {
  margin-top: -2px;
}
.phone-number-wrapper .phone-actions {
  font-size: 80%;
}
.phone-number-wrapper .phone-actions i, .phone-number-wrapper .phone-actions svg {
  margin-top: -3px;
}

.contact-phone-popover {
  left: -330px !important;
  width: 300px;
}

.contact-about-wrapper {
  padding-bottom: 10px;
}
.contact-about-wrapper .information-container {
  display: flex;
  flex-direction: column;
  height: 225px;
  padding: 0 !important;
  margin-bottom: 12px;
}
.contact-about-wrapper .information-container .custom-input-label {
  font-size: 12px;
}
.contact-about-wrapper .information-container.auto-height {
  height: 100%;
}
.contact-about-wrapper .contact-information-toggle {
  background: transparent;
  border: 1px solid #EBEBEB;
  position: absolute;
  margin: 8px 0 0 0;
  padding: 0 0 0 1px;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 32px;
  height: 32px;
}
.contact-about-wrapper .contact-information-toggle .material-icons {
  margin: auto auto;
  display: block;
}
.contact-about-wrapper .contact-information-toggle .icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tags-wrapper .badge-tag {
  background: transparent;
  font-weight: 500;
  border: 1px solid #EBEBEB;
  margin-right: 5px;
}
.tags-wrapper .custom-badge-primary {
  color: #256eff;
}
.tags-wrapper .custom-badge-secondary {
  color: #999999;
}
.tags-wrapper .custom-badge-success {
  color: #00bf4a;
}
.tags-wrapper .custom-badge-danger {
  color: #fa003f;
}
.tags-wrapper .custom-badge-warning {
  color: #F2994A;
}
.tags-wrapper .custom-badge-info {
  color: #17a2b8;
}
.tags-wrapper .custom-badge-light {
  color: #f8f9fa;
}
.tags-wrapper .custom-badge-dark {
  color: #15163f;
}
.tags-wrapper--dense .q-field__native.row {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.tags-wrapper--dense .q-field__native.q-placeholder.input-text-sm {
  padding-left: 0 !important;
}
.tags-wrapper--dense .with-value {
  padding-top: 10px !important;
}
.tags-wrapper.q-field--with-bottom {
  max-width: 100px !important;
}

.tags-filter-wrapper .card-body {
  padding: 0 !important;
}

.contact-notes-wrapper .notes {
  font-size: 14px;
}
.contact-notes-wrapper .contact-notes {
  border: none;
  opacity: 0.5;
}
.contact-notes-wrapper .contact-notes:hover {
  border: 1px solid #256eff;
}
.contact-notes-wrapper .notes-empty-placeholder {
  font-size: 13px;
  opacity: 0.5;
}

.contact-integration-wrapper .integration-title {
  font-size: 16px;
  font-weight: 500;
  color: #303133;
}

.integration-wrapper {
  margin-bottom: 10px;
}
.integration-wrapper .hubspot-card {
  box-shadow: 0 2px 10px rgba(4, 4, 4, 0.1) !important;
}
.integration-wrapper a {
  text-decoration: none;
  margin-left: 12px;
}
.integration-wrapper .name-wrapper {
  font-size: 14px;
  font-weight: 500;
}
.integration-wrapper p {
  font-size: 14px;
}
.integration-wrapper p .data-icon-label {
  color: #62666E;
}
.integration-wrapper .deals {
  background: #F4F4F6;
  border: none;
  border-radius: 6px !important;
}
.integration-wrapper .deals .data-value {
  max-width: 156px !important;
  top: 0 !important;
}
.integration-wrapper .deals .deals-title {
  font-size: 0.88rem;
  font-weight: 500;
  color: #303133;
}
.integration-wrapper .integration-title {
  font-size: 16px;
  font-weight: 500;
  color: #303133;
}
.integration-wrapper .hubspot-icon {
  color: #FF7A59;
  margin-right: 20px;
  font-size: 20px;
}
.integration-wrapper .pipedrive-btn {
  content: url(/integrations/pipedrive-icon.svg);
  margin-right: 20px;
  line-height: 48px;
  width: 32px;
  height: 32px;
}
.integration-wrapper .gohighlevel-btn {
  content: url(/integrations/ghl-icon.png);
  margin-right: 20px;
  line-height: 48px;
  width: 32px;
  height: 32px;
}
.integration-wrapper .data-value {
  max-width: 177px;
  display: inline-block;
  position: relative;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  top: 5px;
  font-weight: 500;
}
.integration-wrapper .data-icon-label {
  font-weight: 400;
}

.workflow-enroll-popover {
  left: -340px !important;
  width: 300px;
}

.contact-crm-integrations-wrapper .integrations-link:first-child {
  margin-top: 5px;
}
.contact-crm-integrations-wrapper .integrations-link:not(:last-child) {
  margin-bottom: 10px;
}
.contact-crm-integrations-wrapper .integrations-link {
  text-decoration: none;
  color: #040404;
  height: 52px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(4, 4, 4, 0.04);
  padding: 16px;
}
.contact-crm-integrations-wrapper .integrations-link .integrations-logo {
  background-size: contain !important;
  width: 18px;
  height: 18px;
  margin-top: 3px;
}

.w-30 {
  width: 30%;
}

.w-35 {
  width: 35%;
}

.w-40 {
  width: 40%;
}

.mx-w-100 {
  max-width: 100% !important;
}

.contact-activity-container {
  display: table;
  table-layout: fixed;
  border-spacing: 0;
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;
  position: relative;
}
.contact-activity-container .contact-activities {
  height: 100%;
  display: table-row;
  flex-direction: column;
  flex-grow: 1;
}
.contact-activity-container .contact-activities .inner-1 {
  position: relative;
  height: 100%;
  width: 100%;
}
.contact-activity-container .contact-activities .inner-1 .inner-2 {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
.contact-activity-container .composer-container-wrapper {
  width: 100%;
}

.composer-container {
  padding: 10px;
  background: #FFFFFF;
}
.composer-container .composer-wrapper {
  position: relative;
  border: 1px solid #D8D8D8;
  border-radius: 8px;
  padding: 10px 12px;
}
.composer-container .composer-wrapper .message-composer-send-button > button {
  border-top-left-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
}
.composer-container .composer-footer {
  font-size: 13px;
}

.tab-links {
  font-weight: 600;
  font-size: 13px;
}
.tab-links a {
  text-decoration: none;
  color: #62666E;
}
.tab-links a:hover {
  border-bottom: 2px solid #256eff;
  color: #256eff;
}
.tab-links a.active, .tab-links .contact-list-sidebar-wrapper a.router-link-exact-active, .contact-list-sidebar-wrapper .tab-links a.router-link-exact-active {
  border-bottom: 2px solid #256eff;
  color: #256eff;
}
.tab-links a:not(:last-child) {
  margin-right: 16px;
}

.message-composer-text-wrapper .file-dropper.position-absolute {
  width: 100%;
  left: 0;
  top: 32px;
  height: 100%;
  z-index: 0;
}
.message-composer-text-wrapper .b-textarea, .message-composer-text-wrapper .b-textarea:focus {
  border: none !important;
  box-shadow: none !important;
  padding: 0 !important;
  overflow: hidden;
}
.message-composer-text-wrapper .message-options {
  padding-top: 6px;
  z-index: 1;
}
.message-composer-text-wrapper .message-options a:focus,
.message-composer-text-wrapper .message-options a:focus-visible {
  outline: none !important;
  outline-offset: 0;
}
.message-composer-text-wrapper .message-options a:not(:first-child) {
  margin-left: 10px;
}
.message-composer-text-wrapper .popover {
  max-width: 100%;
}
.message-composer-text-wrapper .b-dropdown a.dropdown-item.disabled,
.message-composer-text-wrapper .b-dropdown a.dropdown-item:disabled,
.message-composer-text-wrapper .b-dropdown a.dropdown-item {
  font-size: 80%;
}
.message-composer-text-wrapper .media-preview-wrapper div:not(:first-child) {
  margin-left: 10px;
}
.message-composer-text-wrapper .media-preview-wrapper {
  width: 100%;
  overflow-x: auto;
  padding-bottom: 5px;
  padding-right: 1px;
}
.message-composer-text-wrapper .media-preview-wrapper .media-preview {
  position: relative;
}
.message-composer-text-wrapper .media-preview-wrapper .media-preview .img-preview {
  height: 76px;
  width: 76px;
  border: 1px solid #EBEBEB;
  border-radius: 6px;
  padding: 2px;
}
.message-composer-text-wrapper .media-preview-wrapper .media-preview .btn-remove-attachments,
.message-composer-text-wrapper .media-preview-wrapper .media-preview .btn-remove-file {
  position: absolute;
  top: 6px;
  right: 6px;
  font-size: 12px;
  height: 20px;
  width: 20.5px;
  padding: 0;
}
.message-composer-text-wrapper .media-preview-wrapper .media-preview .btn-remove-attachments:hover {
  background: #62666e;
  color: white;
}
.message-composer-text-wrapper .media-preview-wrapper .media-preview .btn-remove-file:hover {
  background: white;
}
.message-composer-text-wrapper .media-preview-wrapper .media-preview .btn-remove-file i {
  color: #62666E;
}
.message-composer-text-wrapper .media-preview-wrapper .media-preview .btn-remove-file:focus {
  outline: none;
  border-color: transparent !important;
  box-shadow: none !important;
}
.message-composer-text-wrapper .media-preview-wrapper .media-preview .btn-remove-attachments {
  opacity: 0;
}
.message-composer-text-wrapper .media-preview-wrapper .media-preview .pdf-thumbnail-wrapper {
  padding: 4px;
  width: 100px;
  height: 76px;
  border-radius: 8px;
  border: 1px solid #EBEBEB;
}
.message-composer-text-wrapper .media-preview-wrapper .media-preview .pdf-thumbnail-wrapper i.media-icon {
  color: #FE2216;
}
.message-composer-text-wrapper .media-preview-wrapper .media-preview .pdf-thumbnail-wrapper p {
  font-size: 10px;
  max-width: 90px;
}
.message-composer-text-wrapper .media-preview-wrapper .media-preview .pdf-thumbnail-wrapper .pdf-preview {
  overflow: hidden !important;
}
.message-composer-text-wrapper .media-preview-wrapper .media-preview .audio-thumbnail-wrapper {
  padding: 4px;
  width: 100px;
  height: 76px;
  border-radius: 8px;
  border: 1px solid #EBEBEB;
}
.message-composer-text-wrapper .media-preview-wrapper .media-preview .audio-thumbnail-wrapper p {
  font-size: 10px;
  max-width: 90px;
}
.message-composer-text-wrapper .media-preview-wrapper .media-preview .video-thumbnail-wrapper {
  height: 76px;
  width: 76px;
  border-radius: 8px;
  border: 1px solid #EBEBEB;
  padding: 4px;
}
.message-composer-text-wrapper .media-preview-wrapper .media-preview .video-thumbnail-wrapper .embed-responsive {
  height: 100%;
}
.message-composer-text-wrapper .media-preview-wrapper .media-preview .video-thumbnail-wrapper .btn-play {
  position: absolute;
  top: 29%;
  left: 31%;
  color: #62666E;
  cursor: inherit;
}
.message-composer-text-wrapper .media-preview-wrapper .media-preview:hover .btn-remove-attachments {
  opacity: 1;
}
.message-composer-text-wrapper .media-preview-wrapper .media-icon-wrapper {
  height: 42px;
  width: 42px;
  background: #EBEBEB;
  border-radius: 6px;
  text-align: center;
  margin: auto;
}
.message-composer-text-wrapper .media-preview-wrapper .media-icon-wrapper i.media-icon {
  margin-top: 10px;
  font-size: 20px;
  color: #B5B7BB;
}

.attachment-wrapper {
  height: 209px;
  width: 362px;
  padding: 10px;
}
.attachment-wrapper #fileInput {
  position: relative;
}
.attachment-wrapper .uploader-label {
  position: absolute;
  width: 100%;
  height: 128px;
  font-size: 14px;
  font-weight: 500;
  border: 1px dashed #D8D8D8;
  border-radius: 4px;
  padding-top: 30px;
}
.attachment-wrapper form {
  height: 130px;
  cursor: pointer;
  margin-bottom: 10px;
  position: relative;
}
.attachment-wrapper .attachment-upload-progress {
  margin: 100px auto 0 auto;
  width: 90%;
}
.attachment-wrapper #fileInput.dragdrop .custom-file,
.attachment-wrapper #fileInput.dragdrop .custom-file-input {
  height: 100px;
}
.attachment-wrapper #fileInput.dragdrop .custom-file-label {
  height: 100px;
  line-height: 90px;
  text-align: center;
  padding: 0;
  border: none !important;
  background: transparent;
}
.attachment-wrapper #fileInput.dragdrop .custom-file-label span {
  visibility: hidden;
}
.attachment-wrapper #fileInput.dragdrop .custom-file-label::after {
  display: none;
}
.attachment-wrapper .notice {
  font-size: 12px;
}
.attachment-wrapper [v-cloak] {
  display: none;
}
.attachment-wrapper .error-notice {
  text-align: center;
  color: #721c24;
  font-size: 80%;
  margin-top: 100px;
}

.file-uploader-wrapper {
  width: 100% !important;
  padding: 0 !important;
}

.message-composer-fax-wrapper .file-info {
  margin-bottom: 0 !important;
}
.message-composer-fax-wrapper .custom-progress-bar {
  width: 97% !important;
  height: 4px !important;
  margin-top: 10px;
}
.message-composer-fax-wrapper .custom-progress-bar div.progress-bar {
  background-color: #D8D8D8 !important;
}
.message-composer-fax-wrapper .btn-remove-file {
  color: #62666E;
}
.message-composer-fax-wrapper .media-preview-wrapper {
  width: 100%;
  overflow-x: auto;
  padding-bottom: 0;
  padding-right: 1px;
}
.message-composer-fax-wrapper .media-preview-wrapper .media-preview {
  position: relative;
}
.message-composer-fax-wrapper .media-preview-wrapper .media-preview .btn-remove-attachments {
  position: absolute;
  top: 6px;
  right: 6px;
  padding: 0.015rem 0.2rem;
  font-size: 50%;
  opacity: 0;
}
.message-composer-fax-wrapper .media-preview-wrapper .media-preview .pdf-thumbnail-wrapper {
  padding: 4px;
  width: 100px;
  height: 76px;
  border-radius: 8px;
  border: 1px solid #EBEBEB;
}
.message-composer-fax-wrapper .media-preview-wrapper .media-preview .pdf-thumbnail-wrapper i.media-icon {
  color: #FE2216;
}
.message-composer-fax-wrapper .media-preview-wrapper .media-preview .pdf-thumbnail-wrapper p {
  font-size: 10px;
  max-width: 90px;
}
.message-composer-fax-wrapper .media-preview-wrapper .media-preview .pdf-thumbnail-wrapper .pdf-preview {
  overflow: hidden !important;
}
.message-composer-fax-wrapper .media-preview-wrapper .media-preview:hover .btn-remove-attachments {
  opacity: 1;
}
.message-composer-fax-wrapper .media-preview-wrapper .media-icon-wrapper {
  height: 42px;
  width: 42px;
  background: #EBEBEB;
  border-radius: 6px;
  text-align: center;
  margin: auto;
}
.message-composer-fax-wrapper .media-preview-wrapper .media-icon-wrapper i.media-icon {
  margin-top: 10px;
  font-size: 20px;
  color: #B5B7BB;
}

.message-composer-email .b-textarea, .message-composer-email .b-textarea:focus {
  border: none !important;
  box-shadow: none !important;
  padding: 0 !important;
  overflow: hidden;
}
.message-composer-email .message-options {
  padding-top: 6px;
}
.message-composer-email .message-options a:focus,
.message-composer-email .message-options a:focus-visible {
  outline: none !important;
  outline-offset: 0;
}
.message-composer-email .message-options a:not(:first-child) {
  margin-left: 10px;
}
.message-composer-email .popover {
  max-width: 100%;
}
.message-composer-email .email-subject-input {
  padding: 0 !important;
}

.message-composer-note-wrapper .b-textarea, .message-composer-note-wrapper .b-textarea:focus {
  border: none !important;
  box-shadow: none !important;
  padding: 0 !important;
  overflow: hidden;
}
.message-composer-note-wrapper .message-options {
  padding-top: 6px;
}
.message-composer-note-wrapper .message-options a:focus,
.message-composer-note-wrapper .message-options a:focus-visible {
  outline: none !important;
  outline-offset: 0;
}
.message-composer-note-wrapper .message-options a:not(:first-child) {
  margin-left: 10px;
}
.message-composer-note-wrapper .popover {
  max-width: 100%;
}
.message-composer-note-wrapper .email-subject-input {
  padding: 0 !important;
}
.message-composer-note-wrapper .atwho-wrap textarea {
  border: none !important;
  box-shadow: none !important;
  padding: 0 !important;
  overflow: hidden !important;
  font-size: 14px;
}
.message-composer-note-wrapper .at-custom-text {
  margin-left: 36px;
  font-size: 13px;
  line-height: 1;
  height: 27px;
  margin-top: 2px;
}
.message-composer-note-wrapper [contenteditable=true] {
  font-size: 14px;
  display: block;
  outline: none;
  word-wrap: break-word;
}
.message-composer-note-wrapper [contenteditable=true]:empty:before {
  content: attr(placeholder);
  pointer-events: none;
  display: block;
  /* For Firefox */
  opacity: 0.9;
}
.message-composer-note-wrapper [contenteditable=true]:focus-visible {
  outline: none;
}

.scheduled-messages-modal .preview.file-name {
  max-width: 45px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.scheduled-messages-modal span.row-summary {
  display: block;
  margin-top: 8px;
}
.scheduled-messages-modal .media-preview-wrapper div:not(:first-child) {
  margin-left: 10px;
}
.scheduled-messages-modal .media-preview-wrapper {
  width: 100%;
  overflow-x: auto;
  padding-bottom: 5px;
  padding-right: 1px;
}
.scheduled-messages-modal .media-preview-wrapper .media-preview {
  position: relative;
}
.scheduled-messages-modal .media-preview-wrapper .media-preview .img-preview {
  height: 40px;
  width: 40px;
  border: 1px solid #EBEBEB;
  border-radius: 6px;
  padding: 2px;
}
.scheduled-messages-modal .media-preview-wrapper .media-preview .pdf-thumbnail-wrapper {
  padding: 4px;
  width: 50px;
  height: 39px;
  border-radius: 8px;
  border: 1px solid #EBEBEB;
}
.scheduled-messages-modal .media-preview-wrapper .media-preview .pdf-thumbnail-wrapper i.media-icon {
  color: #FE2216;
}
.scheduled-messages-modal .media-preview-wrapper .media-preview .pdf-thumbnail-wrapper p {
  font-size: 10px;
  max-width: 90px;
}
.scheduled-messages-modal .media-preview-wrapper .media-preview .pdf-thumbnail-wrapper .pdf-preview {
  overflow: hidden !important;
}
.scheduled-messages-modal .media-preview-wrapper .media-preview .audio-thumbnail-wrapper {
  padding: 4px;
  width: 100px;
  height: 76px;
  border-radius: 8px;
  border: 1px solid #EBEBEB;
}
.scheduled-messages-modal .media-preview-wrapper .media-preview .audio-thumbnail-wrapper p {
  font-size: 10px;
  max-width: 90px;
}
.scheduled-messages-modal .media-preview-wrapper .media-preview .video-thumbnail-wrapper {
  height: 76px;
  width: 76px;
  border-radius: 8px;
  border: 1px solid #EBEBEB;
  padding: 4px;
}
.scheduled-messages-modal .media-preview-wrapper .media-preview .video-thumbnail-wrapper .embed-responsive {
  height: 100%;
}
.scheduled-messages-modal .media-preview-wrapper .media-preview .video-thumbnail-wrapper .btn-play {
  position: absolute;
  top: 29%;
  left: 31%;
  color: #62666E;
  cursor: inherit;
}
.scheduled-messages-modal .media-preview-wrapper .media-preview:hover .btn-remove-attachments {
  opacity: 1;
}
.scheduled-messages-modal .media-preview-wrapper .media-icon-wrapper {
  height: 18px;
  width: 42px;
  border-radius: 6px;
  text-align: center;
  margin: auto;
}
.scheduled-messages-modal .media-preview-wrapper .media-icon-wrapper i.media-icon {
  font-size: 12px;
  color: #B5B7BB;
}

/*
 Bootstrap Vue
 */
.nav-pills .nav-link.active, .nav-pills .contact-list-sidebar-wrapper .nav-link.router-link-exact-active, .contact-list-sidebar-wrapper .nav-pills .nav-link.router-link-exact-active, .nav-pills .show > .nav-link {
  background-color: #256eff;
}

.btn-outline-dark, .btn-outline-dark-primary {
  border: 1px solid #D8D8D8;
}
.btn-outline-dark:active, .btn-outline-dark-primary:active, .btn-outline-dark:hover, .btn-outline-dark-primary:hover, .btn-outline-dark:focus, .btn-outline-dark-primary:focus {
  background-color: #FFFFFF;
  border-color: #D8D8D8;
  color: #040404;
  box-shadow: none !important;
}
.btn-outline-dark i, .btn-outline-dark-primary i {
  font-size: 16px;
  margin-top: -2px;
}

.btn-outline-dark-primary {
  color: #256eff;
}

/*
 Quasar related
 */
.no-q-btn-focus:hover .q-focus-helper, .page-header .q-item > .q-btn:hover .q-focus-helper, .page-sidebar .nav-icons:hover .q-focus-helper, .no-q-btn-focus:focus .q-focus-helper, .page-header .q-item > .q-btn:focus .q-focus-helper, .page-sidebar .nav-icons:focus .q-focus-helper {
  display: none !important;
}

.q-select__dropdown-icon {
  font-size: 16px;
}

.q-pa-sm, .phone .phone-expansion .integrations-card .card-body {
  padding: 10px;
}

.q-pa-md, .phone-settings {
  padding: 12px;
}

.q-btn.icon-btn .q-icon {
  font-size: inherit;
}

.row {
  margin-left: inherit;
  margin-right: inherit;
}

.q-select .q-field__input {
  padding-left: 0;
  padding-right: 0;
}

.tab-dropdown .q-focus-helper {
  display: none;
}
.tab-dropdown .q-btn-dropdown__arrow {
  font-size: 16px;
}
.tab-dropdown .availability-status {
  top: -2px;
  right: -2px;
}

.rounded-badge, .tab-dropdown .availability-status {
  height: 8px;
  width: 12px;
  border-radius: 50%;
  padding: 0;
}
.rounded-badge.small, .tab-dropdown .small.availability-status {
  height: 6px !important;
  width: 6px !important;
  min-height: unset;
}
.rounded-badge.bordered, .tab-dropdown .availability-status {
  border: 2px solid #FFFFFF;
}
.rounded-badge.bordered-grey, .tab-dropdown .bordered-grey.availability-status {
  border: 2px solid #d8d8d8;
}

.tab-dropdown-list .q-item {
  padding: 0 8px;
}
.tab-dropdown-list .q-item .user-status-name {
  top: 7px;
  margin-left: -3px;
  position: absolute;
}
.tab-dropdown-list .q-item .rounded-badge, .tab-dropdown-list .q-item .tab-dropdown .availability-status, .tab-dropdown .tab-dropdown-list .q-item .availability-status {
  margin-top: 8px;
  margin-left: 0;
}
.tab-dropdown-list .q-separator {
  margin-left: 18px !important;
  margin-right: 18px !important;
  background: #F4F4F6 !important;
}

/*
 Utilities
 */
.flex-even {
  flex: 1;
}

.break-all {
  word-break: break-all;
}

.text-word-wrap {
  overflow-wrap: break-word;
}

.auto-size {
  width: auto;
  height: auto;
}

.height-0 {
  height: 0;
}

.height-12 {
  height: 12px;
}

.height-16 {
  height: 16px;
}

.height-22 {
  height: 22px;
}

.height-24 {
  height: 24px;
}

.height-28 {
  height: 28px;
}

.height-32 {
  height: 32px;
}

.height-34 {
  height: 34px;
}

.height-35 {
  height: 35px;
}

.height-36 {
  height: 36px;
}

.height-52 {
  height: 52px;
}

.height-56 {
  height: 56px;
}

.height-140 {
  height: 140px;
}

.height-200 {
  height: 200px;
}

.height-240 {
  height: 240px;
}

.height-410 {
  height: 410px;
}

.height-445 {
  height: 445px;
}

.width-32 {
  width: 32px;
}

.width-40 {
  width: 40px;
}

.width-65 {
  width: 65px;
}

.width-190 {
  width: 190px;
}
.width-190.important {
  width: 190px !important;
}

.max-w-75 {
  max-width: 75% !important;
}

.max-w-85 {
  max-width: 85% !important;
}

.max-width-266 {
  max-width: 266px;
}

.width-250 {
  width: 250px;
}

.width-260 {
  width: 260px;
}

.width-290 {
  width: 290px;
}

.width-300 {
  width: 300px;
}

.width-330 {
  width: 330px;
}

.width-380 {
  width: 380px;
}

.max-width-380 {
  max-width: 380px;
  width: 100%;
}

.width-0 {
  width: 0;
}

.font-size-0 {
  font-size: 0;
}

.margin-auto {
  margin: auto auto;
}

.no-select, .phone .phone-expansion .q-item__label, .phone .phone-header, .dialer-tabs .nav-pills .nav-link {
  user-drag: none;
  -webkit-user-drag: none;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome and Opera */
}
.no-select ::selection, .phone .phone-expansion .q-item__label ::selection, .phone .phone-header ::selection, .dialer-tabs .nav-pills .nav-link ::selection {
  background: transparent;
  /* WebKit/Blink Browsers */
}
.no-select ::-moz-selection, .phone .phone-expansion .q-item__label ::-moz-selection, .phone .phone-header ::-moz-selection, .dialer-tabs .nav-pills .nav-link ::-moz-selection {
  background: transparent;
  /* Gecko Browsers */
}

.allow-select {
  -webkit-touch-callout: default;
  /* iOS Safari */
  -webkit-user-select: text;
  /* Safari */
  -khtml-user-select: text;
  /* Konqueror HTML */
  -moz-user-select: text;
  /* Firefox */
  -ms-user-select: text;
  /* Internet Explorer/Edge */
  user-select: text;
  /* Non-prefixed version, currently supported by Chrome and Opera */
}
.allow-select ::selection {
  background: rgba(37, 110, 255, 0.2) !important;
  /* WebKit/Blink Browsers */
}
.allow-select ::-moz-selection {
  background: rgba(37, 110, 255, 0.2) !important;
  /* Gecko Browsers */
}

.grabbable {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
}

/* (Optional) Apply a "closed-hand" cursor during drag operation. */
.grabbable:active {
  cursor: grabbing;
}

.page-side-menubar {
  max-width: 508px;
  background-color: #FFFFFF;
  display: flex;
  position: relative;
  overflow: hidden;
  overflow-x: auto;
}
@media (min-width: 768px) {
  .page-side-menubar {
    width: auto;
  }
}
.page-side-menubar__nav {
  padding-top: 10px;
}
.page-side-menubar__left {
  max-width: 200px;
  width: 100%;
  overflow: hidden;
}
.page-side-menubar__left--closed {
  min-width: 40px;
}
@media (min-width: 992px) {
  .page-side-menubar__left--closed {
    min-width: 40px;
  }
}
.page-side-menubar__left.settings {
  max-width: 300px;
}
.page-side-menubar__right {
  max-width: 308px;
  width: 100%;
  flex-grow: 1;
  border-left: solid 1px #e4e4e4;
  overflow: hidden;
  height: 100%;
}
.page-side-menubar .header {
  height: 45px;
  display: flex;
  align-items: center;
}
.page-side-menubar .header__label {
  padding-left: 20px;
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 0.3px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.page-side-menubar .header .form-control-search {
  font-size: 14px !important;
}
.page-side-menubar .header .form-control-search .q-field__native {
  padding-left: 15px !important;
}

.inbox-side {
  max-width: 558px;
  width: 100% !important;
  background-color: #FFFFFF;
  display: flex;
  position: relative;
  overflow: hidden;
  overflow-x: auto;
}
@media (min-width: 768px) {
  .inbox-side {
    width: auto;
  }
}
.inbox-side__nav {
  padding-top: 10px;
}
.inbox-side__nav .nav-item-separator {
  margin: 15px;
  border-color: #EBEBEB !important;
}
.inbox-side__left {
  max-width: 250px;
  width: 100%;
  overflow: hidden;
}
.inbox-side__left--closed {
  min-width: 40px;
}
@media (min-width: 992px) {
  .inbox-side__left--closed {
    min-width: 40px;
  }
}
.inbox-side__right {
  width: 100%;
  flex-grow: 1;
  border-left: solid 1px #e4e4e4;
  overflow: hidden;
  height: 100%;
}
.inbox-side .header {
  height: 45px;
  display: flex;
  align-items: center;
  flex-grow: 1;
  border-bottom: solid 1px #e4e4e4;
  padding-left: 6px;
}
.inbox-side .header__label {
  padding-left: 20px;
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 0.3px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.inbox-details {
  border: solid 1px #e4e4e4;
  border-top: 0;
}

.inbox-info {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.inbox-end {
  background-color: #FFFFFF;
  position: relative;
  overflow: hidden;
  max-width: 330px;
  width: 100%;
  display: none;
}
@media (min-width: 992px) {
  .inbox-end {
    display: flex;
  }
}

.form-control-search {
  height: 32px !important;
  font-size: 12px;
  margin-bottom: 0;
  border: 1px solid #D8D8D8;
}
.form-control-search input {
  line-height: 30px;
}
.form-control-search__icon {
  font-size: 12.5px;
  color: #999999;
}
.form-control-search .q-field__prepend {
  padding-left: 15px !important;
  padding-right: 0;
}
.form-control-search::-webkit-input-placeholder {
  font-size: 12px;
}

.task-item.active, .contact-list-sidebar-wrapper .task-item.router-link-exact-active {
  background: #F4F4F6;
}

.task-item .contact-name {
  font-size: 13px;
  font-weight: 600;
  letter-spacing: -0.0025em;
  line-height: 17px;
  color: #828282;
}
.task-item .contact-name.truncated-text {
  padding-right: 5px;
}
.task-item .avatar-wrapper {
  min-width: 64px;
}
.task-item .avatar {
  max-width: 64px;
  width: 100%;
}
.task-item .comm-label {
  font-size: 12px;
  font-weight: normal;
  letter-spacing: -0.0025em;
  line-height: 15px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.task-item .comm-label svg.item-identifier-icon {
  margin-top: -2px;
}
.task-item .comm-label.mention-content > span {
  width: 165px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.task-item .campaign-name {
  font-size: 10px;
  font-weight: normal;
  letter-spacing: -0.0025em;
  line-height: 12px;
  color: #828282 !important;
}
.task-item .time-passed {
  font-size: 12px;
  font-weight: normal;
  letter-spacing: -0.0025em;
  line-height: 15px;
}

.current-tasks .options {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.0025em;
}

.call-active {
  margin-bottom: 15px;
}
@media (min-width: 992px) {
  .call-active {
    display: none;
  }
}

.inbox {
  height: 100%;
  width: 100%;
  display: flex;
}
.inbox .not-in-inbox-label {
  padding: 0 12px;
}
.inbox .not-in-inbox-label span.badge {
  margin-top: -2px;
  position: relative;
}
.inbox .custom-toggle-button.q-btn-group--spread > .q-btn-item {
  flex: inherit;
  flex-grow: 1;
}
.inbox .custom-toggle-button.q-btn-group--spread > .q-btn-item:nth-child(2) {
  flex-grow: 1;
  min-width: 100px;
}
.inbox .task-list .task-item {
  padding-right: 14px;
}

.opacity-1 {
  opacity: 1 !important;
}

.opacity-05 {
  opacity: 0.5 !important;
}

.btn-header-nav-back {
  color: #62666E;
}

.custom-switch .custom-control-input ~ .custom-control-label::before {
  background: #D8D8D8 !important;
  border: none !important;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::before {
  background: #256eff !important;
  border: none !important;
}

.custom-switch.switch-success .custom-control-input:checked ~ .custom-control-label::before {
  background: #00bf4a !important;
  border: none !important;
}

.custom-switch.switch-primary .custom-control-input:checked ~ .custom-control-label::before {
  background: #256eff !important;
  border: none !important;
}

.custom-switch .custom-control-input ~ .custom-control-label::after {
  background: #FFFFFF !important;
  border: none !important;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background: white !important;
}

.custom-checkbox-w-50 .custom-control {
  width: 50% !important;
}

.custom-checkbox-w-100 .custom-control {
  width: 100% !important;
}

.dialer .invalid-feedback {
  width: inherit;
  margin-top: 0;
}

.vbt-autocomplete-list {
  z-index: 6001 !important;
  position: fixed !important;
}
.vbt-autocomplete-list .list-group-item.active, .vbt-autocomplete-list .contact-list-sidebar-wrapper .list-group-item.router-link-exact-active, .contact-list-sidebar-wrapper .vbt-autocomplete-list .list-group-item.router-link-exact-active {
  background: #E5EEFF;
  border: 1px solid #D3E2FF;
}

.dialer-contact-info {
  padding-left: 11px;
  padding-right: 11px;
  margin-bottom: 9px;
  margin-top: 0 !important;
}

.search-form.no-prepend .form-control {
  border-width: 1px !important;
  border-radius: 6px;
}
.search-form .form-control {
  font-size: 12px;
  padding-left: 8px;
  border-width: 1px !important;
  color: #040404;
  border-radius: 6px;
}
.search-form .form-control::-webkit-input-placeholder {
  font-size: 12px;
  color: #95989E;
}
.search-form .form-control:focus {
  border-color: #D8D8D8 !important;
  box-shadow: none !important;
}
.search-form .input-group-prepend {
  margin-right: 0;
}
.search-form .input-group-prepend .input-group-text {
  width: 100%;
  text-align: left;
}
.search-form .input-group-text {
  background: #FFFFFF;
  border-color: #D8D8D8;
  border-style: solid;
  border-width: 1px 0 1px 1px !important;
  padding-left: 8px;
  padding-right: 0;
  border-radius: 6px;
}

.dialer-tabs .col-auto {
  padding-left: 16px !important;
  padding-right: 16px !important;
  border-right: 0.5px solid #EBEBEB;
}
.dialer-tabs .nav-pills .nav-link {
  text-align: center;
}
.dialer-tabs textarea {
  color: #040404 !important;
}
.dialer-tabs textarea::-webkit-input-placeholder {
  color: #95989E;
}
.dialer-tabs textarea:focus {
  border-color: #D8D8D8 !important;
  border-style: solid;
  border-width: 1px 0 1px 1px !important;
  box-shadow: none !important;
  overflow: hidden;
}

.generic-selector {
  margin-bottom: 0;
  height: 40px !important;
  font-size: 12px !important;
}
.generic-selector .q-field__control {
  min-height: 40px !important;
  height: 40px !important;
  border-radius: 6px;
}
.generic-selector .q-field__control:before {
  border: 1px solid #D8D8D8 !important;
  border-radius: 6px;
}
.generic-selector .q-field__prepend {
  padding-left: 10px;
  height: 40px !important;
}
.generic-selector .q-field__native {
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.generic-selector .q-field__native span {
  position: relative;
  overflow: hidden;
  /*width: 100%;*/
  white-space: nowrap;
  text-overflow: ellipsis;
}
.generic-selector .q-field__native span:empty {
  display: none !important;
}
.generic-selector.with-prepend .q-field__native {
  padding-left: 0 !important;
}
.generic-selector .q-field__control-container {
  height: 40px !important;
}
.generic-selector .q-field__native {
  min-height: 40px !important;
  height: 40px !important;
  line-height: 16px !important;
}
.generic-selector .q-field__input {
  min-height: 16px;
  line-height: 16px;
}
.generic-selector .q-field__append {
  height: 40px !important;
}

.active-call {
  background: #15163f;
  border-radius: 7px;
  width: 190px;
}
.active-call.wrap-up {
  background: #95989E;
}
.active-call .call-status {
  font-size: 11px !important;
}

.parked-call {
  background: #F7F1FD;
  border: 1px solid #9B51E0;
  border-radius: 7px;
  width: 190px;
}
.parked-call:hover {
  background: #EEE3FB !important;
}
.parked-call:hover .q-focus-helper {
  background: unset !important;
}
.parked-call .call-status {
  font-size: 11px !important;
}

.mobile-parked-call {
  background: #F6F1FC;
  height: 59px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2px;
}
.mobile-parked-call .parked-call-content {
  padding-left: 2px;
  display: grid;
  grid-template-columns: 33.69px 1fr;
  font-size: 20px;
  font-weight: 600;
  color: #95989E;
  min-width: 0;
}
.mobile-parked-call .parked-call-content svg {
  margin-right: 14.31px;
}
.mobile-parked-call button {
  height: 28px;
  color: #9B51E0;
}
.mobile-parked-call button .q-btn__wrapper {
  padding: 0 10px !important;
}

.phone {
  position: fixed;
  top: 110px;
  right: 5px;
  width: 300px;
  height: 522px;
  border-radius: 12px;
  box-shadow: 0px 0px 12px -2px rgba(0, 0, 0, 0.08), 0px 10px 20px -4px rgba(4, 4, 4, 0.2);
  overflow: hidden;
}
.phone .phone-header {
  width: 100%;
  height: 36px;
  background: #15163f;
  padding-left: 12px;
  padding-right: 12px;
  flex-shrink: 0;
}
.phone .tab-dropdown.q-btn {
  line-height: 1;
}
.phone .no-arrow .q-btn-dropdown__arrow {
  display: none;
}
.phone #signal-strength {
  display: inline-flex;
  height: 12px;
  list-style: none;
  overflow: hidden;
  padding: 0;
  margin: 0;
}
.phone #signal-strength li {
  display: inline-block;
  width: 3px;
  float: left;
  height: 100%;
  margin-right: 1px;
}
.phone #signal-strength li.pretty-strong {
  padding-top: 0px;
}
.phone #signal-strength li.strong {
  padding-top: 3px;
}
.phone #signal-strength li.weak {
  padding-top: 6px;
}
.phone #signal-strength li.very-weak {
  padding-top: 9px;
}
.phone #signal-strength li div {
  height: 100%;
  border: 1px solid #00bf4a;
}
.phone #signal-strength li div.active, .phone #signal-strength li .contact-list-sidebar-wrapper div.router-link-exact-active, .contact-list-sidebar-wrapper .phone #signal-strength li div.router-link-exact-active {
  background: #00bf4a;
}
.phone .phone-notice {
  width: 264px;
  height: 46px;
}
.phone .phone-notice .q-banner__avatar {
  padding-left: 0;
  padding-right: 0;
  margin: auto;
}
.phone .phone-notice .q-banner__avatar > .q-icon {
  font-size: inherit;
}
.phone .phone-notice .q-banner__content {
  line-height: 16px;
}
.phone .phone-notice .q-btn .q-icon {
  font-size: inherit;
}
.phone .phone-notice .q-banner__actions {
  padding-left: 0;
  padding-right: 0;
  width: 14px;
  height: 14px;
  margin: auto;
}
.phone .phone-notice .q-banner__actions .q-btn__content {
  margin-top: -6px;
}
.phone .phone-info {
  width: 200px;
  height: 150px;
}
.phone .phone-info .w-100 {
  width: 100%;
}
.phone .phone-info .add-status {
  margin-left: 23px;
  font-size: 12px;
  color: #62666E;
}
.phone .phone-info.small {
  height: 66px;
}
.phone .phone-info .q-btn:not(.merge-btn) .q-btn__wrapper {
  min-width: 14px;
  min-height: 14px;
}
.phone .phone-info .q-btn:not(.merge-btn) .q-btn__content {
  padding: 0;
  height: auto;
}
.phone .phone-info .q-btn:not(.merge-btn) .q-icon {
  font-size: inherit;
}
.phone .phone-info .merge-btn {
  min-width: 82px;
}
.phone .phone-info .merge-btn .q-btn__content {
  padding: 0 9px;
  font-size: 14px;
  line-height: 1;
}
.phone .phone-status {
  width: 180px;
  height: 17px;
  color: #FFFFFF;
}
.phone .phone-cta {
  width: 180px;
  height: 150px;
  color: #FFFFFF;
}
.phone .ripple .q-btn__content::after {
  opacity: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  bottom: 0;
  content: "";
  height: 100%;
  width: 100%;
  border: 8px solid rgba(107, 107, 132, 0.3);
  border-radius: 100%;
  animation-name: ripple;
  animation-duration: 3s;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.65, 0, 0.34, 1);
  z-index: -1;
}
.phone .ripple .q-btn__content::before {
  opacity: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  bottom: 0;
  content: "";
  height: 100%;
  width: 100%;
  border: 8px solid rgba(107, 107, 132, 0.3);
  border-radius: 100%;
  animation-name: ripple;
  animation-duration: 3s;
  animation-delay: 0.5s;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.65, 0, 0.34, 1);
  z-index: -1;
}
.phone .phone-body {
  background: #15163f;
}
.phone .phone-main, .phone .phone-wrap-up {
  width: 100%;
  height: 100%;
  background: #FFFFFF;
}
.phone .phone-main .phone-avatar, .phone .phone-wrap-up .phone-avatar {
  margin-top: -25px;
}
.phone .phone-main .phone-avatar .contact-avatar, .phone .phone-wrap-up .phone-avatar .contact-avatar {
  font-size: 18px;
  margin: 0;
}
.phone .phone-main .border-bottom, .phone .phone-wrap-up .border-bottom {
  border-bottom: 1px solid #EBEBEB !important;
  border-radius: 0;
}
.phone .phone-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  padding: 0;
}
.phone .phone-buttons:focus, .phone .phone-buttons.focus {
  box-shadow: none;
}
.phone .phone-buttons span {
  margin-top: 10px;
  color: #62666E;
  font-size: 11px;
  font-weight: 500;
}
.phone .phone-buttons.elevated {
  background: #FFFFFF;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04), 0px 3px 10px rgba(4, 4, 4, 0.12);
  border-radius: 10px;
  width: 74px;
  height: 74px;
  padding: 0;
}
.phone .phone-buttons.elevated span {
  color: #62666E;
  font-size: 14px;
  font-weight: 600;
}
.phone .phone-digits {
  border: 0;
  padding: 0;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #040404;
}
.phone .dialpad {
  padding-left: 34px;
  padding-right: 34px;
}
.phone .dialpad .number-btn-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  padding: 0;
  border: 1px solid #EBEBEB;
  border-radius: 50px;
  overflow: hidden;
}
.phone .dialpad .number-btn-wrapper:focus, .phone .dialpad .number-btn-wrapper.focus {
  box-shadow: none;
}
.phone .dialpad .number-btn-wrapper .number-text {
  font-size: 24px;
  line-height: 29px;
  font-weight: 400;
}
.phone .dialpad .number-btn-wrapper .number-text-sub {
  font-size: 8px;
  line-height: 10px;
  text-align: center;
  font-weight: 400;
  color: #62666E;
}
.phone .contact-name {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}
.phone .contact-name .q-btn .q-btn__wrapper {
  min-width: 14px;
  min-height: 14px;
}
.phone .contact-name .q-btn .q-btn__content {
  padding: 0;
  height: auto;
}
.phone .contact-name .q-btn .q-icon {
  font-size: inherit;
}
.phone .contact-phone {
  font-size: 13px;
  line-height: 16px;
}
.phone .phone-wrap-up {
  max-height: 435px;
  font-size: 13px;
}
.phone .phone-footer-buttons {
  z-index: 3;
  height: 52px;
  box-shadow: 0px 0px 10px rgba(4, 4, 4, 0.2);
  background: #FFFFFF;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
  display: flex;
  justify-content: space-between;
}
.phone .phone-footer-buttons .btn {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  border-radius: 6px;
}
.phone .phone-footer-buttons .btn .b-icon {
  font-size: 13px;
  vertical-align: -0.15em;
}
.phone .phone-footer-buttons .btn-primary:active, .phone .phone-footer-buttons .btn-primary:hover, .phone .phone-footer-buttons .btn-primary:focus {
  background-color: #256eff;
  border-color: #256eff;
  color: #FFFFFF;
  box-shadow: none !important;
}
.phone .overlay {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}
.phone .phone-notes .q-field__inner {
  justify-content: start !important;
}
.phone .phone-expansion {
  background: transparent;
}
.phone .phone-expansion .q-expansion-item {
  box-shadow: 0px 0px 8px rgba(4, 4, 4, 0.12);
  border-radius: 12px;
}
.phone .phone-expansion .q-expansion-item .q-item {
  padding-left: 16px;
  padding-right: 16px;
}
.phone .phone-expansion .q-expansion-item .q-focus-helper {
  display: none !important;
}
.phone .phone-expansion .q-expansion-item:hover .q-focus-helper, .phone .phone-expansion .q-expansion-item:focus .q-focus-helper {
  display: none !important;
}
.phone .phone-expansion .q-expansion-item .q-expansion-item__toggle-icon {
  transform: rotate(180deg);
  width: 18px;
  height: 18px;
  font-size: 18px;
}
.phone .phone-expansion .q-expansion-item .q-expansion-item__toggle-icon--rotated {
  transform: rotate(0deg);
}
.phone .phone-expansion .q-expansion-item .q-expansion-item__content > .q-card > .q-card__section {
  padding: 0;
  overflow-y: auto;
}
.phone-widget {
  position: relative;
  top: 0;
  right: 0;
}

.phone-settings {
  width: 210px;
}
.phone-settings .q-field__native {
  padding: 2px 0;
  font-size: 14px;
  overflow: hidden;
}
.phone-settings .btn:focus, .phone-settings .btn.focus {
  box-shadow: none;
  text-decoration: none;
}

.phone-radio-select .q-item__section--avatar {
  min-width: 16px;
  padding-right: 8px;
}
.phone-radio-select .q-field__control {
  margin-top: 0;
}

.giphy-wrapper div.img-box.default-card-animation {
  cursor: pointer;
}

.mx-height-600 {
  max-height: 600px !important;
}

.mx-height-500 {
  max-height: 500px !important;
}

.mx-height-300 {
  max-height: 300px !important;
}

.option__group_title {
  color: #040404;
  font-weight: 600;
}

.basic-selector {
  border-radius: "8px";
}

.q-tooltip {
  background-color: #e0e0e0;
  color: #040404;
  box-shadow: 0 6px 14px rgba(0, 0, 0, 0.22);
}

.contact-save-bar-wrapper {
  position: fixed;
  width: 100%;
  background: #F9F9FB;
  height: 65px;
  padding: 17px 18px;
  bottom: 0;
  border-top: 1px solid #D8D8D8;
  z-index: 999;
  left: 0;
}
.contact-save-bar-wrapper .label {
  font-size: 14px;
  font-weight: 600;
  color: #62666E;
}

.q-item--active {
  background: #256eff !important;
  color: #FFFFFF !important;
}

.q-manual-focusable--focused:not(.q-item--active) {
  background: #F4F4F6 !important;
  color: #040404 !important;
}

.start-state {
  align-items: center;
  background: #FFFFFF;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  left: 0;
  padding-top: 36px;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.disabledButton .btn:not(.dropdown-toggle-split) {
  pointer-events: none;
  cursor: not-allowed;
}

.title-icon {
  padding-left: 5px;
  padding-right: 5px;
}

.selected-contacts {
  font-size: 11px;
}

.action-desc {
  padding-left: 20px;
}

.contacts-filter-sidebar {
  height: 100%;
  width: 400px;
}
.contacts-filter-sidebar .card.filter-container {
  border-top: 0;
}
.contacts-filter-sidebar .card {
  -webkit-border-radius: 0;
}
.contacts-filter-sidebar .card .card-header {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border-radius: unset;
}
.contacts-filter-sidebar .card .card-header .header-buttons {
  color: #62666E;
}
.contacts-filter-sidebar .filter-list {
  border: none;
  padding: 0;
  margin: 0;
}
.contacts-filter-sidebar .filter-divider {
  border: none;
  font-size: 10px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  margin: 0 0 5px 0;
  padding: 0 5px;
  font-weight: bold;
}
.contacts-filter-sidebar .filter-divider:not(:first-child) {
  margin-top: 15px;
}
.contacts-filter-sidebar .filter-list-item {
  border: none;
  padding: 0 5px;
  font-size: 13px;
  line-height: 30px;
  cursor: pointer;
  transition: background-color 100ms ease-in-out;
}
.contacts-filter-sidebar .filter-list-item:hover {
  background-color: #F2FBF6;
}
.contacts-filter-sidebar .filter-search-icon {
  background-color: transparent;
  border-radius: 0;
}
.contacts-filter-sidebar .filter-search-input {
  border-radius: 0;
}
.contacts-filter-sidebar .filter-contents {
  width: 100%;
  height: calc(100vh - 132px);
  overflow: auto;
}
.contacts-filter-sidebar .filter-contents.step-2 {
  overflow: hidden !important;
}
.contacts-filter-sidebar .filter-contents.step-2 .filter-list {
  height: calc(100vh - 220px);
  overflow: auto;
}
.contacts-filter-sidebar .contact-prop-label {
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.5px;
}
.contacts-filter-sidebar .add-filters {
  font-size: 13px;
}
.contacts-filter-sidebar .add-filters .add-icon {
  font-size: 16px;
}
.contacts-filter-sidebar .step-1,
.contacts-filter-sidebar .step-3,
.contacts-filter-sidebar .conjunction-button {
  font-size: 12px;
}
.contacts-filter-sidebar .step-3 .filter-label {
  font-weight: 600;
}
.contacts-filter-sidebar .step-3 .filter-operation {
  border-radius: 0.25rem;
}
.contacts-filter-sidebar .filter-name {
  color: #0090AF;
  font-weight: bold;
}
.contacts-filter-sidebar .filter-item {
  position: relative;
}
.contacts-filter-sidebar .filter-item .delete-filter {
  margin-top: 1rem;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}
.contacts-filter-sidebar .delete-group-filter {
  color: #0090AF;
  font-weight: bold;
}
.contacts-filter-sidebar .group-conjunction {
  line-height: 2.1em;
}

.relative-time {
  color: #999999;
}

.mycard {
  background: #FFFFFF;
  box-sizing: border-box;
  border: solid 1px #e4e4e4;
  border-radius: 10px;
  overflow: hidden;
}
.mycard.no-border {
  border: 0;
}
.mycard.dashed {
  border-style: dashed;
  cursor: pointer;
  background-color: #f8f8f8;
}
.mycard.dashed .row {
  color: #737373 !important;
}
.mycard.dashed .row span {
  font-size: 32px;
}

.mycard--shadow {
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);
}

.time-picker-column::-webkit-scrollbar {
  display: block;
}

.appointment-form {
  font-size: 12px;
}
.appointment-form .form-title {
  font-size: 11px;
}
.appointment-form .sms-reminder-template-variables {
  cursor: pointer;
}
.appointment-form .sms-reminder-template-variables:hover {
  color: #C4183C !important;
}
.appointment-form .checkbox-wrapper .custom-control-label {
  padding-top: 3px;
}

.tree-list-item .folder {
  line-height: 34px;
  cursor: pointer;
  user-select: none;
  transition: background-color 100ms ease-in-out;
}
.tree-list-item .folder__arrow {
  margin-top: -5px;
  margin-right: 5px;
}
.tree-list-item .folder__icon {
  margin-top: 1px;
  margin-right: 5px;
}
.tree-list-item .folder__name {
  display: flex;
  align-items: center;
}
.tree-list-item .folder__name span {
  display: inline-block;
  font-size: 14px;
  line-height: 17px;
  color: #040404;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.tree-list-item .folder__sub {
  padding-left: 10px;
}
.tree-list-item .folder__indent {
  width: 10px;
}
.tree-list-item .folder__option {
  margin-left: 5px;
  height: 34px;
}
.tree-list-item .folder__input {
  font-size: 12px;
  height: 100%;
  width: 100%;
  border: none;
  border-radius: 0;
}
.tree-list-item .folder__input:focus {
  outline-color: #00bf4a;
  -moz-outline-radius: 0;
}

.sublists.is-root .folder__indent {
  display: none;
}
.sublists > .layer-indent-1 > .folder > .folder-item {
  padding-left: 18px;
}
.sublists > .layer-indent-2 > .folder > .folder-item {
  padding-left: 30px;
}
.sublists > .layer-indent-3 > .folder > .folder-item {
  padding-left: 40px;
}

.communication-info .more-details {
  color: #256eff;
}

.avatar {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  font-weight: bold;
  line-height: 0;
  letter-spacing: 0.35px;
}
.avatar--src {
  color: transparent;
}
.avatar--active {
  border: solid 1px #00bf4a;
  background-color: white;
}
.avatar__inner {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.avatar__inner--active {
  background-color: #15163f;
  border: solid 2px #FFFFFF;
}

.checkbox__input {
  position: absolute;
  left: -36px;
  top: -36px;
}
.checkbox__input ~ div {
  width: 18px;
  height: 18px;
  background-color: #FFFFFF;
  border-radius: 2px;
  border: solid 2px #999999;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 100ms ease-in;
}
.checkbox__input ~ div svg {
  display: none;
}
.checkbox__input:checked ~ div {
  background-color: #00bf4a;
  border-color: #00bf4a;
}
.checkbox__input:checked ~ div svg {
  display: block;
}

.column-headers-modal {
  max-height: 100%;
}
@media (min-width: 992px) {
  .column-headers-modal {
    max-height: 100%;
  }
}
.column-headers-modal .ghost {
  border: dashed 1px #00bf4a !important;
}
.column-headers-modal .category-name {
  font-size: 10px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  margin-bottom: 5px;
  margin-top: 15px;
  font-weight: bold;
}
.column-headers-modal__checkboxes {
  overflow: auto;
}
.column-headers-modal__remove {
  width: 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  line-height: 10px;
}
.column-headers-modal__selected {
  font-size: 14px;
}
.column-headers-modal .handle:hover {
  background-color: #F2FBF6;
}
.column-headers-modal .handle {
  cursor: move;
}
.column-headers-modal__item {
  height: 35px;
}
.column-headers-modal__item--hidden {
  height: 0;
  visibility: hidden;
  overflow: hidden;
}
.column-headers-modal__item i {
  color: #989899;
  font-size: 10px;
}
.column-headers-modal__label {
  font-size: 14px;
}
.column-headers-modal .modal-title {
  color: #FFFFFF;
}
.column-headers-modal .modal-header {
  background-color: #15163f;
  border-radius: 0;
  padding: 15px;
}
.column-headers-modal .modal-header .close {
  color: #FFFFFF;
}
.column-headers-modal .modal-content {
  border-radius: 0;
  border: none;
}
.column-headers-modal .modal-footer .custom-btn {
  min-width: 120px;
}
.column-headers-modal .draggable-columns {
  height: calc(100% - 24px) !important;
}

.compact-button {
  height: 24px;
  line-height: 24px;
  padding: 0 8px;
  font-size: 12px;
  display: inline-flex;
  align-items: center;
}

.btn-outlined-light {
  border-color: #e0e0e0;
  color: #040404;
}
.btn-outlined-light.with-border {
  background-color: #EFF6FE;
}

.button-disabled {
  background-color: #E0E0E0 !important;
  border-color: #E0E0E0 !important;
  color: #828282;
}

.folder-create {
  padding-left: 10px;
  padding-right: 10px;
  height: 34px;
  cursor: pointer;
}
.folder-create:hover {
  background-color: white;
}
.folder-create__arrow {
  margin-top: -5px;
  margin-right: 5px;
}
.folder-create__icon {
  margin-top: -5px;
  margin-right: 5px;
}
.folder-create__name {
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.folder-create__indent {
  width: 10px;
}
.folder-create__input {
  height: 100%;
  width: 100%;
  border: none;
  border-radius: 0;
}
.folder-create__input:focus {
  outline-color: #00bf4a;
  -moz-outline-radius: 0;
}

.calls-header {
  height: 45px;
  display: flex;
  align-items: center;
  border-bottom: solid 1px #e4e4e4;
}
.calls-header__label {
  padding-left: 20px;
  font-weight: 600;
  font-size: 16px;
  display: flex;
  min-width: 0;
}
.calls-header__label .contact-name {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.confirm-dialog .modal-title {
  color: #15163f;
  font-size: 16px;
  font-weight: bold;
}
.confirm-dialog .modal-header {
  background-color: #FFFFFF;
  border-radius: 0;
  padding: 15px;
}
.confirm-dialog .modal-header .close {
  color: #15163f;
}
.confirm-dialog .modal-content {
  border-radius: 0;
  border: none;
  font-size: 14px;
}
.confirm-dialog .modal-footer .action-btn {
  min-width: 120px;
}

.contact-menu-item {
  height: 40px;
  cursor: pointer;
  padding-left: 17px;
  padding-right: 8px;
  transition: background-color 100ms ease-in-out;
}
.contact-menu-item__title {
  line-height: 12px;
  font-size: 12px;
  font-weight: 400;
}
.contact-menu-item__icon {
  margin-top: -2px;
}
.contact-menu-item__title, .contact-menu-item__icon, .contact-menu-item__suffix * {
  height: 40px;
}
.contact-menu-item:hover {
  background-color: #EBEBEB;
}

.time-picker-column::-webkit-scrollbar {
  display: block;
}

.appointment-form {
  font-size: 12px;
}
.appointment-form .form-title {
  font-size: 11px;
}
.appointment-form .sms-reminder-template-variables {
  cursor: pointer;
}
.appointment-form .sms-reminder-template-variables:hover {
  color: #C4183C !important;
}
.appointment-form .checkbox-wrapper .custom-control-label {
  padding-top: 3px;
}
.appointment-form span.sms-reminder-label {
  display: block;
  margin-top: 4px;
}

.contact-menu {
  width: 200px;
}
.contact-menu__title {
  padding-left: 2px;
  padding-right: 2px;
}
.contact-menu__title__inner {
  height: 34px;
  font-weight: bold;
  line-height: 14px;
  font-size: 12px;
}

.filters-modal {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.filters-modal .filter-list {
  border: none;
  padding: 0;
  margin: 0;
}
.filters-modal .filter-divider {
  border: none;
  font-size: 10px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  font-weight: bold;
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 5px;
}
.filters-modal .filter-divider:not(:first-child) {
  margin-top: 15px;
}
.filters-modal .filter-list-item {
  border: none;
  padding: 0;
  font-size: 13px;
  line-height: 30px;
  padding-left: 5px;
  padding-right: 5px;
  cursor: pointer;
  transition: background-color 100ms ease-in-out;
}
.filters-modal .filter-list-item:hover {
  background-color: #F2FBF6;
}
.filters-modal .filter-search-icon {
  background-color: transparent;
  border-radius: 0;
}
.filters-modal .filter-search-input {
  border-radius: 0;
}
.filters-modal .modal-title {
  font-size: 16px;
  color: #FFFFFF;
}
.filters-modal .modal-header {
  background-color: #15163f;
  border-radius: 0;
  padding: 15px;
}
.filters-modal .modal-header .close {
  color: #FFFFFF;
}
.filters-modal .modal-dialog {
  margin: 0;
  height: 100vh;
  min-width: 100vw;
  max-width: 100vw;
  display: flex;
  justify-content: flex-end;
}
.filters-modal .modal-body {
  padding: 0px;
  margin: 0;
}
.filters-modal .modal-content {
  border-radius: 0;
  border: none;
  height: 100%;
  overflow: hidden;
}
@media (min-width: 992px) {
  .filters-modal .modal-content {
    max-width: 324px;
  }
}
.filters-modal .filter-contents {
  width: 100%;
  height: calc(100vh - 61px);
  overflow: auto;
}
.filters-modal .modal-footer .custom-btn {
  min-width: 120px;
}

.list--header {
  font-size: 13px !important;
  color: #62666E !important;
}

button#bs-folder-options {
  margin-top: -9.2px;
  position: absolute;
}

.folders {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-bottom: 5px;
}
.folders.border-top .folders__header {
  padding-top: 16px;
  padding-bottom: 10px;
}
.folders.border-top .folders__header button#bs-folder-options {
  margin-top: -1px;
}
.folders__header {
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.03em;
  font-weight: 600;
  text-transform: uppercase;
  padding-bottom: 10px;
}
.folders__header__title {
  color: #62666E;
}
.folders__content {
  overflow: auto;
}
.folders__content.no-folder {
  padding-left: 18px;
  height: 34px;
}
.folders .item {
  cursor: pointer;
  font-size: 13px;
  line-height: 34px;
  transition: background-color 100ms ease-in-out;
}
.folders .item:hover {
  background: rgba(37, 110, 255, 0.06);
}
.folders .sublists .folder--active:not(:hover) {
  background-color: #F4F4F6;
}

.contact-popover__custom {
  padding: 4px !important;
  width: 260px;
}
.contact-popover__custom .popover-body .contact-menu {
  width: 100%;
}
.contact-popover .popover-body {
  padding: 0;
}

@media (min-width: 992px) {
  .create-list-modal .modal-lg {
    max-width: 650px;
  }
}
.create-list-modal .modal-body {
  padding: 0;
}
.create-list-modal__body {
  padding: 40px;
}
.create-list-modal__title {
  font-size: 18px;
  font-weight: 400;
  color: #040404;
}
.create-list-modal__close {
  margin-right: -15px;
  margin-top: -30px;
}
.create-list-modal__list-title {
  font-size: 14px;
  font-weight: 600;
  color: #040404;
}
.create-list-modal__list-desc {
  font-size: 13px;
  color: #202125;
}

@media (min-width: 992px) {
  .integration-import-list-modal .modal-lg {
    max-width: 650px;
  }
}
.integration-import-list-modal .modal-body {
  padding: 0;
}
.integration-import-list-modal__body {
  padding: 40px;
}
.integration-import-list-modal__title {
  font-size: 18px;
  font-weight: 400;
  color: #040404;
}
.integration-import-list-modal__close {
  margin-right: -15px;
  margin-top: -30px;
}
.integration-import-list-modal__list-title {
  font-size: 14px;
  font-weight: 600;
  color: #040404;
}
.integration-import-list-modal__list-desc {
  font-size: 13px;
  color: #202125;
}

@media (min-width: 992px) {
  .trial-expired-modal .modal-lg {
    max-width: 750px;
  }
}
.trial-expired-modal .modal-body {
  padding: 0;
}
.trial-expired-modal__body {
  font-size: 16px;
  padding: 20px;
  word-break: normal;
}
.trial-expired-modal__title {
  font-size: 28px;
  font-weight: 400;
  color: #040404;
}
.trial-expired-modal__close {
  margin-right: -15px;
  margin-top: -30px;
}
.trial-expired-modal__list-title {
  font-size: 14px;
  font-weight: 600;
  color: #040404;
}
.trial-expired-modal__list-desc {
  font-size: 13px;
  color: #202125;
}

.empty-state {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 35px;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFFFFF;
  z-index: 0;
}

.scrollableArea {
  position: relative;
  margin: auto;
  overflow-y: auto;
  overflow-x: auto;
}
.scrollableArea .datatable {
  z-index: 0;
}

.paginated .table-pagination .q-btn__wrapper {
  min-height: 35px !important;
}
.paginated .table-pagination .q-btn__wrapper:before {
  box-shadow: none !important;
}
.paginated .table-pagination .q-btn {
  font-size: 12px !important;
}
.paginated .table-pagination .q-btn-item {
  min-width: 2em !important;
}
.paginated .table-pagination > .q-btn:nth-child(2) {
  margin-right: 10px;
}
.paginated .table-pagination > .q-btn:nth-child(4) {
  margin-left: 10px;
}

.q-select-pager.q-field {
  font-size: 12px !important;
}

.static-list-add-item.scrollableArea {
  max-height: calc(100vh - 196px);
}

div.tableResizer {
  position: absolute;
  user-select: none;
  width: 12px;
  bottom: 0;
  right: 0;
  height: 100%;
  color: transparent;
  cursor: col-resize;
  z-index: 10;
  background-color: transparent;
  border-right: 2px solid rgba(37, 110, 255, 0);
}
div.tableResizer.resizing {
  background-color: rgba(37, 110, 255, 0.7);
}
div.tableResizer:hover {
  border-right: 2px solid rgba(37, 110, 255, 0.7) !important;
}
div.tableResizer:active {
  border-right: 2px solid rgba(37, 110, 255, 0.7);
}

table.datatable {
  border-collapse: separate;
  border-spacing: 0;
  border: none;
  box-sizing: border-box;
  position: relative;
  table-layout: fixed;
  min-width: 100%;
  word-break: break-all;
}
table.datatable--sticky-columns thead tr th {
  position: sticky;
  top: 0;
  z-index: 4;
  font-weight: 500;
  color: #62666E;
  font-size: 13px;
}
table.datatable--sticky-columns tbody tr td {
  z-index: 1;
}
table.datatable--sticky-columns tbody tr td .contact-name {
  font-weight: 500;
  color: #256eff;
}
table.datatable thead tr th.checkbox {
  width: 40px;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  padding: 0;
}
table.datatable thead tr th.checkbox input[type=checkbox] {
  padding: 0;
  display: block;
  height: 19px;
}
table.datatable thead tr .handle {
  cursor: move;
  margin-right: 0px;
  top: 2px;
  position: relative;
  color: #040404;
}
table.datatable thead tr .handle-label {
  margin-right: 0px;
  top: 2px;
  position: relative;
  color: #040404;
}
table.datatable thead tr th {
  background-color: #FFFFFF;
  border-bottom: solid 1px #EBEBEB;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  padding: 10px 30px 10px 8px;
  text-align: left;
  z-index: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  user-select: none;
  font-weight: 600;
}
table.datatable thead tr th > .move-icon-drag-container {
  opacity: 0;
}
table.datatable thead tr th.th-name .handle-label {
  padding-left: 38px;
}
table.datatable thead tr th.ghost {
  background-color: #F2FBF6;
}
table.datatable thead tr th:last-child {
  border-right: none;
}
table.datatable thead tr th:not(.checkbox):hover {
  background-color: #EBEBEB;
}
table.datatable thead tr th:not(.checkbox):hover.hovering {
  background-color: #e9f0ff;
}
table.datatable thead tr th .sorter-container {
  display: inline;
  opacity: 0;
}
table.datatable thead tr th .sorter-container.has-sorting {
  opacity: 1;
}
table.datatable thead tr th .sorter-container.has-sorting a.sorter.sorter-asc:before {
  opacity: 1 !important;
  position: relative;
  top: 0;
}
table.datatable thead tr th .sorter-container.has-sorting a.sorter.sorter-desc:after {
  opacity: 1 !important;
  position: relative;
  top: 0;
}
table.datatable thead tr th:hover .tableResizer {
  border-right: 2px solid #95989E;
}
table.datatable thead tr th:hover > .move-icon-drag-container {
  opacity: 1;
}
table.datatable thead tr th:hover a.sorter:before,
table.datatable thead tr th:hover a.sorter:after {
  opacity: 1;
}
table.datatable thead tr th:hover .sorter-container {
  display: inline;
  opacity: 1;
}
table.datatable thead tr th:hover .sorter-container.has-sorting a.sorter.sorter-desc:before {
  opacity: 1 !important;
  position: relative;
  top: 0px;
}
table.datatable thead tr th:hover .sorter-container.has-sorting a.sorter.sorter-desc:after {
  opacity: 1 !important;
}
table.datatable thead tr th:hover .sorter-container.has-sorting a.sorter.sorter-asc:before {
  opacity: 1 !important;
}
table.datatable thead tr th:hover .sorter-container.has-sorting a.sorter.sorter-asc:after {
  opacity: 1 !important;
  position: relative;
  top: 0px;
}
table.datatable thead tr th a.sorter {
  position: absolute;
  height: 30px;
  width: 30px;
  justify-content: center;
  padding-left: 6px;
}
table.datatable thead tr th a.sorter:before,
table.datatable thead tr th a.sorter:after {
  border: 4px solid transparent;
  content: "";
  display: block;
  height: 0;
  width: 0;
}
table.datatable thead tr th a.sorter:before {
  border-bottom-color: #A3A3A3;
  margin-top: 1px;
}
table.datatable thead tr th a.sorter:after {
  border-top-color: #A3A3A3;
  margin-top: 2px;
  margin-left: 0px;
}
table.datatable thead tr th a.sorter.sorter-asc:before {
  border-bottom-color: #256eff;
  opacity: 1;
}
table.datatable thead tr th a.sorter.sorter-desc:after {
  border-top-color: #256eff;
  opacity: 1;
}
table.datatable tbody tr:hover {
  background-color: #F6F6F6;
}
table.datatable tbody tr:hover td {
  background-color: #F2FBF6;
}
table.datatable tbody tr td.checkbox {
  min-width: 40px;
  max-width: 40px;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
}
table.datatable tbody tr td.checkbox input[type=checkbox] {
  padding: 0;
  display: block;
  height: 19px;
  margin: auto;
}
table.datatable tbody tr td:not(.datatable-row__checkbox) {
  background-color: #FFFFFF;
  padding: 8px;
  border-bottom: solid 1px #EBEBEB;
  font-size: 13px;
  z-index: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 0;
  min-width: 0;
}
table.datatable tbody tr td:not(.datatable-row__checkbox):read-write:focus {
  outline: 1px solid #B2EBC8;
  background: white;
}
table.datatable tbody tr td:not(.datatable-row__checkbox) .unread-text {
  font-size: 11px;
  line-height: 1;
}
table.datatable tbody tr td.datatable-row__checkbox {
  background-color: #FFFFFF;
  border-bottom: solid 1px #EBEBEB;
  font-size: 12px;
  z-index: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 0;
  min-width: 0;
  padding-right: 20px;
}
table.datatable tbody tr td.datatable-row__checkbox:read-write:focus {
  outline: 1px solid #B2EBC8;
  background: white;
}
table.datatable tbody tr:last-child td {
  border-bottom: none;
}

.submenu-items {
  background: white;
  width: 130px;
}

.folder-submenu-items,
.folder-childmenu-items {
  background: white;
  border-radius: 8px;
  padding: 6px 6px;
  border: solid 1px #d8d8d8;
  display: none;
  flex-direction: column;
  position: absolute;
}

.folder-submenu-items {
  width: 200px;
  box-shadow: 0px 0px 12px -2px rgba(0, 0, 0, 0.1), 0px 12px 18px -4px rgba(4, 4, 4, 0.1);
}
.folder-submenu-items.extended {
  width: 300px;
}

.folder-childmenu-items {
  width: 300px;
}

.import-modal .modal-content {
  border-radius: 0;
}
.import-modal .modal-body {
  padding: 0;
}
.import-modal .modal-header {
  color: #FFFFFF;
  background-color: #15163f;
  border-bottom-color: #15163f;
  border-radius: 0;
  padding: 15px;
}
.import-modal .modal-header .close {
  color: #FFFFFF;
}

.btn-prev {
  width: 100px;
  background-color: transparent;
  border-color: #00bf4a;
}
.btn-prev:hover {
  background-color: #00a640;
}

.btn-next {
  width: 100px;
  background-color: #00bf4a;
}
.btn-next:hover {
  background-color: #00a640;
}

.select-columns {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  position: relative;
}

.select-columns-alert {
  font-size: 13px;
  padding: 15px;
  width: 100%;
}
.select-columns-alert--success {
  border: solid 1px #00bf4a;
  background-color: #F2FBF6;
}
.select-columns-alert--dark {
  border: solid 1px #15163f;
  background-color: #f2f2fb;
}

.table-holder {
  max-height: 300px;
  max-width: calc(100% - 1px);
  overflow: auto;
}
.table-holder table {
  border-collapse: separate;
  border-spacing: 0;
}
.table-holder th {
  min-width: 100px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  position: sticky;
  top: 0;
  background-color: #FFFFFF;
  border-bottom-width: 1px !important;
}
.table-holder tr:first-child td {
  border-top: none;
}
.table-holder td {
  min-width: 100px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.datatable-row {
  font-size: 12px;
}
.datatable-row td .col-indented {
  padding-left: 19px;
}
.datatable-row td.col-indented-2 {
  padding-left: 28px !important;
}
.datatable-row__checkbox {
  max-width: 40px;
}
.datatable-row__checkbox input {
  margin-top: 5px;
}
.datatable-row__phone {
  min-width: 50px;
}
.datatable-row__name {
  min-width: 150px;
}
.datatable-row__actions {
  min-width: 40px;
  max-width: 40px;
  /*&__action {
    &--call:hover {
      color: $green;
    }

    &--chat:hover {
      color: $blue;
    }

    &--trash:hover {
      color: $red;
    }
  }*/
}
.datatable-row__actions button {
  color: #999999;
}

.contact-tags-title {
  font-size: 12px;
}

.contact-tags-item {
  font-size: 14px;
}

.import-content {
  min-height: calc(100vh - 155px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;
}
@media (min-width: 992px) {
  .import-content {
    min-height: 500px;
  }
}

.import-dropzone {
  flex-grow: 1;
  height: 100%;
  width: 100%;
  border: dashed 1px #00bf4a;
  display: flex;
  align-items: center;
  justify-content: center;
}
.import-dropzone-link {
  color: #00bf4a;
  font-weight: bold;
}
.import-dropzone-text {
  color: #989899;
  font-size: 14px;
}
.import-dropzone-content {
  text-align: center;
}
.import-dropzone .btn-upload {
  background-color: #00bf4a;
}
.import-dropzone .btn-upload:hover {
  background-color: #00a640;
}

.import-steps {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}
.import-steps__item {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-width: 3px;
  border-bottom-style: solid;
  border-bottom-color: #d8d8d8;
  flex-grow: 1;
  cursor: pointer;
}
.import-steps__item--active {
  border-color: #00bf4a !important;
  background-color: #00bf4a !important;
}
.import-steps__item--active .import-steps__item__number {
  color: #FFFFFF !important;
  border-color: #FFFFFF !important;
}
.import-steps__item--active .import-steps__item__title {
  color: #FFFFFF !important;
}
.import-steps__item--done {
  border-color: #00bf4a;
}
.import-steps__item--done .import-steps__item__number {
  color: #040404;
  border-color: #00bf4a;
}
.import-steps__item--done .import-steps__item__title {
  color: #040404;
}
.import-steps__item__number {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: solid 3px #d8d8d8;
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  justify-content: center;
  margin-bottom: 5px;
  color: #d8d8d8;
}
@media (min-width: 992px) {
  .import-steps__item__number {
    width: 40px;
    height: 40px;
  }
}
.import-steps__item__inner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
  padding-top: 10px;
  padding-bottom: 10px;
  transition: background-color 100ms ease-in-out;
}
.import-steps__item__title {
  display: none;
  font-weight: bold;
  color: #d8d8d8;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (min-width: 768px) {
  .import-steps__item__title {
    display: block;
    font-size: 10px;
  }
}
@media (min-width: 992px) {
  .import-steps__item__title {
    display: block;
    font-size: 14px;
  }
}
.import-steps__item:last-child {
  border-right: none;
}

.list-actions .folder {
  padding-left: 10px;
  padding-right: 10px;
  line-height: 34px;
  cursor: pointer;
  user-select: none;
  transition: background-color 100ms ease-in-out;
}
.list-actions .folder__arrow {
  margin-top: -5px;
  margin-right: 5px;
}
.list-actions .folder__icon {
  margin-top: -5px;
  margin-right: 5px;
}
.list-actions .folder:hover {
  background-color: rgba(37, 110, 255, 0.06);
}
.list-actions .folder__name {
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.list-actions .folder__sub {
  padding-left: 10px;
}
.list-actions .folder__indent {
  width: 10px;
}
.list-actions .folder__option {
  margin-top: -5px;
}

.templates-list-wrapper {
  max-height: 250px;
  overflow: auto;
  padding-right: 5px;
}
.templates-list-wrapper .list-group-title {
  color: #62666E;
  font-size: 13px;
  font-weight: 600;
}
.templates-list-wrapper .action-links {
  color: #00bf4a;
  text-decoration: none;
}
.templates-list-wrapper .action-links svg {
  margin-top: -2px;
}

.group-label {
  font-size: 90%;
}

.custom-multi-select .multiselect__option {
  padding: 5px !important;
}
.custom-multi-select i.search-icon {
  right: 14px;
  position: absolute;
  top: 12px;
}

.option__desc {
  padding-left: 20px;
}
.option__desc .option__title {
  font-size: 13px;
  font-weight: 500;
  line-height: 16.94px;
}
.option__desc .option__small {
  font-size: 11px;
  font-weight: 400;
  color: #62666E;
  max-width: 240px;
  white-space: normal;
}

.option__group_header {
  font-size: 13px;
  font-weight: 500;
  color: #040404;
  padding-top: 0;
  margin-left: 5px;
}

.move-dialog {
  width: 258px;
  max-height: 600px;
  background: #FFFFFF;
  border: solid 1px #d8d8d8;
  position: absolute;
  border-radius: 5px;
  font-size: 12px;
  display: none;
  flex-direction: column;
  z-index: 100;
}
.move-dialog.move-dialog__create {
  width: 280px !important;
}
.move-dialog-close {
  margin-right: -5px;
  padding: 5px 5px 5px 5px;
  display: inline-flex;
  align-items: center;
  color: #999999;
  text-decoration: none;
}
.move-dialog-close:hover, .move-dialog-close:active {
  text-decoration: none;
}
.move-dialog-title {
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  border-bottom: solid 1px #d8d8d8;
  background-color: #F4F4F6;
  font-size: 12px;
  min-height: 35px;
}
.move-dialog-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 12px;
  min-height: 35px;
  box-shadow: 0px -1px 3px 0px rgba(187, 186, 186, 0.5);
}
.move-dialog-input {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50px;
}
.move-dialog-input.mdi_input_2 {
  padding-top: 10px !important;
}
.move-dialog-input input {
  height: 30px;
}
.move-dialog-lists {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  min-height: calc(300px - 120px);
  max-height: calc(100vh - 200px);
  padding-left: 10px;
  padding-right: 10px;
}
.move-dialog__create {
  left: 334px;
  top: 178px;
}

.folder {
  padding-right: 0px;
  min-height: 34px;
  cursor: pointer;
  user-select: none;
  transition: background-color 100ms ease-in-out;
}
.folder__arrow {
  min-height: 34px;
  margin-top: -5px;
  margin-right: 5px;
}
.folder__arrow svg {
  margin-top: 5px;
}
.folder__icon {
  min-height: 34px;
  margin-right: 5px;
}
.folder--target {
  background-color: rgba(37, 110, 255, 0.09);
}
.folder--target .folder__option {
  display: block;
  opacity: 1 !important;
}
.folder:hover .folder__option {
  display: block;
  border: 0 !important;
}
.folder__name {
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.folder__sub {
  padding-left: 10px;
}
.folder__indent {
  width: 10px;
}
.folder__option {
  line-height: 0;
  width: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 34px;
  margin-left: 5px;
}
.folder__option:hover, .folder__option:active, .folder__option:focus {
  text-decoration: none !important;
}
.folder__option i {
  color: #d2d2d2;
}
.folder__option i.fa.fa-circle, .folder__option i.fa.fa-check-circle {
  font-size: 16px;
}
.folder__input {
  font-size: 12px;
  height: 100%;
  width: 100%;
  border: none;
  border-radius: 0;
}
.folder__input:focus {
  outline-color: #00bf4a;
  -moz-outline-radius: 0;
}

@media (min-width: 992px) {
  .select-list-modal .modal-lg {
    max-width: 550px;
  }
}
.select-list-modal .modal-body {
  padding: 0;
  min-height: 90vh;
  max-height: 90vh;
  overflow: hidden !important;
}
.select-list-modal__body {
  padding: 40px;
}
.select-list-modal__title {
  font-size: 18px;
  font-weight: 400;
  color: #040404;
}
.select-list-modal__close {
  margin-right: -15px;
  margin-top: -30px;
}
.select-list-modal__list-title {
  font-size: 14px;
  font-weight: 600;
  color: #040404;
}
.select-list-modal__list-desc {
  font-size: 13px;
  color: #202125;
}
.select-list-modal .tree-container {
  max-height: 65vh;
  overflow: auto;
}
.select-list-modal .row.q-input .q-field__prepend + .q-field__control-container .q-field__native {
  padding-left: 15px !important;
}

.select-list-tree-folder .folder {
  padding-left: 10px;
  padding-right: 10px;
  min-height: 34px;
  cursor: pointer;
  user-select: none;
  transition: background-color 100ms ease-in-out;
  max-height: 40vh;
}
.select-list-tree-folder .folder__arrow {
  margin-top: 4px;
  margin-right: 5px;
}
.select-list-tree-folder .folder__icon {
  margin-top: 7px;
  margin-right: 5px;
}
.select-list-tree-folder .folder--selected {
  background-color: rgba(37, 110, 255, 0.06);
}
.select-list-tree-folder .folder:hover {
  background-color: rgba(37, 110, 255, 0.06);
}
.select-list-tree-folder .folder:hover .folder__option {
  display: block;
}
.select-list-tree-folder .folder__name {
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.select-list-tree-folder .folder__sub {
  padding-left: 10px;
}
.select-list-tree-folder .folder__indent {
  width: 10px;
}
.select-list-tree-folder .folder__option {
  margin-top: -5px;
}
.select-list-tree-folder .folder__option--hide {
  width: 0;
  overflow: hidden;
}
.select-list-tree-folder .folder__input {
  font-size: 12px;
  height: 100%;
  width: 100%;
  border: none;
  border-radius: 0;
}
.select-list-tree-folder .folder__input:focus {
  outline-color: #00bf4a;
  -moz-outline-radius: 0;
}

.select-list-tree-list-item.list--active {
  background-color: rgba(37, 110, 255, 0.06);
}

.select-list-tree-list-item .folder {
  line-height: 34px;
  cursor: pointer;
  user-select: none;
  transition: background-color 100ms ease-in-out;
}
.select-list-tree-list-item .folder__arrow {
  margin-top: -5px;
  margin-right: 5px;
}
.select-list-tree-list-item .folder__icon {
  margin-top: -5px;
  margin-right: 5px;
}
.select-list-tree-list-item .folder:hover, .select-list-tree-list-item .folder--moving, .select-list-tree-list-item .folder--active {
  background-color: rgba(37, 110, 255, 0.06);
}
.select-list-tree-list-item .folder__name {
  display: flex;
  align-items: center;
}
.select-list-tree-list-item .folder__name span {
  display: inline-block;
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.select-list-tree-list-item .folder__sub {
  padding-left: 10px;
}
.select-list-tree-list-item .folder__indent {
  width: 10px;
}
.select-list-tree-list-item .folder__option {
  margin-top: -5px;
  margin-left: -5px;
}
.select-list-tree-list-item .folder__input {
  font-size: 12px;
  height: 100%;
  width: 100%;
  border: none;
  border-radius: 0;
}
.select-list-tree-list-item .folder__input:focus {
  outline-color: #00bf4a;
  -moz-outline-radius: 0;
}

.folder {
  min-height: 34px;
  cursor: pointer;
  user-select: none;
  transition: background-color 100ms ease-in-out;
}
.folder__arrow {
  min-height: 34px;
  min-width: 6px;
  margin-top: -5px;
  margin-right: 5px;
}
.folder__arrow svg {
  margin-top: 5px;
}
.folder__arrow.pd-arrow {
  margin-top: 0 !important;
}
.folder__arrow.pd-arrow-create {
  top: 8px !important;
  position: relative;
}
.folder__icon {
  min-height: 34px;
  margin-right: 5px;
}
.folder__icon svg {
  margin-top: 0px;
}
.folder__icon.pd-icon-create {
  top: 8px !important;
  position: relative;
}
.folder--selected {
  background-color: rgba(37, 110, 255, 0.06);
}
.folder:hover .folder__option {
  opacity: 1;
}
.folder .folder__option {
  opacity: 0;
}
.folder .folder__option:focus {
  opacity: 1;
}
.folder__name {
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.folder__name.pd-name-create {
  top: 2px !important;
  position: relative;
}
.folder__sub {
  padding-left: 10px;
}
.folder__indent {
  width: 10px;
}
.folder__option--hide {
  width: 0;
  overflow: hidden;
}
.folder__input {
  font-size: 12px;
  height: 100%;
  width: 100%;
  border: none;
  border-radius: 0;
}
.folder__input:focus {
  outline-color: #00bf4a;
  -moz-outline-radius: 0;
}

.tree-folder > .folder, .tree-folder > .sublists > .folder, .tree-folder > .animated > .sublists > .folder {
  padding-left: 7px;
}
.tree-folder > .folder:hover, .tree-folder > .sublists > .folder:hover, .tree-folder > .animated > .sublists > .folder:hover, .tree-folder .subfolders .sublists > .folder:hover {
  background-color: rgba(37, 110, 255, 0.06) !important;
}
.tree-folder .folder {
  min-height: 34px;
  cursor: pointer;
  user-select: none;
  transition: background-color 100ms ease-in-out;
}
.tree-folder .folder__arrow {
  margin-top: -5px;
  margin-right: 5px;
}
.tree-folder .folder__icon {
  min-height: 34px;
  margin-right: 5px;
}
.tree-folder .folder--selected {
  background-color: rgba(37, 110, 255, 0.06);
}
.tree-folder .folder:hover .folder__option {
  display: block;
}
.tree-folder .folder__name-wrapper {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.tree-folder .folder__name-wrapper__name {
  font-size: 14px;
  line-height: 17px;
  color: #040404;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.tree-folder .folder__sub {
  padding-left: 10px;
}
.tree-folder .folder__indent {
  width: 10px;
}
.tree-folder .folder__option--hide {
  width: 0;
  overflow: hidden;
}
.tree-folder .folder__input {
  font-size: 12px;
  height: 100%;
  width: 100%;
  border: none;
  border-radius: 0;
}
.tree-folder .folder__input:focus {
  outline-color: #00bf4a;
  -moz-outline-radius: 0;
}

.inbox-nav-item {
  border-radius: 10px;
  display: flex;
  align-items: center;
  height: 40px;
  color: #666666;
  position: relative;
  overflow: hidden;
  transition: background-color 100ms ease-in;
}
.inbox-nav-item--closed {
  overflow: hidden;
}
.inbox-nav-item__active {
  background-color: #F4F4F6;
  color: #040404;
}
@media (min-width: 992px) {
  .inbox-nav-item {
    overflow: auto;
  }
}
.inbox-nav-item:hover {
  text-decoration: none;
}
.inbox-nav-item__inner {
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  justify-content: center;
  padding-left: 5px;
  padding-right: 5px;
}
.inbox-nav-item__icon {
  padding-right: 10px;
}
.inbox-nav-item__label {
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.0025em;
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.inbox-nav-item__label--opened {
  display: block;
}
.inbox-nav-item__label--closed {
  display: none;
}
@media (min-width: 992px) {
  .inbox-nav-item__label {
    display: block;
  }
  .inbox-nav-item__label--closed {
    display: none;
  }
}
.inbox-nav-item .count-label {
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;
  letter-spacing: 8px;
  color: #A3A3A3;
}
.inbox-nav-item .open-count {
  color: #6F6F6F;
}
.inbox-nav-item .pending-count {
  font-weight: 500;
}

.page-side-menubar-list-item {
  border-radius: 10px;
  display: flex;
  align-items: center;
  height: 40px;
  color: #666666;
  position: relative;
  overflow: hidden;
  transition: background-color 100ms ease-in;
}
.page-side-menubar-list-item--closed {
  overflow: hidden;
}
.page-side-menubar-list-item__active {
  background-color: #F4F4F6;
  color: #040404;
}
@media (min-width: 992px) {
  .page-side-menubar-list-item {
    overflow: auto;
  }
}
.page-side-menubar-list-item:hover {
  text-decoration: none;
}
.page-side-menubar-list-item__inner {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  padding-left: 5px;
  padding-right: 5px;
}
.page-side-menubar-list-item__icon {
  padding-right: 7px;
  width: 23px;
}
.page-side-menubar-list-item__label {
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.0025em;
  flex-grow: 1;
}
.page-side-menubar-list-item__label--opened {
  display: block;
}
.page-side-menubar-list-item__label--closed {
  display: none;
}
@media (min-width: 992px) {
  .page-side-menubar-list-item__label {
    display: block;
  }
  .page-side-menubar-list-item__label--closed {
    display: none;
  }
}
.page-side-menubar-list-item .count-label {
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;
  letter-spacing: 8px;
  color: #A3A3A3;
}
.page-side-menubar-list-item .open-count {
  color: #6F6F6F;
}
.page-side-menubar-list-item .pending-count {
  font-weight: 500;
}

.pinned {
  display: flex;
  flex-direction: column;
  padding-top: 16px;
  padding-bottom: 24px;
}
.pinned__header {
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.03em;
  font-weight: 600;
  text-transform: uppercase;
  padding-bottom: 10px;
}
.pinned__header__title {
  color: #62666E;
}
.pinned__content .counts {
  padding-right: 17px;
}
.pinned__content .counts .badge-pill {
  padding-right: 6px;
  padding-left: 6px;
}
.pinned .item {
  padding-left: 18px;
  color: #15163f;
  cursor: pointer;
  min-height: 34px;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-decoration: none;
  text-overflow: ellipsis;
  transition: background-color 100ms ease-in-out;
  white-space: nowrap;
}
.pinned .item .icon svg {
  margin-top: 1px;
}
.pinned .item .counts .badge {
  font-weight: 500;
  font-size: 11px;
  line-height: 11px;
}
.pinned .item .icon,
.pinned .item .item-name,
.pinned .item .counts {
  min-height: 34px;
}
.pinned .item .item-name {
  font-size: 13px;
  line-height: 17px;
  color: #040404;
  overflow: hidden;
  text-overflow: ellipsis;
}
.pinned .item .item-name span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.pinned .item .icon {
  padding-right: 5px;
}
.pinned .item:hover {
  background: rgba(37, 110, 255, 0.06);
  color: #15163f;
  text-decoration: none;
}
.pinned .item-empty {
  padding-left: 20px;
}
.pinned .item.router-link-exact-active {
  background: #F4F4F6;
}

.shared__header {
  padding-bottom: 0 !important;
}

.inbox-nav-item-group-header {
  padding: 10px 5px 10px 7px !important;
  color: #666666;
  letter-spacing: 1px;
  font-size: 10px;
  font-weight: bold;
  text-decoration: underline;
  text-underline-offset: 3px;
}

.inbox-nav-item {
  border-radius: 10px;
  display: flex;
  align-items: center;
  height: 40px;
  color: #666666;
  position: relative;
  overflow: hidden;
  transition: background-color 100ms ease-in;
}
.inbox-nav-item--closed {
  overflow: hidden;
}
.inbox-nav-item__active {
  background-color: #F4F4F6;
  color: #040404;
}
.inbox-nav-item:hover {
  text-decoration: none;
}
.inbox-nav-item__inner {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  padding-left: 5px;
  padding-right: 0px;
}
.inbox-nav-item__icon, .inbox-nav-item__label,
.inbox-nav-item .count-label {
  line-height: 1;
}
.inbox-nav-item__icon {
  padding-right: 5px;
}
.inbox-nav-item__label {
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.0025em;
  flex-grow: 1;
}
.inbox-nav-item__label--opened {
  display: block;
}
.inbox-nav-item__label--closed {
  display: none;
}
@media (min-width: 992px) {
  .inbox-nav-item__label {
    display: block;
  }
  .inbox-nav-item__label--closed {
    display: none;
  }
}
.inbox-nav-item .count-label {
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;
  letter-spacing: 1px;
  color: #A3A3A3;
}
.inbox-nav-item .open-count {
  color: #6F6F6F;
}
.inbox-nav-item .pending-count {
  font-weight: 500;
}

.shine {
  background-color: #fff7df;
}

.message.shine {
  animation: shine-fade 0.5s linear;
}
@keyframes shine-fade {
  0% {
    background-color: rgba(255, 247, 223, 0);
  }
  100% {
    background-color: #fff7df;
  }
}
.message .sms-activity a.linkified {
  color: #62666E;
}
.message .sms-activity.bg-primary a.linkified {
  color: #FFFFFF !important;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.contact-task-item {
  padding-right: 14px !important;
  min-height: 71px;
}
.contact-task-item .contact-unread-badge {
  left: 38px;
  top: -8px;
  height: 20px;
  width: 20px;
}
.contact-task-item .overlay {
  opacity: 0.9 !important;
  background: white;
  width: 100%;
  height: 98%;
  cursor: pointer;
}
.contact-task-item .overlay .avatar {
  margin: 0 auto;
}

.table-more-rows-spinner {
  position: sticky !important;
  height: 96px;
  width: 100%;
  bottom: 0;
  left: 0;
  margin-top: 48px;
  margin-bottom: 48px;
}

.z-index-0 {
  z-index: -1 !important;
}

.z-0 {
  z-index: 0 !important;
}

.input-text-sm,
.input-text-sm .q-field__control {
  max-height: 30px !important;
  min-height: 30px !important;
}

.input-text-sm.no-after-border :before {
  border: 0;
}
.input-text-sm.no-after-border :after {
  display: none;
}

.q-chip--dense {
  border-radius: 5px;
  padding: 0 0.4em;
  height: 1.5em;
  background: transparent;
  border: 1px solid #dee2e6;
  color: black;
}
.q-chip--dense .q-chip__content {
  color: #1D1D1D;
  font-size: 13px;
}

#inbox-channel-filter-modal .modal-content {
  border-radius: 8px;
  max-height: 92vh;
}
#inbox-channel-filter-modal .modal-content .modal-body {
  overflow: hidden;
  padding: 28px 0 !important;
}
#inbox-channel-filter-modal .action-option-container {
  padding-right: 28px;
}
#inbox-channel-filter-modal .filter-name {
  color: #62666E;
  font-size: 14px;
}
#inbox-channel-filter-modal .filter-type-description {
  font-size: 14px;
  font-weight: 600;
  color: #040404;
  display: block;
  width: 83px;
}
#inbox-channel-filter-modal .left-column-wrapper {
  border-right: 1px solid #EBEBEB;
  padding-left: 10px;
  width: 25%;
}
#inbox-channel-filter-modal .left-column-wrapper .left-column-wrapper-scrollable {
  max-height: 85vh;
  overflow: auto;
}
#inbox-channel-filter-modal .left-column-wrapper .filter-group-title {
  font-size: 12px;
  color: #95989E;
  margin-bottom: 10px;
  font-weight: 400;
}
#inbox-channel-filter-modal .left-column-wrapper .filter-items {
  margin: 5px 0;
  padding: 7px 12px;
  line-height: 1.2;
  height: 30px;
}
#inbox-channel-filter-modal .left-column-wrapper .filter-items span:not(.check-icon) {
  font-size: 13px;
  color: #040404;
  width: 95%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
}
#inbox-channel-filter-modal .left-column-wrapper .filter-items span.position-absolute.check-icon {
  right: 10px;
  top: 5px;
}
#inbox-channel-filter-modal .left-column-wrapper .filter-items .b-dropdown {
  height: 10px !important;
  margin-top: 0 !important;
  right: 0;
}
#inbox-channel-filter-modal .left-column-wrapper .filter-items .b-dropdown button {
  margin-top: -1px;
  background: none;
  padding: 2px;
  border: none !important;
  display: none;
  height: 20px !important;
  font-size: 10px;
}
#inbox-channel-filter-modal .left-column-wrapper .filter-items .b-dropdown .dropdown-menu.show {
  min-width: 5rem;
}
#inbox-channel-filter-modal .left-column-wrapper .filter-items .b-dropdown .dropdown-menu.show .dropdown-item {
  padding: 0.25rem 1rem;
}
#inbox-channel-filter-modal .left-column-wrapper .filter-items button:hover,
#inbox-channel-filter-modal .left-column-wrapper .filter-items button:focus,
#inbox-channel-filter-modal .left-column-wrapper .filter-items button:active {
  border: none;
}
#inbox-channel-filter-modal .left-column-wrapper .filter-items:hover {
  background: #F4F4F6;
}
#inbox-channel-filter-modal .left-column-wrapper .filter-items:hover button {
  display: block;
}
#inbox-channel-filter-modal .left-column-wrapper .filter-items {
  border-radius: 5px;
}
#inbox-channel-filter-modal .left-column-wrapper .filter-items.active, #inbox-channel-filter-modal .left-column-wrapper .contact-list-sidebar-wrapper .filter-items.router-link-exact-active, .contact-list-sidebar-wrapper #inbox-channel-filter-modal .left-column-wrapper .filter-items.router-link-exact-active {
  background: #EBEBEB;
}
#inbox-channel-filter-modal .right-column-wrapper {
  padding-left: 5px;
}
#inbox-channel-filter-modal .q-field__focusable-action {
  font-size: 0.8em;
}
#inbox-channel-filter-modal .q-toggle {
  margin-left: -10px !important;
}
#inbox-channel-filter-modal .comm-owner-filter-tooltip-wrapper {
  position: absolute;
  top: -1px;
  right: 48%;
}

.inbox-channel-filter-form {
  position: relative;
  overflow: auto;
  height: auto;
  max-height: 72vh;
  padding-right: 8px;
}
.inbox-channel-filter-form .section-header {
  font-size: 14px;
  font-weight: 600;
  color: #040404;
}
.inbox-channel-filter-form .form-label {
  color: #62666E;
}
.inbox-channel-filter-form .custom-control-input ~ .custom-control-label::before,
.inbox-channel-filter-form .custom-control-input ~ .custom-control-label::after {
  cursor: pointer;
}
.inbox-channel-filter-form .custom-control-input:checked ~ .custom-control-label::before {
  color: #FFFFFF;
  border-color: #00bf4a;
  background-color: #00bf4a;
}
.inbox-channel-filter-form .quick-access .vue-daterange-picker .reportrange-text {
  height: 40px;
  display: flex;
  align-items: center;
}

@media (min-width: 992px) {
  #inbox-channel-filter-modal .modal-lg,
#inbox-channel-filter-modal.modal-xl {
    max-width: 950px;
  }
}
#create-filter-modal .modal-content {
  border-radius: 8px;
}

div.q-menu {
  min-width: 0;
}

button.primary {
  color: #256eff;
}

button.grey-90 {
  color: #62666E;
}

.channel-filter-actions-wrapper {
  margin-top: 14px;
}
.channel-filter-actions-wrapper .filter-toggle-button {
  max-width: 81px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
}

.btn.not-focusable:focus {
  box-shadow: none !important;
}

.tag-selected-chip {
  height: auto !important;
}
.tag-selected-chip .q-chip__content {
  white-space: initial;
}

.bottom-border__none .q-field__control:before {
  border-bottom: none !important;
}
.bottom-border__none .q-field__control:after {
  background: none !important;
}

.q-field--highlighted.highlighted-primary .q-field__native, .q-field--highlighted.highlighted-primary .q-field__marginal {
  color: #256eff;
}

.padding-left__none .q-field__native {
  padding-left: 0 !important;
}

.make-primary-label {
  display: inline-block;
  margin-top: 3px;
  cursor: pointer;
}

.bordered-right {
  border-right: 1px solid #EBEBEB;
}

.bordered-left {
  border-left: 1px solid #EBEBEB;
}

.bordered-top {
  border-top: 1px solid #EBEBEB;
}

.bordered-bottom {
  border-bottom: 1px solid #EBEBEB;
}

.bulk-action-menu {
  background: white;
}
.bulk-action-menu__power-dialer, .bulk-action-menu__tags {
  margin-left: 40px !important;
  margin-top: 2px !important;
}
.bulk-action-menu .menu-actions {
  min-height: 35px !important;
  position: relative;
}
.bulk-action-menu .menu-actions a {
  text-decoration: none;
  font-weight: 500;
}
.bulk-action-menu .menu-actions a .fa {
  color: #6F6F6F;
}
.bulk-action-menu .menu-actions a svg {
  margin-top: -5px;
}
.bulk-action-menu .menu-actions .custom-checkbox-container {
  position: absolute;
  padding-right: 35px;
  margin-left: -0.25rem;
  z-index: 20;
}
.bulk-action-menu .menu-actions .custom-checkbox-container:not(.checked):not(.pd-add):not(.contacts) {
  top: 13px;
}
.bulk-action-menu .menu-actions .custom-checkbox-container:not(.checked).pd-add {
  top: 26px;
}
.bulk-action-menu .menu-actions .custom-checkbox-container:not(.checked).contacts {
  top: 20px;
}
.bulk-action-menu .menu-actions .custom-checkbox-container.checked {
  top: 116px;
}
.bulk-action-menu .menu-actions .custom-checkbox-container .checkmark {
  position: initial !important;
}
.bulk-action-menu .menu-actions {
  font-size: 0.75rem;
}
.bulk-action-menu .menu-actions .items {
  margin-right: 80px;
}

.modal.br-8 .modal-content {
  border-radius: 8px;
}

.btn-grey-80 {
  color: #FFFFFF;
  background-color: #95989E;
  border-color: #95989E;
}

#create-contact-modal .scrollable {
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.general-notification.bg-green-10 {
  border: 1px solid #04BE4C;
}
.general-notification.bg-red-10 {
  border: 1px solid #F23333;
}
.general-notification.bg-orange-2 {
  border: 1px solid #F9A925;
}
.general-notification .close-button .q-btn__wrapper {
  min-height: auto !important;
  padding: 0;
}
.general-notification .close-button .q-btn__content {
  display: flex;
  align-items: center;
  padding-top: 1px;
}
.general-notification .close-button {
  height: 17px;
  width: 23px;
  display: flex;
  align-items: center;
}
.general-notification .q-notification__message,
.general-notification .close-button .q-btn__content .q-icon {
  font-size: 14px !important;
  font-weight: normal !important;
  letter-spacing: -0.0025em !important;
}

.notification-border-round {
  box-shadow: 0px 0px 12px -2px rgba(0, 0, 0, 0.08), 0px 10px 20px -4px rgba(4, 4, 4, 0.2);
  border-radius: 12px;
}

#b-toaster-bottom-right .b-toaster-slot,
#b-toaster-top-center .b-toaster-slot {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.b-toast {
  max-width: 300px !important;
}
.b-toast .action-notification {
  width: 300px !important;
}
.b-toast .action-notification.has-clear-queues {
  margin-top: 30px !important;
}
.b-toast .action-notification.incoming-call-notification .toast-header {
  display: none;
}
.b-toast .action-notification .toast-header .close {
  position: absolute;
  width: 18px;
  height: 18px;
  background: #62666E;
  opacity: 0.7;
  color: white;
  margin-bottom: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  float: none;
  padding-bottom: 2px;
  border-radius: 50%;
  font-weight: 100;
  left: -5px;
  top: -5px;
}
.b-toast .action-notification .toast-body {
  text-decoration: none;
  padding: 0 0 !important;
}
.b-toast .action-notification .toast-body > div {
  padding: 12px 12px 15px 12px;
}
.b-toast .action-notification .toast-body .title {
  font-size: 14px !important;
  font-weight: 600 !important;
  letter-spacing: -0.0025em !important;
  line-height: 18px;
  max-width: 162px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.b-toast .action-notification .toast-body .time {
  font-size: 14px !important;
  font-weight: normal !important;
  letter-spacing: -0.0025em !important;
  line-height: 16px;
  padding-top: 1px;
}
.b-toast .action-notification .toast-body .notification-icon {
  padding-top: 2px;
}
.b-toast .action-notification .toast-body .message-body {
  font-size: 12px;
  font-weight: normal;
  letter-spacing: -0.0025em;
  line-height: 15px;
  padding-top: 1.39px;
}
.b-toast .action-notification .toast-body .message-body .message-text {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.b-toast .action-notification .toast-body .message-body .message-text .mention-tag {
  color: #EAEAEC !important;
  background: unset;
  padding: 0 0 !important;
  font-weight: 600;
}
.b-toast .action-notification .toast-body .message-body .attachment {
  border-radius: 5px;
  max-width: 32px;
  height: 32px;
}

.btn-filter-wrapper {
  border-radius: 6px;
}

.btn-filter-wrapper.background {
  background: rgba(37, 110, 255, 0.12);
}

.v-divider {
  border: 1px solid #EBEBEB;
  height: 24px;
  margin-right: 10px;
}

/* The custom-checkbox-container */
.custom-checkbox-container {
  display: block;
  position: relative;
  margin-bottom: 0px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.custom-checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  display: flex;
  height: 15px;
  width: 15px;
  background-color: #FFFFFF;
  border: 1px solid #EBEBEB;
  border-radius: 2px;
}

/* On mouse-over, add a grey background color */
.custom-checkbox-container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.custom-checkbox-container input:checked ~ .checkmark {
  background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.custom-checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.custom-checkbox-container .checkmark:after {
  left: 5px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.bg-red-80 {
  background: #EB5757 !important;
}

.dropdown-white {
  height: 28px;
}
.dropdown-white.dropdown-active button {
  border: 1px solid #256eff !important;
}
.dropdown-white button {
  background: #FFFFFF !important;
  color: #040404 !important;
}

.dropdown-white:not(.border-0) button {
  border: 1px solid #D8D8D8;
}

.dropdown-white.contacts-options-dropdown button {
  color: #62666E !important;
}

.filter-toggle-button {
  height: 28px;
}

.title-static-icon {
  margin-right: 10px;
}

.mention-tag,
span.mention {
  color: #040404;
  font-weight: 500;
  background: #CDDCFF;
  border-radius: 4px;
  padding: 2px 5px;
}

.notes-body p {
  display: -webkit-box;
}
.notes-body p .mention,
.notes-body p .mention-tag {
  background: none;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.custom-toggle-button {
  background: #EBEBEB;
  padding: 2px;
}
.custom-toggle-button button:not(:last-child),
.custom-toggle-button button:not(:first-child) {
  margin: 0 1px;
}
.custom-toggle-button button.bg-transparent:hover .task-count,
.custom-toggle-button button.bg-transparent:hover .badge-active-count {
  background: transparent;
}
.custom-toggle-button button {
  border-radius: 5px !important;
  border: none !important;
  height: 24px;
}
.custom-toggle-button button[aria-pressed=true]:hover {
  background: #5890ff !important;
}
.custom-toggle-button button:not(.active) .task-count,
.custom-toggle-button button:not(.active) .badge-active-count,
.custom-toggle-button button:not(.active) .broadcast-count {
  background: #D8D8D8 !important;
  color: #62666E !important;
}
.custom-toggle-button button .options {
  font-size: 14px !important;
  font-weight: 500;
  width: 100% !important;
  text-align: center;
  font-family: "Inter", sans-serif !important;
}
.custom-toggle-button button .options .task-status-name,
.custom-toggle-button button .options .toggle-button-name,
.custom-toggle-button button .options .broadcast-filter-name {
  margin-top: -2px;
}
.custom-toggle-button button .options .task-count,
.custom-toggle-button button .options .badge-active-count,
.custom-toggle-button button .options .broadcast-count {
  background: rgba(255, 255, 255, 0.22);
  border-radius: 5px;
  font-size: 10px;
  font-weight: 600;
  padding: 0 2px;
  color: #FFFFFF;
  height: 16px;
  margin-top: 1px;
}
.custom-toggle-button button .options .task-count span,
.custom-toggle-button button .options .badge-active-count span,
.custom-toggle-button button .options .broadcast-count span {
  top: -3px;
  position: relative;
}

.channel-filter-actions-wrapper.inbox-tab--filter .filter-wrapper.--highlighted {
  background: rgba(37, 110, 255, 0.12);
  padding: 0 11px;
  border-radius: 6px;
  height: 29px;
}
.channel-filter-actions-wrapper.inbox-tab--filter .filter-wrapper .filter-icon {
  margin-top: -4px;
}
.channel-filter-actions-wrapper.inbox-tab--filter .search-icon {
  margin-top: -2px;
  height: 28px;
}
.channel-filter-actions-wrapper.inbox-tab--filter .q-separator {
  margin-left: 8px;
  margin-right: 8px;
  margin-top: 2px;
}

.inbox-tab--filter .filter-wrapper .filter-icon {
  margin-top: -2px;
}
.inbox-tab--filter .search-wrapper .compact-button {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.inbox-filter-actions-wrapper {
  width: 50%;
  margin-top: 15px;
}
.inbox-filter-actions-wrapper div.search-icon {
  margin-top: -2px;
}
.inbox-filter-actions-wrapper .q-user-selector {
  width: 8vw;
}
.inbox-filter-actions-wrapper .q-line-and-ring-group-selector {
  width: 8vw;
}
.inbox-filter-actions-wrapper .generic-selector {
  margin-top: -3px;
}
.inbox-filter-actions-wrapper .generic-selector .q-field__control:before {
  border: none !important;
}
.inbox-filter-actions-wrapper .generic-selector .q-field__native {
  padding-left: 20px !important;
}
.inbox-filter-actions-wrapper .generic-selector .q-field__native span {
  font-size: 14px;
  color: #040404;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 5.5vw;
  overflow: hidden;
}
.inbox-filter-actions-wrapper .generic-selector .q-field__input {
  font-size: 14px;
  font-weight: 500;
}
.inbox-filter-actions-wrapper .generic-selector .q-field__marginal {
  font-size: 14px;
}

.mentions-filter-actions-wrapper {
  width: 50%;
  margin-top: 15px;
}
.mentions-filter-actions-wrapper .filter-wrapper {
  margin-left: 15px;
}
.mentions-filter-actions-wrapper div.search-icon {
  margin-top: -2px;
}
.mentions-filter-actions-wrapper .q-user-selector {
  width: 8vw;
}
.mentions-filter-actions-wrapper .q-line-and-ring-group-selector {
  width: 8vw;
}
.mentions-filter-actions-wrapper .generic-selector {
  margin-top: -7px;
}
.mentions-filter-actions-wrapper .generic-selector .q-field__control:before {
  border: none !important;
}
.mentions-filter-actions-wrapper .generic-selector .q-field__native {
  padding-left: 20px !important;
}
.mentions-filter-actions-wrapper .generic-selector .q-field__native span {
  font-size: 14px;
  color: #040404;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 5.5vw;
  overflow: hidden;
}
.mentions-filter-actions-wrapper .generic-selector .q-field__input {
  font-size: 14px;
  font-weight: 500;
}
.mentions-filter-actions-wrapper .generic-selector .q-field__marginal {
  font-size: 14px;
}

.ml-7 {
  margin-left: 5rem !important;
}

.settings-form-wrapper {
  padding-bottom: 5rem;
}
.settings-form-wrapper .scrollableArea {
  overflow-y: auto !important;
  overflow-x: hidden !important;
}
.settings-form-wrapper .scrollableArea .datatable {
  margin-left: 0 !important;
}
.settings-form-wrapper .form-label {
  font-weight: 500;
  font-size: 13px;
  color: #040404;
}
.settings-form-wrapper .form-helper-text {
  font-size: 12px;
  color: #62666E;
  letter-spacing: -0.0025em;
  margin-top: 10px;
}
.settings-form-wrapper form .form-row {
  margin-right: 0 !important;
  margin-left: 0 !important;
  padding: 0.5rem !important;
}
.settings-form-wrapper .highlighted {
  background: #FFFBD4;
  border-radius: 6px;
}
.settings-form-wrapper .q-field--with-bottom {
  padding-bottom: 0 !important;
}
.settings-form-wrapper .q-field--error .q-field__marginal {
  display: none !important;
}

.r-0 {
  right: 0 !important;
}

.q-item__label {
  font-weight: normal !important;
}

.flex-table {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin: 0.75em 0;
  height: 45px;
  width: 100%;
}

.flex-row.day {
  width: 110px;
}

.flex-row.toggle {
  width: 110px;
}

.business-hours-wrapper .selector-container {
  width: 110px;
}

.group-checkbox .custom-checkbox {
  width: 120px;
}

.recording {
  animation-name: pulse;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 5px 0 rgba(173, 0, 0, 0.3);
  }
  65% {
    box-shadow: 0 0 5px 13px rgba(173, 0, 0, 0.3);
  }
  90% {
    box-shadow: 0 0 5px 13px rgba(173, 0, 0, 0);
  }
}
.ring-group-list li {
  list-style: circle;
  left: 0;
  margin-left: -22px;
}

.custom-control .custom-control-label {
  cursor: pointer;
  padding-top: 2px;
}

.business-hours-container {
  font-family: "Inter", sans-serif !important;
}
.business-hours-container div.hours {
  position: relative;
}
.business-hours-container div.hours select {
  border-radius: 6px;
  background: #FFFFFF;
  height: 40px;
  padding: 0 10px;
  font-weight: 400;
  font-size: 14px;
  -webkit-appearance: none;
  appearance: none;
}
.business-hours-container div.hours select:focus-visible {
  border: 2px solid #256eff;
  outline-width: inherit;
}
.business-hours-container div.hours::after {
  content: "▼";
  font-size: 0.75rem;
  top: 12px;
  right: 10px;
  position: absolute;
}
.business-hours-container .add-hours {
  font-weight: normal !important;
}

.fixed-header {
  table-layout: fixed;
  border-collapse: collapse;
}

.fixed-header tbody {
  display: block;
  width: 100%;
  overflow: auto;
  height: 100px;
}

.fixed-header thead tr {
  display: block;
}

.fixed-header th, .fixed-header td {
  padding: 5px;
  text-align: left;
}

.filter-types .q-field__native.row .q-field__input {
  padding-left: 0 !important;
}
.filter-types .b-calendar-inner {
  min-width: 215px !important;
  width: 215px !important;
}

.input-field-shadow {
  box-shadow: 0px 0px 0px 1.5px rgba(37, 110, 255, 0.12);
  border-radius: 6px;
}

.rounded-input .q-field__control {
  border-radius: 6px;
}

@media (min-height: 378px) {
  .sidebar-wrapper .sidebar .q-list {
    height: 100% !important;
  }
}
.menu-avatar-wrapper .contact-info-wrapper {
  margin-right: 5px;
  margin-top: -10px;
}
.menu-avatar-wrapper .user-full-name {
  font-weight: 500 !important;
  font-size: 14px !important;
  color: #040404;
  margin-top: 8px;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.menu-avatar-wrapper .user-full-name .focus-mode-icon {
  margin-top: -2px;
  margin-left: 7px;
}
.menu-avatar-wrapper .user-company-name {
  font-weight: 500 !important;
  font-size: 12px !important;
  color: #95989E;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.menu-avatar-wrapper .q-btn__wrapper {
  min-height: 44px;
}

.header-help-wrapper {
  margin-right: 20px !important;
}
.header-help-wrapper .q-btn-dropdown__arrow {
  display: none;
}
.header-help-wrapper .q-btn__wrapper {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.header-help-wrapper .col {
  flex-basis: unset;
}
.header-help-wrapper .header-help-icon-wrapper {
  color: #ad002b;
  font-size: 34px;
}

.header-help-dropdown-list .q-btn:hover {
  text-decoration: none;
}

.tab-avatar-menu {
  min-width: 227px !important;
  max-width: 227px !important;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 6px !important;
}
.tab-avatar-menu .q-list {
  padding: 0;
}
.tab-avatar-menu .q-list .q-item {
  padding: 0 17px 0 16px !important;
  font-size: 14px !important;
  min-height: 33px !important;
}
.tab-avatar-menu .q-list .q-item .rounded-badge, .tab-avatar-menu .q-list .q-item .tab-dropdown .availability-status, .tab-dropdown .tab-avatar-menu .q-list .q-item .availability-status {
  margin-right: 14px !important;
}
.tab-avatar-menu .q-list .q-item .focus-mode-icon,
.tab-avatar-menu .q-list .q-item .logout-icon {
  margin-right: 8px;
  margin-top: -2px;
}
.tab-avatar-menu .q-list .q-item:not(:first-child) {
  margin-top: 8px;
}
.tab-avatar-menu .q-list .q-item:nth-child(6) {
  margin-top: 0 !important;
}

.badge-task-status {
  margin: 1px 0 0 12px;
}

.default-btn-padding .q-btn,
.default-btn-padding .btn {
  padding: 7px 12px !important;
  display: flex;
  align-items: center;
}
.default-btn-padding .q-btn .fa-chevron-down,
.default-btn-padding .btn .fa-chevron-down {
  height: 17px;
  display: flex;
  margin-left: 4px;
  align-items: flex-end;
}

.transparent {
  opacity: 0;
}

.q-menu.settings-menu-links {
  padding: 10px;
}
.q-menu.settings-menu-links .q-item {
  padding: 10px;
  border-radius: 6px;
}

.settings-searcher .q-field__prepend i.material-icons.q-icon {
  font-size: 18px;
}
.settings-searcher .q-field__focusable-action.q-icon {
  font-size: 14px !important;
}

.form-control.is-invalid,
.form-control.is-valid {
  background-image: none !important;
}

.form-control.is-valid {
  border-color: #ced4da !important;
}

.shared {
  padding-top: 16px;
  padding-bottom: 16px;
}

.pinned .folders__header button,
.pinned .header__header__title,
.shared .folders__header button,
.shared .header__header__title,
.folders .folders__header button,
.folders .header__header__title {
  margin-left: 18px !important;
  margin-right: 10px !important;
  display: flex;
  align-items: center;
}
.pinned .item-empty,
.shared .item-empty,
.folders .item-empty {
  padding-top: 20px;
  padding-left: 18px;
}
.pinned.public-lists .item-empty,
.shared.public-lists .item-empty,
.folders.public-lists .item-empty {
  padding-top: 4px;
}

.contacts__title {
  height: 19px;
}
.contacts__title .list-name {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}
.contacts .title-breadcrumb {
  font-family: Roboto;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #62666E;
}
.contacts .title-breadcrumb {
  height: 19px;
  margin-right: 5px;
}
.contacts .title-path:not(:last-child) .title-slash {
  height: 19px;
  margin-right: 5px;
}
.contacts .title-path:last-child .title-slash {
  height: 19px;
  margin-right: 15.5px;
}
.contacts .contacts-left-sidebar {
  min-width: 260px;
  max-width: 260px;
}
.contacts .contacts-left-sidebar.sidebar-1 {
  min-width: 280px;
  max-width: 280px;
}
.contacts .contacts-left-sidebar.minimized {
  min-width: 15px;
  max-width: 15px;
}
.contacts .contacts-left-sidebar .contacts-sidebar-card {
  overflow-y: auto !important;
}
.contacts .contacts-left-sidebar .contacts-sidebar-card .q-item.q-item-type {
  padding: 0 17px;
}
.contacts .contacts-left-sidebar .contacts-sidebar-card .q-item.q-item-type .q-item__label {
  text-transform: uppercase;
  font-weight: 700 !important;
  font-size: 13px !important;
  color: #62666E !important;
}
.contacts-header-separator {
  margin: 0 15px;
}
.contacts .sessions-main-page.minimized {
  width: 100%;
}

.custom-control-input:disabled {
  opacity: 0 !important;
}

.inbox-search {
  border-radius: 0 !important;
  box-shadow: none !important;
  z-index: 1;
  padding: 0 15px;
  margin-top: -9px;
  border-bottom: 1px solid #EBEBEB;
  background: white;
}
.inbox-search .q-item {
  padding: 0 !important;
  height: 38px;
  min-height: unset;
}
.inbox-search .q-item label.q-field {
  height: 38px;
  margin-bottom: 0;
}
.inbox-search .q-item .q-field--filled .q-field__control {
  padding: 0;
  border-radius: 4px 4px 0 0;
  background: transparent;
}
.inbox-search .q-item .q-field--filled .q-field__control:before {
  background: transparent;
}
.inbox-search .q-item .q-field--filled .q-field__control:after {
  background: transparent !important;
}
.inbox-search .q-item .q-field--filled .q-field__control:before {
  border-bottom: none !important;
}
.inbox-search .q-item .q-field__marginal {
  font-size: 14px !important;
}
.inbox-search .q-item .q-field--filled .q-field__control:hover:before {
  opacity: 0 !important;
}
.inbox-search .q-item input {
  padding-left: 0 !important;
  font-size: 14px;
  font-weight: 500;
}
.inbox-search .q-item .q-field__append {
  padding-right: 0;
}

.mt-37 {
  margin-top: 37px;
}

.contact-details-container {
  max-width: 250px;
  width: 100%;
}
.contact-details-container-drawer aside {
  border-radius: 0.25rem;
  background: #F4F4F6;
  padding: 15px 0px 15px 15px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2), 0 2px 2px rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12);
  top: 0 !important;
  position: absolute !important;
}
.contact-details-container-drawer aside .details-component-container {
  padding-right: 15px;
}
.contact-details-container-drawer__close {
  border-radius: 50%;
  background: #62666E;
  position: absolute;
  top: 0;
  right: 5px;
  z-index: 9;
  padding: 0 0;
  width: 24px;
}
.contact-details-container-drawer .contact-details-wrapper .details-component-container {
  height: calc(100vh - 83px);
}
.contact-details-container .mobile-header {
  margin-left: -15px;
  margin-top: -15px;
  width: calc(100% + 15px);
  margin-bottom: 15px;
  background: white;
}

.contact-activity-wrapper {
  width: 100%;
}

.contact-activities-actions__mobile {
  display: none;
}
.contact-activities-actions__drawer_btn, .contact-activities-actions__mobile_btn {
  border-radius: 50%;
  height: 32px;
  width: 32px;
  min-height: 24px;
  display: flex;
  align-items: center;
}
.contact-activities-actions__drawer_btn .q-btn__wrapper, .contact-activities-actions__mobile_btn .q-btn__wrapper {
  padding: 0 0 !important;
}
.contact-activities-actions__drawer_btn .q-btn__wrapper,
.contact-activities-actions__drawer_btn .q-btn__content, .contact-activities-actions__mobile_btn .q-btn__wrapper,
.contact-activities-actions__mobile_btn .q-btn__content {
  height: 30px;
  min-height: 30px;
}
.contact-activities-actions-dropdown {
  width: 20px;
  height: 20px;
  margin-right: 5px !important;
}
.contact-activities-actions-dropdown button {
  width: 100%;
  height: 100%;
}

.contact-activities-actions__mobile_btn {
  display: none !important;
}

.contact-details-container-drawer .q-drawer__content {
  overflow: unset;
}

.mobile-header {
  display: none;
}

.inbox-wrapper {
  display: flex;
  max-width: 558px;
  width: 100%;
  flex: 0 0 100%;
}

.unsupported {
  display: none !important;
  height: 0;
}

.dashboard:not(.contact-page) .mobile-back-btn {
  display: none;
}

.mobile-back-btn {
  border-radius: 50%;
  height: 27px;
  width: 24px;
  min-height: 27px;
  align-items: center;
  margin-right: 5px;
}
.mobile-back-btn .q-btn__wrapper {
  padding: 0 0 !important;
}
.mobile-back-btn .q-btn__wrapper,
.mobile-back-btn .q-btn__content {
  height: 27px;
  min-height: 27px;
}

.b-toaster-slot {
  max-width: 399px !important;
  width: 100%;
}

.stats-container {
  height: 100vh;
  max-width: 100 wh;
}

.settings-side__right .mobile-back-btn {
  display: none !important;
}

.contacts-total.mobile {
  display: none;
}

@media screen and (max-width: 1425px) and (min-width: 1085px), screen and (max-width: 956px) {
  .contacts-total.mobile {
    display: none;
  }
}
@media (max-width: 1485px) and (min-width: 1085px) {
  .inbox .inbox-details .contact-activity-container .composer-footer:not(.is-widget) {
    flex-direction: column;
  }
  .inbox .inbox-details .contact-activity-container .composer-footer:not(.is-widget) .inline-select.q-select--with-input {
    min-width: unset;
  }
  .inbox .inbox-details .contact-activity-container .composer-footer:not(.is-widget) > div {
    width: auto !important;
    height: 24px;
  }
  .inbox .inbox-details .contact-activity-container .composer-footer:not(.is-widget) > div > div {
    float: none !important;
  }
  .inbox .inbox-details .contact-activity-container .composer-footer:not(.is-widget) > div > div .q-field__append {
    align-items: center;
    height: 29px !important;
  }
  .inbox .inbox-details .contact-activity-container .composer-footer:not(.is-widget) .q-field__control {
    flex-direction: column;
  }
}
@media (max-width: 1220px) and (min-width: 1085px) {
  .contact-page .contact-activity-container .composer-footer:not(.is-widget) {
    flex-direction: column;
  }
  .contact-page .contact-activity-container .composer-footer:not(.is-widget) .inline-select.q-select--with-input {
    min-width: unset;
  }
  .contact-page .contact-activity-container .composer-footer:not(.is-widget) > div {
    width: auto !important;
    height: 24px;
  }
  .contact-page .contact-activity-container .composer-footer:not(.is-widget) > div > div {
    float: none !important;
  }
  .contact-page .contact-activity-container .composer-footer:not(.is-widget) > div > div .q-field__append {
    align-items: center;
    height: 29px !important;
  }
  .contact-page .contact-activity-container .composer-footer:not(.is-widget) .q-field__control {
    flex-direction: column;
  }
}
@media (max-width: 956px) {
  .contact-activity-container .composer-footer:not(.is-widget) {
    flex-direction: column;
  }
  .contact-activity-container .composer-footer:not(.is-widget) .inline-select.q-select--with-input {
    min-width: unset;
  }
  .contact-activity-container .composer-footer:not(.is-widget) > div {
    width: auto !important;
    height: 24px;
  }
  .contact-activity-container .composer-footer:not(.is-widget) > div > div {
    float: none !important;
  }
  .contact-activity-container .composer-footer:not(.is-widget) > div > div .q-field__append {
    align-items: center;
    height: 29px !important;
  }
  .contact-activity-container .composer-footer:not(.is-widget) .q-field__control {
    flex-direction: column;
  }
}
@media (max-width: 650px) {
  .contact-activity-container .composer-footer.is-widget {
    flex-direction: column;
  }
  .contact-activity-container .composer-footer.is-widget .inline-select.q-select--with-input {
    min-width: unset;
  }
  .contact-activity-container .composer-footer.is-widget > div {
    width: auto !important;
    height: 24px;
  }
  .contact-activity-container .composer-footer.is-widget > div > div {
    float: none !important;
  }
  .contact-activity-container .composer-footer.is-widget > div > div .q-field__append {
    align-items: center;
    height: 29px !important;
  }
  .contact-activity-container .composer-footer.is-widget .q-field__control {
    flex-direction: column;
  }
}
@media (min-width: 1319px) {
  .contact-details-container {
    max-width: 330px;
  }
}
@media (min-width: 1160px) and (max-width: 1261px) {
  .inbox-side__right .custom-toggle-button {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }
}
@media (max-width: 1261px) {
  .inbox-wrapper,
.inbox-side {
    max-width: 433px;
  }

  .inbox-side__left {
    max-width: 120px;
  }
  .inbox-side__left .count-label {
    margin-left: 10px;
  }
  .inbox-side .inbox-nav-list > div:not(:first-child) .inbox-nav-item__label {
    min-width: 73.88px;
  }
  .inbox-side .inbox-nav-item__icon {
    display: none;
  }
}
@media (max-width: 1159px) {
  .contacts-filter-sidebar {
    position: fixed;
    right: 0;
    z-index: 999;
  }

  .inbox-side .inbox-nav-item__icon {
    display: block;
  }
}
@media (min-width: 605px) and (max-width: 1159px) {
  .inbox-wrapper,
.inbox-side {
    max-width: 313px;
  }

  .inbox-side__nav {
    padding-top: 2.92px;
  }

  .contact-details-container {
    width: 250px;
  }

  .contact-activity-wrapper {
    width: 100%;
  }

  .inbox-wrapper:not(.inbox-side-mobile-full-width) .inbox-side__left .header,
.inbox-wrapper:not(.inbox-side-mobile-full-width) .inbox-side__left .count-label {
    display: none;
  }
  .inbox-wrapper:not(.inbox-side-mobile-full-width) .inbox-side__left .inbox-nav-item {
    margin: 0 0 !important;
  }
  .inbox-wrapper.inbox-side-mobile-full-width {
    max-width: unset;
  }
  .inbox-wrapper.inbox-side-mobile-full-width .inbox-side {
    max-width: unset;
  }

  .inbox-side__right {
    width: 270px;
  }

  .contact-page .contact-view-wrapper .contact-list-sidebar-container {
    width: 270px;
  }

  .contacts-filter-sidebar {
    width: 300px;
  }

  .main-content .contacts > .main .mycard > div:first-child,
.main-content .contacts .table-header > div {
    padding: 0 16px !important;
  }
  .main-content .contacts .table-header > div > .row {
    justify-content: space-between;
  }
  .main-content .contacts .table-header > div > .row > div {
    width: auto !important;
    flex: unset;
    max-width: unset;
  }
  .main-content .contacts .table-header > div > .row > div:first-child > div:nth-child(2) {
    display: none !important;
  }
}
@media (max-width: 1084px) {
  .contact-activities-actions-dropdown {
    height: 24px;
    width: auto;
  }

  .contact-activity-container.contact-activity--closed .contact-activities-actions {
    margin-right: 22.81px;
  }
  .contact-activity-container.contact-activity--closed .contact-activities-actions__mobile {
    display: flex;
    align-items: center;
  }
  .contact-activity-container.contact-activity--closed .contact-activities-actions__desktop {
    display: none;
  }
  .contact-activity-container.contact-activity--closed .contact-activities-actions-dropdown button {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    padding: 0 0 !important;
  }
  .contact-activity-container.contact-activity--closed .contact-activities-actions-dropdown button:not(:hover):not([aria-expanded=true]) {
    border: 1px solid white !important;
  }

  .contact-details-container {
    width: 0;
    margin: 0;
  }
  .contact-details-container:not(.contact-details--opened) .mobile-header {
    margin: 0;
  }

  .contact-activities-actions__mobile {
    display: flex;
  }

  .contact-activities-actions__desktop {
    display: none !important;
  }
}
@media (max-width: 1019px) {
  .main-content .contacts .table-header > div > .row .contacts-header-separator {
    display: none;
  }
}
@media (min-width: 992px) and (max-width: 1019px) {
  .contacts-total.desktop {
    padding-left: 10px;
  }

  .main-content .contacts .table-header > div > .row > div:nth-child(2) {
    padding-right: 0 !important;
  }
}
@media (max-width: 991px) {
  .contacts-total {
    white-space: nowrap;
    margin-left: 15px;
  }

  .datatable-wrapper .scrollableArea:not(.mobile-scrollableArea) {
    max-height: calc(100vh - 195px);
  }

  .main-content .contacts > .main .mycard > div:first-child,
.main-content .contacts .table-header > div {
    padding: 0 16px !important;
  }
  .main-content .contacts .table-header > div > .row {
    flex-flow: column-reverse;
  }
  .main-content .contacts .table-header > div > .row > div {
    justify-content: space-between;
    width: 100%;
    padding: 0 0 !important;
  }
  .main-content .contacts .table-header > div > .row > div .contacts-options-dropdown {
    margin-right: 0 !important;
  }
  .main-content .contacts .table-header > div > .row > div:first-child .width-250 {
    width: 100%;
  }
  .main-content .contacts .table-header > div > .row > div:nth-child(2) {
    justify-content: flex-end;
  }
  .main-content .contacts .table-header > div > .row > div:nth-child(2) > div:first-child {
    display: none;
  }
  .main-content .contacts .table-header > div > .row > div:nth-child(2) > div:nth-child(2) {
    padding-left: 0 !important;
  }
  .main-content .contacts .contacts-total.mobile {
    display: block !important;
  }
  .main-content .contacts .contacts-total.desktop {
    display: none;
  }
}
@media (min-width: 785px) and (max-width: 1084px) {
  .contact-details-container-drawer.has-contact-changes aside {
    height: calc(100% - 65px);
  }
}
@media (min-width: 601px) and (max-width: 1084px) {
  .inbox-side {
    height: 100%;
  }
}
@media (max-width: 784px) {
  .page-layout .sidebar-active .page-header + .page-container {
    padding-top: 62px;
  }

  .mobile-phone-drawer:not(.mobile-phone-visible) .phone-padding {
    padding: 0 16px;
  }

  .dialer-wrapper:not(.hide) {
    display: grid !important;
    grid-template-rows: 1fr auto;
  }
  .dialer-wrapper:not(.hide).on-text-tab .mobile-parked-calls-list {
    max-height: calc(100vh - 413px);
  }
  .dialer-wrapper:not(.hide).on-call-tab .mobile-parked-calls-list {
    max-height: calc(100vh - 343px);
  }
  .dialer-wrapper.hide {
    display: none !important;
  }

  .mobile-back-btn-global-header {
    transform: translateX(-10px);
    margin-right: 4px;
  }

  /*.main-content {
    padding-top: 62px;
  }*/
  .general-notification {
    margin-left: 5px !important;
  }
  .general-notification:last-child:not(:only-child), .general-notification:only-child {
    margin-bottom: 50px !important;
  }

  .b-toaster.b-toaster-bottom-right {
    bottom: 50px !important;
  }
  .b-toaster.b-toaster-bottom-right .b-toaster-slot {
    max-width: 300px !important;
  }

  .dashboard:not(.communications-page) .h-1-livebar .sidebar-active > .page-container,
.dashboard:not(.communications-page) .h-2-livebar .sidebar-active > .page-container {
    padding-top: 62px;
  }

  .h-2-livebar .stats-container {
    height: calc(100vh - 153px);
  }
  .h-2-livebar .sidebar-active > .page-header {
    top: 45px !important;
  }

  .h-1-livebar .stats-container {
    height: calc(100vh - 129px);
  }
  .h-1-livebar .sidebar-active > .page-header {
    top: 20px !important;
  }

  .stats-container {
    padding-bottom: 0 !important;
    height: calc(100vh - 107px);
  }
  .stats-container .stats-header .q-card__actions {
    padding-bottom: 1rem !important;
  }
  .stats-container .stats-metrics-container {
    padding-bottom: 0 !important;
  }

  .contacts-total.desktop {
    display: none;
  }
  .contacts-total.mobile {
    display: block !important;
  }

  .page-header {
    height: 58px;
  }
  .page-header.dialer-header {
    left: 0 !important;
  }
  .page-header > div {
    min-width: 0;
  }
  .page-header > div:first-child {
    flex: 1;
  }
  .page-header > div > h1 {
    font-size: 18px;
    font-family: "Inter", sans-serif;
  }
  .page-header.q-toolbar {
    height: 58px;
    padding: 0 15px 0 18.5px !important;
    justify-content: space-between;
    flex: 1 0 100%;
  }

  .mobile-back-btn {
    display: flex !important;
  }

  .page-footer {
    border-top: solid 1px #e4e4e4;
  }

  .inbox-side, .inbox-details {
    border-bottom: unset;
  }

  .sidebar-wrapper {
    display: none !important;
  }

  .page-layout {
    padding-bottom: 49px;
  }
  .page-layout .page-container {
    padding-left: 0 !important;
  }
  .page-layout .page-footer,
.page-layout .page-footer .dropdown-menu.show {
    z-index: 999;
  }

  .sidebar-active .q-header {
    left: 0 !important;
    display: flex;
    justify-content: flex-start;
  }
  .sidebar-active .q-header .page-header > div:nth-child(2) > div > div:not(.menu-avatar-wrapper), .sidebar-active .q-header .page-header > div:nth-child(2) > div > hr:not(.menu-avatar-wrapper), .sidebar-active .q-header .page-header > div:nth-child(3) {
    display: none !important;
  }

  .contact-list-sidebar-wrapper .card .list-group-item > div {
    flex: 1 0 100%;
  }

  .main-content .contacts .main {
    width: calc(100vw - 260px);
  }
  .main-content .contacts .table-header > div > .row {
    flex-flow: column-reverse;
  }
  .main-content .contacts .table-header > div > .row > div {
    justify-content: space-between;
    width: 100%;
    padding: 0 0 !important;
  }

  .contact-details-container-drawer.has-contact-changes aside {
    height: calc(100% - 16px);
  }

  .notification-container-header {
    display: none !important;
  }
}
@media (min-width: 605px) and (max-width: 780px), (max-width: 500px) {
  .main-content .contacts .table-header > div > .row > div:nth-child(2) > div:nth-child(3) {
    display: none;
  }
}
@media (max-width: 721px) {
  .main-content .contacts .table-header > div > .row {
    flex-flow: column-reverse;
  }
  .main-content .contacts .table-header > div > .row > div {
    justify-content: space-between;
    width: 100%;
    padding: 0 0 !important;
  }
  .main-content .contacts .table-header > div > .row > div .contacts-header-separator {
    margin: 0 13px;
  }
}
@media (min-width: 605px) and (max-width: 721px) {
  .main-content .contacts .table-header > div > .row > div:nth-child(2) > div:nth-child(2) {
    padding-right: 0px !important;
  }
}
@media (min-width: 605px) and (max-width: 670px), (max-width: 425px) {
  .main-content .contacts .table-header > div > .row > div:nth-child(2) > div:nth-child(2) {
    display: none !important;
  }
}
@media (max-width: 667px) {
  .contact-activity-container .calls-header__label {
    padding-left: 15px;
  }
}
@media (max-width: 605px) {
  .stats-container .stats-header .q-btn {
    width: 100%;
  }
}
@media (max-width: 604px) {
  .contact-activities-actions {
    margin-right: 5px;
  }

  .profile-menu {
    padding-right: 0 !important;
  }

  .mobile-header {
    display: flex !important;
  }

  .inbox-page .page-header {
    display: none;
  }

  .inbox-wrapper {
    display: block;
    width: 100%;
    overflow: hidden;
  }

  .mobile-header {
    height: 58px;
    width: 100%;
    padding-left: 10px;
    border-bottom: 1px solid #EBEBEB;
  }
  .mobile-header span:not(.label-my-contacts) {
    color: #333333;
    font-size: 18px;
    font-weight: 600;
    font-family: "Inter", sans-serif;
  }

  .contact-activities-actions__desktop {
    display: none;
  }

  .sidebar-active .q-header {
    justify-content: flex-start;
  }

  .contact-activities-actions__drawer_btn {
    display: none;
  }
  .contact-activities-actions__mobile_btn {
    display: flex !important;
  }

  .inbox {
    overflow-x: hidden;
  }
  .inbox-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    max-width: unset;
  }
  .inbox-wrapper.mobile-contact-active {
    flex: 0 0 auto !important;
    width: 0 !important;
  }
  .inbox-side, .inbox-details {
    border-left: unset;
    border-right: unset;
  }
  .inbox-side {
    max-width: unset;
    overflow-x: inherit;
  }
  .inbox-side__left {
    max-width: unset;
    min-width: unset;
    z-index: 1;
  }
  .inbox-side__left.inbox-side__left--closed {
    width: 0 !important;
    height: 0;
  }
  .inbox-side__right {
    width: 0;
    z-index: 2;
  }
  .inbox-side__right .task-list-scroller {
    padding-bottom: 60px;
  }
  .inbox-side__right.inbox-side__right--opened {
    width: 100% !important;
  }
  .inbox-side__right:not(.inbox-side__right--opened) {
    display: none !important;
  }
  .inbox-side__right .calls-header__label {
    padding-right: 0 !important;
  }
  .inbox-side__right .calls-header__label .channel-filter-actions-wrapper {
    margin-left: 17px !important;
  }
  .inbox-side__right .calls-header__label .q-select {
    margin-right: 17px !important;
  }
  .inbox-side__right .calls-header__label .q-select .q-field__append {
    padding-right: 0;
  }
  .inbox-side__right .task-list .task-item {
    padding-right: 14px !important;
  }

  /*.inbox-side__left,
  .inbox-side__right,
  .inbox-details,
  .contact-activity-wrapper {
    -webkit-transition: width 0.3s ease-in-out;
    -moz-transition: width 0.3s ease-in-out;
    -o-transition: width 0.3s ease-in-out;
    transition: width 0.3s ease-in-out;
  }*/
  .settings.page-side-menubar__left {
    max-width: unset;
  }
  .settings-content-wrapper {
    padding-right: 0 !important;
  }
  .settings-content-wrapper > .row {
    width: 100%;
  }
  .settings-content-wrapper > .row .settings-form-wrapper,
.settings-content-wrapper > .row .settings-form-wrapper .container {
    padding-right: 0 !important;
    margin: 0 0 !important;
  }
  .settings-content-wrapper > .row .settings-form-wrapper {
    margin: 0 0 !important;
    padding: 0 18.5px !important;
  }
  .settings-content-wrapper > .row .settings-form-wrapper .container {
    max-width: unset;
  }
  .settings-content-wrapper > .row .settings-form-wrapper .container .form-row,
.settings-content-wrapper > .row .settings-form-wrapper .container .form-row > div {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .settings-content-wrapper > .row .settings-form-wrapper .container .form-row:first-child > div > div > button,
.settings-content-wrapper > .row .settings-form-wrapper .container .row:first-child > div > div > button {
    transform: translateX(-8px);
    margin-top: 0.5rem !important;
    height: 24px !important;
    min-height: 24px !important;
    max-height: 24px !important;
    margin-right: 0;
  }
  .settings-content-wrapper > .row .settings-form-wrapper .container .form-row:first-child h1,
.settings-content-wrapper > .row .settings-form-wrapper .container .row:first-child h1 {
    line-height: 1;
    font-size: 18px;
    display: flex;
    align-items: center;
  }
  .settings-content-wrapper > .row .settings-form-wrapper .container .row.row-no-padding > div, .settings-content-wrapper > .row .settings-form-wrapper .container .row.row-no-padding > div table {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .settings-content-wrapper > .row .settings-form-wrapper .container .row.row-no-padding > div th:nth-child(2),
.settings-content-wrapper > .row .settings-form-wrapper .container .row.row-no-padding > div th:nth-child(3), .settings-content-wrapper > .row .settings-form-wrapper .container .row.row-no-padding > div table th:nth-child(2),
.settings-content-wrapper > .row .settings-form-wrapper .container .row.row-no-padding > div table th:nth-child(3) {
    min-width: 170px !important;
  }
  .settings-content-wrapper > .row .settings-form-wrapper .container .business-hours-container .flex-row:first-child {
    min-width: 86px;
  }
  .settings-content-wrapper > .row .settings-form-wrapper .container #voicemail-drop-library-container table tbody tr td audio {
    max-width: 250px;
  }
  .settings-form-wrapper, .settings-form-wrapper .container {
    padding-left: 0 !important;
  }
  .settings-side {
    max-width: unset;
    overflow-x: inherit;
  }
  .settings-side__left {
    max-width: unset;
    min-width: unset;
    width: 100%;
    z-index: 1;
  }
  .settings-side__left.settings-side__left--closed {
    width: 0 !important;
    height: 0;
  }
  .settings-side__left .page-side-menubar-list-item {
    background-color: unset !important;
  }
  .settings-side__right {
    width: 0;
    z-index: 2;
  }
  .settings-side__right.settings-side__right--opened {
    width: 100% !important;
  }
  .settings-side__right:not(.settings-side__right--opened) {
    display: none !important;
  }
  .settings-side__right .mobile-back-btn {
    display: flex !important;
  }

  .contact-activity-wrapper.contact-activity--closed {
    width: 0 !important;
    height: 0;
  }
  .contact-activity-wrapper.contact-activity--closed * {
    display: none;
  }

  .contact-details-container.contact-details--opened {
    padding-left: 15px;
    padding-top: 15px;
    width: 100% !important;
    display: block !important;
    max-width: unset !important;
  }
  .contact-details-container.contact-details--opened .details-component-container > div {
    margin-right: 10px !important;
  }
  .contact-details-container.contact-details--opened .mobile-header {
    padding-right: 13px !important;
  }
  .contact-details-container.contact-details--opened .mobile-header .profile-menu {
    min-width: 63px;
  }
  .contact-details-container.contact-details--opened .menu-avatar-wrapper {
    padding-top: 0 !important;
  }

  .contact-activity-container .calls-header__label {
    padding-left: 10px;
  }
  .contact-activity-container .calls-header__label span {
    display: flex;
    align-items: center;
  }
  .contact-activity-container .calls-header .profile-menu {
    min-width: 63px;
  }
  .contact-activity-container .contact-activities-actions-dropdown {
    height: 27px;
    width: auto;
  }

  .mobile-more-dropdown {
    left: unset !important;
    top: unset !important;
    right: 0;
    margin-right: 10px;
    transform: unset !important;
    bottom: 49px;
  }

  .calls-header {
    height: 58px;
    padding-right: 15px !important;
  }

  .calls-header__label .contact-name {
    font-size: 18px;
  }

  .contact-list-sidebar-container.contact-list-sidebar-open {
    flex: 1 0 100%;
  }
  .contact-list-sidebar-container .contact-list-sidebar-wrapper .card {
    width: 100%;
    padding-bottom: 50px;
  }
  .contact-list-sidebar-container .contact-list-sidebar-wrapper .card .list-group {
    padding-right: 0.5rem !important;
  }

  .contacts .contacts__title,
.contacts .mobile-back-btn .q-btn__content {
    height: 27px;
  }
  .contacts .main .mycard {
    padding-top: 15px !important;
  }

  .contacts-filter-sidebar {
    width: 260px;
  }
}
@media screen and (min-width: 400px) {
  #callFishing__toast_outer.b-toast,
#callFishing__toast_outer .action-notification.in-progress {
    max-width: 399px !important;
    width: 100% !important;
  }
  #callFishing__toast_outer .action-notification {
    margin: 0 auto;
  }
  #callFishing__toast_outer .action-notification.in-progress button {
    padding: 0 17px;
  }
  #callFishing__toast_outer .action-notification .toast-body > .notification-body-wrapper > div > div:nth-child(3) > button:first-child svg {
    margin-right: 2px !important;
  }
  #callFishing__toast_outer .action-notification .toast-body > .notification-body-wrapper > div .notification-icon {
    max-width: 32px;
    width: 100%;
  }
  #callFishing__toast_outer .action-notification .toast-body > .notification-body-wrapper > div > .notification-details {
    max-width: 150px;
    width: 100%;
    flex-grow: unset !important;
  }
  #callFishing__toast_outer .action-notification .toast-body > .notification-body-wrapper > div > .call-actions {
    max-width: 84px;
    width: 100%;
  }
  #callFishing__toast_outer .action-notification .message-text.has-ring-group .campaign-wrapper,
#callFishing__toast_outer .action-notification .message-text.has-ring-group .ring-group-wrapper {
    padding: 0 0;
  }
  #callFishing__toast_outer .action-notification .message-text div:nth-child(2) {
    padding: 0 5px;
    max-width: 17px;
    min-width: 17px;
  }
}
@media screen and (max-width: 399px) {
  #callFishing__toast_outer .action-notification.in-progress {
    max-width: 210px !important;
    width: 100% !important;
  }
  #callFishing__toast_outer .action-notification {
    margin: 0 auto;
  }
  #callFishing__toast_outer .action-notification .message-text.has-ring-group .campaign-wrapper,
#callFishing__toast_outer .action-notification .message-text.has-ring-group .ring-group-wrapper {
    padding: 0 0;
  }
  #callFishing__toast_outer .action-notification .message-text div:nth-child(2) {
    padding: 0 5px;
    max-width: 17px;
    min-width: 17px;
  }
}
@media (max-width: 470px) {
  .paginated .table-pagination .q-btn__wrapper {
    padding: 0 0 !important;
  }
}
@media (max-width: 325px) {
  .paginated .table-pagination button {
    margin: 0 0 !important;
  }
  .paginated .table-pagination .q-btn__wrapper {
    padding: 0 0 !important;
  }
}
@media (max-width: 306px) {
  .contacts .main .mycard .datatable-wrapper .q-select-pager {
    margin-left: 18px !important;
  }
}
@media (max-width: 299px) {
  .unsupported {
    display: flex !important;
    height: 100%;
  }

  .page {
    display: none;
  }
}
@media screen and (max-height: 500px) {
  .unsupported {
    display: flex !important;
    height: 100%;
  }

  .page {
    display: none;
  }
}
.mark-all-as-read-icon {
  margin-top: -2px;
}

.dropdown-icon {
  margin-right: 10px;
}

.border {
  border: 1px solid #EBEBEB;
}
.border-bottom {
  border-bottom: 1px solid #EBEBEB !important;
}
.border-top {
  border-top: 1px solid #EBEBEB !important;
}
.border-left {
  border-left: 1px solid #EBEBEB !important;
}
.border-right {
  border-right: 1px solid #EBEBEB !important;
}

.border-required {
  border: 1px solid #fa003f;
}

.stats-refresh-btn {
  height: 32px;
  font-size: 13px;
  margin-left: 15px;
  font-size: 14px;
  font-weight: 500;
}
.stats-refresh-btn:hover {
  border-color: #000;
  border: 1px solid #000 !important;
  transition: border-color 0.36s cubic-bezier(0.4, 0, 0.2, 1);
}
.stats-refresh-btn svg {
  margin-right: 5px;
}

.contact-popover {
  border-radius: 8px;
  padding: 6px 6px;
  box-shadow: 0px 0px 12px -2px rgba(0, 0, 0, 0.1), 0px 12px 18px -4px rgba(4, 4, 4, 0.1);
}
.contact-popover .arrow {
  display: none;
}
.contact-popover .contact-menu-item {
  padding: 6px 10px;
  border-radius: 6px;
}
.contact-popover .contact-menu-item__icon {
  margin-right: 10px;
}
.contact-popover .contact-menu-item__title span {
  color: #040404;
}

.filter-toggle-button .dropdown-menu,
.contacts-options-dropdown .dropdown-menu,
.tags-table .dropdown-menu {
  border-radius: 8px;
  padding: 6px 6px;
  box-shadow: 0px 0px 12px -2px rgba(0, 0, 0, 0.1), 0px 12px 18px -4px rgba(4, 4, 4, 0.1);
}
.filter-toggle-button .dropdown-menu li a,
.contacts-options-dropdown .dropdown-menu li a,
.tags-table .dropdown-menu li a {
  display: flex;
  align-items: center;
  padding: 6px 10px;
  border-radius: 6px;
  font-size: 13px;
  font-weight: 500;
}
.filter-toggle-button .dropdown-menu li a:not(.disabled),
.contacts-options-dropdown .dropdown-menu li a:not(.disabled),
.tags-table .dropdown-menu li a:not(.disabled) {
  color: #040404;
}
.filter-toggle-button .dropdown-menu li a:not(.disabled) i,
.contacts-options-dropdown .dropdown-menu li a:not(.disabled) i,
.tags-table .dropdown-menu li a:not(.disabled) i {
  color: #62666E;
}
.filter-toggle-button .dropdown-menu li a i,
.contacts-options-dropdown .dropdown-menu li a i,
.tags-table .dropdown-menu li a i {
  margin-right: 14px;
}
.filter-toggle-button .dropdown-menu li a svg,
.contacts-options-dropdown .dropdown-menu li a svg,
.tags-table .dropdown-menu li a svg {
  margin-right: 10px;
}

.hours {
  position: relative;
}

.fw-500 {
  font-weight: 500 !important;
}

.q-item--active.text-primary .q-item__label {
  color: #FFFFFF !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-bottom-transparent {
  border-bottom: 1px solid transparent !important;
}

.workflow-selector {
  max-width: 310px !important;
}

.mobile-phone-drawer:not(.mobile-phone-visible) {
  height: 100%;
}
.mobile-phone-drawer.mobile-phone-visible {
  height: calc(100vh - 50px);
}
.mobile-phone-drawer .q-header {
  left: 0 !important;
  display: flex;
  justify-content: flex-start;
}
.mobile-phone-drawer .q-header .page-header > div:nth-child(2) > div > div:not(.menu-avatar-wrapper), .mobile-phone-drawer .q-header .page-header > div:nth-child(2) > div > hr:not(.menu-avatar-wrapper), .mobile-phone-drawer .q-header .page-header > div:nth-child(3) {
  display: none !important;
}
.mobile-phone-drawer .phone-header-title {
  font-size: 34px;
  font-weight: 600;
}
.mobile-phone-drawer .phone-header {
  position: fixed;
  z-index: 9;
}
.mobile-phone-drawer .dialerForm {
  padding-top: 75px !important;
}
.mobile-phone-drawer .q-drawer__backdrop {
  display: none;
}
.mobile-phone-drawer aside {
  max-width: 784px !important;
  width: 100% !important;
  position: absolute !important;
}
.mobile-phone-drawer aside .q-drawer__content > div {
  width: 100% !important;
}
.mobile-phone-drawer aside .q-drawer__content > div.row {
  display: flex;
  flex-direction: column;
  padding-bottom: 0 !important;
}
.mobile-phone-drawer aside .q-drawer__content > div.row.dialerForm {
  height: 100%;
}
.mobile-phone-drawer aside .q-drawer__content > div.row:not(.dialerForm) {
  height: calc(100% - 51px);
}
.mobile-phone-drawer aside .q-drawer__content > div.row.loading-cover-screen {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #15163F;
  padding-top: 0 !important;
}
.mobile-phone-drawer aside .q-drawer__content > div.row.loading-cover-screen .loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.mobile-phone-drawer aside .q-drawer__content > div .dialer-tabs {
  flex-direction: column !important;
  height: 100%;
}
.mobile-phone-drawer aside .q-drawer__content > div .dialer-tabs > div {
  padding: 0 0 !important;
  border: unset;
}
.mobile-phone-drawer aside .q-drawer__content > div .dialer-tabs > div ul[role=tablist] {
  background: #EBEBEB;
  border: 2px solid #EBEBEB;
  border-radius: 6px;
  display: flex;
  flex-direction: row !important;
  margin-bottom: 15px;
}
.mobile-phone-drawer aside .q-drawer__content > div .dialer-tabs > div ul[role=tablist] li {
  flex: 1;
}
.mobile-phone-drawer aside .q-drawer__content > div .dialer-tabs > div ul[role=tablist] li .nav-link {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08);
  font-size: 14px;
}
.mobile-phone-drawer aside .q-drawer__content > div .dialer-tabs > div ul[role=tablist] li .nav-link.active, .mobile-phone-drawer aside .q-drawer__content > div .dialer-tabs > div ul[role=tablist] li .contact-list-sidebar-wrapper .nav-link.router-link-exact-active, .contact-list-sidebar-wrapper .mobile-phone-drawer aside .q-drawer__content > div .dialer-tabs > div ul[role=tablist] li .nav-link.router-link-exact-active {
  background-color: #FFFFFF !important;
  color: #040404;
  border-radius: 5px;
  font-weight: 600;
}
.mobile-phone-drawer aside .q-drawer__content > div .dialer-tabs > div ul[role=tablist] li .nav-link:not(.active) {
  color: #62666E;
  border-radius: unset;
}
.mobile-phone-drawer aside .q-drawer__content > div .dialer-tabs .tab-content {
  display: flex;
  height: 100%;
  width: 100%;
}
.mobile-phone-drawer aside .q-drawer__content > div .dialer-tabs .tab-content .tab-pane {
  width: 100%;
}
.mobile-phone-drawer aside .q-drawer__content > div .dialer-tabs .mobile-message-composer textarea {
  font-size: 16px !important;
}
.mobile-phone-drawer aside .q-drawer__content > div .tab-content .tab-pane .dialer {
  justify-content: flex-start !important;
}
.mobile-phone-drawer aside .q-drawer__content > div .tab-content .tab-pane .dialer > .form-group {
  flex: 1;
}
.mobile-phone-drawer aside .q-drawer__content > div .tab-content .tab-pane .dialer > .form-group .search-form {
  width: 100% !important;
}
.mobile-phone-drawer aside .q-drawer__content > div .tab-content .tab-pane .dialer .q-btn {
  margin-left: 17px;
}
.mobile-phone-drawer aside .q-drawer__content .line-selector label,
.mobile-phone-drawer aside .q-drawer__content .line-selector .generic-selector .q-field__control,
.mobile-phone-drawer aside .q-drawer__content .line-selector .generic-selector .q-field__control:after,
.mobile-phone-drawer aside .q-drawer__content .line-selector .generic-selector .q-field__prepend,
.mobile-phone-drawer aside .q-drawer__content .line-selector .generic-selector .q-field__append,
.mobile-phone-drawer aside .q-drawer__content .line-selector .generic-selector .q-field__append,
.mobile-phone-drawer aside .q-drawer__content .line-selector .generic-selector .q-field__control-container,
.mobile-phone-drawer aside .q-drawer__content .line-selector .generic-selector .q-field__native {
  min-height: 40px !important;
}
.mobile-phone-drawer aside .q-drawer__content .line-selector label {
  height: unset !important;
  padding-bottom: 0 !important;
}
.mobile-phone-drawer aside .q-drawer__content .line-selector .q-field__prepend span,
.mobile-phone-drawer aside .q-drawer__content .line-selector .q-field__native span,
.mobile-phone-drawer aside .q-drawer__content .line-selector .q-field__control-container input {
  font-size: 16px;
}
.mobile-phone-drawer aside .q-drawer__content .search-form .input-group *,
.mobile-phone-drawer aside .q-drawer__content .search-form input::-webkit-input-placeholder {
  font-size: 16px !important;
}
.mobile-phone-drawer aside .q-drawer__content .search-form .form-control {
  min-height: 40px !important;
}
.mobile-phone-drawer aside .q-drawer__content .dialer-contact-info {
  width: 100% !important;
}
.mobile-phone-drawer aside .q-drawer__content .dialer-contact-info div {
  font-size: 13px;
}
.mobile-phone-drawer aside .q-drawer__content .invalid-feedback {
  font-size: 13px;
}
.mobile-phone-drawer aside .q-drawer__content .phone {
  position: unset;
  height: 100%;
  overflow-y: scroll;
  border-radius: unset;
  padding-bottom: 44px;
}
.mobile-phone-drawer aside .q-drawer__content .phone .mobile-live-call-bar {
  z-index: 10;
  position: absolute;
}
.mobile-phone-drawer aside .q-drawer__content .phone .mobile-live-call-bar .dropdown-menu {
  max-width: 375px;
  width: 100%;
  top: 23px !important;
  right: 0;
  margin: auto;
  transform: unset !important;
}
.mobile-phone-drawer aside .q-drawer__content .phone .mobile-live-call-bar .dropdown-menu.bg-grey-93 .dropdown-item:hover {
  background: #585A6D;
  color: #FFF;
}
.mobile-phone-drawer aside .q-drawer__content .phone #signal-strength {
  height: 20px;
}
.mobile-phone-drawer aside .q-drawer__content .phone #signal-strength li {
  width: 4px;
}
.mobile-phone-drawer aside .q-drawer__content .phone #signal-strength li:not(:last-child) {
  margin-right: 2px;
}
.mobile-phone-drawer aside .q-drawer__content .phone #signal-strength li.very-weak {
  padding-top: 17px;
}
.mobile-phone-drawer aside .q-drawer__content .phone #signal-strength li.weak {
  padding-top: 12px;
}
.mobile-phone-drawer aside .q-drawer__content .phone #signal-strength li.strong {
  padding-top: 6px;
}
.mobile-phone-drawer aside .q-drawer__content .phone-header {
  padding-bottom: 10px;
}
.mobile-phone-drawer aside .q-drawer__content .phone-header:not(.call-ended) {
  height: 92px;
}
.mobile-phone-drawer aside .q-drawer__content .phone-header.call-ended {
  height: 88px;
}
.mobile-phone-drawer aside .q-drawer__content .phone-header.call-ended + .phone-body {
  margin-top: 88px;
}
.mobile-phone-drawer aside .q-drawer__content .phone-header.call-ended + .phone-body .phone-wrap-up {
  padding-top: 0;
}
.mobile-phone-drawer aside .q-drawer__content .phone-header div:last-child button:last-child {
  display: none;
}
.mobile-phone-drawer aside .q-drawer__content .phone-header div:first-child span {
  font-size: 14px;
}
.mobile-phone-drawer aside .q-drawer__content .phone-header div:nth-child(2) span {
  font-size: 16px;
}
.mobile-phone-drawer aside .q-drawer__content .phone-header div:nth-child(3) {
  width: 80px;
}
.mobile-phone-drawer aside .q-drawer__content .phone-header div:nth-child(3) svg {
  width: 22px !important;
  height: 22px !important;
}
.mobile-phone-drawer aside .q-drawer__content .phone-header div:nth-child(3) button .height-12 {
  height: 20px;
  width: 19px;
}
.mobile-phone-drawer aside .q-drawer__content .phone-header div:nth-child(3) button .height-12 img {
  width: 19px;
  height: 20px;
}
.mobile-phone-drawer aside .q-drawer__content .phone-header .phone-settings-icon {
  font-size: 16px !important;
}
.mobile-phone-drawer aside .q-drawer__content .phone-body .phone-wrap-up {
  max-height: unset;
  padding-top: 36px;
  padding-bottom: 76px;
}
.mobile-phone-drawer aside .q-drawer__content .phone-body .phone-info {
  margin-top: 20px;
  width: 100%;
}
.mobile-phone-drawer aside .q-drawer__content .phone-body .phone-info .q-item__label:first-child {
  margin-top: 25px !important;
}
.mobile-phone-drawer aside .q-drawer__content .phone-body .phone-info .q-item__label:first-child > span {
  font-size: 24px;
  font-weight: 600;
}
.mobile-phone-drawer aside .q-drawer__content .phone-body .phone-info .q-item__label:first-child button {
  display: block;
  font-size: 18px;
}
.mobile-phone-drawer aside .q-drawer__content .phone-body .phone-info .q-item__label:nth-child(2) {
  margin-top: 10px !important;
}
.mobile-phone-drawer aside .q-drawer__content .phone-body .phone-info .q-item__label:nth-child(2) span {
  font-size: 16px;
}
.mobile-phone-drawer aside .q-drawer__content .phone-body .phone-info .q-item__label:nth-child(3) {
  margin-top: 10px !important;
}
.mobile-phone-drawer aside .q-drawer__content .phone-body .phone-info .q-item__label:nth-child(3) span {
  font-size: 14px;
  color: #62666E;
}
.mobile-phone-drawer aside .q-drawer__content .phone-main .dummy, .mobile-phone-drawer aside .q-drawer__content .phone .phone-wrap-up .dummy, .phone .mobile-phone-drawer aside .q-drawer__content .phone-wrap-up .dummy {
  height: 92px;
}
.mobile-phone-drawer aside .q-drawer__content .phone-main .phone-avatar, .mobile-phone-drawer aside .q-drawer__content .phone .phone-wrap-up .phone-avatar, .phone .mobile-phone-drawer aside .q-drawer__content .phone-wrap-up .phone-avatar {
  position: fixed;
  top: 68px;
  margin-top: unset;
  z-index: 9;
}
.mobile-phone-drawer aside .q-drawer__content .phone .actions-block {
  padding: 0 21px !important;
  max-width: 400px;
  margin-top: 50px !important;
}
.mobile-phone-drawer aside .q-drawer__content .phone-expansion .q-expansion-item {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border-bottom-left-radius: unset;
  border-bottom-right-radius: unset;
}
.mobile-phone-drawer aside .q-drawer__content .phone-expansion .q-expansion-item .q-item {
  height: 44px;
}
.mobile-phone-drawer aside .q-drawer__content .phone-expansion .q-expansion-item .q-item .q-item__label {
  font-size: 16px;
  font-weight: 600 !important;
  color: #62666E;
}
.mobile-phone-drawer aside .q-drawer__content .phone-expansion .q-expansion-item__toggle-icon {
  font-size: 25px;
  color: #62666E;
}
.mobile-phone-drawer aside .q-drawer__content .phone-expansion .q-expansion-item__content .q-card__section > div {
  justify-content: space-between !important;
  max-width: 400px;
  margin: auto;
}
.mobile-phone-drawer aside .q-drawer__content .phone-buttons.elevated {
  max-width: 98px;
  width: 100%;
  height: 98px;
}
.mobile-phone-drawer aside .q-drawer__content .phone-buttons.elevated:not(:last-child) {
  margin-right: 10px;
}
.mobile-phone-drawer aside .q-drawer__content .phone-footer-buttons {
  border-radius: unset !important;
  padding: 18px 16px !important;
  height: unset !important;
  position: fixed;
  bottom: 0;
  width: 100%;
  box-shadow: 0px -5px 10px 0px rgba(4, 4, 4, 0.2) !important;
}
.mobile-phone-drawer aside .q-drawer__content .phone-footer-buttons button:first-child {
  background: #00bf4a;
  color: #FFFFFF;
  margin-right: 10px;
}
.mobile-phone-drawer aside .q-drawer__content .phone-footer-buttons button {
  height: 40px;
  max-width: 166px;
  width: 100%;
}

.q-tree {
  width: 100%;
}
.q-tree__node-header:before {
  width: 10px !important;
}
.q-tree__node-header-content {
  padding-left: 0 !important;
}
.q-tree__node--child {
  padding: 0 0 3px 22px !important;
}
.q-tree__node-collapsible .q-tree__node-header > div {
  margin-left: -20px;
}

.mobile-dialer-button {
  display: flex;
  justify-content: center;
  width: 100%;
}
.mobile-dialer-button button {
  display: flex;
  justify-content: center;
  width: 100%;
  color: white;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 500;
  height: 48px;
  margin: 0 0 13px 0 !important;
  padding: 0 10px;
  min-width: 0;
  flex: 1;
}
.mobile-dialer-button button:hover {
  background: #00bf4a !important;
  color: white;
}
.mobile-dialer-button button svg {
  margin-right: 8px;
}
.mobile-dialer-button button .contact-name {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.b-toaster {
  z-index: 9999;
}

.notes-wrapper {
  border-radius: 12px;
  padding: 14px;
  background: linear-gradient(0deg, #EEF3FF, #EEF3FF);
  border: 1px solid rgba(47, 128, 237, 0.25);
}
.notes-wrapper .q-item {
  padding: 0 !important;
  min-height: 10px !important;
}

.contact-sidebar-list-wrapper .folders__header .header__header__title {
  margin-top: -1px;
  position: relative;
}

.contact-filter-count {
  top: 0 !important;
  padding-top: 6px !important;
}

.q-select-auto-width .q-field__control-container {
  width: auto !important;
  max-width: fit-content;
}
.q-select-auto-width .q-field__control-container .q-field__native {
  padding-right: 6px !important;
}

.vue-daterange-picker {
  width: 100%;
}

.last-engagement-tooltip-wrapper {
  position: absolute;
  right: 50%;
  top: -1px;
}

.last-engagement-dynamic-tooltip-wrapper {
  position: absolute;
  right: 40%;
  top: -1px;
}

.bridge-menu-wrapper {
  margin-right: 20px;
}

.q-shared-login-menu-dropdown {
  box-shadow: none !important;
  margin: 0 !important;
}
.q-shared-login-menu-dropdown button:first-child {
  text-transform: none;
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
  border-right: none !important;
}
.q-shared-login-menu-dropdown button:first-child .q-btn__wrapper {
  padding-right: 0 !important;
}
.q-shared-login-menu-dropdown button:first-child .q-btn__content span {
  margin-left: 10px;
  color: #040404 !important;
}
.q-shared-login-menu-dropdown button:nth-child(2) {
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
}
.q-shared-login-menu-dropdown button:nth-child(2) .q-btn__wrapper {
  padding-left: 0 !important;
  padding-right: 6px !important;
}
.q-shared-login-menu-dropdown button {
  border: 1px solid #D8D8D8;
  color: #62666E;
  background: transparent !important;
}
.q-shared-login-menu-dropdown button:hover, .q-shared-login-menu-dropdown button:active {
  background-color: transparent !important;
}
.q-shared-login-menu-dropdown button:focus {
  box-shadow: none !important;
}
.q-shared-login-menu-dropdown button .q-btn__wrapper {
  color: #62666E !important;
}
.q-shared-login-menu-dropdown .q-separator {
  background: #D8D8D8 !important;
  height: 19px;
}

.q-shared-login-menu-dropdown-list {
  padding: 15px !important;
  width: 180px !important;
}
.q-shared-login-menu-dropdown-list .q-item {
  min-height: 16px;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.q-shared-login-menu-dropdown-list .q-item:not(:last-child) {
  margin-bottom: 13px;
}
.q-shared-login-menu-dropdown-list .q-item .custom-control-label {
  color: #040404 !important;
}

.shared-login-menu-dropdown button {
  border: 1px solid #EBEBEB;
  color: #62666E;
}
.shared-login-menu-dropdown button:hover, .shared-login-menu-dropdown button:active {
  background-color: transparent !important;
  border-color: #EBEBEB !important;
  color: #62666E !important;
}
.shared-login-menu-dropdown button:focus {
  box-shadow: none !important;
}
.shared-login-menu-dropdown button:not(.dropdown-toggle) {
  color: #040404 !important;
}
.shared-login-menu-dropdown button:not(.dropdown-toggle) i {
  color: #62666E !important;
}

.my-lists .q-expansion-item__content > .folders .folders__content {
  overflow-x: hidden;
}
.my-lists .q-expansion-item__content > .folders .folders__content .tree-folder > .folder,
.my-lists .q-expansion-item__content > .folders .folders__content .tree-folder .tree-list-item {
  min-width: 0;
  padding-right: 17px;
}

.public-lists .item {
  min-width: 0;
  padding-right: 17px;
}

.w-0 {
  width: 0 !important;
  min-width: 0 !important;
  max-width: 0 !important;
}

.no-min-max-width {
  min-width: unset !important;
  max-width: unset !important;
}

.textual-filters .filter-item .card-body {
  padding-right: 26px;
}

.q-tooltip.b-tooltip {
  padding: 0 0 !important;
  max-width: 180px;
  width: auto;
}
.q-tooltip.b-tooltip .tooltip-inner {
  background-color: unset;
  font-size: 10px;
  font-family: Inter, sans-serif;
  font-weight: 400;
  color: #040404;
  text-align: left;
}
.q-tooltip.b-tooltip .arrow:before {
  display: none;
}

.message-composer-send-dropdown-button {
  border-radius: 6px !important;
}
.message-composer-send-dropdown-button button:first-child {
  border-top-left-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
}
.message-composer-send-dropdown-button button:nth-child(2) {
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}
.message-composer-send-dropdown-button .q-btn {
  text-transform: none !important;
  font-size: 14px !important;
  font-weight: 600;
  height: 32px;
}
.message-composer-send-dropdown-button .q-btn .q-btn__wrapper .q-btn__content {
  height: 32px !important;
}

.message-composer-send-dropdown-button-list .q-item {
  padding: 0 12px !important;
}
.message-composer-send-button {
  height: 32px;
  text-transform: none !important;
  border-radius: 6px !important;
}
.message-composer-send-button span.q-btn__wrapper span.q-btn__content {
  padding: 0 12px;
  margin-top: -2px;
  font-weight: 600;
}
.message-composer-send-button .q-btn__wrapper:before {
  box-shadow: none !important;
}

.call-fishing-actions {
  padding-top: 2px;
}
.call-fishing-actions > button:first-child {
  margin-right: 10px;
}
.call-fishing-actions button .q-btn__wrapper {
  margin: 0 0;
  padding: 0 0;
  min-height: 32px !important;
}
.call-fishing-actions button .q-btn__wrapper:before {
  box-shadow: unset;
}
.call-fishing-actions button span {
  font-size: 13px;
  font-weight: 500;
}
.call-fishing-actions .b-compact-dropdown-button {
  margin: 0 0 !important;
}
.call-fishing-actions .b-compact-dropdown-button button {
  border: unset;
  padding: 0 0 !important;
}
.call-fishing-actions .b-compact-dropdown-button ul {
  margin-top: 8px;
  padding: 6px 6px;
  background: rgba(21, 22, 63, 0.8) !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 0px 12px -2px rgba(0, 0, 0, 0.08) !important;
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  border-radius: 8px;
}
.call-fishing-actions .b-compact-dropdown-button ul li a {
  color: white;
  padding: 6px 12.65px;
}
.call-fishing-actions .b-compact-dropdown-button ul li a:not(svg) {
  font-size: 13px;
}
.call-fishing-actions .b-compact-dropdown-button ul li a .icon-margin {
  margin-right: 8px;
}
.call-fishing-actions .b-compact-dropdown-button ul[x-placement=bottom-end] {
  width: 298px;
  transform: translate(-249px, 40px) !important;
}
.call-fishing-actions .b-compact-dropdown-button ul[x-placement=right-start] {
  margin: 0 !important;
  transform: translate(36px, -6px) !important;
}
.call-fishing-actions .b-compact-dropdown-button .dropdown-item:hover,
.call-fishing-actions .b-compact-dropdown-button .dropdown-item:focus {
  background: #585A6D;
  border-radius: 8px;
}

.incoming-call-notification .message-text {
  -webkit-line-clamp: unset !important;
  -webkit-box-orient: unset !important;
}
.incoming-call-notification .message-text.has-ring-group {
  overflow: auto !important;
  text-overflow: unset !important;
}
.incoming-call-notification .message-text.has-ring-group .campaign-wrapper,
.incoming-call-notification .message-text.has-ring-group .ring-group-wrapper {
  min-width: 0;
}
.incoming-call-notification .message-text.has-ring-group .campaign-wrapper .campaign-name,
.incoming-call-notification .message-text.has-ring-group .ring-group-wrapper .ring-group-name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.incoming-call-notification .message-text:not(.has-ring-group) {
  display: block !important;
  white-space: nowrap;
}

.b-radius-12 {
  border-radius: 12px;
}

.notification-body-wrapper {
  /* cursor: pointer; */
}
.notification-body-wrapper .call-actions {
  padding-top: 2px;
}
.notification-body-wrapper .call-actions button:first-child {
  margin-right: 10px;
}
.notification-body-wrapper .call-actions .q-btn__wrapper {
  min-width: unset;
  min-height: unset;
}

.shared-login-menu-dropdown.dropdown.show .dropdown-toggle {
  border: 1px solid #EBEBEB !important;
  background-color: transparent !important;
  color: #62666E !important;
}

.q-btn-standard {
  border: 1px solid #D8D8D8;
  color: #62666E;
  text-transform: none !important;
  padding: 0 14px 0 10px;
  border-radius: 6px;
}
.q-btn-standard .q-btn__wrapper:before {
  border: none !important;
}
.q-btn-standard .q-btn__wrapper .q-btn__content {
  color: #62666E;
}
.q-btn-standard .q-btn__wrapper .q-btn__content span {
  margin-left: 7px;
  color: #040404;
}

.background-blur {
  box-shadow: 0 10px 20px -4px rgba(4, 4, 4, 0.2);
  border-radius: 8px;
}
.background-blur::before {
  content: "";
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  border-radius: 8px;
}

.live-call-options {
  padding: 6px;
}
.live-call-options .q-list .q-item {
  padding: 5px 10px;
  border-radius: 6px;
  min-height: 28px !important;
}
.live-call-options .q-list .q-item .q-item__section {
  display: block !important;
  min-height: 0 !important;
}
.live-call-options .q-list .q-item .q-item__section svg:not(.hangup-icon) {
  margin-right: 10px;
}
.live-call-options .q-list .q-item .q-item__section svg.hangup-icon {
  margin-right: 6px !important;
}

.menu-phone:hover {
  color: #11508e;
}

.pt-60 {
  padding-top: 60px !important;
}

.contact-phone-number-search .vbt-autocomplete-list .list-group-item {
  padding: 0.75rem;
}

.call-connected {
  border: 2px solid #5890ff;
}
.call-connected-text {
  font-size: 14px;
  font-weight: 500;
  color: #256eff;
}

.contact-task-item.item-call-parked, .contact-task-item.item-call-connected {
  background: #EEF3FF !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
}
.contact-task-item .call-parked-label {
  font-size: 12px;
  color: #9B51E0;
}
.contact-task-item .call-connected-label {
  font-size: 12px;
  color: #256eff;
}

.call-fishing-clear-queues {
  top: -32px;
}

.disabled-user-status {
  background: #747981 !important;
}

.incoming-call-notification .message-text:not(.has-ring-group) {
  max-width: 162px;
}

.border-radius-1 {
  border-radius: 5px;
}

.border-radius-2 {
  border-radius: 10px;
}

.border-radius-3 {
  border-radius: 15px;
}

.border-radius-4 {
  border-radius: 20px;
}

.daterange-picker-highlighted .reportrange-text {
  border: 2px solid #256eff !important;
}

.live-call-badge {
  top: 1px !important;
  right: 4px;
  height: 8px;
  width: 2px;
}

.item-live-call + .item-live-call {
  border-bottom: 2px solid #EBEBEB !important;
}

.h-1-livebar {
  height: calc(100vh - 21px);
  min-height: unset !important;
}

.h-2-livebar {
  height: calc(100vh - 46px);
  min-height: unset !important;
}

.mobile-header-labels {
  display: flex;
  min-width: 0;
}
.mobile-header-labels .contact-name {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.mobile-header .menu-avatar-wrapper {
  padding-right: 2.5px !important;
}

.btn-light {
  border-color: #d8d8d8;
}

.q-avatar .avatar-initials {
  height: 7px;
  font-weight: 500;
}

.q-tooltip {
  z-index: 9999;
}

.ring-group-snapshot-table {
  box-shadow: none !important;
}

.ring-group-snapshot-card,
.communication-details-card {
  border-radius: 8px !important;
  background: #FFFFFF !important;
  color: #040404;
  border: 1px solid rgba(120, 140, 140, 0.13);
}
.ring-group-snapshot-card .q-card,
.communication-details-card .q-card {
  border: 1px solid rgba(120, 140, 140, 0.13);
}
.ring-group-snapshot-card hr:not(.q-separator),
.communication-details-card hr:not(.q-separator) {
  border-top: 1px solid rgba(120, 140, 140, 0.13);
}

.communication-details-card .record-was-deleted-label {
  margin-top: 1rem !important;
  line-height: 1.2em !important;
  display: block;
}
.communication-details-card .header-line {
  margin-bottom: 33px !important;
}
.communication-details-card .comm-description-card {
  padding-top: 0 !important;
}
.communication-details-card .comm-type-container {
  padding-bottom: 20px !important;
}
.communication-details-card .comm-type-wrapper {
  margin-top: 1.4px;
}
.communication-details-card .comm-type-wrapper span {
  margin-left: 11px;
}
.communication-details-card .form-row {
  padding: 10px 0;
}
.communication-details-card hr:not(.has-margin) {
  margin: 0 !important;
}
.communication-details-card .q-item__label:not(.custom-item-label) {
  margin-top: 0.25rem !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.communication-details-card .q-item__label.custom-item-label {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.br-8 {
  border-radius: 8px;
}

.text-dark-greenish {
  color: #00702f !important;
}

@media (max-width: 767px) {
  .ring-group-snapshot-wrapper {
    padding-left: 0 !important;
    margin-top: 10px;
  }

  .communication-details-card {
    margin-top: 60px;
  }
}
@media (min-width: 401px) {
  .communication-details-card .header .header-btn-wrapper .report-issue-wrapper {
    margin-right: 5px !important;
  }
}
@media (max-width: 400px) {
  .communication-details-card .header {
    flex-direction: column !important;
  }
  .communication-details-card .header .header-title {
    text-align: center;
  }
  .communication-details-card .header .header-btn-wrapper {
    margin-top: 5px;
    flex-direction: column !important;
  }
  .communication-details-card .header .header-btn-wrapper button {
    margin-top: 5px;
  }
}
.min-w-0 {
  min-width: 0;
}

.d-grid {
  display: grid;
}

.truncated-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.grid-2-col.task-item-body {
  grid-template-columns: 26px 1fr;
}

.park-call-icon {
  margin-top: -3px;
}

.lh-27 {
  line-height: 27px;
}

.mobile-live-call-bar .dropdown-menu {
  z-index: 9999;
}

.green-phone .q-tab__indicator,
.purple-phone .q-tab__indicator {
  top: 0 !important;
  max-width: 38px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  opacity: 1;
}

.green-phone svg path {
  fill: #07D858 !important;
}
.green-phone .q-tab__content {
  color: #07D858 !important;
}
.green-phone .q-tab__indicator {
  background: #07D858 !important;
}

.purple-phone svg path {
  fill: #9B51E0 !important;
}
.purple-phone .q-tab__content {
  color: #9B51E0 !important;
}
.purple-phone .q-tab__indicator {
  background: #9B51E0 !important;
}

.communication-page .main-content {
  overflow: auto !important;
}

.account-level-notification-tooltip-wrapper {
  position: absolute;
  left: 300px;
  margin-top: -22px;
  z-index: 10;
}

.cursor-default {
  cursor: default !important;
}

.cursor-blocked {
  cursor: not-allowed !important;
}

.template-preview {
  min-width: 15rem !important;
}

.aloicons {
  font-family: "Aloicons", sans-serif;
  color: #62666E !important;
}
.aloicons.action-icons {
  font-size: 16px;
}

button.q-field__focusable-action {
  font-size: 16px;
}

h1.contact-list-name-label {
  width: 12vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.btn-contact-sequence-enrol:hover svg path {
  fill: #FFFFFF;
}
.btn-contact-sequence-enrol svg {
  margin-top: -4px;
}
.btn-contact-sequence-enrol svg path {
  fill: #256eff;
}

.contain-text {
  padding-top: 6px !important;
}

.mt-n-5 {
  margin-top: -5px;
}

.contact-info-editable .q-icon {
  color: var(--q-color-primary);
}
.contact-info-editable.inline-input .q-field__append.q-field__marginal button {
  padding: 0;
  line-height: 0;
}
.contact-info-editable.inline-input:focus-within .q-field__append.q-field__marginal {
  opacity: 1 !important;
}
.contact-info-editable.inline-input:focus-within .q-field__append.q-field__marginal button {
  padding: 0;
  line-height: 0;
}

.contact-info-popover .q-date {
  min-width: unset;
  box-shadow: unset;
}
.contact-info-popover .q-date__main {
  flex: 10000 1 0% !important;
  padding: 0 !important;
}
.contact-info-popover .q-date__main button.close-button .q-btn__wrapper, .contact-info-popover .q-date__years-content button .q-btn__wrapper, .contact-info-popover .q-date__months-item button .q-btn__wrapper, .contact-info-popover .q-date__view .q-date__navigation .relative-position button .q-btn__wrapper {
  padding-top: 0;
  padding-bottom: 0;
  min-height: 1.9em;
}
.contact-info-popover .q-date__content {
  min-height: unset;
}
.contact-info-popover .q-date__years-content {
  justify-content: space-between;
  padding-top: 10px;
  padding-left: 0;
  padding-right: 0;
}
.contact-info-popover .q-date__years-content button {
  margin-bottom: 4px;
}
.contact-info-popover .q-date__years-item button {
  width: 45px;
}
.contact-info-popover .q-date__years-content button, .contact-info-popover .q-date__months-item button, .contact-info-popover .q-date__view .q-date__navigation .relative-position button {
  padding-left: 4px;
  padding-right: 4px;
}
.contact-info-popover .q-date__main, .contact-info-popover .q-date__content, .contact-info-popover .q-date__view {
  padding: 0 !important;
}

.session-integration-title {
  color: #303133;
}

.power-dialer-list {
  height: calc(100% - 100px);
  overflow-y: auto;
}
.power-dialer-list .sublists {
  overflow-y: auto !important;
}

.modal-pd-add {
  width: 420px;
  max-width: 90vw;
}

.hubspot-crm-iframe {
  overflow: hidden;
}

#pro-feature-alert .modal-header {
  border: none;
}

.hyperlink-color {
  color: #1976D2;
}

.line-height-1 {
  line-height: 1 !important;
}

.line-height-15 {
  line-height: 1.5 !important;
}

.no-bg {
  background: none !important;
}

.calendar__header {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #e0e0e0;
  z-index: 1000;
}
.calendar__header__action-left, .calendar__header__action-right {
  height: 60px;
  flex-grow: 1;
  display: flex;
  justify-content: space-evenly;
  padding: 0px 20px;
  align-items: center;
}
.calendar__header .q-field {
  margin-bottom: 0px !important;
}
.calendar__header .q-field__control,
.calendar__header .q-field__native {
  min-height: 31px !important;
}
.calendar__header .q-field__control::before,
.calendar__header .q-field__native::before {
  border: solid 1px #d8d8d8;
}
.calendar__header .date-time-picker .arrow-month {
  padding-top: 5px;
}
@media (min-width: 992px) {
  .calendar__header {
    flex-direction: row;
  }
  .calendar__header__action-left {
    justify-content: left;
    width: 50%;
  }
  .calendar__header__action-right {
    justify-content: right;
    width: 50%;
  }
}
.calendar__helper__popover .popover-header {
  background-color: #fff;
  border-bottom: none;
  font-weight: 500;
}
.calendar .scheduler {
  background-color: #fff;
}
.calendar .scheduler__header {
  z-index: 910;
  position: relative;
  background-color: #fff;
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
}
.calendar .scheduler__header__table {
  table-layout: fixed;
  text-align: center;
  width: calc(100% - 10px);
  z-index: 910;
  background: transparent 0 0;
  display: table;
}
.calendar .scheduler__header__table--week {
  width: calc(100% - 50px);
  margin-left: auto;
}
.calendar .scheduler__header__table td {
  border: 0;
  font-size: 13px;
  line-height: 1.25;
  padding-top: 0.8rem;
  height: 57px;
}
.calendar .scheduler__header__table td .day-of-week {
  text-transform: uppercase;
  display: block;
  color: rgba(0, 0, 0, 0.8);
}
.calendar .scheduler__header__table td .day {
  display: block;
  font-size: 18px;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.54);
}
.calendar .scheduler__header__table td.today .day-of-week {
  color: #2D96F3;
}
.calendar .scheduler__header__table td.today .day {
  color: #2D96F3;
}
.calendar .scheduler__body {
  z-index: 901;
  margin-top: -22px;
  position: relative;
  background-color: #fff;
}
.calendar .scheduler__body .actual-scheduler .dhx_before .dhx_month_head,
.calendar .scheduler__body .actual-scheduler .dhx_after .dhx_month_head {
  font-weight: 300 !important;
}
.calendar .scheduler__body .actual-scheduler .dhx_cal_tab.active, .calendar .scheduler__body .actual-scheduler .contact-list-sidebar-wrapper .dhx_cal_tab.router-link-exact-active, .contact-list-sidebar-wrapper .calendar .scheduler__body .actual-scheduler .dhx_cal_tab.router-link-exact-active {
  color: #fff;
  background-color: #2196f3;
}
.calendar .scheduler__body .actual-scheduler .dhx_cal_date {
  text-align: left !important;
  margin-left: 1rem;
}
.calendar .scheduler__body .actual-scheduler .dhx_cal_navline.dhx_cal_navline_flex .dhx_cal_tab {
  box-shadow: none !important;
  border: 1px solid #dcdfe6;
  color: #606266;
  vertical-align: middle;
  line-height: 30px;
  text-transform: none !important;
}
.calendar .scheduler__body .actual-scheduler .dhx_cal_navline.dhx_cal_navline_flex .dhx_cal_tab.dhx_cal_tab_first {
  border-right: 0;
  border-radius: 4px 0 0 4px;
}
.calendar .scheduler__body .actual-scheduler .dhx_cal_navline.dhx_cal_navline_flex .dhx_cal_tab.dhx_cal_tab_last {
  border-left: 0;
  border-radius: 0 4px 4px 0;
}
.calendar .scheduler__body .actual-scheduler .dhx_cal_navline.dhx_cal_navline_flex .dhx_cal_tab.active, .calendar .scheduler__body .actual-scheduler .dhx_cal_navline.dhx_cal_navline_flex .contact-list-sidebar-wrapper .dhx_cal_tab.router-link-exact-active, .contact-list-sidebar-wrapper .calendar .scheduler__body .actual-scheduler .dhx_cal_navline.dhx_cal_navline_flex .dhx_cal_tab.router-link-exact-active {
  color: #fff;
}
.calendar .scheduler__body .actual-scheduler .dhx_cal_nav_button {
  color: #606266;
  border-radius: 4px;
  text-transform: none !important;
}
.calendar .scheduler__body .actual-scheduler .dhx_cal_today_button.dhx_cal_nav_button {
  border: 1px solid #dcdfe6;
  color: #606266;
  vertical-align: middle;
  line-height: 32px;
  margin-right: 1rem !important;
  border-radius: 4px;
}
.calendar .scheduler__body .actual-scheduler .dhx_cal_prev_button::after,
.calendar .scheduler__body .actual-scheduler .dhx_cal_next_button::after {
  color: #232323 !important;
}
.calendar .scheduler__body .actual-scheduler .dhx_cal_event .dhx_header,
.calendar .scheduler__body .actual-scheduler .dhx_cal_event .dhx_body,
.calendar .scheduler__body .actual-scheduler .dhx_cal_event .dhx_footer,
.calendar .scheduler__body .actual-scheduler .dhx_cal_event .dhx_title {
  background-color: transparent !important;
}
.calendar .scheduler__body .actual-scheduler .dhx_cal_scale_placeholder,
.calendar .scheduler__body .actual-scheduler .dhx_cal_header {
  display: none !important;
  visibility: hidden;
}
.calendar .scheduler__body .actual-scheduler .dhx_month_head {
  text-align: center;
  font-size: 14px;
  font-weight: bold;
}
.calendar .scheduler__body .actual-scheduler .dhx_now > .dhx_month_head {
  color: #2196f3;
}
.calendar .scheduler__body .actual-scheduler .dhx_cal_data td:hover .dhx_month_head,
.calendar .scheduler__body .actual-scheduler .dhx_cal_data td:hover .dhx_month_body {
  background: rgba(0, 191, 80, 0.05);
}
.calendar .scheduler__body .actual-scheduler .dhx_event_move.dhx_title {
  padding: 0;
}
.calendar .scheduler__body .actual-scheduler .dhx_cal_event .dhx_body,
.calendar .scheduler__body .actual-scheduler .dhx_cal_event.dhx_cal_select_menu .dhx_body {
  padding: 0;
  line-height: 19px !important;
  height: 19px !important;
}
.calendar .scheduler__body .actual-scheduler .dhx_event_resize.dhx_footer {
  display: none;
}
.calendar .scheduler__body .actual-scheduler .dhx_cal_data table tr {
  border-left: 0;
  border-right: 0;
}
.calendar .scheduler__body .actual-scheduler .dhx_cal_data table tr td:last-child .dhx_month_body, .calendar .scheduler__body .actual-scheduler .dhx_cal_data table tr td:last-child .dhx_month_head {
  border-right: 0;
}
.calendar .scheduler__body .actual-scheduler .dhx_cal_data table tr td:first-child .dhx_month_body, .calendar .scheduler__body .actual-scheduler .dhx_cal_data table tr td:first-child .dhx_month_head {
  border-left: 0;
}
.calendar .scheduler__body .actual-scheduler .dhx_cal_data {
  border-left: 1px solid #dcdfe6;
  border-right: 1px solid #dcdfe6;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.calendar .scheduler__body .actual-scheduler .dhx_month_link {
  text-align: center;
  color: #62666E;
  font-size: 11px;
  margin-top: 5px;
}
.calendar .scheduler__body .actual-scheduler .dhx_cal_event,
.calendar .scheduler__body .actual-scheduler .dhx_cal_event_clear {
  margin-left: 1px;
  font-size: 13px;
  border-radius: 6px;
}
.calendar .scheduler__body .actual-scheduler .dhx_month_head {
  text-align: right;
  font-weight: normal;
}
.calendar .scheduler__body .day {
  margin-top: -24px;
  z-index: 901;
}
.calendar .scheduler__body .day .actual-scheduler {
  height: calc(100vh - 90px);
}
.calendar #dd-views {
  color: #fa003f;
}
.calendar .b-overlay-wrap {
  z-index: 1005;
}

#calendar-manager-modal .form-control:disabled {
  background-color: inherit;
}
#calendar-manager-modal .q-field--disabled input:disabled {
  display: none;
}
#calendar-manager-modal .multiselect__content-wrapper {
  border: none !important;
}

/* Event Colors */
.event-color-type-12.status-11 {
  color: #256EFF;
}
.event-color-type-12.status-9 {
  color: #00BF4A;
}
.event-color-type-12.status-10 {
  color: #256EFF;
}
.event-color-type-12.status-14 {
  color: #DC3545;
}
.event-color-type-12.is_past {
  color: rgba(37, 110, 255, 0.6);
}

.event-color-type-13.status-8 {
  color: #8956F8;
}
.event-color-type-13.status-4 {
  color: #00BF4A;
}
.event-color-type-13.status-5 {
  color: #8956F8;
}
.event-color-type-13.status-3 {
  color: #DC3545;
}
.event-color-type-13.is_past {
  color: rgba(137, 86, 248, 0.6);
}

.event-bg-color-type-12 {
  color: #ffffff !important;
}
.event-bg-color-type-12.status-11 {
  background-color: #256EFF;
}
.event-bg-color-type-12.status-9 {
  background-color: #00BF4A;
}
.event-bg-color-type-12.status-10 {
  background-color: #256EFF;
  text-decoration: line-through;
}
.event-bg-color-type-12.status-14 {
  background-color: #DC3545;
}
.event-bg-color-type-12.is_past {
  background-color: rgba(37, 110, 255, 0.6);
}

.event-bg-color-type-13 {
  color: #ffffff !important;
}
.event-bg-color-type-13.status-8 {
  background-color: #8956F8;
}
.event-bg-color-type-13.status-4 {
  background-color: #00BF4A;
}
.event-bg-color-type-13.status-5 {
  background-color: #8956F8;
  text-decoration: line-through;
}
.event-bg-color-type-13.status-3 {
  background-color: #DC3545;
}
.event-bg-color-type-13.is_past {
  background-color: rgba(137, 86, 248, 0.6);
}

.btn-calendar-prev-next {
  height: 32px;
  width: 32px;
}

.btn-calendar-today {
  height: 32px;
}

.sending {
  background-color: #E5EEFF !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.pe-none {
  pointer-events: none;
}

#ss-messenger-button {
  pointer-events: none;
  height: 49px;
  overflow: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
#ss-messenger-button::-webkit-scrollbar {
  display: none !important;
  width: 0 !important;
  height: 0 !important;
}

.new-car-container {
  width: 600px;
  max-width: 600px;
  max-height: 576px;
  height: 576px;
}
.new-car-container .container-cars {
  overflow-y: scroll;
  max-height: 400px;
}
.new-car-container .infinite-scroll-loading {
  height: 50px;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.h-93 {
  height: 93% !important;
}

.h-100 {
  height: 100% !important;
}

.vh-100 {
  height: 100vh;
}

#daytime-hours-confirmation___BV_modal_outer_ {
  z-index: 9999 !important;
}

.t-session-settings__actions button {
  font-size: 1em !important;
}

.dial-sessions__form__script-selector.populated:not(.q-field--highlighted) input {
  display: none;
}

.centered-contact-deleted {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.communication-overlay {
  position: inherit !important;
}

.agents-status-pills {
  width: 100%;
  display: flex;
}
.agents-status-pills__pill {
  color: #FFFFFF;
  margin-left: 10px;
  display: flex;
  align-items: center;
  border: none;
}
.agents-status-pills__pill__counter {
  background: rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  font-size: 10px;
  font-weight: 600;
  padding: 0 2px;
  color: #FFFFFF;
  height: 16px;
  margin-right: 5px;
}
.agents-status-pills__pill:hover {
  color: #FFFFFF;
}
.agents-status-pills__pill:focus {
  box-shadow: none;
}

.wallboard {
  height: 100%;
}
.wallboard__sidebar {
  min-width: 200px;
  width: 200px;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 6px;
  color: #62666E;
}
.wallboard__sidebar__title {
  font-size: 13px;
  font-weight: 700;
  margin: 20px 10px 5px 10px;
}
.wallboard__sidebar__item {
  height: 36px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 8px;
  text-decoration: none !important;
  margin-bottom: 5px;
  border-radius: 5px;
}
.wallboard__sidebar__item__icon {
  margin: 0px 5px;
}
.wallboard__sidebar__item__name {
  flex-grow: 1;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
}
.wallboard__sidebar__item--active {
  background-color: #F4F4F6;
}
.wallboard__header {
  height: 100px;
  display: flex;
  flex-direction: column;
}
.wallboard__header__title {
  height: 40px;
  display: flex;
  align-items: center;
  font-weight: bold;
  padding: 0px 15px;
}
.wallboard__header__actions {
  height: 60px;
  display: flex;
  align-items: center;
  padding: 0px 15px;
  color: #333333;
}
.wallboard__header__actions .ring-group-filter {
  max-width: 300px;
}
.wallboard__header__actions .form-group {
  margin-bottom: 0px;
}
.wallboard__header__actions .form-group span {
  font-size: 14px;
}
.wallboard__body {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  transition: all 1s;
  overflow-y: hidden;
  flex-grow: 1;
}
.wallboard__body .overview {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.wallboard__body .overview--comfort .overview__body__card {
  width: 20%;
}
.wallboard__body .overview--comfort .overview__body__card__container {
  flex-direction: column;
  justify-content: space-between;
}
.wallboard__body .overview--comfort .overview__body__card__container__icon {
  margin-bottom: 20px;
}
.wallboard__body .overview--compact .overview__body__card {
  width: calc(100% / 3);
}
.wallboard__body .overview--compact .overview__body__card__container {
  flex-direction: row;
}
.wallboard__body .overview--compact .overview__body__card__container__icon {
  display: flex;
  align-items: center;
}
.wallboard__body .overview--compact .overview__body__card__container__body {
  padding-left: 20px;
}
.wallboard__body .overview__header {
  display: flex;
  justify-content: flex-end;
  padding: 10px 20px;
}
.wallboard__body .overview__body {
  flex-grow: 1;
  border-radius: 15px;
  border: 1px solid #EBEBEB;
  display: flex;
  flex-wrap: wrap;
  margin: 0px 20px;
  padding: 20px;
}
.wallboard__body .overview__body__card {
  display: flex;
  padding: 10px;
  transition: all 1s;
  color: #040404;
}
.wallboard__body .overview__body__card__container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  border: 1px solid #EBEBEB;
  border-radius: 10px;
  padding: 20px;
}
.wallboard__body .overview__body__card__container__body__value {
  font-size: 22px;
  font-weight: bold;
}
.wallboard__body .overview__body__card:hover {
  text-decoration: none;
}
.wallboard__body .users {
  color: #333333;
}
.wallboard__body .users--compact .users__table table.datatable td {
  padding-top: 2px;
  padding-bottom: 2px;
}
.wallboard__body .users__header {
  display: flex;
  padding: 10px 20px;
}
.wallboard__body .users__header__status {
  border: solid 1px #d8d8d8;
  height: 32px;
  border-radius: 0.25rem;
  margin-left: 10px;
  min-width: 120px;
}
.wallboard__body .users__header__status .q-field {
  font-size: 12px;
}
.wallboard__body .users__header__status .q-field__native, .wallboard__body .users__header__status .q-field__control {
  color: #495057;
  min-height: 32px;
}
.wallboard__body .users__table table.datatable td {
  transition: all 0.5s;
}
.wallboard__body .users__table table.datatable tbody td {
  padding-left: 28px;
}
.wallboard__body .users__table__agent-name a {
  color: #333333;
  text-decoration: none !important;
}
.wallboard__body .users__table__agent-status__button {
  color: #FFFFFF;
  transition: all 0.3s;
}
.wallboard__body .users__table__agent-status__popover__header {
  color: #333333;
  font-size: 13px;
  margin-bottom: 10px;
}
.wallboard__body .users__table__agent-status__popover__body {
  color: #333333;
  font-size: 13px;
  display: flex;
  flex-direction: column;
}
.wallboard__body .users__table__agent-status__popover__body span {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  padding: 4px;
}
.wallboard__body .users__table__agent-status__popover__body span:hover {
  background-color: #f8f9fa;
}
.wallboard__body .users__table__agent-ring-groups__ring-group-name {
  text-overflow: ellipsis;
  max-width: 270px;
  white-space: nowrap;
  overflow: hidden;
  float: left;
}
.wallboard__body .users__table__agent-ring-groups .popover-body {
  color: #333333;
  font-size: 13px;
}
.wallboard__body .calls {
  width: 100%;
  color: #333333;
}
.wallboard__body .calls--compact .calls__table table.datatable td {
  padding-top: 2px;
  padding-bottom: 2px;
}
.wallboard__body .calls__header {
  display: flex;
  padding: 10px 20px;
}
.wallboard__body .calls__header__columns-dropdown .dropdown-item {
  padding-bottom: 0px;
}
.wallboard__body .calls__table table.datatable td {
  transition: all 0.5s;
}
.wallboard__body .calls__table table.datatable tbody td {
  padding-left: 28px;
}
.wallboard__body .calls__table__collapse i {
  transition: all 0.5s;
}
.wallboard__body .calls__table__collapse--collapsed i {
  transform: rotate(90deg);
}
.wallboard__body .calls__table__contact__tags {
  white-space: normal !important;
  word-break: break-word;
  font-size: 12px;
}
.wallboard__body .calls__table__user {
  white-space: normal !important;
  word-break: break-word !important;
}
.wallboard__body .calls__table__tags {
  white-space: normal !important;
}
.wallboard__body .calls__table__notes textarea {
  word-break: break-word !important;
}
.wallboard__body .calls__table__operations {
  display: flex;
}
.wallboard__body .calls__table .relative-time {
  font-size: 13px !important;
  color: #333333;
}
.wallboard .q-basic-selector {
  margin-bottom: 0px;
}

.chip-ellipsis .b-overlay > div {
  border-radius: 10px;
}

.top-0 {
  top: 0;
}

.text-decoration-underline {
  text-decoration: underline;
}
.text-decoration-underline:hover {
  text-decoration: none;
}
.text-decoration-underline.text-red-130:hover {
  color: #721C24;
}

.view-filter-name.row.q-input .q-field__control .q-field__native {
  padding-top: 14px;
  padding-bottom: 2px;
}

.compliance-badge {
  background-color: #f44336;
  color: white;
  padding: 8px;
  border-radius: 8px;
  height: unset !important;
}

.tags__modal .modal-header,
.tags__modal .modal-footer {
  border: none;
}
.tags__modal .modal-body {
  padding-bottom: 0;
}
.tags__tabs.q-btn-group--spread > .q-btn-item {
  flex: inherit;
  flex-grow: 1;
  min-width: 200px;
}
.tags__helper__popover .popover-header {
  background-color: #fff;
  border-bottom: none;
  font-weight: 500;
}
.tags__form .color-picker .dropdown-toggle::after {
  margin-left: 0.5em;
  vertical-align: 0.5em;
}
.tags__form .color-picker ul.dropdown-menu {
  text-align: center;
  min-width: 20rem;
}
.tags__form .color-picker ul.dropdown-menu li {
  display: inline-block;
}
.tags__form .color-picker a.dropdown-item {
  padding: 0;
}
.tags__form .color-picker span.color-pick.selected::after {
  content: "✓";
  color: #fff;
  font-size: medium;
  vertical-align: super;
  margin-left: -25px;
  padding-right: 10px;
}

.tree-selector.vue-treeselect--focused .vue-treeselect__control {
  border-color: #256eff !important;
  border-width: 2px;
  box-shadow: none !important;
  transform: scale3d(1, 1, 1);
}
.tree-selector .vue-treeselect__control {
  height: 40px;
  padding: 2px 5px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.64);
  border: 1px solid rgba(0, 0, 0, 0.24);
}
.tree-selector .vue-treeselect__control.vue-treeselect--open {
  border-color: rgba(0, 0, 0, 0.24);
}
.tree-selector .vue-treeselect__control .vue-treeselect__single-value {
  font-size: 14px;
  color: #040404;
}
.tree-selector .vue-treeselect__menu {
  font-weight: 400;
  color: rgba(0, 0, 0, 0.8);
}
.tree-selector .vue-treeselect__menu .vue-treeselect__list-item {
  transition: color 0.3s, background-color 0.3s;
}
.tree-selector .vue-treeselect__menu .vue-treeselect__list-item .vue-treeselect__option {
  padding: 3px 5px;
  border-radius: 5px;
  margin: 2px 0;
}
.tree-selector .vue-treeselect__menu .vue-treeselect__list-item .vue-treeselect__option.vue-treeselect__option--selected {
  background: #256eff;
  color: #FFFFFF;
  font-weight: normal;
}
.tree-selector .vue-treeselect__menu .vue-treeselect__list-item .vue-treeselect__option.vue-treeselect__option--highlight:not(.vue-treeselect__option--selected) {
  background: #EBEBEB;
  color: #040404;
}
.tree-selector .vue-treeselect__menu .vue-treeselect__list-item .vue-treeselect__option .vue-treeselect__label {
  white-space: inherit;
}

.g-recaptcha {
  transform: scale(0.8);
  transform-origin: 0 0;
}

.broadcasts-cancel-button {
  border: 1.6px solid #D8D8D8;
}
.broadcasts__home {
  overflow-y: scroll;
}
.broadcasts__home__loading-overlay {
  position: fixed !important;
  width: calc(100% - 64px);
  height: calc(100% - 62px);
  top: 62px;
  left: 64px;
  z-index: 1029;
}
.broadcasts__home__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  row-gap: 10px;
}
.broadcasts__home__header__search {
  order: 2;
}
.broadcasts__home__header__status {
  order: 1;
  flex-grow: 1;
  width: 100%;
  overflow-x: auto;
}
.broadcasts__home__header__status .custom-toggle-button {
  min-width: 450px;
}
.broadcasts__home__header__new-button {
  order: 3;
  display: flex;
  justify-content: flex-end;
}
@media (min-width: 1024px) {
  .broadcasts__home__header__search {
    order: 1;
  }
  .broadcasts__home__header__status {
    order: 2;
    width: auto;
    flex-grow: 0;
    margin-bottom: 0px;
    width: 50%;
  }
}
.broadcasts__home__graph {
  padding: 0px 10px;
}
.broadcasts__home__graph .highcharts-scrollbar {
  display: none;
}
@media print {
  .broadcasts__home__graph .activity-graph {
    background-color: white;
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1051;
  }
  .broadcasts__home__graph .activity-graph__header .chart-types {
    display: none !important;
  }
  .broadcasts__home__graph .activity-graph .highcharts-container svg {
    width: 100vw !important;
  }
  .broadcasts__home__graph .activity-graph .highcharts-container svg .highcharts-legend-item text {
    font-size: 13px !important;
  }
}
.broadcasts__home__table .broadcast-status-pill {
  border: solid black 1px;
}
.broadcasts__home__table .broadcast-status-pill.new {
  border-color: #256eff;
}
.broadcasts__home__table .broadcast-status-pill.enrolling, .broadcasts__home__table .broadcast-status-pill.sending {
  border-color: #3f51b5;
}
.broadcasts__home__table .broadcast-status-pill.paused {
  border-color: #F2994A;
}
.broadcasts__home__table .broadcast-status-pill.sent {
  border-color: #00bf4a;
}
.broadcasts__home__table .broadcast-status-pill.stopped {
  border-color: #fa003f;
}
.broadcasts__home__table .datatable {
  padding-left: 0 !important;
}
.broadcasts__home__table .datatable .sorted-column {
  padding-left: 28px !important;
}
.broadcasts__home__table .datatable .relative-time {
  color: inherit;
}
.broadcasts__home__table .datatable tr {
  min-height: 50px;
  height: 50px;
}
.broadcasts__home__table .datatable tr .context-menu:not(.keep-visible) {
  visibility: hidden;
}
.broadcasts__home__table .datatable tr .context-menu .dropdown-toggle::after {
  display: none;
}
.broadcasts__home__table .datatable tr .context-menu button,
.broadcasts__home__table .datatable tr .context-menu .show button {
  background-color: #F4F4F6 !important;
  border: #d2d2d2 solid 1px !important;
}
.broadcasts__home__table .datatable tr .context-menu button:focus {
  box-shadow: 0 0 0 0.2rem #e4e4e4 !important;
}
.broadcasts__home__table .datatable tr:hover .context-menu {
  visibility: visible;
}
.broadcasts__home__table .datatable tr:hover,
.broadcasts__home__table .datatable tr:hover td {
  background-color: #F4F4F6 !important;
}
.broadcasts__home__table .datatable td.datatable-row__checkbox {
  padding-right: 0 !important;
}
.broadcasts__home__table .datatable td.datatable-row__checkbox .custom-checkbox-container .checkmark {
  left: 10px;
}
.broadcasts__home__table--empty {
  min-height: 100px;
  width: 100%;
  height: 100%;
  top: 35px;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFFFFF;
  z-index: 0;
}
.broadcasts__add {
  display: flex;
  height: 100%;
  background-color: #F9F9FB;
  padding: 15px;
}
.broadcasts__add__sidebar {
  width: 200px;
  margin-right: 15px;
  background-color: #FFFFFF;
  border-radius: 8px;
  padding: 20px;
}
@media (min-width: 1425px) {
  .broadcasts__add__sidebar {
    width: 300px;
  }
}
.broadcasts__add__contacts-filters {
  transition: all 0.5s;
  position: absolute;
  right: 0px;
  margin-top: -15px;
  width: 300px;
}
@media (min-width: 1425px) {
  .broadcasts__add__contacts-filters {
    width: 400px;
  }
}
.broadcasts__add__view {
  max-width: calc(100% - 550px);
  flex-grow: 1;
  margin-right: 15px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
@media (min-width: 1425px) {
  .broadcasts__add__view {
    max-width: calc(100% - 750px);
  }
}
@media (min-width: 1900px) {
  .broadcasts__add__view {
    max-width: 1000px;
  }
}
.broadcasts__add__view__form {
  transition: all 0.5s;
  margin-bottom: 15px;
  background-color: #FFFFFF;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}
.broadcasts__add__view__form__header {
  height: 50px;
  border-bottom: 1px solid #EBEBEB;
  display: flex;
  align-items: center;
  font-weight: bold;
  padding: 0px 30px;
}
.broadcasts__add__view__form__content {
  background-color: #FFFFFF;
}
.broadcasts__add__view__form__content .broadcast-add {
  padding: 15px 30px;
  height: 100%;
}
.broadcasts__add__view__form__content .broadcast-add__contacts__options {
  display: flex;
}
.broadcasts__add__view__form__content .broadcast-add__contacts__options__option {
  flex-grow: 1;
  text-align: center;
  height: 70px;
  border-radius: 6px;
  border: solid 1px #d8d8d8;
  display: flex;
  align-items: center;
  margin: 0px 5px;
  padding-left: 20px;
  cursor: pointer;
  transition: all 0.3s;
  position: relative;
}
.broadcasts__add__view__form__content .broadcast-add__contacts__options__option__icon {
  position: absolute;
  top: 7px;
  right: 7px;
  border-radius: 50%;
  background-color: #256eff;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.broadcasts__add__view__form__content .broadcast-add__contacts__options__option--active {
  border-color: #256eff;
}
.broadcasts__add__view__form__content .broadcast-add__contacts__options__option--disabled {
  cursor: not-allowed;
  border-color: #e4e4e4;
  color: #e4e4e4;
}
.broadcasts__add__view__form__content .broadcast-add__contacts__selection {
  margin-top: 25px;
}
.broadcasts__add__view__form__content .broadcast-add__message__sms {
  margin: 20px 0px;
}
.broadcasts__add__view__form__content .broadcast-add__message__sms__composer-header, .broadcasts__add__view__form__content .broadcast-add__message__sms__composer-footer {
  margin: 5px 0px;
}
.broadcasts__add__view__form__content .broadcast-add__message__sms__composer-body {
  position: relative;
  border: 1px solid #D8D8D8;
  border-radius: 8px;
  padding: 10px 12px;
}
.broadcasts__add__view__form__content .broadcast-add__message__sms__composer-header {
  text-align: right;
}
.broadcasts__add__view__form__content .broadcast-add__message__sms__composer-footer {
  display: flex;
  justify-content: space-between;
}
.broadcasts__add__view__form__content .broadcast-add__message__sms__label-preview {
  color: #62666E;
  margin: 20px 0px;
}
.broadcasts__add__view__form__content .broadcast-add__message__sms__preview {
  background-color: #256eff;
  border-radius: 8px;
  padding: 10px;
  transition: all 0.3s;
  min-height: 58px;
}
.broadcasts__add__view__form__content .broadcast-add__message__sms__preview textarea {
  color: #FFFFFF;
}
.broadcasts__add__view__form__content .broadcast-add__message__sms__preview--empty {
  background-color: #D8D8D8;
}
.broadcasts__add__view__form__content .broadcast-add__message__sms__preview__image {
  display: block;
  margin-bottom: 10px;
}
.broadcasts__add__view__form__content .broadcast-add__message__rmv {
  margin-top: 20px;
}
.broadcasts__add__view__form__content .broadcast-add__message__rmv__file-upload {
  display: flex;
  column-gap: 20px;
}
.broadcasts__add__view__form__content .broadcast-add__message__rmv__file-preview {
  position: relative;
  background-color: #F9F9FB;
  border-radius: 10px;
  padding: 25px 50px;
}
.broadcasts__add__view__form__content .broadcast-add__message__rmv__file-preview__remove-icon {
  cursor: pointer;
  position: absolute;
  top: -10px;
  right: -10px;
}
.broadcasts__add__view__form__content .broadcast-add__message__rmv__file-preview__remove-icon svg {
  background-color: #62666E;
  padding: 3px;
  border-radius: 50%;
}
.broadcasts__add__view__form__content .broadcast-add__schedule {
  display: flex;
  flex-direction: column;
}
.broadcasts__add__view__form__content .broadcast-add__schedule__row {
  display: flex;
  margin-bottom: 30px;
}
.broadcasts__add__view__form__content .broadcast-add__schedule__row__label {
  width: 30%;
  color: #62666E;
}
.broadcasts__add__view__form__content .broadcast-add__schedule__row__fields {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  max-width: 70% !important;
}
.broadcasts__add__view__form__content .broadcast-add__schedule__row__fields .time-schedule {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  row-gap: 15px;
}
.broadcasts__add__view__form__content .broadcast-add__schedule__row__fields .time-schedule__row {
  display: flex;
  align-items: center;
}
.broadcasts__add__view__form__content .broadcast-add__schedule__row__fields .time-schedule__row__label {
  color: #62666E;
  width: 50px;
}
.broadcasts__add__view__form__content .broadcast-add__schedule__row__fields .time-schedule__row__field {
  flex-grow: 1;
}
.broadcasts__add__view__form__content .broadcast-add__schedule__row__fields .time-schedule__row__field .multiselect__placeholder {
  width: 100%;
}
.broadcasts__add__view__form__content .broadcast-add__schedule__row__fields .time-schedule__row__field__time-selector {
  position: relative;
}
.broadcasts__add__view__form__content .broadcast-add__schedule__row__fields .time-schedule__row__field__time-selector .pickers-container .time-picker {
  flex-wrap: nowrap;
}
.broadcasts__add__view__form__content .broadcast-add__preview {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
}
.broadcasts__add__view__form__content .broadcast-add__preview__row {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
.broadcasts__add__view__form__content .broadcast-add__preview__row__label {
  color: #62666E;
}
.broadcasts__add__view__form__content .broadcast-add__preview__row__field__sms-preview {
  background-color: #256eff;
  border-radius: 8px;
  padding: 10px;
  transition: all 0.3s;
}
.broadcasts__add__view__form__content .broadcast-add__preview__row__field__sms-preview textarea {
  color: #FFFFFF;
}
.broadcasts__add__view__form__content .broadcast-add__preview__row__field__rvm-preview {
  background-color: #F9F9FB;
  border-radius: 10px;
  padding: 25px 50px;
}
.broadcasts__add__view__form__footer {
  padding: 30px;
  text-align: right;
  display: flex;
  justify-content: flex-end;
}
.broadcasts__add__view__details {
  flex-grow: 1;
  border-radius: 8px;
}
.broadcasts__add__view__details .contacts-preview {
  height: 100%;
  position: relative;
  background-color: #FFFFFF;
  border-radius: 8px;
}
.broadcasts__add__view__details .contacts-preview__header {
  height: 50px;
  border-bottom: 1px solid #EBEBEB;
  display: flex;
  align-items: center;
  font-weight: bold;
  padding: 0px 30px;
}
.broadcasts__add__view__details .contacts-preview__header__counter {
  margin-left: 20px;
  color: #62666E;
}
.broadcasts__add__view__details .contacts-preview__body .b-overlay-wrap {
  display: none;
}
.broadcasts__add__view__details .contacts-preview__body .datatable {
  margin-bottom: 60px;
}
.broadcasts__add__view__details .cards {
  position: relative;
}
.broadcasts__add__view__details .cards__body {
  display: flex;
  column-gap: 10px;
}
.broadcasts__add__view__details .cards__body__card {
  background-color: #FFFFFF;
  border-radius: 6px;
  flex-grow: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  max-width: 50%;
}
.broadcasts__add__view__details .cards__body__card__title {
  font-size: 18px;
  color: #040404;
  margin-bottom: 5px;
  font-weight: 500;
}
.broadcasts__add__view__details .cards__body__card__text {
  color: #62666E;
}

.broadcast-proxy-modal-title {
  font-size: 18px;
  font-weight: bold;
}

.steps-wrapper {
  display: flex;
  flex-direction: column;
}
.steps-wrapper__step {
  color: #62666E;
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 36px;
  font-weight: bold;
}
.steps-wrapper__step__icon {
  border: solid 1px #D8D8D8;
  margin-right: 15px;
  border-radius: 50%;
  height: 26px;
  width: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.steps-wrapper__step__separator {
  position: absolute;
  background-color: #D8D8D8;
  height: 24px;
  width: 2px;
  left: 12px;
  top: 32px;
}
.steps-wrapper__step--active .steps-wrapper__step__icon {
  background-color: #00bf4a;
  color: #FFFFFF;
}
.steps-wrapper__step--active .steps-wrapper__step__name {
  color: #040404;
}
.steps-wrapper__step--completed .steps-wrapper__step__icon {
  background-color: #00bf4a;
  color: #FFFFFF;
}
.steps-wrapper__step--completed .steps-wrapper__step__name {
  color: #040404;
}
.steps-wrapper__step--completed .steps-wrapper__step__separator {
  background-color: #00bf4a;
}

.activity-graph__header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  row-gap: 10px;
  margin-top: 10px;
}
@media (min-width: 785px) {
  .activity-graph__header {
    flex-direction: row;
    margin-top: 0px;
  }
}

.download-dropdown .q-btn-dropdown__arrow {
  display: none;
}

#maitre-inline-button {
  border: 1px solid #D8D8D8 !important;
  text-transform: none !important;
  padding: 8px 14px 7px 10px !important;
  border-radius: 6px !important;
  box-shadow: none !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

#maitre-inline-button::after {
  content: "🎁";
  padding-left: 6px;
}

.account-registration {
  display: flex;
  height: 100vh;
}
.account-registration .row.q-input .q-field__control .q-field__native {
  height: 35px !important;
}
.account-registration .q-field--auto-height .q-field__control,
.account-registration .q-field--auto-height .q-field__native {
  min-height: 35px !important;
}
.account-registration .toggle-border {
  border: 1px solid #256eff;
}
.account-registration .q-stepper__dot {
  height: 32px !important;
  width: 32px !important;
}
.account-registration .stepper__container {
  display: flex;
  text-align: center;
  text-align: -webkit-center;
}
.account-registration .text-h4 {
  font-size: 2.5rem;
}
@media (max-width: 575px) {
  .account-registration .text-h4 {
    font-size: 20px;
  }
}
@media (min-width: 576px) {
  .account-registration .text-h4 {
    font-size: 20px;
  }
}
@media (min-width: 768px) {
  .account-registration .text-h4 {
    font-size: 2.5rem;
  }
}
.account-registration .text-h5 {
  text-align: start;
  font-size: 1.25rem !important;
}
.account-registration .text-body1 {
  font-size: 1.25rem;
  margin-top: 28px;
  width: 82%;
}
@media (max-width: 575px) {
  .account-registration .text-body1 {
    font-size: 14px;
    margin-top: 10px;
    width: 100%;
  }
}
@media (min-width: 576px) {
  .account-registration .text-body1 {
    font-size: 14px;
    margin-top: 10px;
    width: 100%;
  }
}
@media (min-width: 768px) {
  .account-registration .text-body1 {
    font-size: 1.25rem;
    margin-top: 28px;
    width: 82%;
  }
}
.account-registration .password-hint ul {
  padding-top: 10px;
}
.account-registration .password-hint li {
  list-style: none;
  font-size: 14px;
  color: #000000;
  margin-top: 4px;
}
.account-registration .zipcode-hint {
  padding-top: 0;
  padding-left: 10px;
  font-size: 14px;
  color: #000000;
  text-align: left;
}
.account-registration .negative-top {
  margin-top: -10px;
}
.account-registration .banner {
  align-items: center;
  background: linear-gradient(240deg, #0037ff2a 0%, #22ff001c 100%);
  height: 100vh;
}
.account-registration .banner .card {
  background-color: #ffffff;
  border-radius: 12px;
}
.account-registration .banner .card > h3 {
  font-family: Inter;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
}
.account-registration .banner .card > p {
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  padding: 0 24px 10px 24px;
}
.account-registration-action-btn .row.q-field,
.account-registration-action-btn .row.q-field__control-container,
.account-registration-action-btn .row.q-field__control,
.account-registration-action-btn .row.q-notification__wrapper,
.account-registration-action-btn .row.q-notification__content,
.account-registration-action-btn .row.q-btn__wrapper,
.account-registration-action-btn .row.q-btn__content,
.account-registration-action-btn .row.q-avatar__content,
.account-registration-action-btn .row.q-item,
.account-registration-action-btn .row.q-field__native,
.account-registration-action-btn .row.q-chip__content,
.account-registration-action-btn .row .q-field__inner {
  padding-right: 6px !important;
  padding-left: 6px !important;
  display: flex !important;
  align-content: center !important;
}
.account-registration .stepper__container {
  display: flex;
  flex-direction: column;
  height: 90vh !important;
}
.account-registration .stepper__container .q-stepper__header,
.account-registration .stepper__container .stepper__content__header {
  position: relative;
  top: 0;
  z-index: 10;
  background-color: white;
}
.account-registration .stepper__container .stepper__content {
  overflow-y: auto;
  flex-grow: 1;
  height: 56vh;
}
@media (min-height: 720px) {
  .account-registration .stepper__container .stepper__content {
    height: 50vh;
  }
  .account-registration .stepper__container .stepper__container {
    height: 70vh !important;
  }
}
@media (min-height: 800px) {
  .account-registration .stepper__container .stepper__container {
    height: 90vh !important;
  }
}
@media (min-height: 900px) {
  .account-registration .stepper__container .stepper__content {
    height: 56vh;
  }
}
.account-registration .stepper__container .business-information__form {
  flex-grow: 1;
  height: 65vh;
}
@media (min-height: 720px) {
  .account-registration .stepper__container .business-information__form {
    height: 55vh;
  }
}
@media (min-height: 900px) {
  .account-registration .stepper__container .business-information__form {
    height: 72vh;
  }
}
.account-registration .stepper__container .business-information__container {
  flex-grow: 1;
  height: 60vh;
}
@media (min-height: 480px) {
  .account-registration .stepper__container .business-information__container {
    height: 42vh;
  }
}
@media (min-height: 620px) {
  .account-registration .stepper__container .business-information__container {
    height: 50vh;
  }
}
@media (min-height: 720px) {
  .account-registration .stepper__container .business-information__container {
    height: 55vh;
  }
}
@media (min-height: 900px) {
  .account-registration .stepper__container .business-information__container {
    height: 65vh;
  }
}
@media (min-height: 1024px) {
  .account-registration .stepper__container .business-information__container {
    height: 68vh;
  }
}
.account-registration .stepper__container .q-stepper__nav {
  position: sticky;
  bottom: 0;
  background-color: white;
  padding: 0 16px 16px;
  z-index: 10;
}
@media (max-width: 575px) {
  .account-registration .stepper__container .q-stepper__nav {
    padding: 0 16px 0;
  }
}
@media (min-width: 576px) {
  .account-registration .stepper__container .q-stepper__nav {
    padding: 0 16px 0;
  }
}
.account-registration ::-webkit-scrollbar {
  width: 9px !important;
  max-height: 397px !important;
}
.account-registration ::-webkit-scrollbar-thumb {
  background-color: #256EFF !important;
  border-radius: 6px !important;
}
.account-registration ::-webkit-scrollbar-thumb:hover {
  background-color: #256EFF !important;
}
.account-registration ::-webkit-scrollbar-track {
  background-color: #E0E0E0 !important;
  border-radius: 6px !important;
}
@media (max-width: 575px) {
  .account-registration .banner {
    display: none !important;
  }
  .account-registration .stepper__container {
    display: flex;
    text-align: -webkit-center;
  }
  .account-registration .stepper__container .q-stepper__header,
.account-registration .stepper__container .stepper__content__header {
    max-width: 100%;
    position: relative;
    z-index: 10;
    background-color: white;
    font-size: 10px;
  }
}
@media (min-width: 576px) {
  .account-registration .banner {
    display: none !important;
  }
  .account-registration .stepper__container {
    display: flex;
    text-align: -webkit-center;
  }
  .account-registration .stepper__container .stepper__container {
    display: flex;
    text-align: -webkit-center;
  }
  .account-registration .stepper__container .stepper__container .q-stepper__header,
.account-registration .stepper__container .stepper__container .stepper__content__header {
    max-width: 100%;
    position: relative;
    z-index: 10;
    background-color: white;
    font-size: 10px;
  }
}
@media (min-width: 768px) {
  .account-registration .banner {
    display: none !important;
  }
  .account-registration .q-stepper__header {
    max-width: 600px !important;
  }
  .account-registration .stepper__container {
    display: flex;
    text-align: -webkit-center;
    padding-left: 4vh;
  }
}
@media (min-width: 992px) {
  .account-registration .banner {
    display: none !important;
  }
}
@media (min-width: 1200px) {
  .account-registration .banner {
    display: flex !important;
  }
}
@media (max-width: 575px) {
  .account-registration .q-stepper__header {
    max-width: 100%;
  }
  .account-registration .stepper__container {
    display: flex;
    text-align: -webkit-center;
  }
}
@media (min-width: 576px) {
  .account-registration .stepper__container {
    display: flex;
    text-align: -webkit-center;
  }
}
@media (min-width: 768px) {
  .account-registration .q-stepper__header {
    max-width: 600px !important;
  }
  .account-registration .stepper__container {
    display: flex;
    text-align: -webkit-center;
    padding-left: 4vh;
  }
}
.account-registration .w-65 {
  width: 65%;
}
.account-registration .min-h-60 {
  min-height: 60vh;
}
.account-registration .terms-iframe {
  border: 0;
  width: 100%;
  height: 30vh;
  /* Default iframe height */
  padding-bottom: 20px;
}
@media (max-width: 575px) {
  .account-registration .terms-iframe {
    height: 30vh;
  }
}
@media (min-width: 576px) {
  .account-registration .terms-iframe {
    height: 30vh;
    padding-right: 3rem;
    padding-left: 3rem;
  }
}
@media (min-width: 768px) {
  .account-registration .terms-iframe {
    height: 30vh;
    padding-right: 3rem;
    padding-left: 3rem;
  }
}
@media (min-width: 992px) {
  .account-registration .terms-iframe {
    height: 30vh;
    padding-right: 3rem;
    padding-left: 3rem;
  }
}
.account-registration .accept-box {
  width: 80%;
  display: flex;
  justify-content: flex-start;
  padding-right: 2.8rem;
}
.account-registration .accept-box .carrier-fees {
  position: relative;
  left: -8.1%;
}
.account-registration .h-55 {
  height: 55vh;
}
.account-registration .h-75 {
  height: 75%;
}
.account-registration .q-mx-6 {
  margin-left: 3.3rem !important;
  margin-right: 3.3rem !important;
}
.account-registration .logo {
  z-index: 11;
}
.account-registration .phone-row {
  display: flex;
  height: 35px;
  width: 100%;
}
.account-registration .phone-row > .col-md-4 {
  flex-grow: 1;
}
.account-registration #recaptcha-element {
  position: relative;
  left: -12%;
}
.account-registration .q-field--disabled .q-field__control > div,
.account-registration .q-field--disabled .q-field__control > div * {
  background: #D8D8D8;
  color: #040404;
  border-radius: 20px;
}
.account-registration .q-field--outlined.q-field--with-bottom.disabled > div {
  background: #D8D8D8;
  color: #040404;
  border-radius: 20px;
  opacity: 100%;
}
.account-registration .form-actions {
  display: flex;
  position: absolute;
  bottom: 20px;
  right: -14px;
  justify-content: flex-end;
  width: 100%;
}
.account-registration .business-information-actions {
  display: flex;
  position: absolute;
  top: calc(100vh - 20vh);
  width: 100%;
  padding: 0px 5rem;
}
@media (min-height: 480px) {
  .account-registration .business-information-actions {
    top: calc(100vh - 36vh);
  }
}
@media (min-height: 620px) {
  .account-registration .business-information-actions {
    top: calc(100vh - 32vh);
  }
}
@media (min-height: 720px) {
  .account-registration .business-information-actions {
    top: calc(100vh - 30vh);
  }
  .account-registration .business-information-actions .business-information-actions {
    top: calc(100vh - 22vh);
    height: 65vh;
  }
}
@media (min-height: 720px) and (min-height: 1024px) {
  .account-registration .business-information-actions {
    top: calc(100vh - 16vh);
    height: 68vh;
  }
}
@media (min-height: 720px) {
  .account-registration .loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}
@media (min-height: 720px) {
  .account-registration .loading-icon {
    width: 250px;
    height: 250px;
  }
}
@media (min-height: 720px) {
  .account-registration .loading-text {
    font-size: 20px;
    font-weight: 500;
  }
}
@media (min-height: 900px) {
  .account-registration {
    margin-top: 20px;
  }
}

.trial-banner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #256EFF1F;
}
.trial-banner .left-content {
  display: flex;
  align-items: center;
  gap: 10px;
}
.trial-banner .trial--text {
  margin: 0;
  font-size: 1rem;
  font-weight: 700 !important;
  margin-right: 2rem;
}
.trial-banner .button-index {
  z-index: 1 !important;
  margin-right: 1rem;
}
.trial-banner .button-index .q-btn__wrapper {
  margin-bottom: 3px;
}
.trial-banner .button-index .block {
  padding-right: 20px;
  padding-left: 20px;
  min-width: 200px;
}
.trial-banner .button-index > a {
  color: #256EFF;
  font-size: 0.875rem;
  text-decoration: none;
}
.trial-banner .button-index > a:hover {
  text-decoration: underline;
}
.trial-banner .demo--button {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 0.875rem;
}
.trial-banner .demo--button > img {
  margin-right: 0.5rem;
}
@media only screen and (max-width: 1280px) {
  .trial-banner .trial-banner {
    flex-direction: column;
    align-items: flex-start;
  }
  .trial-banner .left-content {
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 10px;
  }
  .trial-banner .left-content > .trial--text {
    flex: 1 0 100%;
  }
}

.tags-deprecation-banner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #FFFFE7;
  border: #F2C94D 1px solid;
  border-radius: 5px;
}
.tags-deprecation-banner .left-content {
  display: flex;
  align-items: center;
  gap: 10px;
}
.tags-deprecation-banner .trial--text {
  margin: 0;
  font-size: 0.8rem;
  margin-right: 2rem;
}
@media only screen and (max-width: 1280px) {
  .tags-deprecation-banner .trial-banner {
    flex-direction: column;
    align-items: flex-start;
  }
  .tags-deprecation-banner .left-content {
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 10px;
  }
  .tags-deprecation-banner .left-content > .trial--text {
    flex: 1 0 100%;
  }
}

.video-modal-section .activator-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 34px;
  border-radius: 4px;
  border: none;
  background-color: rgba(37, 255, 72, 0.12) !important;
  cursor: pointer;
}
.video-modal-section .activator-button:hover {
  background-color: rgba(37, 255, 73, 0.15) !important;
}
.video-modal-section .activator-icon {
  flex-shrink: 0;
  color: #00A04F;
  width: 24px;
  height: 24px;
}
.video-modal-section .activator-outline__button {
  border-radius: 4px;
}
.video-modal-section .activator-outline__button .q-btn__wrapper {
  padding-left: 6px;
  padding-right: 6px;
}
.video-modal-section .video-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100000;
  padding: 40px;
}
.video-modal-section .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #0000003b;
  cursor: pointer;
}
.video-modal-section .modal-content {
  position: relative;
  width: 80%;
  max-width: 800px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  padding: 20px;
}
.video-modal-section .modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding: 10px 15px;
}
.video-modal-section .modal-header h3 {
  margin: 0;
  font-size: 1.5em;
  color: #333;
}
.video-modal-section .close-button {
  background: none;
  border: none;
  font-size: 18px;
  /* Smaller font size */
  font-weight: 300;
  /* Lighter font weight */
  line-height: 1;
  cursor: pointer;
  color: #333;
  transition: color 0.3s;
}
.video-modal-section .close-button:hover {
  color: #555;
  /* Slightly darker on hover for feedback */
}
.video-modal-section .modal-body {
  padding: 0;
}
.video-modal-section .modal-body iframe {
  width: 100%;
  height: 50vh;
  border: none;
}
.video-modal-section .modal-notes {
  padding: 15px;
  font-size: 1em;
  color: #666;
  font-weight: normal;
  text-align: center;
}
.video-modal-section .modal-link {
  text-align: center;
  color: var(--Primary-Blue, #256EFF);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.transcription .speaker--title {
  font-weight: 500;
  font-size: 14px;
}
.transcription ::-webkit-scrollbar-thumb {
  background-color: #256EFF !important;
  border-radius: 6px !important;
}
.transcription ::-webkit-scrollbar-thumb:hover {
  background-color: #256EFF !important;
  border-radius: 6px !important;
}
.transcription ::-webkit-scrollbar-track {
  background-color: #E0E0E0 !important;
  border-radius: 6px !important;
}
.transcription .chat-area {
  background: whitesmoke;
  max-height: 64vh;
  padding: 1em;
  margin: 0 auto 2em auto;
  border-radius: 14px;
  overflow: auto;
  flex-grow: 1;
}
.transcription .chat-area .sentiment {
  width: 45%;
}
.transcription .chat-area .sentiment-out {
  margin-left: 0% !important;
}
.transcription .chat-area .sentiment-in {
  margin-left: 53% !important;
}
.transcription .chat-area .sentiment-circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 6px;
}
.transcription .chat-area .sentiment-description {
  font-size: 12px;
  font-weight: 700;
}
.transcription .chat-area .message-box {
  width: 45%;
  border-radius: 10px;
  padding: 0.5em;
  font-size: 14px;
  margin: 0.5em 0;
  color: black;
  background-color: #85858511;
}
.transcription .chat-area .message-box-out {
  color: black !important;
}
.transcription .chat-area .message-box-in {
  color: black;
  margin-left: 53% !important;
}
.transcription .q-chip {
  height: 100%;
  max-height: 34px;
}
.transcription .q-chip__content .white-color {
  color: white;
}
.transcription .link {
  color: blue;
}

.loading-overlay {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.loading-icon {
  width: 250px;
  height: 250px;
}

.loading-text {
  font-size: 20px;
  font-weight: 500;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .intercom-lightweight-app {
    display: none !important;
  }
}
.trial-expired-icon {
  margin: 2rem;
  color: #d0d0d0;
  font-size: 120px;
}

thead.conflicted-contact-thead-class tr th {
  border-top: none;
  border-bottom-width: 1px;
}

.center-modal-header .modal-title {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.btn-tiny, .btn-group-tiny > .btn {
  padding: 2px 5px;
  font-size: 6px;
  line-height: 1.5;
}

.scrollable-tags-area {
  overflow-y: auto;
}

.dark-tooltip {
  background-color: #161922;
  color: #fff;
  font-size: 12px;
}

.custom-date-time-picker {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 10px;
}

#start-date-time-picker-wrapper, #end-date-time-picker-input {
  margin-right: 5px;
}

#start-date-time-picker-input, #end-date-time-picker-input {
  min-height: 32px;
  height: 32px;
  border-radius: 24px;
}

label[for=start-date-time-picker-input], label[for=end-date-time-picker-input] {
  top: -4px !important;
}

.btn-custom-date {
  display: flex;
}

.btn-custom-date .btn-apply {
  margin-right: 10px;
}

.time-picker-column {
  height: 100%;
}

@media (max-width: 768px) {
  .custom-date-time-picker {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  #start-date-time-picker-input, #end-date-time-picker-input {
    margin-bottom: 10px;
  }
}
.power-dialer-session-toggle-button {
  z-index: 1;
  margin-top: -14px !important;
  margin-bottom: -12px !important;
}

.select-campaign-dialog .label {
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  line-height: 15.73px;
  text-align: left;
  margin-bottom: 2px;
}
.select-campaign-dialog .q-dialog__backdrop {
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
}
.select-campaign-dialog .q-dialog__inner > div {
  border-radius: 8px 8px 0 0;
}
.select-campaign-dialog .card {
  width: 375px;
  height: 130px;
  padding: 36px 36px 0 36px;
}
.select-campaign-dialog .q-btn {
  border-radius: 6px;
  height: 40px;
  margin-left: 16px;
}
.select-campaign-dialog .q-btn__content {
  padding-right: 14px;
  padding-left: 14px;
  text-transform: capitalize;
}
.select-campaign-dialog .line-selector {
  width: 75%;
}
.select-campaign-dialog .inline-select.q-select--with-input {
  min-width: 75% !important;
}

.login-form-logo {
  max-width: 200px;
  height: auto;
}

.hubspot-widget-error-details {
  color: #000;
  padding: 0 12px;
}
.hubspot-widget-error-details h4 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}
.hubspot-widget-error-details p {
  margin: 0 0 1rem;
  font-size: 0.95rem;
}

.broadcast-warning-note {
  font-size: 12px;
  border-radius: 4px;
  margin-top: 16px;
  padding: 12px;
  border-width: 1px;
  border-style: solid;
  flex: 1 1 100%;
}
.broadcast-warning-note--warning {
  color: #7e641d;
  background-color: #fcf2d7;
  border-color: #fbedc7;
}
.broadcast-warning-note__title {
  color: #a17d1c;
  font-weight: bold;
  margin-bottom: 6px;
}

.contact-activity-container .contact-activities-hs-widget {
  min-height: 250px;
}
@media (max-height: 500px) {
  .contact-activity-container .contact-activities-hs-widget {
    height: 250px !important;
  }
}

.activity-bottom-info-hs-widget {
  min-height: 30px;
  height: auto;
  max-height: 100%;
  overflow-x: auto;
  overflow-y: auto;
}
@media (max-width: 500px) {
  .activity-bottom-info-hs-widget span {
    white-space: normal;
  }
}

.contact-view-wrapper-hs-widget {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-y: auto;
}
@media (max-width: 400px) {
  .contact-view-wrapper-hs-widget {
    width: 400px !important;
    overflow-x: auto;
  }
}

.text-menu-purple {
  color: #9797AE;
}